import React from "react";
import { useDebouncedValue } from "../../hooks/useDebounce";
import { searchMFAQWithSearchTerm } from "./useMFAQsService";

export function useSearchQuestions(
  inputText,
  setWhereFrom,
  whereFrom,
  questionDataRef,
  store_id
) {
  const [similarQuestions, setSimilarQuestions] = React.useState([]);
  const [shouldShowLoadMore, setShouldShowLoadMore] = React.useState(true);
  const debouncedInputText = useDebouncedValue(inputText, 1000);
  const prevWhereFrom = React.useRef(whereFrom);

  React.useEffect(() => {
    if (debouncedInputText === undefined) return;

    if (debouncedInputText === "") {
      setSimilarQuestions([]);
      return;
    }

    const updateQuestionsFromSearch = (matchingQuestions) => {
      setSimilarQuestions(matchingQuestions);
      setWhereFrom(0);
      prevWhereFrom.current = whereFrom;
    };

    const paginateQuestions = (matchingQuestions) => {
      setSimilarQuestions((current) => current.concat(matchingQuestions));
      prevWhereFrom.current = whereFrom;
    };

    const isPagination = prevWhereFrom.current !== whereFrom && whereFrom !== 0;

    if (!isPagination && debouncedInputText?.length < 3) return;

    let mounted = true;

    searchMFAQWithSearchTerm(
      debouncedInputText,
      undefined,
      questionDataRef.current?.area,
      store_id,
      isPagination ? whereFrom : 0
    ).then(({ matchingQuestions }) => {
      if (!mounted) return;
      isPagination
        ? paginateQuestions(matchingQuestions)
        : updateQuestionsFromSearch(matchingQuestions);
      matchingQuestions.length < 10
        ? setShouldShowLoadMore(false)
        : setShouldShowLoadMore(true);
    });

    return () => (mounted = false);
  }, [
    debouncedInputText,
    questionDataRef,
    setShouldShowLoadMore,
    setSimilarQuestions,
    setWhereFrom,
    store_id,
    whereFrom,
    //TODO: MFAQ validate this
  ]);

  return { similarQuestions, shouldShowLoadMore };
}
