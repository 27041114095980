import { useTranslation } from "@coworker/locales";
import useFormatter from "./useFormatter";
import useOrdinal from "./useOrdinal";
import dayjs from "dayjs";
import { recurringTaskOptions } from "@coworker/enums/recurringTaskOptions";
import { defaultTimezone } from "../constants/clientTime";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const formatRecursOn = (task, t, formatter) =>
  ({
    [recurringTaskOptions.DAILY]: t("dailyRepeatString"),
    [recurringTaskOptions.WEEKLY]:
      t("everyString") + " " + formatter(new Date(task?.next_repeat).getDay()),
    [recurringTaskOptions.MONTHLY]: t("everyMonthString"),
    // frequency was saved both in task. and in task.repeatable. This is for backwards compatibility.
  }[task?.frequency || task?.repeatable?.frequency] || t("unknownString"));

function useFormatRecurring(default_date = undefined) {
  const { t } = useTranslation();
  const { formatWeekday } = useFormatter();
  const toOrdinal = useOrdinal();

  const recurrenceStrings = (task) => ({
    timeAgo: dayjs().to(task.next_repeat),
    recursOn: formatRecursOn(task, t, formatWeekday.long),
  });

  const formatRecurring = (frequency, params = {}) => {
    const { timezone = defaultTimezone } = params;
    if (frequency === recurringTaskOptions.NONE) {
      return {
        frequency,
        name: t("doesNotRepeatString"),
      };
    }
    if (frequency === recurringTaskOptions.DAILY) {
      return {
        frequency,
        timezone,
        name: t("dailyRepeatString"),
      };
    }

    // Else prepare `date` - needed for monthly / weekly
    let date = dayjs(default_date);
    if (params && params.day_of_week) {
      date = date.add(params.day_of_week - date.day(), "days");
    }
    const day_of_week = date.day();

    switch (frequency) {
      case recurringTaskOptions.WEEKLY:
        return {
          frequency,
          day_of_week,
          timezone,
          name: `${t("weeklyRepeatString")} ${formatWeekday.long(day_of_week)}`,
        };
      case recurringTaskOptions.MONTHLY:
        const week_of_month =
          (params && params.week_of_month) || Math.ceil(date.date() / 7);
        return {
          frequency,
          day_of_week,
          week_of_month,
          timezone,
          name:
            // TODO: this translation hack works with almost all European languages.
            // Primarily: Irish & Hungarian likely requires a diffent word order for "last" vs 1st up to 4th
            t("monthlyRepeatString") +
            " " +
            (week_of_month === 5 ? t("lastString") : toOrdinal(week_of_month)) +
            " " +
            formatWeekday.long(day_of_week),
        };
      default:
        // NOTE: should only happen if we add a new frequency and the user is still using an old version of the app
        return {
          frequency,
          name: t("unknownString"),
          ...params,
        };
    }
  };

  return { formatRecurring, recurrenceStrings };
}

export { useFormatRecurring };
