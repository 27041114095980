import React from "react";
import FixaImage from "@coworker/apprestructured/src/shared/wrappers/FixaImage/FixaImage";
import { useUserAvatarQuery } from "@coworker/app/src/hooks/API/avatar.service";
import defaultPhotoUrl from "@coworker/app/src/assets/svg/generic-profile.svg";
import styles from "./avatar.module.css";

interface AvatarProps {
  isBigImage: boolean;
  fixaUid: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  isBigImage,
  fixaUid,
  className = "",
}) => {
  const avatarImage = useUserAvatarQuery(fixaUid);
  const avatarUrl = avatarImage
    ? URL.createObjectURL(avatarImage)
    : defaultPhotoUrl;
  return (
    <div className={className}>
      <FixaImage
        className={
          (isBigImage ? styles["big-image"] : styles["small-image"]) as string
        }
        src={avatarUrl}
      />
    </div>
  );
};
