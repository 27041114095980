import { perform } from "./tasks.service.helper";
import { testbuyServiceBaseUrl } from "@coworker/app/src/core/cloud-services/cloud-services.helper";
import { isChineseEnvironment, getEnvironmentId } from "@coworker/reusable";
import { makeServiceHeaders } from "../../core/hooks/fetch.helpers";

const isChina = isChineseEnvironment();

const TESTBUY_SERVICE_URL = testbuyServiceBaseUrl(getEnvironmentId(), isChina);

/***
 * @returns A promise which resolves when the request is done.
 * If you care about results then use the callback.
 */

export async function postToTestbuyService(
  path,
  bodyData,
  callback,
  responseType
) {
  const url = `${TESTBUY_SERVICE_URL}/${path}`;
  await perform("POST", url, bodyData, callback, responseType);
}

export async function getFromTestbuyService(path, callback) {
  const url = `${TESTBUY_SERVICE_URL}/${path}`;
  await perform("GET", url, null, callback);
}

// TODO: fetchAPI should replace perform eventually that is after global rollout which removes the firebase functions
export async function fetchAPI(path, method, body) {
  const url = `${TESTBUY_SERVICE_URL}/${path}`;
  try {
    const options = {
      method,
      headers: await makeServiceHeaders(),
    };

    if (method !== "GET") options.body = JSON.stringify(body);

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    const text = await response.text();
    return JSON.parse(text);
  } catch (error) {
    console.error("%s %s", url, error);
  }
}
