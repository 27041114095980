// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
// Enums
import taskTypeOptions from "@coworker/enums/taskType";
import {
  PRIORITY_WIDGET,
  REPEATABLE_WIDGET,
  CUSTOM_TITLE_WIDGET,
  ASSIGN_WIDGET,
  DESCRIPTION_WIDGET,
  PHOTOS_WIDGET,
  OPTIONAL_LOCATION_WIDGET,
  SCHEDULE_WIDGET,
} from "./_common";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  table: "tasks",
  type: "planned",
  enumType: taskTypeOptions.PLANNED,
  validate: (form) => {
    const potentialInvalids = [
      !form.assigned_team_id,
      !form.custom_title,
      !form.description,
      form.images?.some((image) => image.loading),
    ];
    return !potentialInvalids.reduce((invalid, curr) => invalid || curr, false);
  },
  fieldsFilledCount: (form) => {
    let count = 0;
    if (form.assigned_team_id) count++;
    if (form.custom_title) count++;
    if (form.description) count++;
    return count;
  },
  header: {
    newTitle: <Trans>customString</Trans>,
    editTitle: <Trans>editTaskString</Trans>,
  },
  fields: [
    CUSTOM_TITLE_WIDGET,
    DESCRIPTION_WIDGET(),
    ASSIGN_WIDGET,
    PRIORITY_WIDGET,
    OPTIONAL_LOCATION_WIDGET,
    REPEATABLE_WIDGET,
    SCHEDULE_WIDGET,
    PHOTOS_WIDGET,
  ],
};
