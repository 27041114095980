import React from "react";
import { useStoreId } from "../core/auth/useLoggedInUser";
import useCallUrl from "../hooks/API/useCallUrl";
import { parseProduct } from "../services/products.service";
import useFormatter from "../hooks/useFormatter";
const {
  extractDelivery,
  headersCIAv2,
  makeAvailabilitiesCIAurl,
} = require("@coworker/utils/cia");

type ExpectedDelivery = {
  nextDelivery: any;
  formattedDelivery: string | null;
  loading: boolean;
  error: string | undefined;
};

/**
 * Finds nextDelivery, if there is one.
 */
export function useExpectedDelivery(shortId: string): ExpectedDelivery {
  const itemNos = parseProduct(shortId)[0] || "";
  const storeId = useStoreId();
  const { formatDateShort } = useFormatter();
  const { data, loading, error } = useCallUrl(
    makeAvailabilitiesCIAurl(storeId, { itemNos, expand: "Restocks" }),
    { headers: headersCIAv2 },
    { blockRequest: !itemNos || !storeId }
  );

  return React.useMemo(() => {
    const nextDelivery = extractDelivery(data); // Assumes that `data` keeps being sorted by earliest date.
    const { earliestDate } = nextDelivery || {};
    return {
      nextDelivery,
      formattedDelivery: earliestDate ? formatDateShort(earliestDate) : null,
      loading,
      error,
    };
  }, [error, loading, data, formatDateShort]);
}
