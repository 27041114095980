import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CircleArrows } from "../../../assets/svg/icon-circlearrows.svg";
import { ReactComponent as ArrowForward } from "../../../assets/svg/arrow-small-forward.svg";
import { ReactComponent as Close } from "../../../assets/svg/icon-close-large.svg";
import useFormatter from "../../../hooks/useFormatter";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { Trans, useTranslation } from "@coworker/locales";
import { shallowEqual } from "../../../helpers/performance";
import {
  describeAreaFilter,
  areaIsWholeStore,
} from "../../InputPopup/UserFiltersRow/filterChoices";
import { useTestbuyTasks } from "../CommonComponents/Repositories/useTestbuyService";
import { ElidedText } from "@coworker/reusable/components/ElidedText";
import { testBuyCloseReasons } from "../TestBuyDetailPage/useTestbuyOptionsMenuTestbuyService";
import { GLOBAL_PASS_THRESHOLD } from "../../../constants/global";
import { COMPLETED_STATUS } from "../constants";
import { describeDivision } from "../../InputPopup/UserFiltersRow/filterChoices";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  box-shadow: inset 0px 1px 0px var(--grey200);
  &:last-child {
    box-shadow: 1px 0px 0px 1px var(--grey200);
  }
`;

const LeftIconContainer = styled.div`
  width: 69px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: 14px;
`;

const Subtitles = styled.span`
  color: var(--grey718);
`;

const RightIconContainer = styled.div`
  width: 50px;
  flex-grow: 0;
`;

const OrangeCircleArrows = styled(CircleArrows)`
  fill: var(--orange);
`;

const NumberIcon = styled.span`
  font-size: 16px;
  color: ${(p: { isOk: boolean }) =>
    p.isOk ? css`var(--green)` : css`var(--pink)`};
`;

interface TestBuyListItemProps {
  id: string;
  startDate: string;
  numberOfArticles: number;
  numberOfSucceededArticles: number;
  customName?: string | undefined;
  isMine?: boolean;
  isClosed?: boolean;
  reasonForClosing?: string;
  area: string;
  division: string | undefined;
}

interface SubtitleProps {
  numberOfArticles: number;
  numberOfSucceededArticles: number;
  numberOfTasks: number;
  numberOfCompletedTasks: number;
  isMine: boolean;
  isSuccessful: boolean;
  isClosed: boolean;
  reasonForClosing?: string;
  area: string | JSX.Element;
}

const translateReasonForClosing = (reason: string): JSX.Element => {
  const translationMap = new Map(
    testBuyCloseReasons.map((closeReason) => [
      closeReason.type,
      closeReason.name,
    ])
  );
  return translationMap.get(reason) || <>{reason}</>;
};

const SubtitleInner: React.FC<SubtitleProps> = ({
  numberOfArticles,
  numberOfSucceededArticles,
  numberOfTasks,
  numberOfCompletedTasks,
  isMine,
  isClosed,
  reasonForClosing,
  area,
}) => {
  const numberOfFailedArticles = numberOfArticles - numberOfSucceededArticles;
  let element;

  if (isMine) {
    element = (
      <>
        <Trans values={{ count: numberOfArticles, area }}>
          articlesInAreaString
        </Trans>
        <br />
        <Trans>onlyVisibleToYouString</Trans>
      </>
    );
  } else if (isClosed) {
    element = (
      <>
        <Trans>closedFirstUppercaseString</Trans>
        {": "}
        {translateReasonForClosing(reasonForClosing || "")}
        <br />
        {numberOfTasks > 0 && (
          <Trans count={numberOfTasks} values={{ count: numberOfTasks }}>
            tbTasksCreatedString
          </Trans>
        )}
      </>
    );
  } else {
    element = (
      <>
        <Trans
          values={{
            count: numberOfSucceededArticles,
            total: numberOfArticles,
          }}
        >
          someProductsSucceededString
        </Trans>
        <br />
        <>
          {numberOfFailedArticles !== 0 && (
            <Trans
              count={numberOfCompletedTasks}
              values={{ count: numberOfCompletedTasks }}
            >
              tbTasksCompletedString
            </Trans>
          )}
          {numberOfCompletedTasks < numberOfFailedArticles && (
            <>
              {", "}
              <Trans
                count={numberOfFailedArticles - numberOfCompletedTasks}
                values={{
                  count: numberOfFailedArticles - numberOfCompletedTasks,
                }}
              >
                tbTasksPendingString
              </Trans>
            </>
          )}
        </>
      </>
    );
  }

  return <Subtitles>{element}</Subtitles>;
};

const SubtitleMemo = React.memo(SubtitleInner, (prevProps, nextProps) =>
  shallowEqual(prevProps, nextProps)
);

export const TestBuyItem: React.FC<TestBuyListItemProps> = ({
  id,
  isMine,
  isClosed,
  reasonForClosing,
  numberOfArticles,
  numberOfSucceededArticles,
  startDate,
  area,
  customName,
  division,
}) => {
  const { formatDateLong } = useFormatter();
  const { push } = useWorkspacesAction();
  const tasks = useTestbuyTasks(id);
  const { t } = useTranslation();

  const successPercentage = Math.round(
    (numberOfSucceededArticles / numberOfArticles) * 100
  );

  const isSuccessful = successPercentage >= GLOBAL_PASS_THRESHOLD;

  let leftIcon;
  let dataType;
  if (isMine) {
    leftIcon = <OrangeCircleArrows />;
    dataType = "tb-active";
  } else if (isClosed) {
    leftIcon = <Close />;
    dataType = "tb-closed";
  } else {
    leftIcon = (
      <NumberIcon isOk={isSuccessful}>{successPercentage}%</NumberIcon>
    );
    dataType = "tb-completed";
  }

  const divisionName = division && describeDivision(division);

  const getDivionOrWholeStore = (area: string) => {
    return areaIsWholeStore(area)
      ? division
        ? t(`${divisionName}`)
        : t("wholeStoreString")
      : describeAreaFilter([area]);
  };

  return (
    <ItemContainer
      data-testid={`tblistitem-${id}`}
      onClick={() => !isClosed && push(`/testbuy/${id}`)}
      data-type={dataType}
    >
      <LeftIconContainer>{leftIcon}</LeftIconContainer>
      <TextContainer>
        <ElidedText>
          <b>
            {formatDateLong(startDate)}
            {!!customName ? ` - ${decodeURIComponent(customName)}` : ""}
          </b>
        </ElidedText>
        <SubtitleMemo
          isMine={!!isMine}
          isSuccessful={isSuccessful}
          isClosed={!!isClosed}
          reasonForClosing={reasonForClosing || ""}
          numberOfArticles={numberOfArticles}
          numberOfSucceededArticles={numberOfSucceededArticles}
          numberOfTasks={tasks?.length || 0}
          numberOfCompletedTasks={
            tasks?.filter(
              (task) =>
                task.state === COMPLETED_STATUS || task.state === "CLOSED"
            ).length
          }
          area={getDivionOrWholeStore(area)}
        />
      </TextContainer>
      <RightIconContainer>{!isClosed && <ArrowForward />}</RightIconContainer>
    </ItemContainer>
  );
};
