import React from "react";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

const PopoverContainer = styled.div`
  z-index: 102;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 194px;
  background: var(--white);
  & > div {
    padding: 39px 20px 27px 19px;
  }
  & h1 {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    color: var(--grey900);
  }
  & h2 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--grey700);
    margin-bottom: 27px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const FullWidthButton = styled(FixaButton)`
  flex: 1;
`;

function ConfirmationPrompt(
  {
    onConfirm,
    onDeny,
    question,
    description,
    negativeText,
    positiveText,
    swapButtons,
  },
  ref
) {
  return (
    <PopoverContainer ref={ref} data-testid="confirmationPrompt">
      <div>
        <h1>{question}</h1>
        <h2>{description}</h2>
        <ButtonContainer>
          {
            // swapButtons is required, since we need different order of buttons in Out of stock Warning
            swapButtons ? (
              <>
                <FullWidthButton
                  data-testid="positive"
                  secondary
                  dark
                  text={positiveText}
                  onClick={onConfirm}
                />
                <FullWidthButton
                  data-testid="negative"
                  primary
                  dark
                  text={negativeText}
                  onClick={onDeny}
                />
              </>
            ) : (
              <>
                {negativeText && (
                  <FullWidthButton
                    data-testid="negative"
                    secondary
                    dark
                    text={negativeText}
                    onClick={onDeny}
                  />
                )}
                <FullWidthButton
                  data-testid="positive"
                  primary
                  dark
                  text={positiveText}
                  onClick={onConfirm}
                />
              </>
            )
          }
        </ButtonContainer>
      </div>
    </PopoverContainer>
  );
}

export default React.forwardRef(ConfirmationPrompt);
