//@ts-ignore
import { shoppingtoolsServiceBaseUrl } from "@coworker/app/src/core/cloud-services/cloud-services.helper";
import { getTokenAsync } from "../../hooks/API/getAuthToken";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import {
  DeleteShoppingToolsBody,
  FetchChartDataBody,
  ShoppingToolsBody,
  ShoppingToolsCount,
} from "./Types";
const isChina = isChineseEnvironment();

export const SHOPPINGTOOLS_SERVICE_URL = shoppingtoolsServiceBaseUrl(
  getEnvironmentId(),
  isChina
);

const makeHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
});

/***
 * @returns A promise which resolves when the request is done.
 * If you care about results then use the callback.
 */
async function perform<TResponse>(
  method: string,
  url: RequestInfo | URL,
  bodyData: ShoppingToolsBody | ShoppingToolsCount | FetchChartDataBody,
  callback?: (arg0: TResponse) => TResponse
) {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const params = {
    method,
    headers: makeHeaders(token),
    body: JSON.stringify(bodyData),
  };
  if (bodyData && "GET" !== method) params.body = JSON.stringify(bodyData);
  const endpoint = `${SHOPPINGTOOLS_SERVICE_URL}/${url}`;
  const response = await fetch(endpoint, params);
  let result;
  // Since `await response.json()` may fail, we do it the safe way:
  const text = await response.text();
  try {
    result = JSON.parse(text);
  } catch {}
  if (result && callback) {
    callback(result);
  }
}

export async function postToShoppingToolsService<TResponse>(
  url: string,
  bodyData: ShoppingToolsBody | ShoppingToolsCount | FetchChartDataBody,
  callback?: (data: TResponse) => TResponse
) {
  await perform("POST", url, bodyData, callback);
}
export async function deleteToShoppingToolsService<TResponse>(
  url: string,
  bodyData: DeleteShoppingToolsBody,
  callback?: (data: TResponse) => TResponse
) {
  await perform("DELETE", url, bodyData, callback);
}
