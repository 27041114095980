import React from "react";
import { updateTask } from "../../services/task.service";
import taskStateOptions from "@coworker/enums/taskState";
import activityTypes from "@coworker/enums/activityTypes";
import trackerHelper from "../../helpers/tracker";
import useFlag, { FLAGS } from "../useFlag";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useTaskRefresher } from "../useWatchedTasks";

export const usePickUpTask = (task, taskId) => {
  const uid = useUserId();
  const storeId = useStoreId();

  const enablePickupFunction = useFlag(FLAGS.ENABLE_PICKUP_FUNCTION);

  const refreshTask = useTaskRefresher();

  const pickUpTask = React.useCallback(async () => {
    if (!uid) return;
    const actionId = `pickup-${new Date().getTime()}`;

    const data = {
      last_editor_id: uid,
      assigned_user_id: uid,
      state: taskStateOptions.ASSIGNED,
      activity_type: activityTypes.PICK_UP,
      action_id: actionId,
      // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
      undo_action_id: "",
    };

    const timeToPickup = new Date().getTime() - task.created_at;

    if (!task.first_pickup_at) {
      data.first_pickup_at = new Date().getTime();
      trackerHelper.trackPickUpTask({
        time_to_pickup: timeToPickup,
        task_id: taskId,
      });
    }

    if (task.first_pickup_at) {
      data.last_pickup_at = new Date().getTime();
      const timeFromPickup = new Date().getTime() - task.first_pickup_at;

      trackerHelper.trackPickUpTask({
        time_to_pickup: timeToPickup,
        time_from_pickup: timeFromPickup,
        task_id: taskId,
      });
    }

    if (!enablePickupFunction) {
      await updateTask(data, taskId, "", storeId);
      refreshTask(taskId, data, task);
      return;
    }
  }, [uid, taskId, task, enablePickupFunction, refreshTask, storeId]);

  return {
    call: pickUpTask,
  };
};
