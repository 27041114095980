import { analytics } from "@coworker/common/config/firebase";
import { gaService } from "@coworker/common/services/google-analytics.service";
import { virtualStoreRegex } from "./filters";

let lastScanned, lastFound, lastFailed, scannerLastCode; // TODO: remove after we fix half-sheet rerenders / scanner stops emitting duplicate on barcode scanned for a single camera scan event.
let hashResolve = null;

function createHashPromise() {
  return new Promise((resolve) => {
    hashResolve = resolve;
  });
}

let hashPromise = createHashPromise();

export async function setUserId(userId, hashedUid) {
  let hashedUserId;
  if (process.env.REACT_APP_ENV !== "ci") {
    hashedUserId = hashedUid;
    await analytics?.setUserId(hashedUserId);
    await gaService.setGAUserId(hashedUserId);
    return hashResolve();
  }
}

export function setUserStore(store) {
  analytics && analytics.setUserProperties({ store: `store-${store}` });
  return gaService.setGAUserProperties({ store: `store-${store}` });
}

export function setStartedAtVersion(started_at_version) {
  analytics && analytics.setUserProperties({ started_at_version });
  return gaService.setGAUserProperties({ started_at_version });
}

export function setCountry(country) {
  analytics && analytics.setUserProperties({ country });
  return gaService.setGAUserProperties({ country });
}

export function resetUserId() {
  hashPromise = createHashPromise();
  analytics && analytics.setUserId(null);
  return gaService.setGAUserId(null);
}

/**
 * Wrapper function that checks if the analytics object exists and then logs the
 * event.
 *
 * @param {String} eventName
 * @param {Object} eventParams
 */
function logEvent(eventName, eventParams = {}) {
  if (window.debugLogEvent) console.log("GA logEvent", eventName, eventParams);
  return hashPromise.then(() => {
    analytics && analytics.logEvent(eventName, eventParams);
    gaService.logEvent(eventName, eventParams);
  });
}

const trackerHelper = {
  trackLogin: () => logEvent("ce:login"),
  trackLogout: () => logEvent("ce:logout"),
  trackPageView: (pathname) =>
    logEvent("page_view", { page_location: pathname }),

  trackUserFeedback(data) {
    const { feedback, feedback_description } = data;
    logEvent("ce:submit_feedback", { feedback, feedback_description });
  },

  trackAskMeLater: () => logEvent("ce:feedback_ask_me_later"),

  trackOutboundLink: ({ type, name, url }) =>
    logEvent("ce:outbound_click", { type, name, url }),

  trackInstructionsComplete: (type) =>
    logEvent("ce:instructions_complete", { type }),

  trackCamera(duration, code) {
    // This typically logs 2+ events per actual camera scan, and we should instead use ce:barcode:scan
    logEvent("ce:scan_barcode", { duration });

    if (lastScanned === code) return;
    logEvent("ce:barcode:scan", { duration });
    lastScanned = code;
  },

  trackSearch: (duration) => logEvent("ce:scan_search", { duration }),

  trackCreateTask(task) {
    const {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      task_id,
      state,
      assigned_status,
    } = task;

    logEvent("ce:create_task", {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      task_id,
      state,
      assigned_status,
    });
  },

  trackCreateTaskAbandon(task_type, response, fieldsFilledCount) {
    logEvent("ce:create_task_abandon", {
      task_type,
      response,
      fieldsFilledCount,
    });
  },

  trackCreateAdditionalTestBuy(response, testBuyId) {
    logEvent("ce:create_additional_test_buy", {
      response,
      testBuyId,
    });
  },

  trackMaxAmountExceeded(data) {
    const { warningShown, popupShown, popupSelection } = data;

    logEvent("ce:max_amount_exceeded", {
      warning_shown: warningShown,
      popup_shown: popupShown,
      popup_selection: popupSelection,
    });
  },

  trackPickUpTask({ time_to_pickup, time_from_pickup, task_id }) {
    logEvent("ce:pickup_task", { time_to_pickup, time_from_pickup, task_id });
  },

  trackPickAddonTask(
    data,
    with_changes = false,
    location_change = false,
    quantity_change = false
  ) {
    const { task_id, requested_quantity, pick_quantity } = data;
    logEvent("ce:pick_addon_task", {
      task_id,
      requested_quantity,
      pick_quantity,
      with_changes,
      location_change,
      quantity_change,
    });
  },

  trackNotificationScreenItemClick: (event_type) =>
    logEvent("ce:notification_screen_item_click", { event_type }),

  trackRefillAddonTask({ task_id }, with_changes = false) {
    logEvent("ce:refill_addon_task", {
      task_id,
      with_changes,
    });
  },
  accordionTracker: () => {
    logEvent("ce:location:accordion");
  },
  trackNotificationCTAClick(event_type = false) {
    logEvent("ce:push_notification_cta_click", { event_type });
  },

  trackCompleteTask(
    task,
    {
      with_changes = false,
      create_and_complete = false,
      pick_and_refill = false,
    }
  ) {
    const {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      task_id,
      requested_quantity,
      refilled_quantity,
      pick_quantity,
    } = task;

    logEvent("ce:complete_task", {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      with_changes,
      task_id,
      create_and_complete,
      pick_and_refill,
      requested_quantity,
      refilled_quantity,
      pick_quantity,
    });
  },

  trackCloseTask(task, issue) {
    const {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      picked,
      task_id,
    } = task;

    logEvent("ce:close_task", {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      picked,
      task_id,
      issue,
    });
  },

  trackDropTask(task, issue) {
    const {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      picked,
      task_id,
    } = task;

    logEvent("ce:drop_task", {
      task_type,
      custom_title,
      store_id,
      issue_type,
      priority_flag,
      picked,
      task_id,
      issue,
    });
  },

  trackPickupLocationChange: () => logEvent("ce:pickup_location_changed"),
  trackReassignTask: (id) => logEvent("ce:task_reassigned", { id }),
  trackHandoffTask: (id, user, group) =>
    logEvent("ce:task_handoff", { id, user, group }),

  trackFeedbackOnHome: () => logEvent("ce:feedback:question_opened_from_home"),
  trackPIPEntered: () => logEvent("ce:pip_entered"),
  trackPIPToInsights: (product_id) =>
    logEvent("ce:from_pip_to_insights", { product_id }),

  trackUndo: (undone_action) => logEvent("ce:undo", { undone_action }),
  trackDismissModal: (usingX) => logEvent("ce:dismiss_modal", { usingX }),

  task: {
    trackMarkAsCompleteToggle(state) {
      logEvent("ce:mark_as_complete_toggled", { state });
    },
    trackCompletePickingOnlyToggle(state) {
      logEvent("ce:complete_picking_only_toggled", { state });
    },
    trackKeepTaskWithMeClick() {
      logEvent("ce:keep_task_with_me_clicked");
    },
    trackNearbyAddonAddClick() {
      logEvent("ce:nearby_addon_add_clicked");
    },
    trackMissingNearbyAddonAddClick() {
      logEvent("ce:missing_nearby_addon_add_clicked");
    },
    trackAssignToMyTeamOpenedToggle(state) {
      logEvent("ce:assign_to_my_team_foldout_toggled", { state });
    },
  },

  insights: {
    trackDepartmentTab: (tab) =>
      logEvent("ce:insights:department_tab", { tab }),
    trackStoreBox: () => logEvent("ce:insights:click_storeBox"),
    trackExport: (filters) => logEvent("ce:insights:export", { filters }),
    trackProductSearch: (from) =>
      logEvent("ce:insights:product_insights_search", { from }),
    trackProductIdNot8Digits: (product_id) =>
      logEvent("ce:insights:productid_not_8_digits", { product_id }),

    store: {
      trackToggle(name, type) {
        logEvent("ce:insights:store:toggle", { name, type });
      },
      trackSeeAll(name, type) {
        logEvent("ce:insights:store:click_see_all", { name, type });
      },
      trackSeeProduct(name, type, product) {
        logEvent("ce:insights:store:click_product", { name, type, product });
      },
    },

    corrections: {
      enableCorrections() {
        logEvent("ce:insights:correction:enable");
      },
      openCorrectionsList(key) {
        logEvent("ce:insights:correction:open", { key });
      },
      startEditing(data) {
        logEvent("ce:insights:correction:edit", data);
      },
    },
    trackHomeAwayInfo(level) {
      logEvent("ce:insights:click_home_away_info", { level });
    },
    trackHFBLinkTap(type, hfb) {
      logEvent("ce:insights:tap_home_hfb", { type, hfb });
    },
    trackChangeSecondLevelShare(type) {
      logEvent("ce:insights:second_level:change_share", { type });
    },
    trackChangeHomeValueToShow(value) {
      logEvent("ce:insights:home_value_to_show", { value });
    },
  },

  top3TaskCard: (data) => logEvent("ce:top3TaskCard", data),

  taskSwipeButtons: {
    trackComplete: () => logEvent("ce:taskSwipeButtons:complete"),
    trackCompleteWithChanges: () =>
      logEvent("ce:taskSwipeButtons:complete_with_changes"),
    trackOpenMenu: () => logEvent("ce:taskSwipeButtons:open_menu"),
    trackPickup: () => logEvent("ce:taskSwipeButtons:pickup"),
  },

  changeTeam: {
    confirm: (value) => logEvent("ce:changeTeam:confirm", { value }),
    viewTeamList: () => logEvent("ce:changeTeam:viewTeamList"),
  },

  plannedTasks: {
    openPopup: () => logEvent("ce:plannedTasks:openPopup"),
    created(planned) {
      logEvent("ce:plannedTasks:created", {
        recurring: planned.recurring,
        daysOfWeek: planned.days_of_week?.length ?? 0,
        everyNWeeks: planned.every_n_weeks ?? 0,
      });
    },
    submitted(planned) {
      logEvent("ce:plannedTasks:submitted", {
        recurring: planned.recurring,
        daysOfWeek: planned.days_of_week?.length ?? 0,
        everyNWeeks: planned.every_n_weeks ?? 0,
      });
    },
    linkToParent: () => logEvent("ce:plannedTasks:detail:linkToParent"),
    formClickX: () => logEvent("ce:plannedTasks:form:clickX"),
  },

  barcode: {
    logErrorUseStream(e) {
      logEvent("ce:barcode:error:usestream", {
        message: e.message,
        stack: e.stack,
      });
    },
    logErrorTrackTorch(e) {
      logEvent("ce:barcode:error:tracktorch", {
        message: e.message,
        stack: e.stack,
      });
    },
    logItemNotFound(code, store) {
      if (!code || lastFailed === code || lastFound === code) return;
      const store_id = store.split(virtualStoreRegex)[0];
      logEvent("ce:barcode:itemnotfound", { code, store_id });
      lastFailed = code;
    },
    logErrorVideoPlay(e) {
      logEvent("ce:barcode:debug:errorvideoplay", {
        message: e.message,
        stack: e.stack,
      });
    },

    logRDTCaptured(code, isValid) {
      if (!code || lastFound === code) return;
      logEvent("ce:barcode:debug:rdt_captured", { code, isValid });
      lastFound = code;
    },

    // This gets called on capture of any barcode, but ONLY if flag BARCODE_DEBUG is enabled.
    logCaptured(code, isValid) {
      if (!code || lastFound === code) return;
      logEvent("ce:barcode:debug:captured", { code, isValid });
      lastFound = code;
    },

    logScannerType(type, code) {
      if (!code || scannerLastCode === code) return;
      logEvent("ce:scanner:type", { type });
      scannerLastCode = code;
    },

    logZoomUsage(multiplier) {
      logEvent("ce:zoom_slider", { multiplier });
    },
  },

  // Pin lock for shared devices
  pinLock: {
    logSet: () => logEvent("ce:pin_lock:set"),
    logReset: () => logEvent("ce:pin_lock:reset"),
    logNonDigitEntered: () => logEvent("ce:pin_lock:not_numeric"),
    logResetRequested: () => logEvent("ce:pin_lock:reset_requested"),
    logCloseReset: () => logEvent("ce:pin_lock:close_reset"),
  },

  safetyAlarmInfo: {
    open: () => logEvent("ce:safety_alarm_info:open"),
    closeX: () => logEvent("ce:safety_alarm_info:close_x"),
    closeOK: () => logEvent("ce:safety_alarm_info:close_ok"),
  },

  releaseNotes: {
    logDismiss: () => logEvent("ce:release_notes:dismiss"),
    logView: () => logEvent("ce:release_notes:view"),
  },

  multipleSelect: {
    logCancel(selectedTaskCount) {
      logEvent("ce:multiple_select:stop_cancel_click", { selectedTaskCount });
    },
    logProcessed(selectedTaskCount, failedCount, action_type) {
      logEvent("ce:multiple_select:tasks_processed", {
        selectedTaskCount,
        failedCount,
        action_type,
      });
    },
    logMultiselectIconClicked() {
      logEvent("ce:multiple_select:start_icon_click");
    },
    logMultiselectLongpress() {
      logEvent("ce:multiple_select:start_longpress");
    },
  },

  filtersButtonTracker: () => {
    logEvent("ce:tasks:filters_button");
  },

  TasksFilters: {
    trackFilters({
      sortOrder,
      selectedGroupFilter,
      checked,
      checkedLocation,
      locationMenu,
    }) {
      const active = window.location.href.match(/active=(\S+)/)?.[1];
      logEvent("ce:tasks:filters", {
        active, // open or my
        sortOrder,
        selectedGroupFilter,
        checked,
        checkedLocation,
        locationMenu,
      });
    },
  },

  UserFilters: {
    trackFilterChange(store_id, filters, nextFilters) {
      logEvent("ce:mfaq:filter_change", { store_id, filters, nextFilters });
    },
  },

  MFAQ: {
    trackCreateQuestion(question) {
      const { store_id, type, area, question_text, subject } = question;
      logEvent("ce:mfaq:create_question", {
        store_id,
        type,
        area,
        question_text,
        subject,
      });
    },
    trackVote(question_id, store_id, value, vote_location) {
      logEvent("ce:mfaq:track_vote", {
        question_id,
        store_id,
        value,
        vote_location,
      });
    },
    trackNewQuestionNotSubmitted(question) {
      const { store_id, type, area, question_text, subject } = question;
      logEvent("ce:mfaq:create_question_not_submitted", {
        store_id,
        type,
        area,
        question_text,
        subject,
      });
    },
    trackExport(filters) {
      logEvent("ce:mfaq:export", { filters });
    },
    trackTaskResolutionWidget(question_text, question_area) {
      logEvent("ce:mfaq:resolution_widget_clicked", {
        question_text,
        question_area,
      });
    },
    trackMenuClick3Dots() {
      logEvent("ce:mfaq:menu_click_3dots");
    },
    trackEditQuestion(question_text, question_id) {
      logEvent("ce:mfaq:edit_question", {
        question_text,
        question_id,
      });
    },
    trackMergeQuestion(question_text, question_id) {
      logEvent("ce:mfaq:merge_question", {
        question_text,
        question_id,
      });
    },
    trackMergeButton(firstQuestionId, secondQuestionId) {
      logEvent("ce:mfaq:merge_button", {
        firstQuestionId,
        secondQuestionId,
      });
    },
    trackMergeNotPossible(firstQuestionId, secondQuestionId) {
      logEvent("ce:mfaq:merge_not_possible", {
        firstQuestionId,
        secondQuestionId,
      });
    },
  },

  taskEfficiency: {
    trackTeamChange() {
      logEvent("ce:task_efficiency:team_change");
    },
    trackNearbyAddonAdded() {
      logEvent("ce:task_efficiency:nearby_addon_added");
    },
    trackWarnDuplicate() {
      logEvent("ce:task_efficiency:warn_duplicate");
    },
    trackWarnOutOfStock() {
      logEvent("ce:task_efficiency:warn_out_of_stock");
    },
    trackCreateOutOfStockProductIssue() {
      logEvent("ce:task_efficiency:create_out_of_stock_product_issue");
    },
  },

  livlig: {
    trackLinkDisplay(store_id, product_id, feature) {
      logEvent("ce:livlig:track_link_display", {
        store_id,
        product_id,
        feature,
      });
    },
    trackLinkClick(store_id, product_id, feature) {
      logEvent("ce:livlig:track_link_click", {
        store_id,
        product_id,
        feature,
      });
    },
  },

  insightStoreOverview: {
    trackBlueBannerSeen() {
      logEvent("ce:insight_store_overview:blue_banner_seen");
    },
    trackBlueBannerClicked() {
      logEvent("ce:insight_store_overview:blue_banner_clicked");
    },
    trackLinkClicked(link) {
      logEvent("ce:insight_store_overview:link_clicked", { link });
    },
    trackGotItClicked() {
      logEvent("ce:insight_store_overview:got_it_clicked");
    },
  },

  trackCreateTaskInEmptyState: () => {
    logEvent("ce:task_preview_createtask_button:clicked");
  },

  trackMyOpenTasksPreview: (tabName) => {
    logEvent("ce:my_open_tasks_card_preview:clicked", {
      section: tabName,
      is_from_home_page: true,
    });
  },

  trackOpenTasksPreview: (tabName) => {
    logEvent("ce:open_tasks_card_preview:clicked", {
      section: tabName,
      is_from_home_page: true,
    });
  },

  trackTutorials: (type) => {
    logEvent("ce:tutorials:open", {
      tutorialsType: type,
    });
  },

  trackQuickActionsButton: (type) => {
    logEvent(`ce:quick_actions:open`, { type: type });
  },

  trackTaskCardPreview: (tabName) => {
    logEvent("ce:task_card_preview:clicked", { section: tabName });
  },

  trackTaskCardSwipeActionButtons: (buttonName, wasSwiped) => {
    logEvent("ce:swipeale_task_card_swipe_action_buttons:open", {
      was_swiped: wasSwiped,
      button_name: buttonName,
    });
  },

  trackTaskDetailActionButtons: (buttonName) => {
    logEvent("ce:task_detail_page_swipe_action_buttons:open", {
      button_name: buttonName,
    });
  },

  trackTaskCardClickedFromTab: (tabName, taskId) => {
    logEvent("ce:task_card:clicked", {
      clicked_from_tab: tabName,
      task_id: taskId,
    });
  },

  trackTaskCompletion: (buttonName, hasChanges) => {
    logEvent("ce:complete_task_button:clicked", {
      button_name: buttonName,
      has_changes: hasChanges,
    });
  },

  trackMultiSelectButtonTab: (tabName) => {
    logEvent("ce:multi_select_button:clicked", {
      clicked_from_tab: tabName,
    });
  },

  trackFilterApplyButton: () => {
    logEvent("ce:filter_apply_button:clicked");
  },

  trackFilterResetButton: () => {
    logEvent("ce:filter_reset_button:clicked");
  },

  trackProfileButtonClick: () => {
    logEvent("ce:profile_button:clicked");
  },

  trackNotificationButtonClick: () => {
    logEvent("ce:notification_bell:clicked");
  },

  trackSearchButtonClick: () => {
    logEvent("ce:search_button:clicked");
  },

  trackButtomNavigationClick: (buttonName) => {
    logEvent("ce:bottom_navigation:clicked", {
      button_name: buttonName,
    });
  },

  trackMainHeaderNavigationLinksClick: (linkName) => {
    logEvent("ce:main_header_navigation_links:clicked", {
      link_name: linkName,
    });
  },

  trackTutorialsNextButtonClick: (tutorialName) => {
    logEvent("ce:tutorials_next_button:clicked", {
      tutorial_name: tutorialName,
    });
  },

  trackNamingConventionPopupButton: () => {
    logEvent("ce:naming_convention_popup_button:clicked");
  },

  trackAddCustomLocationButtonClick: () => {
    logEvent("ce:add_custom_location_button:clicked");
  },

  trackCutomLocationItemClick: (locationName) => {
    logEvent("ce:custom_location_item:clicked", {
      location_name: locationName,
    });
  },

  trackSLMLocationItemClick: (locationName) => {
    logEvent("ce:SLM_location_item:clicked", {
      location_name: locationName,
    });
  },

  trackHejComponentSquareGridIconClick: () => {
    logEvent("ce:hej_component_square_grid_icon");
  },

  trackHejComponentEvent: (eventName, params) => {
    // notice that it's not ce: as Hej can pass any event through here
    logEvent("hej_app_switcher_event", {
      custom_title: eventName,
      link: params[0]?.url ? params[0].url : "",
      state: params[1],
    });
  },

  trackPQRDescriptionCopyButton: () => {
    logEvent("ce:copy_PQR_description_button:clicked");
  },

  trackActivityAllFiltersButtonClick: () => {
    logEvent("ce:activity_all_filters_button:clicked");
  },

  trackActivityDropdownPillClick: (type) => {
    logEvent("ce:activity_dropdown_pill:clicked", {
      type: type,
    });
  },

  trackActivityDropdownPillValueClick: (type, value) => {
    logEvent("ce:activity_dropdown_pill_value:clicked", {
      type: type,
      value: value,
    });
  },

  trackActivitySelectablePillClick: (type) => {
    logEvent("ce:activity_selectable_pill:clicked", {
      type: type,
    });
  },

  trackActivityAppliedFilterPillClick: () => {
    logEvent("ce:activity_applied_filter_pill:clicked");
  },

  trackActivityTabClick: (tab) => {
    logEvent("ce:activity_tab:clicked", {
      type: tab,
    });
  },

  trackActivityTaskCardClick: () => {
    logEvent("ce:activity_task_card:clicked");
  },

  trackActivityResetFilterButtonClick: () => {
    logEvent("ce:activity_reset_filter_button:clicked");
  },

  trackActivityApplyFilterButtonClick: () => {
    logEvent("ce:activity_apply_filter_button:clicked");
  },

  trackActivityFilterPanelClick: (type, value) => {
    logEvent("ce:activity_filter_panel:clicked", {
      type: type,
      value: value,
    });
  },

  trackDiscoverNowClick: () => {
    logEvent("ce:discover_now:clicked");
  },

  trackUpptackaSetUpHelpClick: () => {
    logEvent("ce:upptacka_set_up_help:clicked");
  },

  trackUpptackaCreateTaskClick: () => {
    logEvent("ce:upptacka_create_task:clicked");
  },
};

export default trackerHelper;
export { logEvent };
