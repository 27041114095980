export function getActivityLocationsUrl(
  taskType: string,
  addonLocationType: string
) {
  const baseUrl = "locations/allfromtasks";
  let queryParams = [];

  if (taskType && taskType !== "all") {
    queryParams.push(`taskType=${taskType}`);
  }

  if (addonLocationType) {
    queryParams.push(`addonLocationType=${addonLocationType}`);
  }

  return `${baseUrl}${queryParams.length ? "?" + queryParams.join("&") : ""}`;
}
