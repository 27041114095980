import React from "react";
import ArrowRightFromBaseIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ArrowRightFromBaseIconPath";
import {
  useGivenName,
  useSurname,
  useRoleId,
  useFixaUID,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { capitalizeFirst } from "@coworker/app/src/hooks/useFormatter";
// @ts-ignore
import { displayRoleTranslationString } from "@coworker/common/helpers/user.helper";
import styles from "./profile-header.module.css";
import { Trans } from "@coworker/locales";
import { Avatar } from "../../../../shared/components/Avatar/Avatar";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { FixaText } from "../../../../shared/wrappers/FixaText/FixaText";
import FixaButton from "../../../../shared/wrappers/FixaButton/FixaButton";

export const ProfileHeader: React.FC = () => {
  const { push } = useWorkspacesAction();
  const firstName = useGivenName();
  const lastName = useSurname();
  const roleId = useRoleId();
  const fixaUid = useFixaUID();
  const fullName = [firstName, lastName].map(capitalizeFirst).join(" ");
  React.useState(false);
  const onLogout = async () => {
    // resetTaskListFilters();
    push("/logout");
  };
  return (
    <div className={styles["profile-header"]}>
      <div>
        <Avatar isBigImage={true} fixaUid={fixaUid}></Avatar>
      </div>
      <div className={styles["profile-header-info"]}>
        <FixaText tagName="h2" headingSize="m">
          {fullName}
        </FixaText>
        <FixaText
          tagName="h3"
          headingSize="xs"
          className={styles["text-gap"] as string}
        >
          <Trans>{displayRoleTranslationString(roleId)}</Trans>
        </FixaText>
        <FixaButton
          type="secondary"
          key="logOutButton"
          text={<Trans>logoutString</Trans>}
          size="small"
          fluid={true}
          onClick={onLogout}
          ssrIcon={ArrowRightFromBaseIconPath}
          iconPosition="trailing"
        ></FixaButton>
      </div>
    </div>
  );
};
