import React from "react";
import { useToastNotification } from "@coworker/components";
import useUndoneNotification from "../../components/useUndoneNotification";
import { updateTask } from "../../services/task.service";
import { useInputPopup } from "../../components/InputPopup";
import { Trans } from "@coworker/locales";
import activityTypes from "@coworker/enums/activityTypes";
import taskStateOptions from "@coworker/enums/taskState";
import trackerHelper from "../../helpers/tracker";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useTaskRefresher } from "../useWatchedTasks";
import taskTypeOptions from "@coworker/enums/taskType";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";

export const useUndoReassignTask = ({ task, afterUndo }) => {
  const { toggleModal } = React.useContext(ModalContext);
  const undoCurtain = useUndoneNotification();
  const uid = useUserId();
  const refreshTask = useTaskRefresher();

  const undoTask = React.useCallback(
    async (actionId) => {
      const data = {
        last_editor_id: uid,
        assigned_team_id: task.assigned_team_id,
        assigned_user_id: task.assigned_user_id,
        state: task.assigned_user_id
          ? taskStateOptions.ASSIGNED
          : taskStateOptions.UNASSIGNED,
        undo_action_id: actionId || "",
      };

      await undoCurtain(data, task.id);
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: task.id },
      });
      afterUndo();
      refreshTask(task.id, data, task);
    },
    [afterUndo, task, uid, undoCurtain, refreshTask, toggleModal]
  );

  return {
    call: undoTask,
  };
};

export const useReassignTask = (task, taskId, config = {}) => {
  const { undo, afterUndo = () => {} } = config;
  const uid = useUserId();
  const storeId = useStoreId();

  const { showToastWithUndo } = useToastNotification();
  const { getInput } = useInputPopup();
  const { call: undoTask } = useUndoReassignTask({
    task: { ...task, id: taskId },
    afterUndo,
  });
  const refreshTask = useTaskRefresher();

  const reassignTask = React.useCallback(async () => {
    const actionId = `reassign-${new Date().getTime()}`;
    const isPqr = task.task_type === taskTypeOptions.PRODUCT_QUALITY;
    const data = await getInput("selectAssignees", {
      title: <Trans>reassignString</Trans>,
      shortId: task.product_article_id,
      assignees: { gid: task.assigned_team_id, uid: task.assigned_user_id },
      isPqr,
    });
    if (data) {
      // if no changes were made we just close the popup
      if (
        data.uid === task.assigned_user_id &&
        data.gid === task.assigned_team_id
      ) {
        return;
      }

      let activityType;
      if (data.uid && data.uid !== task.assigned_user_id) {
        activityType = activityTypes.REASSIGN;
      } else if (
        data.gid !== task.assigned_team_id ||
        (!data.uid && data.gid === task.assigned_team_id)
      ) {
        activityType = activityTypes.REASSIGN_TEAM;
      }

      const updateData = {
        last_editor_id: uid,
        assigned_team_id: data.gid,
        assigned_user_id: data.uid,
        action_id: actionId,
        activity_type: activityType,
        // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
        undo_action_id: "",
        state: data.uid
          ? taskStateOptions.ASSIGNED
          : taskStateOptions.UNASSIGNED,
      };

      updateTask(updateData, task.id, task.task_type, storeId);
      refreshTask(task.id, updateData, task);
      trackerHelper.trackReassignTask(task.id);

      if (!undo) return;

      showToastWithUndo(<Trans>youReassignedString</Trans>, () =>
        undoTask(actionId)
      );

      return true;
    }
  }, [
    undoTask,
    refreshTask,
    undo,
    uid,
    task,
    getInput,
    showToastWithUndo,
    storeId,
  ]);

  return {
    call: reassignTask,
  };
};
