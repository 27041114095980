import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";
import React from "react";
import styled from "styled-components";

const SkeletonWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

export const ToolsSkeleton = () => {
  return (
    <>
      <SkeletonWrapper>
        <FixaSkeleton width="90%" height="26px" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <FixaSkeleton width="90%" height="26px" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <FixaSkeleton width="90%" height="26px" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <FixaSkeleton width="90%" height="26px" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <FixaSkeleton width="90%" height="26px" />
      </SkeletonWrapper>
    </>
  );
};
