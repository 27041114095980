import React from "react";
import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import styles from "../activity-filters.module.css";
import {
  ActivityFilterType,
  FilterOptionsKeys,
} from "../../../types/filterOptionsTypes";
import { Trans } from "@coworker/locales";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import {
  useFixaUID,
  useTeamId,
} from "@coworker/app/src/core/auth/useLoggedInUser";

interface SelectablePillProps {
  id: Partial<FilterOptionsKeys>;
  label: string;
  filters: ActivityFilterType;
  type: "checkbox" | "radio";
  setFilters: (value: ActivityFilterType) => void;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const SelectablePill = ({
  label,
  id,
  filters,
  type,
  setFilters,
  setActiveTab,
}: SelectablePillProps) => {
  const fixaUID = useFixaUID();
  const teamId = useTeamId();

  const [isChecked, setIsChecked] = React.useState(false);
  const filtersToAssignOnClick: Partial<ActivityFilterType> = {};
  const isPriority = id === "priority";
  const isAssignedToMe = id === "assignedToMe";
  const isAssignedToMyTeam = id === "assignedToMyTeam";

  if (isAssignedToMe) {
    filtersToAssignOnClick.assignedTo =
      filters.assignedTo === fixaUID ? "" : fixaUID;
  } else if (isAssignedToMyTeam) {
    filtersToAssignOnClick.assignedTo =
      filters.assignedTo === teamId ? "" : teamId;
  }

  React.useEffect(() => {
    if (filters.assignedTo) {
      if (filters.assignedTo === fixaUID) {
        setActiveTab("IN_PROGRESS");
      } else {
        setActiveTab("OPEN");
      }
    }
  }, [filters, fixaUID, setActiveTab]);

  React.useEffect(() => {
    if (isPriority) {
      setIsChecked(!!filters.priority);
    } else if (isAssignedToMe) {
      setIsChecked(filters.assignedTo === fixaUID);
    } else if (isAssignedToMyTeam) {
      setIsChecked(filters.assignedTo === teamId);
    } else {
      setIsChecked(false);
    }
  }, [
    filters,
    fixaUID,
    teamId,
    isPriority,
    isAssignedToMe,
    isAssignedToMyTeam,
  ]);

  return (
    <FixaPill
      size="small"
      label={
        <div className={styles["selectable-pill-wrapper"]}>
          <Trans>{label}</Trans>
          <input
            className={styles["selectable-pill-input"]}
            type={type}
            checked={isChecked}
          />
        </div>
      }
      onClick={() => {
        trackerHelper.trackActivitySelectablePillClick(id);
        setIsChecked(!isChecked);
        if (isAssignedToMe || isAssignedToMyTeam) {
          setFilters({
            ...filters,
            ...filtersToAssignOnClick,
          });
        } else {
          setFilters({
            ...filters,
            priority: !isChecked,
          });
        }
      }}
    />
  );
};
