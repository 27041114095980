import React from "react";
import styled, { css } from "styled-components";
import { StoredImage } from "./StoredImage";
import PreviewImage from "./PreviewImage";
import { ReactComponent as FolderIcon } from "../assets/svg/folder.svg";
import { ReactComponent as CameraIcon } from "../assets/svg/CameraNew.svg";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { Trans, useTranslation } from "@coworker/locales";
import { useUserId } from "../core/auth/useLoggedInUser";
import { useCurtainNotification } from "./CurtainNotification";
import {
  putImageToTasksService,
  getCleanFileNameWithTimestamp,
} from "../hooks/useRemoteImages";

const ImageContainer = styled.div`
  margin-left: 40px;
  margin-top: 24px;
  display: none;
`;

const Optional = styled.div`
  opacity: 0.58;
  font-size: 14px;
  line-height: 24px;
  width: 40%;
  margin-right: 16px;
  text-align: right;
`;

export const Container = styled.div`
  background: white;
  padding: ${(props) => props.overridePadding || "32px 16px 32px 26px"};
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${({ lblColor }) => lblColor || "var(--grey600)"};
  ${({ removeBottomBorder }) =>
    !removeBottomBorder && "border-bottom: 1px solid var(--grey150);"}
  ${(props) =>
    props.active
      ? css`
          color: var(--grey900);
          font-weight: 700;
          ${ImageContainer} {
            display: block;
          }
        `
      : css`
          cursor: pointer;
          ${Optional} {
            display: block;
          }
        `}
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
`;

export const Title = styled.div`
  flex-grow: 1;
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.lblColor || "var(--grey600)"};
`;

const Label = styled.label`
  width: 73px;
  height: 73px;
  border-radius: 5px;
  background-color: var(--grey150);
  color: var(--grey900);
  vertical-align: top;
  margin-right: 9px;
  margin-bottom: 9px;
  overflow: hidden;
  position: relative;
  ${(props) => props.disabled && "opacity: 0.2;"}
  display: inline-flex;
  cursor: pointer;
  input {
    display: none;
  }
  svg {
    margin: auto;
  }
`;

export const Camera = styled(CameraIcon)`
  margin: 0px auto;
`;

export const IconColumn = styled.div`
  width: 32px;
  margin-right: 12px;
  margin-top: 6px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: left;
  flex-direction: column;
`;

export const UpperRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const AnimatedLoader = styled(LoaderIcon)`
  height: 20px;
  width: 20px;
  color: black;
  margin: auto;
`;

export const ImageHolder = styled.div`
  border-radius: 5px;
  margin-right: 9px;
  margin-bottom: 9px;
  background-color: var(--grey150);
  overflow: hidden;
  display: inline-flex;
  vertical-align: top;
  width: 73px;
  height: 73px;
`;

const InfoImageUpload = styled.div`
  font-size: 12px;
  margin-bottom: 15px;
  color: var(--grey600);
`;

function getExifOrientation(file) {
  return import("exif-js").then(({ default: EXIF }) => {
    return new Promise((resolve) => {
      EXIF.getData(file, function () {
        resolve(EXIF.getTag(this, "Orientation"));
      });
    });
  });
}

export async function putImage(imagesToAdd, file, folder, uid) {
  let imageOnTasksService = {};
  const [generatedCleanFileName, fileTimestamp] = getCleanFileNameWithTimestamp(
    file.name
  );
  const getFileName = () =>
    imageOnTasksService.file_name || generatedCleanFileName;

  const finallyHandler = (provider) => {
    imagesToAdd.push({
      path: `images/${folder}/${getFileName()}`, // TODO: If needed, document here why, otherwise remove this line.
      folder: `images/${folder}`,
      file_name: getFileName(), // If we have a file name generated in tasks-service we want to prefer that, to avoid image duplication.
      provider,
      created_at: fileTimestamp, // Future TODO: As soon as we stop storing in firestore this part can be removed, since tasks-service ignores any client-provided created_at value.
      creator_id: uid, // This too, since tasks-service ignores any client-provided creator_id value.
    });
  };

  imageOnTasksService = await putImageToTasksService(file);
  finallyHandler(imageOnTasksService.provider);
  return;
}

export default function AttachPhoto({
  onImageListChange,
  images,
  folder,
  localImages,
  setLocalImages,
  removeBottomBorder,
  lblColor,
  overridePadding,
  isObligatory = false,
  additionalSubTitle = <></>,
}) {
  const allowAddingImages = navigator.userAgent.indexOf(" Chrome/59") === -1;
  // The above blocking is meant to only block out the one that failed here: https://sentry.io/organizations/ingka/issues/2238655186/
  // Let's keep this specific, to ensure we only block out verifiabyl old and incompatible browsers. There's also COAPP-2720 about either adding more general "Too incompatible browser" warnings or becoming compatible with all (!!!) that just stalled.
  const uid = useUserId();
  const [selectedImage, setSelectedImage] = React.useState(undefined);
  const [active, setActive] = React.useState(false);
  const fileSelectRef = React.useRef();
  const cameraCaptureRef = React.useRef();
  const [uploading, setUploading] = React.useState(false);
  const { showCurtain } = useCurtainNotification();
  const { t } = useTranslation();

  async function onFileSelected(event) {
    const imagesToAdd = [];

    const uploadedImages = [];
    for (const file of event.target.files) {
      if (file?.type?.startsWith("image/")) {
        uploadedImages.push(file);
      }
    }
    if (event.target.files.length > uploadedImages.length) {
      showCurtain(t("someFilesWereNotUploadedString"));
    }

    // we send to parent component that some images are in loading state, so the submit button can be disabled.
    onImageListChange([
      ...images,
      ...uploadedImages.map((file) => ({
        loading: true,
        file_name: getCleanFileNameWithTimestamp(file.name), // Probably not needed?
      })),
    ]);

    setUploading(true);
    const imageUploads = uploadedImages.map(async (file) => {
      const orientation = await getExifOrientation(file);

      // take timestamp from the file name. Avoid serverTimestamp which returns an object and breaks AttachImage

      const compressor = (await import("browser-image-compression")).default;

      if (localImages) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          setLocalImages([
            ...localImages,
            {
              image: e.target.result,
            },
          ]);
        };
      }

      try {
        const compressedImage = await compressor(file, {
          maxSizeMB: 0.8,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
          exifOrientation: orientation || 1,
        });
        return await putImage(imagesToAdd, compressedImage, folder, uid);
      } catch (e) {
        try {
          // Either compression or uploading the compressed file failed, so try instead with the original.
          return await putImage(imagesToAdd, file, folder, uid);
        } catch (ie) {
          // And if in the end upload still fails, show a warning.
          showCurtain(t("someFilesWereNotUploadedString"));
        }
      }
    });
    await Promise.all(imageUploads);
    setUploading(false);
    onImageListChange([...images, ...imagesToAdd]);
  }

  function onDeleteImage(deletedImage) {
    setSelectedImage(undefined);
    const imagesWithoutDeleted = images.filter(
      (image) => image !== deletedImage
    );
    onImageListChange(imagesWithoutDeleted);
  }

  function activate() {
    if (!isPhotoSectionActive()) {
      setActive(true);
    }
  }

  function isPhotoSectionActive() {
    return active || images?.length > 0;
  }
  return (
    <Container
      active={isPhotoSectionActive()}
      onClick={activate}
      removeBottomBorder={removeBottomBorder}
      overridePadding={overridePadding}
      lblColor={lblColor}
    >
      <UpperRow>
        <IconColumn>
          <Camera />
        </IconColumn>
        <Stack>
          <Title lblColor={lblColor}>
            {isPhotoSectionActive() ? (
              <span data-testid="photoWidgetAttachedCount">
                {printPhotoLabel(images.length)}
              </span>
            ) : (
              <Trans>addPhotoString</Trans>
            )}
          </Title>
          {additionalSubTitle}
        </Stack>
        {!isObligatory && (
          <Optional>
            <Trans>optionalString</Trans>
          </Optional>
        )}
      </UpperRow>
      <ImageContainer>
        <InfoImageUpload>
          <Trans>infoAddPhoto</Trans>
        </InfoImageUpload>
        {images?.map((image) => (
          <ImageHolder
            key={image.file_name}
            onClick={() => {
              !image.loading && setSelectedImage(image);
            }}
          >
            {!image.loading ? (
              <StoredImage image={image} size="small" />
            ) : (
              <AnimatedLoader />
            )}
          </ImageHolder>
        ))}
        {allowAddingImages && (
          <>
            <Label htmlFor="file" disabled={uploading}>
              <FolderIcon />
              <input
                ref={fileSelectRef}
                name="file-picer"
                type="file"
                id="file"
                accept=".filePicker" // Not an actual filetype, but a way to mark intent for the wrapper to handle.
                multiple
                capture="environment"
                onChange={onFileSelected}
                disabled={uploading}
              />
            </Label>
            <Label htmlFor="camera-capture" disabled={uploading}>
              <CameraIcon />
              <input
                ref={cameraCaptureRef}
                name="camera-capture"
                type="file"
                capture="environment"
                id="camera-capture"
                accept="image/*"
                multiple
                onChange={onFileSelected}
                disabled={uploading}
              />
            </Label>
          </>
        )}
        <PreviewImage
          image={selectedImage}
          onDelete={() => onDeleteImage(selectedImage)}
          onClose={() => setSelectedImage(undefined)}
        ></PreviewImage>
      </ImageContainer>
    </Container>
  );
}

function printPhotoLabel(number) {
  switch (number) {
    case 1:
      return <Trans>onePhotoString</Trans>;
    default:
      // TODO: localize for languages with dual, etc?
      return (
        <Trans number={number} i18nKey="numberOfPhotosString">
          {{ number }} photos
        </Trans>
      );
  }
}
