// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
// Enums
import taskTypeOptions from "@coworker/enums/taskType";
import { splitLocation } from "../../../services/locations.service";
import {
  DATE_STAMP_WIDGET,
  SUPPLIER_NUMBER_WIDGET,
  PHOTOS_WIDGET,
  PRIORITY_WIDGET,
  DESCRIPTION_WIDGET,
  getProductWidget,
  RADIOBUTTONS_WIDGET,
  SAFETY_ALARM_INFO_WIDGET,
  DESCRIBE_THE_PROBLEM_WIDGET,
} from "./_common";
import { SECTION, LOCATION } from "../widgets";

// Icons
import { ReactComponent as WrenchIcon } from "../../../assets/svg/wrench.svg";
import { ReactComponent as PriorityIcon } from "@coworker/reusable/svg/priority-exclamation-sign.svg";

import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../../hooks/useWorkspaces";
import { useCurtainNotification } from "../../CurtainNotification";
import { useTranslation } from "@coworker/locales";
import { isChineseEnvironment } from "@coworker/reusable";
import SAFETY_ALARM_TITLE from "../common/safety_alarm_title";

function validate(form) {
  const checks = [
    form.supplier_number || form.supplier_number_unknown,
    form.pq_date_stamp,
    form.description,
    form.location || form.location_custom,
    form.product_article_id,
    form.pq_discovered_type,
    !form.images?.some((image) => image.loading),
    !isChineseEnvironment() || form.images?.length, // For .cn we require at least one image to accept a PQ report.
  ];
  return checks.every((x) => x);
}

function fieldsFilledCount(form) {
  let count = 0;
  if (
    (form.supplier_number &&
      form.supplier_number !== form.scanned_supplier_number) ||
    form.supplier_number_unknown
  )
    count++;
  if (form.pq_date_stamp) count++;
  if (form.description) count++;
  if (form.location || form.location_custom) count++;
  if (form.done_so_far) count++;
  if (form.pq_discovered_type) count++;
  if (form.priority_flag) count++;
  if (form.images && form.images.length > 0) count++; // Do not care if the image is loading. User has interacted with the field if length > 0
  return count;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  table: "tasks",
  type: "product_quality",
  enumType: taskTypeOptions.PRODUCT_QUALITY,
  validate,
  fieldsFilledCount,
  header: {
    newTitle: <Trans>productQualityReportString</Trans>,
    editTitle: <Trans>editTaskString</Trans>,
    testId: "productQualityFromTitle",
  },
  fields: [
    {
      type: SECTION,
      conf: { titleTranslationKey: "productInformationString" },
    },
    getProductWidget(),
    SUPPLIER_NUMBER_WIDGET,
    DATE_STAMP_WIDGET,
    {
      ...PHOTOS_WIDGET,
      conf: { lblColor: "var(--grey900)" },
    },
    { type: SECTION, conf: { titleTranslationKey: "description4String" } },
    DESCRIBE_THE_PROBLEM_WIDGET,
    {
      type: LOCATION,
      conf: {
        optional: false,
        locationString: <Trans>defectedProductLocationString</Trans>,
        popupName: "pqrLocation",
        deactivated: false,
        newAddonLocation: true,
        noArrow: true,
      },
      values: {
        in: ({
          location,
          location_custom,
          product_article_id,
          product_article_type,
        }) => {
          const [code, department] = splitLocation(
            (location || "").replace(/%26/g, "&").replace(/%2F/g, "/")
          );

          return {
            code,
            department,
            custom: location_custom,
            articleId: product_article_id,
            articleType: product_article_type,
          };
        },
        out: ({ code, department, custom, section }) => {
          let locationText = code || "";
          if (department || section)
            locationText += ` - ${department || section}`;

          let departmentText = "";
          if (department || section)
            departmentText = `${department || section}`;

          return {
            location: custom ? "" : locationText,
            location_grid_code: custom ? "" : code,
            location_department: custom ? "" : departmentText,
            location_custom: custom ? custom : "",
          };
        },
      },
    },
    RADIOBUTTONS_WIDGET,
    DESCRIPTION_WIDGET(
      true,
      "done_so_far",
      "whatOtherActions",
      // TODO: Replace below with <Icon family="icons" name="wrench" size=... />
      <WrenchIcon />,
      "var(--grey900)"
    ),
    {
      ...PRIORITY_WIDGET,
      conf: {
        title: SAFETY_ALARM_TITLE,
        icon: <PriorityIcon />,
        testId: "safetyAlarmCheckbox",
      },
    },
    SAFETY_ALARM_INFO_WIDGET,
  ],
  useReactToChanges: function (taskId, task, onChange) {
    const { navigationState } = useWorkspacesState();
    const { pop } = useWorkspacesAction();
    const { showCurtain } = useCurtainNotification();
    const { t } = useTranslation();
    React.useEffect(() => {
      if (navigationState.qualityTeamId) {
        onChange({
          assigned_team_id: navigationState.qualityTeamId,
        });
      } else {
        pop();
        showCurtain(t("noProductQualityTeamString"));
      }

      // Ignored: onChange since it changes on every render :(
      // eslint-disable-next-line
    }, []);
  },
};
