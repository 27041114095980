import React from "react";
import styles from "./multiselect-tasks-button.module.css";
import { Trans } from "@coworker/locales";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

interface MultiselectTasksButtonProps {
  selectedTasksCount: number;
  handleSelectedTasks: () => void;
}

export const MultiselectTasksButton = ({
  selectedTasksCount,
  handleSelectedTasks,
}: MultiselectTasksButtonProps) => {
  return (
    <div className={styles["multiselect-tasks-button"]}>
      <FixaButton
        text={
          <Trans count={selectedTasksCount}>countTasksSelectedString</Trans>
        }
        onClick={handleSelectedTasks}
        type="primary"
      />
    </div>
  );
};
