import React from "react";
import ProductIdentifier from "@ingka/product-identifier";
import "@ingka/product-identifier/dist/style.css";

interface Props {
  value: string;
}

export function FixaProductIdentifier(props: Props) {
  return <ProductIdentifier {...props} />;
}
