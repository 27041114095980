import issueTypeOptions from "@coworker/enums/issueTypes";
import { Trans } from "@coworker/locales";

export const issueTypeName = {
  [issueTypeOptions.INVENTORY]: <Trans>issueNameInventoryString</Trans>,
  [issueTypeOptions.DAMAGED_OR_BROKEN]: <Trans>issueNameDamagedString</Trans>,
  [issueTypeOptions.POOR_ASSEMBLY]: <Trans>issueNameAssemblyString</Trans>,
  [issueTypeOptions.DIRTY]: <Trans>issueNameDirtyString</Trans>,
  [issueTypeOptions.MISSING_PARTS]: <Trans>issueNameMissingPartsString</Trans>,
  [issueTypeOptions.NO_PRICE_TAG]: <Trans>issueNameNoPriceTagString</Trans>,
  [issueTypeOptions.RACKING]: <Trans>issueNameRackingString</Trans>,
  [issueTypeOptions.OUT_OF_STOCK]: <Trans>issueNameOutOfStockString</Trans>,
  [issueTypeOptions.MISSING_DISPLAY_MODEL]: (
    <Trans>issueNameMissingDisplayModelString</Trans>
  ),
  [issueTypeOptions.OTHER]: <Trans>issueNameOtherString</Trans>,
};
