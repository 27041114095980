import React from "react";
import FixaLoading from "../../wrappers/FixaLoading/FixaLoading";
import FixaLoadingBall from "../../wrappers/FixaLoading/FixaLoadingBall";
import FixaLoadingLinear from "../../wrappers/FixaLoading/FixaLoadingLinear";

type FixaLoadingBallProps = {
  text: string;
  size: "small" | "medium" | "large";
};

const SimpleLoadingBall: React.FC<FixaLoadingBallProps> = ({
  text,
  size = "large",
}: FixaLoadingBallProps) => {
  return (
    <FixaLoading text={text}>
      <FixaLoadingBall size={size} />
    </FixaLoading>
  );
};

type FixaLoadingLinearProps = {
  text: string;
  loadingValue: number;
  loadingMax: number;
};

const SimpleLoadingLinear: React.FC<FixaLoadingLinearProps> = ({
  text,
  loadingValue,
  loadingMax,
}: FixaLoadingLinearProps) => {
  return (
    <FixaLoading text={text}>
      <FixaLoadingLinear loadingMax={loadingMax} loadingValue={loadingValue} />
    </FixaLoading>
  );
};
export { SimpleLoadingLinear, SimpleLoadingBall };
