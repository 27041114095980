import { ActivityFilterType } from "../types/filterOptionsTypes";

export function getCheckedStatus(
  location: string,
  isAddonTask: boolean,
  addonLocationType: string,
  panelFilters: ActivityFilterType
) {
  const {
    locations = [],
    pickupLocations = [],
    refillLocations = [],
  } = panelFilters;

  if (!isAddonTask) {
    return locations.length > 0 && locations.includes(location);
  }

  switch (addonLocationType) {
    case "pickup":
      return pickupLocations.length > 0 && pickupLocations.includes(location);
    default:
      return refillLocations.length > 0 && refillLocations.includes(location);
  }
}
