import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { TwoLinedGreyBox } from "../Molecules/TwoLinedGreyBox";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { useRoomsByStoreId } from "../hooks/useRoomsByStoreId";
import { RoomsViewRoomsListMobile } from "../Organisms/RoomsViewRoomsListMobile";
import { RoomsViewSearchListMobile } from "../Organisms/RoomsViewSearchListMobile";
import { RoomsCountText } from "../Atoms/RoomsAmountText";
import { MessageAndButton } from "../Molecules/MessageAndButton";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { CreateSettingsModal } from "../Organisms/Modals/CreateSettingsModal";
import { useSearchArticlesInRoom } from "../hooks/useSearchArticlesInRooms";
import { SearchResultCountText } from "../Atoms/SearchResultCountText";
import { SearchBarContainer } from "../styles/styles";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { useMatchingRooms } from "../../../hooks/useMatchingRooms";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";

const ApplicationFooterActionBar = styled.div`
  position: fixed;
  bottom: 68px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-block: 10px;
  background-color: white;
  border-top: 1px solid var(--grey200);
`;

const ScrollContainerEditableSettings = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100% - 76px);
`;

export function RoomsViewMobile() {
  const [searchQuery, setSearchQuery] = useState("");
  const [displayedQuery, setDisplayedQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const { data: rooms } = useRoomsByStoreId(storeId);
  const { data: articles } = useSearchArticlesInRoom(storeId, searchQuery);
  const { data: alerts } = useAlertsByStore(storeId);
  const matchingRooms = useMatchingRooms(rooms, searchQuery);

  const tasks = useTasksByStore(storeId);
  const hideForGermanyUsers = useFlag(FLAGS.GERMANY_ROLLOUT);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[.,]/g, "");
    setDisplayedQuery(inputValue);
    setSearchQuery(sanitizedInput);
  };

  const onClear = () => {
    setDisplayedQuery("");
    setSearchQuery("");
  };

  const linkbar = (
    <Linkbar currentLabel={t("roomSettingsString")} currentAsH1 />
  );

  if (hideForGermanyUsers) {
    return (
      <>
        {linkbar}
        {t("roomSettingsDisabledString")}
      </>
    );
  }

  // No rooms
  if (rooms && rooms.length === 0) {
    return (
      <>
        {linkbar}
        <MessageAndButton
          keyForMessageText={"noRoomSettingsCreatedString"}
          keyForButtonText={"addRoomSettingsString"}
          buttonAction={async () => {
            setShowModal(true);
          }}
        />
        <CreateSettingsModal
          storeId={storeId}
          isVisible={showModal}
          onClose={(roomId) => {
            setShowModal(false);
            if (roomId) {
              push(`/roomsettings/tabs/${roomId}?tab=article-list-tab`);
            }
          }}
        />
      </>
    );
  }

  return (
    <>
      <ScrollContainerEditableSettings>
        {linkbar}
        <TwoLinedGreyBox
          tabIndex={0}
          role="button"
          ariaLabel={t("ongoingTasksAriaLabelString", { count: tasks.count })}
          headLine={t("ongoingTasksString")}
          bottomleftText={`${tasks.count}`}
          rightComponent={<RightArrow />}
          onClick={() => {
            push(`/roomsettings/tasks`);
          }}
        />

        <SearchBarContainer>
          <FixaSearch
            id="room-search"
            value={displayedQuery}
            onChange={onChange}
            onClear={onClear}
            placeholder={t("roomsViewSearch")}
            ariaLabel={t("roomsViewSearch")}
          />
        </SearchBarContainer>
        {searchQuery.length > 1 ? (
          <>
            <SearchResultCountText
              count={(matchingRooms?.length ?? 0) + (articles?.length ?? 0)}
            />
            <RoomsViewSearchListMobile
              rooms={matchingRooms}
              query={searchQuery}
              taskLinks={tasks.taskLinks}
              alerts={alerts}
              storeId={storeId}
              articles={articles ?? undefined}
            />
          </>
        ) : (
          <>
            <RoomsCountText roomSettings={rooms?.length || undefined} />
            <RoomsViewRoomsListMobile
              rooms={rooms}
              taskLinks={tasks.taskLinks}
              alerts={alerts}
            />
          </>
        )}
      </ScrollContainerEditableSettings>
      <ApplicationFooterActionBar>
        <FixaButton
          aria-label={t("addRoomSettingsString")}
          text={t("addRoomSettingsString")}
          type="primary"
          onClick={() => {
            setShowModal(true);
          }}
          style={{ width: "100%", margin: "0px 16px" }}
        />
      </ApplicationFooterActionBar>
      <CreateSettingsModal
        storeId={storeId}
        isVisible={showModal}
        onClose={(roomId) => {
          setShowModal(false);
          if (roomId) {
            push(`/roomsettings/tabs/${roomId}?tab=article-list-tab`);
          }
        }}
      />
    </>
  );
}
