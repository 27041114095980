import React from "react";
import { Theatre } from "@ingka/modal";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";

interface TheatreProps {
  header: React.ReactElement | null;
  children: React.ReactNode;
}

export const FixaTheatre = (props: TheatreProps) => {
  return <Theatre {...props} />;
};
