import React from "react";
import Carousel, { OverflowCarousel } from "@ingka/carousel";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/focus/dist/style.css";

type FixaOverflowCarouselProps = {
  children?: React.ReactNode;
  id?: string;
  controlBtnType?:
    | "primary"
    | "emphasised"
    | "secondary"
    | "tertiary"
    | "danger"
    | "plain"
    | "image-overlay";
  "data-testid"?: string;
};

export function FixaOverflowCarousel(props: FixaOverflowCarouselProps) {
  return <OverflowCarousel {...props} />;
}

type FixaCarouselProps = {
  children?: React.ReactElement[];
  hasNoControls?: boolean;
  id: string;
  isSlideShow?: boolean;
  "data-testid"?: string;
};
function FixaCarousel(props: FixaCarouselProps) {
  return <Carousel {...props} />;
}

export default FixaCarousel;
