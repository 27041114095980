import { ActivityFilterType } from "../types/filterOptionsTypes";

export function updateLocationsPanelFilters(
  panelFilters: ActivityFilterType,
  newLocationsFilterSet: string[],
  addonLocationType: string,
  isAddonTask: boolean,
  setPanelFilters: React.Dispatch<React.SetStateAction<ActivityFilterType>>
) {
  if (isAddonTask) {
    const key =
      addonLocationType === "pickup" ? "pickupLocations" : "refillLocations";

    const updatedPanelFilters = { ...panelFilters };

    if (addonLocationType === "pickup") {
      updatedPanelFilters.refillLocations = [];
    } else {
      updatedPanelFilters.pickupLocations = [];
    }

    updatedPanelFilters[key] = newLocationsFilterSet;

    setPanelFilters(updatedPanelFilters);
  } else {
    setPanelFilters({
      ...panelFilters,
      locations: newLocationsFilterSet,
    });
  }
}
