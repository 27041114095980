import React, { useContext } from "react";
import MenuIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/MenuIconPath";
import { ModalContext } from "../../../../context/ModalContextProvider";
import { ModalType } from "../../../../context/modalType";
import styles from "./hamburger-menu.module.css";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "react-i18next";

const HamburgerMenu = () => {
  const { toggleModal } = useContext(ModalContext);
  const { t } = useTranslation();
  return (
    <div className={styles["hamburger-menu"]}>
      <FixaButton
        aria-label={t("menuString")}
        type="tertiary"
        onClick={() => toggleModal({ type: ModalType.MainNavigation })}
        inverseTheme={true}
        iconOnly={true}
        ssrIcon={MenuIconPath}
      />
    </div>
  );
};

export default HamburgerMenu;
