import { Criterion, CriteriaState, CriterionKey } from "@coworker/types";
import { createOrUpdateCriterion } from "../../CommonComponents/Repositories/criteria.repository.testbuy.service";

export type CriteriaReducerAction =
  | {
      type: "updateCriterion";
      criterion: Criterion;
      newState: CriteriaState;
      articleId: string;
      testBuyId: string;
    }
  | {
      type: "updateCriterionIsDisplayedNotOk";
      criterion: Criterion;
      articleId: string;
      testBuyId: string;
    };

const updateCriterionState = (
  criteria: Criterion[],
  key: CriterionKey,
  newState: CriteriaState
) => {
  return criteria.map((criterion) => {
    if (criterion.key === key) {
      return { ...criterion, ...{ state: newState } };
    } else {
      return criterion;
    }
  });
};

const updateCriterion = (
  criteria: Criterion[],
  testBuyId: string,
  articleId: string,
  key: CriterionKey,
  newState: CriteriaState
): Criterion[] => {
  createOrUpdateCriterion(testBuyId, articleId, { key: key, state: newState });

  return updateCriterionState(criteria, key, newState);
};

export const updateCriterionReducer = (
  state: Criterion[],
  action: CriteriaReducerAction
) => {
  switch (action.type) {
    case "updateCriterion":
      if (action.criterion.state === action.newState) {
        state = updateCriterion(
          state,
          action.testBuyId,
          action.articleId,
          action.criterion.key,
          "NOT_ANSWERED"
        );
      } else {
        state = updateCriterion(
          state,
          action.testBuyId,
          action.articleId,
          action.criterion.key,
          action.newState
        );
      }
      return state;
    case "updateCriterionIsDisplayedNotOk":
      const criterionKeys: CriterionKey[] = [
        "IsItDisplayed",
        "IsItCleanAndPerfect",
        "AreBuyInstructionClear",
        "DoesItHaveTag",
      ];
      criterionKeys.forEach((criterionKey) => {
        state = updateCriterion(
          state,
          action.testBuyId,
          action.articleId,
          criterionKey,
          "NOT_OK"
        );
      });
      return state;
    default:
      return state;
  }
};
