import React, { useContext } from "react";
import styled from "styled-components";
import { TasksTodosTitle } from "./TasksTodosTitle";
import { TasksEmpty } from "./TasksEmpty";
import { TasksListView } from "../TasksListView/TasksListView";
import { TaskTabsTypes } from "../../../shared/constants/taskTabsTypes";
import trackerHelper from "../../../../../coworker-app/src/helpers/tracker";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import { SimpleLoadingBall } from "../../../shared/simple/SimpleLoading/SimpleLoading";

const TasksTodosContentContainer = styled.div`
  background-color: var(--white);
  @media (min-width: 1440px) {
    min-height: 187px;
  }
`;
interface TasksTodosProps {
  title: string;
  tasksCount: number;
  tasksList: any[];
  tabType: TaskTabsTypes;
  isLoading: boolean;
  hideViewButton?: boolean;
}

export const TasksTodos: React.FC<TasksTodosProps> = ({
  title,
  tasksCount,
  tasksList,
  tabType,
  isLoading,
  hideViewButton,
}) => {
  const { toggleModal } = useContext(ModalContext);

  const goToTaskDetail = (taskId: string) => {
    trackerHelper.trackTaskCardPreview();
    toggleModal({ type: ModalType.TaskDetail, payload: { taskId } });
    const section = tabType === TaskTabsTypes.OPEN ? "open_tasks" : "my_tasks";
    trackerHelper.trackTaskCardPreview(section);
  };

  return (
    <div>
      <TasksTodosTitle
        title={title}
        tasksCount={tasksCount}
        tabType={tabType}
      ></TasksTodosTitle>
      <TasksTodosContentContainer className="tasks-todos">
        {isLoading && <SimpleLoadingBall text="" size="medium" />}
        {tasksCount === 0 && !isLoading && (
          <TasksEmpty
            hideViewButton={hideViewButton as boolean}
            id="title"
            tasksTabType={tabType}
          ></TasksEmpty>
        )}
        {tasksCount > 0 && !isLoading && (
          <TasksListView
            id={title}
            tasksList={tasksList}
            onClickTaskListItem={goToTaskDetail}
          ></TasksListView>
        )}
      </TasksTodosContentContainer>
    </div>
  );
};
