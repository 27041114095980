const issueTypeOptions = {
  INVENTORY: "INVENTORY",
  DAMAGED_OR_BROKEN: "DAMAGED_OR_BROKEN",
  POOR_ASSEMBLY: "POOR_ASSEMBLY",
  DIRTY: "DIRTY",
  MISSING_PARTS: "MISSING_PARTS",
  NO_PRICE_TAG: "NO_PRICE_TAG",
  RACKING: "RACKING",
  OUT_OF_STOCK: "OUT_OF_STOCK",
  MISSING_DISPLAY_MODEL: "MISSING_DISPLAY_MODEL",
  WRONG_SALES_LOCATION: "WRONG_SALES_LOCATION",
  OTHER: "OTHER",
};

export default issueTypeOptions;
