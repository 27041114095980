import React from "react";
import styled from "styled-components";
import type { Store } from "@coworker/types/lib/store";
import { RadioButton } from "@coworker/components";
import { useAllStoresQuery } from "../../core/hooks/useAllStores";
import { LoaderIcon } from "@coworker/reusable";
import { Trans } from "@coworker/locales";
//import { inMyRegionChecker } from "@coworker/functions/src/core/regions/storeRegions";

const StyledLoaderIcon = styled(LoaderIcon)`
  margin: auto;
`;

const Container = styled.div`
  overflow-y: scroll;
  max-height: 392px;
  min-height: 112px;
  padding: 0 48px 0 24px;
`;
const StoreName = styled.div<{ selected: boolean }>`
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
`;

function queryAndSort(query: string | undefined, list: Store[]): Store[] {
  const sorted = [...list].sort((a, b) => a.name.localeCompare(b.name));

  return sorted.filter(({ name, id }: { name: string; id: string }) => {
    // if (!inMyRegionChecker(id)) return false;
    if (!query) return true;
    const displayText = `${name} ${id}`;
    return displayText.toLowerCase().includes(query.toLowerCase());
  });
}

interface SelectedStoreProps {
  query: string | undefined;
  selected: string | undefined;
  onSelect: (value: object) => void;
}
export function StoreList({ query, selected, onSelect }: SelectedStoreProps) {
  const { data: fetchedStores, isLoading } = useAllStoresQuery();

  const stores = React.useMemo(
    () => queryAndSort(query, fetchedStores || []),
    [query, fetchedStores]
  );
  const resultCount = stores.length;

  return (
    <Container>
      {isLoading ? (
        <StyledLoaderIcon />
      ) : !resultCount ? (
        <Trans i18nKey={"showingSearchResults"}>
          {{ query }}
          {{ resultCount }}
        </Trans>
      ) : (
        stores.map((store) => (
          <StoreName
            key={store.id}
            data-testid={`store-${store.id}`}
            onClick={() => onSelect(store)}
            selected={selected === store.id}
          >
            {store.name} {store.id}
            <RadioButton readOnly checked={selected === store.id} />
          </StoreName>
        ))
      )}
    </Container>
  );
}
