import React from "react";
import { FixaChoice } from "@coworker/apprestructured/src/shared/wrappers/FixaChoice/FixaChoice";
import { FixaChoiceItem } from "@coworker/apprestructured/src/shared/wrappers/FixaChoice/FixaChoiceItem";
import { RefillTypes } from "../../../constants/refillTypes";
import { useTranslation } from "@coworker/locales";

interface ChooseRefillTypeProps {
  selectedRefillTypeIndex: number;
  onRefillTypeSelectionChange: (selectedRefillType: RefillTypes) => void;
}

export default function ChooseRefillType({
  selectedRefillTypeIndex,
  onRefillTypeSelectionChange,
}: ChooseRefillTypeProps) {
  const { t } = useTranslation();

  const getRefillTypeTitle = (refillType: string) => {
    switch (refillType) {
      case RefillTypes.REGULAR:
        return t("refillTypeRegularString");
      case RefillTypes.FIRST_FILL:
        return t("refillTypeFirstFillString");
      default:
        return t("refillTypeRegularString");
    }
  };

  return (
    <FixaChoice subtle preselectedIndex={selectedRefillTypeIndex}>
      {(Object.keys(RefillTypes) as Array<keyof typeof RefillTypes>).map(
        (refillType) => {
          return (
            <FixaChoiceItem
              key={refillType}
              data-testid={refillType + "_section"}
              id={refillType}
              title={getRefillTypeTitle(refillType)}
              onClick={(value: any) => {
                onRefillTypeSelectionChange(value);
              }}
            />
          );
        }
      )}
    </FixaChoice>
  );
}
