import React from "react";
import tracker from "@coworker/app/src/helpers/tracker";
import { postToTasksService } from "@coworker/app/src/hooks/API/tasks.service.helper";

interface GenerateReportParams {
  dataFetchParams: any;
  type: "insights";
  extra?: any;
}
export const useGenerateReport = () => {
  const [isReportLoading, setIsReportLoading] = React.useState(false);
  const fetchReport = async ({
    dataFetchParams,
    type,
    extra,
  }: GenerateReportParams) => {
    try {
      setIsReportLoading(true);
      let downloadUrl = "";
      let filename = "";
      if (type === "insights") {
        await postToTasksService(
          "/v1/insights/excelExport_v0",
          { ...dataFetchParams, ...extra },
          "blob"
        ).then((result: any) => {
          downloadUrl = window.URL.createObjectURL(
            new Blob([result?.blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
        });

        //TODO Need to see if we need UUID in filename
        filename = "insights-exports.xlsx";
      }
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      // @ts-ignore
      link?.parentNode.removeChild(link);
      setIsReportLoading(false);
      // @ts-ignore
      tracker[type].trackExport(dataFetchParams);
    } catch (error) {
      setIsReportLoading(false);
    }
  };
  return { isReportLoading: isReportLoading, fetchReport: fetchReport };
};
