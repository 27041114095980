import statusTypes from "@coworker/enums/statusTypes";
import { useStoreTeams } from "../core/hooks/useStoreTeams";

const activeProductQualityGroup = (team, teamId) =>
  team.status === statusTypes.ACTIVE && team.is_product_quality;

export function useProductQualityTeamId() {
  const teams = useStoreTeams();
  return teams.find(activeProductQualityGroup)?.id || false;
}
