import React from "react";
import {
  ListItemBanner,
  MainProductMessage,
  OngoingTaskMessage,
  SalesStopMessage,
  BackupProductMessage,
  EDSMessage,
} from "../styles/styles";
import { formatEDSDate } from "../../Insights/helpers";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { EdsData, SalesStopData } from "../types/article";
import { useTranslation } from "@coworker/locales";

interface ArticleListItemBannerProps {
  isMainProduct: boolean;
  isBackupForProduct: boolean;
  hasOngoingTask: boolean;
  edsData: EdsData | null | undefined;
  salesStopData: SalesStopData | null | undefined;
}

export function ArticleListItemBanner({
  isMainProduct,
  isBackupForProduct,
  hasOngoingTask,
  edsData,
  salesStopData,
}: ArticleListItemBannerProps) {
  const countryId = useCountryId();
  const { t } = useTranslation();

  const getBadgeColourForEDSDate = (edsDate: string) => {
    const validToDate = new Date(edsDate);
    // Get the current date and time
    const today = new Date();
    // Calculate the date 2 weeks (14 days) from today
    const twoWeeksFromToday = new Date();
    twoWeeksFromToday.setDate(today.getDate() + 14);
    return validToDate < twoWeeksFromToday ? "EDSwarning" : "EDSregular";
  };

  return (
    <ListItemBanner>
      {isMainProduct && <MainProductMessage message={t("mainProductString")} />}
      {isBackupForProduct && (
        <BackupProductMessage
          message={t("backupProductString").toUpperCase()}
        />
      )}
      {hasOngoingTask && (
        <OngoingTaskMessage
          message={(t("ongoingTasksString") as string).toUpperCase()}
        />
      )}
      {edsData && edsData.validToDateTime && (
        <EDSMessage
          className={getBadgeColourForEDSDate(edsData.validToDateTime)}
          message={`${
            new Date(edsData.validToDateTime) > new Date() ? "EDS" : "PEDS"
          } ${formatEDSDate(edsData.validToDateTime, countryId)}`}
        />
      )}
      {salesStopData && (
        <SalesStopMessage
          message={`${(t("salesStopString") as string).toUpperCase()}`}
        />
      )}
    </ListItemBanner>
  );
}
