const DAY = 24 * 3600 * 1000;

export const ARCHIVE_OFFSET = 7 * DAY;
export const NEARBY_PRODUCTS_OFFSET = 21 * DAY;
export const NOTIFICATION_OFFSET = 7 * DAY;
export const CLOSED_TASKS_OFFSET = 7 * DAY;
export const LOCATIONS_FROM_TASKS_OFFSET = 28 * DAY;
export const RELATED_TASKS_MAX_CLOSED = 7 * DAY;
export const RELATED_TASKS_MAX_FINISHED = 10 * DAY;
export const USER_EVENT_TTL = 14 * DAY;

// This is a date also set in the Tasks Service
export const AVeryLargeDate = "3000-01-01T00:00:00.000Z";

export const FINISHED_TASKS_OFFSET_28_DAYS = 28 * DAY; // AT, DE (DK?)
export const FINISHED_TASKS_OFFSET_70_DAYS = 70 * DAY; // All other countries.
