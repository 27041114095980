import Number from "./Number";
import SupplierNumber from "./SupplierNumber";
import Description from "./Description";
import SelectIssue from "./SelectIssue";
import ScanAndSearch, { SearchOnly } from "./ScanAndSearch";
import ConfirmationPrompt from "./ConfirmationPrompt";
import { AmountTabsWithPills } from "./AmountTabsWithPills";
import { TaskAction } from "./TaskAction";
import TeamChange from "./TeamChange";
import User from "./User";
import InsightsFilters from "./InsightsFilters";
import Error from "./Error";
import Title from "./Title";
import Repeatable from "./Repeatable";
import Schedule from "./Schedule";
import OptionalLocation from "./OptionalLocation";
import RadioButtons from "./RadioButtons";
import TaskListFilters from "./TaskListFilters";
import { UserFilterPopup } from "./UserFiltersRow/UserFilterPopup";
import ImageGallery from "./ImageGallery";
import AddNote from "./AddNote";
import { PopupMenu } from "./PopupMenu";
import TextNote from "./TextNote";
import Handoff from "./Handoff";
import { HomeAndAway } from "./HomeAndAway";
import CompleteWithText from "./CompleteWithText";
import Location from "./Location";
import NewAssign from "./NewAssign";
import FullscreenPopup from "./fullscreenPopup";
import MultiSelectPopup from "./MultiSelectPopup";
import { AreaSearchListPopup } from "./TestBuyAreas";
import { StoreListPopup } from "./SelectStore";
import MFAQresolution from "./MFAQresolution";
import MFAQeditQuestion from "./MFAQeditQuestion";
import { MFAQmergeQuestion } from "./MFAQmergeQuestion";
import { PillSelectionPopup } from "./PillSelectionPopup";
import { ArticleSelection } from "./ArticleSelectionPopup";
import { SafetyAlarmMoreInfo } from "./SafetyAlarmMoreInfo";
import { DescribeTheProblem } from "./DescribeTheProblem";
import { ConfirmAssignment } from "./ConfirmAssignment";
import RefillType from "./RefillType/RefillType";
import { TypeOfRoomSettingTask } from "./TypeOfRoomSettingTask";
import { AddMultipleArticles } from "./AddMultipleArticles";
import { RoomArticleFilters } from "./RoomArticleFilters";
import { HFBLocations } from "./HFBLocations";

const types = {
  multiSelectPopup: { component: MultiSelectPopup },
  fullscreen: { component: FullscreenPopup },
  number: {
    component: Number,
  },
  confirm_assignment: {
    component: ConfirmAssignment,
    hideHeader: true,
    hideOverlay: true,
  }, // returns true ONLY when reassign button is pressed
  supplierNumber: {
    component: SupplierNumber,
  },
  description: {
    component: Description,
  },
  textNote: {
    component: TextNote,
  },
  addNote: {
    component: AddNote,
  },
  popupMenu: {
    component: PopupMenu,
    hideHeader: true,
    hideOverlay: true,
    noAnimation: true,
  },
  scansearch: {
    component: ScanAndSearch,
    hideHeader: true,
  },
  search: {
    component: SearchOnly,
    hideHeader: true,
  },
  selectIssue: {
    component: SelectIssue,
  },
  taskAction: {
    component: TaskAction,
  },
  imageGallery: {
    component: ImageGallery,
    hideHeader: true,
  },
  selectAssignees: {
    component: NewAssign,
    hideHeader: true,
    hideOverlay: true,
  },
  confirmation: {
    component: ConfirmationPrompt,
    hideHeader: true,
    noAnimation: true,
    hideOverlay: true,
  },
  addonLocation: {
    component: Location,
    hideHeader: true,
    hideOverlay: true,
  },
  refillType: {
    component: RefillType,
    hideHeader: true,
    hideOverlay: true,
  },
  optionalLocation: {
    component: OptionalLocation,
    hideHeader: true,
  },
  selectAmount: {
    component: AmountTabsWithPills,
    hideHeader: true,
    hideOverlay: true,
  },
  pickFromSelection: {
    component: PillSelectionPopup,
    hideHeader: true,
    hideOverlay: true,
  },
  searchSelectArea: {
    component: AreaSearchListPopup,
    hideHeader: true,
    hideOverlay: true,
  },
  selectStore: {
    component: StoreListPopup,
    hideHeader: true,
    hideOverlay: true,
  },
  infoAboutSafetyAlarm: {
    component: SafetyAlarmMoreInfo,
  },
  teamChange: {
    component: TeamChange,
    hideHeader: true,
  },
  insightsFilters: {
    component: InsightsFilters,
    hideHeader: true,
  },
  error: {
    component: Error,
    hideHeader: true,
  },
  title: {
    component: Title,
  },
  repeatable: {
    component: Repeatable,
  },
  schedule: {
    component: Schedule,
  },
  user: {
    component: User,
    hideHeader: true,
  },
  radiobuttons: {
    component: RadioButtons,
  },
  filters: {
    component: TaskListFilters,
    hideHeader: true,
  },
  userFilters: {
    component: UserFilterPopup,
  },
  handoff: {
    component: Handoff,
    hideHeader: true,
  },
  homeAndAway: {
    component: HomeAndAway,
  },
  completeWithText: {
    component: CompleteWithText,
    hideHeader: true,
  },
  mfaqResolution: {
    component: MFAQresolution,
    hideHeader: true,
    hideOverlay: true,
  },
  mfaqEditQuestion: {
    component: MFAQeditQuestion,
    hideHeader: true,
    hideOverlay: true,
  },
  mfaqMergeQuestion: {
    component: MFAQmergeQuestion,
    hideHeader: true,
    hideOverlay: true,
  },
  typeOfArticlesPopup: {
    component: ArticleSelection,
    hideHeader: true,
    hideOverlay: true,
  },
  describeTheProblem: {
    component: DescribeTheProblem,
  },
  typeOfRoomSettingTask: {
    component: TypeOfRoomSettingTask,
  },
  roomArticleFilters: {
    component: RoomArticleFilters,
    hideHeader: true,
  },
  hfbs: {
    component: HFBLocations,
    hideHeader: true,
  },
  addMultipleArticles: {
    component: AddMultipleArticles,
    hideHeader: true,
    hideOverlay: true,
  },
};

export default types;
