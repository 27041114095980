import React, { MouseEventHandler } from "react";
import Image from "@ingka/image";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/image/dist/style.css";

type FixaImageProps = {
  src: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLImageElement>;
  key?: React.Key;
  id?: string;
  alt?: string;
  "data-testid"?: string;
};

function FixaImage(props: FixaImageProps) {
  return <Image {...props} />;
}

export default FixaImage;
