import React from "react";
import { FixaQuantityStepper } from "@coworker/apprestructured/src/shared/wrappers/FixaQuantityStepper/FixaQuantityStepper";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import { Trans } from "@coworker/locales";
import { MAX_CASHLINE_TOOLS, NO_TOOL, ShoppingToolType } from "../constants";
import { CardWrapper, SubTitle } from "../Showroom/ShowroomQuantityCard";
import { ToolsCount } from "../Types";
import {
  isMaxToolsReachedCashline,
  hasNoTools,
  getMaxToolValue,
} from "../helper";
import styles from "./CashlineQuantityCard.module.css";

interface CashlineQuantityCardProps {
  type: ShoppingToolType;
  lastShoppingTools: ToolsCount;
  setLastShoppingTools: React.Dispatch<React.SetStateAction<ToolsCount>>;
  isWarningShown: boolean;
}

export const CashlineQuantityCard = ({
  type,
  lastShoppingTools,
  setLastShoppingTools,
  isWarningShown,
}: CashlineQuantityCardProps) => {
  const currentToolValue = lastShoppingTools[type.name as string] ?? 0;
  const isNoTool = type.name === NO_TOOL;
  const maxToolLimit = isMaxToolsReachedCashline(
    { ...(lastShoppingTools as ToolsCount) },
    MAX_CASHLINE_TOOLS
  );

  const handleIncrease = () => {
    let updatedTools = { ...lastShoppingTools } as ToolsCount;
    if (isNoTool) {
      updatedTools = { no_tool: 1 };
    } else if (maxToolLimit) {
      updatedTools[type.name] = currentToolValue + 1;
    }
    setLastShoppingTools(updatedTools);
  };

  const handleDecrease = () => {
    const updatedTools = { ...lastShoppingTools };
    updatedTools[type.name] = currentToolValue - 1;
    setLastShoppingTools(updatedTools);
  };

  return (
    <CardWrapper key={type.label}>
      <FixaQuantityStepper
        value={currentToolValue}
        small
        minVal={0}
        maxVal={getMaxToolValue(isWarningShown)}
        ariaDescribedById="unique-dom-id"
        ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
        onIncrease={handleIncrease}
        onDecrease={handleDecrease}
        disabled={hasNoTools(lastShoppingTools, type)}
      />
      <FixaSSRIcon paths={type.icon!} className={styles["icon"] as string} />
      <SubTitle style={{ margin: !type.icon ? "0px 10px" : 0 }}>
        <Trans>{type.label}</Trans>
      </SubTitle>
    </CardWrapper>
  );
};
