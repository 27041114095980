import { FixaChoice } from "@coworker/apprestructured/src/shared/wrappers/FixaChoice/FixaChoice";
import { FixaChoiceItem } from "@coworker/apprestructured/src/shared/wrappers/FixaChoice/FixaChoiceItem";
import React from "react";
import styled from "styled-components";
import { Reasons } from "../../components/RoomSettings/constants";
import { useTranslation } from "@coworker/locales";

const RoomSettingsPopupContainer = styled.div`
  background: white;
  position: fixed;
  height: 100%;
  width: calc(100% - 48px);
  top: 0;
  left: 0;
  margin: 80px 24px 24px 24px;
`;
const RoomSettingsPopupTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: var(--grey900);
  padding-left: 24px;
  margin-bottom: 24px;
`;

interface TypeOfRoomSettingTaskProps {
  selectedReason: string;
  onSubmit: (value: string) => void;
}

export const TypeOfRoomSettingTask = ({
  selectedReason,
  onSubmit,
}: TypeOfRoomSettingTaskProps) => {
  const { t } = useTranslation();
  const getTitleOfTask = (reasonTitle: string) => {
    if (reasonTitle === "daily_maintenance") return t("dailyMaintenanceString");
    if (reasonTitle === "replace_product") return t("changeProductString");
    if (reasonTitle === "remove_product") return t("removeProductString");
    return "";
  };

  const enumIndex = Object.keys(Reasons).indexOf(selectedReason.toUpperCase());
  return (
    <RoomSettingsPopupContainer>
      <RoomSettingsPopupTitle>
        {t("selectTypeOfTaskString")}
      </RoomSettingsPopupTitle>
      <FixaChoice preselectedIndex={enumIndex} subtle>
        {Object.keys(Reasons)
          .filter((reason) => reason !== "SALES_STOP")
          .map((reason) => {
            const reasonValue = Reasons[reason as keyof typeof Reasons];
            return (
              <FixaChoiceItem
                key={reason}
                id={reasonValue}
                title={getTitleOfTask(reasonValue)}
                onClick={() => onSubmit(reasonValue)}
                data-testid={reasonValue}
              />
            );
          })}
      </FixaChoice>
    </RoomSettingsPopupContainer>
  );
};
