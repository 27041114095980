import React from "react";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";

interface OkCancelModalProps {
  onOk: () => void;
  onCancel: () => void;
  title: string;
  text: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
}

export const OkCancelModal = ({
  onOk,
  onCancel,
  title,
  text,
  primaryButtonText,
  secondaryButtonText,
}: OkCancelModalProps) => {
  const { t } = useTranslation();
  primaryButtonText ??= t("OkString");
  secondaryButtonText ??= t("cancelString");

  return (
    <FixaModal handleCloseBtn={onCancel} visible={true}>
      <FixaPrompt
        aria-label={text}
        footer={
          <FixaModalFooter>
            <FixaButton
              text={secondaryButtonText}
              type="secondary"
              onClick={onCancel}
              aria-label={secondaryButtonText}
            />
            <FixaButton
              text={primaryButtonText}
              type="primary"
              onClick={onOk}
              aria-label={primaryButtonText}
            />
          </FixaModalFooter>
        }
        header={
          <FixaModalHeader title={title} ariaCloseTxt={secondaryButtonText} />
        }
        title=""
        titleId=""
      >
        <p>{text}</p>
      </FixaPrompt>
    </FixaModal>
  );
};
