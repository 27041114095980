import { callInternalApi } from "../hooks/API/useCallInternal";

export async function createNote(taskId, note, images, storeId) {
  await callInternalApi(`task/create_note?id=${taskId}`, {
    note,
    images,
    store_id: storeId,
  });
  if (!window.forwardToTasksService) return;

  // TODO: Remove this once the Tasks Service is the only source. Even remove the whole createNote function and this file.
  const response = await callInternalApi(
    `create/note?id=${taskId}`,
    { note, store_id: storeId },
    false,
    true
  );
  const noteId = response?.data?.id;
  const parentPath = `tasks/${taskId}/notes/${noteId}/images`;
  if (!noteId)
    console.error(
      "Missing noteId -- needs another run of fixNoteImages",
      response
    );
  await callInternalApi(
    "create/images",
    { parentPath, list: images, store_id: storeId },
    false,
    true
  );
}
