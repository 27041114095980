import React, { useEffect } from "react";
import styled from "styled-components";
import { Button } from "@coworker/components";
import { Trans } from "@coworker/locales";
import NearbyAddonProductRow from "./NearbyAddonProductRow";
import { useNearbyProducts } from "../../services/products.service";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import { createProductLocationKey } from "./_helpers";
import { useStoreTeams } from "../../core/hooks/useStoreTeams";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";

const PAGE_LENGTH = 5;

const StyledButton = styled(Button)`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const NearbyDescription = styled.div`
  margin: 40px 24px;
  padding-bottom: 90px;
  font-size: 12px;
  color: var(--grey700);
`;

const taskNearbyKey = (task) => {
  const { product_article_type, product_article_id: id, location } = task || {};
  return createProductLocationKey(product_article_type, id, location);
};

export function NearbyAddons() {
  const { pop, push } = useWorkspacesAction();
  const { form: initialTask } = useWorkspacesState().navigationState;
  const keyOfInitialTask = taskNearbyKey(initialTask);

  const [createdTasks, setCreatedTasks] = React.useState(
    new Set([keyOfInitialTask])
  );
  const [page, setPage] = React.useState(1);

  // Workaround for sentry issue 2485374957
  // If the user navigates to this page with no navigationState, then we have no idea
  // what tasks to create, so we just bail.
  React.useEffect(() => {
    if (!initialTask) pop();
  }, [initialTask, pop]);

  const { assigned_team_id, location_grid_code } = initialTask ?? {};
  const teams = useStoreTeams();
  const teamName = teams.find((team) => team.id === assigned_team_id)?.name;

  const { nearbyProducts, loading: nearbyProductsLoading } =
    useNearbyProducts(location_grid_code);

  const filteredNearbyProducts = React.useMemo(() => {
    if (nearbyProductsLoading) return [];
    return nearbyProducts.filter(
      (task) => !createdTasks.has(taskNearbyKey(task))
    );
  }, [createdTasks, nearbyProducts, nearbyProductsLoading]);

  const postTaskCreate = React.useCallback(
    (productLocationKey) => {
      setCreatedTasks(new Set(createdTasks).add(productLocationKey));
    },
    [createdTasks]
  );

  const appBarActions = [
    {
      name: "close",
      icon: <FixaSSRIcon paths={CrossIconPath} />,
      onClick: () => push("/home?newTask=true"),
      position: "right",
    },
  ];

  useEffect(() => {
    if (!nearbyProductsLoading && !filteredNearbyProducts.length) {
      push("/home?newTask=true");
    }
  }, [filteredNearbyProducts, nearbyProductsLoading, push]);

  return (
    <FullScreenPopup
      appBarConfig={{
        title: <Trans>addNearbyAddonsString</Trans>,
        actions: appBarActions,
      }}
      noPadding
    >
      {filteredNearbyProducts
        .slice(0, page * PAGE_LENGTH)
        .map(({ product_article_id, product_article_type, location }) => (
          <NearbyAddonProductRow
            key={`${product_article_id}-${location}`}
            type={product_article_type}
            art={product_article_id}
            location={location}
            teamName={teamName}
            initialTask={initialTask}
            postTaskCreate={postTaskCreate}
          />
        ))}
      {!nearbyProductsLoading &&
        filteredNearbyProducts.length > page * PAGE_LENGTH && (
          <StyledButton
            text={<Trans>loadMore</Trans>}
            onClick={() => {
              setPage(page + 1);
            }}
          />
        )}
      {!nearbyProductsLoading && (
        <NearbyDescription>
          <Trans>nearbyAddonsDescriptionString</Trans>
        </NearbyDescription>
      )}
    </FullScreenPopup>
  );
}
