import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Header, Button, RadioButton } from "@coworker/components";
import taskTypeOptions from "@coworker/enums/taskType";
import {
  useUpdateUserPreferences,
  useUserPreferences,
} from "../../hooks/useProfilePreferencesQuery";
import preferences from "@coworker/enums/profilePreferences";
import SortFilter from "./Filters/SortFilter";
import { sortingTypes } from "@coworker/types/lib/taskSortingTypes";
import { taskStatusFilterTypes } from "@coworker/types/lib/taskStatusFilterTypes";
import { taskStateFilterTypes } from "@coworker/types/lib/taskStateFilterTypes";
import { closedReasonFilterTypes } from "@coworker/types/lib/closedReasonFilterTypes";
import { TaskGroupBy } from "../TaskGroupBy";
import { groupByTypes } from "../../constants/groupBy";
import FixaCheckbox from "@coworker/apprestructured/src/shared/wrappers/FixaCheckbox/FixaCheckbox";
import {
  FixaAccordion,
  FixaAccordionItem,
} from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";
import SkapaTabs from "./SkapaTabs";

import {
  getRelevantLocationFields,
  markethallRegEx,
  showroomRegEx,
  isRefillOrPickAndRefill,
} from "../../helpers/taskFilters";
import { taskFilters } from "../../constants/filters";
import {
  showGroupTaskFilter,
  showGroupPackageFilter,
  showGroupGridCodeFilter,
} from "../../helpers/taskGrouping";
import { useStoreTeams } from "../../core/hooks/useStoreTeams";
import { useTeamId } from "../../core/auth/useLoggedInUser";
import tracker from "../../helpers/tracker";
import { TaskStatusFilter } from "./Filters/TaskStatusFilter";
import { TaskStateFilter } from "./Filters/TaskStateFilter";
import { ClosedReasonFilter } from "./Filters/ClosedReasonFilter";
import useFlag, { FLAGS } from "../../hooks/useFlag";

const typesWithLocation = [
  taskFilters.PICK_AND_REFILL,
  taskFilters.REFILL,
  taskFilters.PRODUCT_ISSUE,
  taskFilters.ALL,
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background: var(--white);
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const RadioButtonWrapper = styled.div`
  min-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FiltersWrapper = styled.div`
  overflow: scroll;
  height: 100%;
`;
const Filter = styled.div`
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 39px;
`;

const FilterName = styled.div`
  margin-right: 20px;
  font-size: 14px;
  ${(props) => (props.uppercaseLocation ? "text-transform:uppercase" : "")};
`;
const Title = styled.div`
  margin: 10px 24px;
  font-weight: bold;
  font-size: 18px;
`;
const ButtonWrapper = styled.div`
  background: var(--white);
  border-top: 1px solid var(--grey100);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 60px;
  & > button {
    width: 45%;
    height: 40px;
    & > div {
      padding: 0;
    }
  }
`;
const StyledButton = styled(Button)`
  background: var(--grey100);
  color: ${(props) => (props.disabled ? "var(--grey500)" : "var(--grey900)")};
`;

const StyledSkapaTabs = styled.div`
  margin-left: 24px;
`;

const TopDivider = styled.div`
  border-top: solid 1px var(--grey200);
  background-color: var(--grey100);
  width: 100%;
  height: 6px;
`;
const SectionDivider = styled(TopDivider)`
  margin-top: 12px;
`;

// NOTE: The `type` field is also used for finding translation strings
const options = [
  { type: taskFilters.ALL, name: <Trans>userTaskFilterAllString</Trans> },
  {
    type: taskFilters.PICK_AND_REFILL,
    name: <Trans>userTaskFilterPickAndRefillString</Trans>,
  },
  { type: taskFilters.REFILL, name: <Trans>userTaskFilterRefillString</Trans> },
  {
    type: taskFilters.PRODUCT_ISSUE,
    name: <Trans>userTaskFilterProductString</Trans>,
  },
  {
    type: taskFilters.PRODUCT_QUALITY,
    name: <Trans>productQualityReportString</Trans>,
  },
  {
    type: taskFilters.TESTBUY_FOLLOW_UP,
    name: <Trans>userTaskFilterTestBuyFollowUpString</Trans>,
  },
  {
    type: taskFilters.MFAQ_FOLLOW_UP,
    name: <Trans>userTaskFilterMFAQFollowUpString</Trans>,
  },
  { type: taskFilters.CUSTOM, name: <Trans>userTaskFilterCustomString</Trans> },
  // { type: "recurring", name: <Trans>userTaskFilterRecurringString</Trans> },
  // { type: "other", name: <Trans>otherString</Trans> },
  { type: taskFilters.ROOM_SETTINGS, name: <Trans>roomSettingsString</Trans> },
];

// NOTE: the strings in locationOptions and addonLocationOptions are also used for finding translation strings, i.e. t(`${option}String`)
const locationOptions = [
  "markethall",
  "showroom",
  "other",
  "warehouse",
  "wholeStore",
];

const emptySettings = {
  [preferences.TASK_FILTERS]: [taskFilters.ALL],
  [preferences.LOCATION_FILTERS]: [],
  [preferences.LOCATION_GROUP]: [],
  [preferences.ADDON_OPTION]: [],
  [preferences.TEAM_FILTER]: "",
  [preferences.TASK_SORT_FILTERS]: sortingTypes.BY_NEWEST_FIRST,
  [preferences.TASK_GROUP_BY_FILTERS]: groupByTypes.NO_GROUPING,
  [preferences.TASK_STATUS_FILTERS]: taskStatusFilterTypes.ALL_COMPLETED_TASKS,
  [preferences.CLOSED_REASON_FILTERS]:
    closedReasonFilterTypes.ALL_CLOSED_REASONS,
  [preferences.TASK_STATE_FILTER]: taskStateFilterTypes.ALL_STATES,
};

export function useTaskListFilters() {
  const { data: userPreferences } = useUserPreferences(
    preferences.TASK_FILTERS_ALL
  );

  const activeFilters = userPreferences?.[preferences.TASK_FILTERS] ?? [
    taskFilters.ALL,
  ];

  const activeTaskStateFilter =
    userPreferences?.[preferences.TASK_STATE_FILTER] ??
    taskStateFilterTypes.ALL_STATES;

  const activeTaskStatusFilters =
    userPreferences?.[preferences.TASK_STATUS_FILTERS] ??
    taskStatusFilterTypes.ALL_COMPLETED_TASKS;

  const activeClosedReasonFilters =
    userPreferences?.[preferences.CLOSED_REASON_FILTERS] ??
    closedReasonFilterTypes.ALL_CLOSED_REASONS;

  const activeTaskSortFilters =
    userPreferences?.[preferences.TASK_SORT_FILTERS] ??
    sortingTypes.BY_NEWEST_FIRST;

  const activeLocationFilters =
    userPreferences?.[preferences.LOCATION_FILTERS] ?? [];

  const activeTeamFilter = userPreferences?.[preferences.TEAM_FILTER] ?? "";

  const locationGroup = userPreferences?.[preferences.LOCATION_GROUP] ?? [];

  const isFiltering =
    !!locationGroup.length ||
    !activeFilters.includes(taskFilters.ALL) ||
    (activeLocationFilters.length &&
      !activeLocationFilters.includes("allLocations")) ||
    activeTaskStatusFilters !== taskStatusFilterTypes.ALL_COMPLETED_TASKS ||
    activeTaskStateFilter !== taskStateFilterTypes.ALL_STATES ||
    activeClosedReasonFilters !== closedReasonFilterTypes.ALL_CLOSED_REASONS ||
    activeTeamFilter !== "";

  const groupByActiveOption = userPreferences?.[
    preferences.TASK_GROUP_BY_FILTERS
  ] ?? [groupByTypes.NO_GROUPING];

  const isGrouping = !groupByActiveOption.includes(groupByTypes.NO_GROUPING);
  const isSorting = !activeTaskSortFilters.includes(
    sortingTypes.BY_NEWEST_FIRST
  );

  const updatePreferences = useUpdateUserPreferences(
    preferences.TASK_FILTERS_ALL
  );

  const resetTaskListFilters = React.useCallback(() => {
    if (isFiltering || isGrouping || isSorting) {
      updatePreferences.mutate(emptySettings);
    }
  }, [updatePreferences, isFiltering, isGrouping, isSorting]);

  return {
    activeFilters,
    isFiltering,
    isGrouping,
    groupByActiveOption,
    resetTaskListFilters,
    locationGroup,
    activeTaskSortFilters,
    activeTaskStatusFilters,
    activeClosedReasonFilters,
    activeTeamFilter,
    activeTaskStateFilter,
  };
}

const filterByLocationAll = [
  "allShowroomString",
  "allMarkethallString",
  "allWarehouseLocationsString",
  "allOtherLocationsString",
  "allLocationsString",
];

const filterAll = (checked) => checked?.includes(taskFilters.ALL);
const filterPickedAndRefill = (checked) =>
  checked?.includes(taskFilters.PICK_AND_REFILL);
const filterRefill = (checked) => checked?.includes(taskFilters.REFILL);
const filterProduct = (checked) => checked?.includes(taskFilters.PRODUCT_ISSUE);

const filterMFAQFollowup = (checked) =>
  checked?.includes(taskFilters.MFAQ_FOLLOW_UP);
const filterTestbuyFollowup = (checked) =>
  checked?.includes(taskFilters.TESTBUY_FOLLOW_UP);
const filterCustom = (checked) => checked?.includes(taskFilters.CUSTOM);
const filterRecurring = (checked) => checked?.includes(taskFilters.RECURRING);
const filterProductQuality = (checked) =>
  checked?.includes(taskFilters.PRODUCT_QUALITY);
const filterOther = (checked) => checked?.includes(taskFilters.OTHER);
const filterRoomSettings = (checked) =>
  checked?.includes(taskFilters.ROOM_SETTINGS);

const filterPickingAddon = (addonOption) =>
  addonOption?.includes("pickingFilter");
const filterRefillAddon = (addonOption) =>
  addonOption?.includes("refillFilter");
const isTestbuyFollowup = (task) =>
  task.task_type === taskTypeOptions.TESTBUY_FOLLOW_UP;
const isMFAQFollowup = (task) =>
  task.task_type === taskTypeOptions.MFAQ_FOLLOW_UP;
const isCustom = (task) => task.task_type === taskTypeOptions.CUSTOM;
const isRecurring = (task) => task.task_type === taskTypeOptions.PLANNED;
const isAddon = (task) => task.task_type === taskTypeOptions.ADDON;
const isProduct = (task) => task.task_type === taskTypeOptions.PRODUCT_ISSUE;
const isProductQuality = (task) =>
  task.task_type === taskTypeOptions.PRODUCT_QUALITY;
const isPicked = (task) => task.picked;
const isRoomSettings = (task) =>
  task.task_type === taskTypeOptions.ROOM_SETTINGS;

const hasCustomPickupLocation = (task) => task?.pickup_location_custom || false;
const hasCustomLocation = (task) => task?.location_custom || false;
const hasPickupLocation = (task) => task?.pickup_location || false;
const hasLocation = (task) => task.location || false;

const filterByType = (task, checked) => {
  if (filterAll(checked)) {
    return true;
  } else if (filterPickedAndRefill(checked)) {
    return isAddon(task) && !isPicked(task);
  } else if (filterRefill(checked)) {
    return isAddon(task) && isPicked(task);
  } else if (filterProduct(checked)) {
    return isProduct(task);
  } else if (filterTestbuyFollowup(checked)) {
    return isTestbuyFollowup(task);
  } else if (filterMFAQFollowup(checked)) {
    return isMFAQFollowup(task);
  } else if (filterCustom(checked)) {
    return isCustom(task);
  } else if (filterRecurring(checked)) {
    return isRecurring(task);
  } else if (filterProductQuality(checked)) {
    return isProductQuality(task);
  } else if (filterRoomSettings(checked)) {
    return isRoomSettings(task);
  } else if (filterOther(checked)) {
    return !isAddon(task) && !isProduct(task);
  } else {
    return false;
  }
};
const filterByAddonOption = (task, checked, addonOption) => {
  if (filterOther(checked)) {
    if (filterPickingAddon(addonOption)) {
      return hasCustomPickupLocation(task);
    } else {
      return hasCustomLocation(task);
    }
  } else {
    if (filterPickingAddon(addonOption)) {
      return hasPickupLocation(task);
    } else if (filterRefillAddon(addonOption)) {
      return hasLocation(task) || hasCustomLocation(task);
    } else {
      return hasLocation(task) || hasPickupLocation(task);
    }
  }
};

const getLocationsList = (tasks = [], locationMenu, addonOption, checked) => {
  if (locationMenu?.includes("wholeStore")) return ["allLocationsString"];
  if (locationMenu?.includes("warehouse"))
    return ["allWarehouseLocationsString"];
  if (locationMenu?.includes("other")) return ["allOtherLocationsString"];

  const regex =
    (locationMenu?.includes("markethall") && markethallRegEx) ||
    (locationMenu?.includes("showroom") && showroomRegEx);

  const locationSet = new Set();

  // Collect location options based on all the relevant fields
  const fields = getRelevantLocationFields(addonOption, checked);

  function adder(task) {
    for (const field of fields) {
      const string = task[field];
      if (regex.test(string)) {
        locationSet.add(string.slice(0, 3) + " •" + string.slice(8));
      }
    }
  }

  for (const task of tasks) {
    if (!regex) break;
    if (
      filterByType(task, checked) &&
      filterByAddonOption(task, checked, addonOption)
    ) {
      adder(task);
    }
  }

  const list = [...locationSet];
  list.sort();
  if (locationMenu?.includes("markethall")) list.unshift("allMarkethallString");
  if (locationMenu?.includes("showroom")) list.unshift("allShowroomString");
  return list;
};

function typeHasLocation(checked) {
  return checked.some((type) => typesWithLocation?.includes(type));
}

export default function TaskListFilters({ onClose, onSubmit, title, tasks }) {
  const updatePreferences = useUpdateUserPreferences(
    preferences.TASK_FILTERS_ALL
  );
  const { data: userPreferences } = useUserPreferences(
    preferences.TASK_FILTERS_ALL
  );
  const isRelated = window.location.pathname.includes("/tasks/related/");

  const [checked, setChecked] = React.useState(
    userPreferences?.[preferences.TASK_FILTERS] ?? [taskFilters.ALL]
  );
  const [taskState, setTaskState] = React.useState(
    userPreferences?.[preferences.TASK_STATE_FILTER] ??
      taskStateFilterTypes.ALL_STATES
  );
  const [sortOrder, setSortOrder] = React.useState(
    userPreferences?.[preferences.TASK_SORT_FILTERS] ??
      sortingTypes.BY_NEWEST_FIRST
  );
  const [checkedLocation, setCheckedLocation] = React.useState(
    userPreferences?.[preferences.LOCATION_FILTERS] ?? []
  );

  const [checkedTeam, setCheckedTeam] = React.useState(
    userPreferences?.[preferences.TEAM_FILTER] ?? ""
  );
  const [checkedAddon, setCheckedAddon] = React.useState(
    userPreferences?.[preferences.ADDON_OPTION] ?? []
  );
  const [locationMenu, setLocationMenu] = React.useState(
    userPreferences?.[preferences.LOCATION_GROUP] ?? []
  );
  const [selectedGroupFilter, setSelectedGroupFilter] = React.useState(
    userPreferences?.[preferences.TASK_GROUP_BY_FILTERS] ??
      groupByTypes.NO_GROUPING
  );

  const [taskStatusFilter, setTaskStatusFilters] = React.useState(
    userPreferences?.[preferences.TASK_STATUS_FILTERS] ??
      taskStatusFilterTypes.ALL_COMPLETED_TASKS
  );
  const [closedReasonFilters, setClosedReasonFilters] = React.useState(
    userPreferences?.[preferences.CLOSED_REASON_FILTERS] ??
      closedReasonFilterTypes.ALL_CLOSED_REASONS
  );

  const onChange = (filter) => {
    setChecked([filter]);
    if (filter === taskFilters.PICK_AND_REFILL) {
      setCheckedAddon(["pickingFilter"]);
    }
  };

  const onChangeAddon = (filter) => setCheckedAddon([filter]);
  const [savedSelections, setSavedSelections] = React.useState({});
  const [locationData, setLocationData] = React.useState(() =>
    getLocationsList(tasks, locationMenu, checkedAddon, checked)
  );

  React.useEffect(() => {
    if (!checkedAddon.length) {
      if (checked?.includes(taskFilters.PICK_AND_REFILL)) {
        setCheckedAddon(["pickingFilter"]);
      } else if (checked?.includes(taskFilters.REFILL)) {
        setCheckedAddon(["refillFilter"]);
      }
    } else if (
      checked?.includes(taskFilters.REFILL) &&
      !checkedAddon?.includes("refillFilter")
    ) {
      setCheckedAddon(["refillFilter"]);
    } else if (!isRefillOrPickAndRefill(checked)) {
      setCheckedAddon([]);
    }
  }, [checkedAddon, checked]);

  React.useEffect(() => {
    setLocationData(
      getLocationsList(tasks, locationMenu, checkedAddon, checked)
    );
  }, [tasks, locationMenu, checkedAddon, checked]);

  React.useEffect(() => {
    if (locationMenu?.includes("showroom") && !checkedLocation.length) {
      if (savedSelections["showroom"]) {
        setCheckedLocation(savedSelections["showroom"]);
      } else {
        setCheckedLocation(["allShowroomString"]);
      }
    }

    if (locationMenu?.includes("markethall") && !checkedLocation.length) {
      if (savedSelections["markethall"]) {
        setCheckedLocation(savedSelections["markethall"]);
      } else {
        setCheckedLocation(["allMarkethallString"]);
      }
    }

    if (locationMenu?.includes("warehouse") && !checkedLocation.length) {
      if (savedSelections["warehouse"]) {
        setCheckedLocation(savedSelections["warehouse"]);
      } else {
        setCheckedLocation(["allWarehouseLocationsString"]);
      }
    }
    if (locationMenu?.includes("other") && !checkedLocation.length) {
      if (savedSelections["other"]) {
        setCheckedLocation(savedSelections["other"]);
      } else {
        setCheckedLocation(["allOtherLocationsString"]);
      }
    }
    if (locationMenu?.includes("wholeStore") && !checkedLocation.length) {
      if (savedSelections["wholeStore"]) {
        setCheckedLocation(savedSelections["wholeStore"]);
      } else {
        setCheckedLocation(["allLocationsString"]);
      }
    }
  }, [locationMenu, checkedLocation, setCheckedLocation, savedSelections]);

  const pickAndRefillTabs = [
    {
      key: "pickingFilter",
      title: <Trans>pickupLocation</Trans>,
      testId: "pickingFilterButton",
    },
    {
      key: "refillFilter",
      title: <Trans>refillLocString</Trans>,
      testId: "refillFilterButton",
    },
  ];

  const selectedLocationIsLocationAll = (selectedLocation) => {
    return filterByLocationAll?.includes(selectedLocation);
  };

  const handleOnClick = (selectedLocation) => {
    const codes = new Set(checkedLocation);
    if (codes.has(selectedLocation)) {
      codes.delete(selectedLocation);
    } else {
      codes.add(selectedLocation);
    }
    if (!selectedLocationIsLocationAll(selectedLocation)) {
      setCheckedLocation(
        [...codes].filter((e) => !filterByLocationAll?.includes(e))
      );
    } else {
      setCheckedLocation(
        [...codes].filter((e) => filterByLocationAll?.includes(e))
      );
    }
  };

  const locationAccordionItems = locationOptions
    .filter((option) => option !== "wholeStore")
    .map((option) => (
      <FixaAccordionItem
        key={option}
        id={option}
        title={<Trans>{String(option) + "String"}</Trans>}
        onHeadingClicked={() => {
          !!locationMenu.length &&
            setSavedSelections({
              ...savedSelections,
              [locationMenu]: checkedLocation,
            });
          setLocationMenu([option]);
          setCheckedLocation([]);
        }}
        open={false}
        children={
          locationMenu?.includes(option) &&
          locationData.map((location) => (
            <Filter
              key={location}
              onClick={() => handleOnClick(location)}
              data-testid={location}
            >
              <FilterName uppercaseLocation={location.match(/all/g)}>
                {filterByLocationAll?.includes(location) ? (
                  <Trans>{location}</Trans>
                ) : (
                  location
                )}
              </FilterName>
              <FixaCheckbox
                id={location}
                subtle={true}
                value={location}
                readOnly
                checked={checkedLocation?.includes(location)}
                data-testid={location + "Button"}
              />
            </Filter>
          ))
        }
      />
    ));

  React.useEffect(() => {
    if (
      (!showGroupTaskFilter(checked) &&
        selectedGroupFilter === groupByTypes.BY_TASK_TYPE) ||
      (!showGroupPackageFilter(checked) &&
        selectedGroupFilter === groupByTypes.BY_PACKAGE_TYPE) ||
      (!showGroupGridCodeFilter(checked) &&
        selectedGroupFilter === groupByTypes.BY_GRID_CODE)
    )
      setSelectedGroupFilter(groupByTypes.NO_GROUPING);
  }, [checked, selectedGroupFilter]);

  const { resetTaskListFilters } = useTaskListFilters();

  const teamId = useTeamId();
  const isUserPartOfPQTeam = useStoreTeams().find(
    (team) => team.id === teamId
  )?.is_product_quality;

  const hideForGermanyUsers = useFlag(FLAGS.GERMANY_ROLLOUT);

  const filteredTaskTypes = React.useMemo(() => {
    let filteredOptions = options;
    if (!isUserPartOfPQTeam) {
      filteredOptions = filteredOptions.filter(
        (option) => option.type !== taskFilters.PRODUCT_QUALITY
      );
    }

    if (hideForGermanyUsers) {
      filteredOptions = filteredOptions.filter(
        (option) => option.type !== taskFilters.ROOM_SETTINGS
      );
    }
    return filteredOptions;
  }, [isUserPartOfPQTeam, hideForGermanyUsers]);

  // TODO: Temporarily disable grouping. Bugs with height (related) and not grouping at all (activity)
  const showGroupBy =
    !window.location.pathname.includes("/activity") &&
    !window.location.pathname.includes("/tasks/related");

  const showAssignedTo =
    !window.location.href.includes("tasks?active=my") &&
    !window.location.href.includes("tasks?active=open") &&
    !window.location.href.includes("tasks/activity/tasks/in_progress");

  const isCompletedListing = window.location.pathname.endsWith("completed");
  const isClosedListing = window.location.pathname.endsWith("closed");

  const showTaskStatusFilter =
    isCompletedListing && isRefillOrPickAndRefill(checked);

  const storeTeams = useStoreTeams();

  const updateUserPreferences = () => {
    const isTypeHasLocation = typeHasLocation(checked);
    const newPreferences = {
      [preferences.TASK_STATUS_FILTERS]: taskStatusFilter,
      [preferences.TASK_STATE_FILTER]: taskState,
      [preferences.CLOSED_REASON_FILTERS]: closedReasonFilters,
      [preferences.TASK_SORT_FILTERS]: sortOrder,
      [preferences.TASK_GROUP_BY_FILTERS]: selectedGroupFilter,
      [preferences.TEAM_FILTER]: checkedTeam,
      [preferences.TASK_FILTERS]: checked,
      [preferences.ADDON_OPTION]: checkedAddon,
      [preferences.LOCATION_FILTERS]: isTypeHasLocation ? checkedLocation : [],
      [preferences.LOCATION_GROUP]: isTypeHasLocation ? locationMenu : [],
    };
    updatePreferences.mutate(newPreferences, {});
  };

  return (
    <Container>
      <Header title={title} onClose={() => onClose()} showRightClose border />
      <FiltersWrapper>
        <React.Fragment>
          <TopDivider />
          <Title>
            <Trans>TasksString</Trans>
          </Title>
          {filteredTaskTypes.map((filter) => {
            return (
              <Filter
                key={filter.type}
                data-testid={`filter-${filter.type}`}
                onClick={() => onChange(filter.type)}
              >
                <FilterName>{filter.name}</FilterName>
                <RadioButtonWrapper key={filter.type}>
                  <RadioButton
                    key={filter.type}
                    readOnly
                    checked={checked?.includes(filter.type)}
                    data-testid={filter.type + "Button"}
                  />
                </RadioButtonWrapper>
              </Filter>
            );
          })}
        </React.Fragment>

        {showTaskStatusFilter && (
          <>
            <SectionDivider />
            <TaskStatusFilter
              filterSetter={setTaskStatusFilters}
              selectedFilter={taskStatusFilter}
            />
          </>
        )}

        {isRelated && (
          <>
            <SectionDivider />
            <TaskStateFilter
              filterSetter={setTaskState}
              selectedFilter={taskState}
            />
          </>
        )}

        {isClosedListing && (
          <>
            <SectionDivider />
            <ClosedReasonFilter
              filterSetter={setClosedReasonFilters}
              selectedFilter={closedReasonFilters}
            />
          </>
        )}

        <SectionDivider />
        <SortFilter filterSetter={setSortOrder} selectedFilter={sortOrder} />

        {showGroupBy && (
          <>
            <SectionDivider />
            <TaskGroupBy
              activeSelected={selectedGroupFilter}
              setActiveSelected={setSelectedGroupFilter}
              selectedFilter={checked}
            />
          </>
        )}

        <React.Fragment>
          <SectionDivider />
          {checked?.includes(taskFilters.PICK_AND_REFILL) && (
            <StyledSkapaTabs>
              <SkapaTabs
                data-testid="tabs"
                tabs={pickAndRefillTabs}
                onTabChanged={(key) => {
                  onChangeAddon(key);
                }}
              />
            </StyledSkapaTabs>
          )}

          <Title
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Trans>locationFilterString</Trans>
            <FixaCheckbox
              id={"select-all"}
              label={"Select all"}
              value={"Select all"}
              subtle={true}
              onChange={() => {
                if (checkedLocation?.includes("allLocationsString")) {
                  setCheckedLocation([]);
                  setLocationMenu([]);
                } else {
                  setCheckedLocation(["allLocationsString"]);
                  setLocationMenu(["wholeStore"]);
                }
              }}
              checked={checkedLocation?.includes("allLocationsString")}
              data-testid={"selectAllLocationsCheckbox"}
            />
          </Title>
          <FixaAccordion key={"locationAccordion"} padded>
            {locationAccordionItems}
          </FixaAccordion>
        </React.Fragment>

        {showAssignedTo && (
          <React.Fragment>
            <SectionDivider />
            <Title>
              <Trans>assignedToTaskFilterString</Trans>
            </Title>

            <Filter //All Teams button
              onClick={() => {
                setCheckedTeam("");
              }}
            >
              <FilterName>All Teams</FilterName>
              <RadioButtonWrapper>
                <RadioButton
                  readOnly
                  checked={!checkedTeam}
                  data-testid={"All teams Button"}
                />
              </RadioButtonWrapper>
            </Filter>

            {storeTeams.map(
              (
                team //Store teams buttons
              ) => (
                <Filter
                  key={team.id}
                  data-testid={`filter-${team.id}`}
                  onClick={() => {
                    setCheckedTeam(team.id);
                  }}
                >
                  <FilterName>{team.name}</FilterName>
                  <RadioButtonWrapper key={team.id}>
                    <RadioButton
                      key={team.id}
                      readOnly
                      checked={checkedTeam?.includes(team.id)}
                      data-testid={team.name + "Button"}
                    />
                  </RadioButtonWrapper>
                </Filter>
              )
            )}
          </React.Fragment>
        )}
      </FiltersWrapper>

      <ButtonWrapper>
        <StyledButton
          data-testid="filterResetButton"
          primary
          dark
          text={<Trans>resetString</Trans>}
          onClick={() => {
            resetTaskListFilters();
            onSubmit();
            tracker.trackFilterResetButton();
          }}
        />
        <Button
          data-testid="filterApplyButton"
          primary
          dark
          text={<Trans>applyString</Trans>}
          onClick={() => {
            updateUserPreferences();
            tracker.trackFilterApplyButton();
            tracker.TasksFilters.trackFilters({
              sortOrder,
              selectedGroupFilter,
              checked,
              checkedLocation,
              locationMenu,
            });
            onSubmit();
          }}
        />
      </ButtonWrapper>
    </Container>
  );
}
