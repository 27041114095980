import { firebase } from "@coworker/common/config/firebase";
//import promised from "@coworker/functions/src/shared/promised";
import { fetchFromCoreService } from "./core.service";
import { rerouteToTasksService } from "./tasks.service.helper";
import { getTokenAsync } from "./getAuthToken";

/**
 * @param {string} which
 */
export async function callSameOriginInternalApi(which, postdata = {}) {
  // It seems we can only support one region per rewrite, if it needs to be defined in firebase.json
  if (
    process.env["REACT_APP_ENV"] === "prod" &&
    process.env["REACT_APP_PROJECT_ID"].includes("ikea-coworker-app-") // Only EU and in deployed state supported, for now.
  ) {
    return callInternalApi(which, postdata);
  }

  const url = `${process.env.PUBLIC_URL || ""}/v0/api/${which}`;
  const _clientVersion = require("@coworker/app/src/core/version.json").version;
  const token = await getTokenAsync();
  if (!token) {
    console.error("No token for callSameOriginInternalApi", { which });
    return;
  }
  const { uid } = firebase.auth().currentUser;
  console.log("callSameOriginInternalApi", { url, token, uid, postdata });

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      uid,
      ...postdata,
      _clientVersion,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json(); // Needs await to fetch and parse the body.
  return { data }; // return as { data } to match Firebase function call response.
}

const NOT_YET_ON_V1 = [
  "global_config",
  "metadata/deployed_version",
  "my/store",
  "my/events",
  "my/unreads",
  "user",
  "teams",
];

const NOT_YET_ON_CORE_SERVICE = [
  "global_config",
  "metadata/deployed_version",
  "my/events",
  "my/unreads",
];

export async function callInternalApi(
  method,
  postdata,
  forceCallTasksService = false,
  ignoreForwardToTasksService = false,
  serviceToCall = "TASKS_SERVICE"
) {
  if (serviceToCall === "TASKS_SERVICE") {
    if (NOT_YET_ON_V1.find((path) => method.includes(path))) {
    } else {
      const result = await rerouteToTasksService(method, postdata);
      return result;
    }
  } else if (serviceToCall === "CORE_SERVICE") {
    if (!NOT_YET_ON_CORE_SERVICE.find((path) => method.includes(path))) {
      const result = await fetchFromCoreService(method, postdata);
      return result;
    }
  }
}

function tooOld(at, maxStale) {
  return at < Date.now() - maxStale;
}

const cache = {};
const fetching = {};

// TODO maxStale = Inf if offline
export function getLatestCacheValue(method) {
  const [last] = cache[method] || [];
  return last;
}

// See also TS-ified version getCachedUsersOrContacts in hooks/useStoreContacts.ts
export async function getCachedInternalApi(
  method,
  callback,
  maxStale = 10_000,
  forceCallTasksService = false,
  serviceToCall,
  postData = {}
) {
  if (!method) return;
  const [last, at = 0] = cache[method] || [];
  if (last) callback(last); // TODO: pretend like cache is missing if extremely old, refresh async and return stale if medium old.

  // Refresh if stale
  if (tooOld(at, maxStale) && !fetching[method]) {
    fetching[method] = callInternalApi(
      method,
      postData,
      forceCallTasksService,
      false,
      serviceToCall
    );
  }

  if (fetching[method]) {
    const response = await fetching[method];
    fetching[method] = undefined;
    if (response) {
      cache[method] = [response, Date.now()];
      callback(response);
    }
  }
}
