import CircleFilledIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CircleFilledIconPath";
import CircleHalfIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CircleHalfIconPath";
import CircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CircleIconPath";
import IncorrectIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/IncorrectIconPath";

export const getStockStatusIndicatorColor = (
  available: number,
  elevated: null | number
) => {
  if (available > 0) {
    return "green";
  } else if (available === 0 && elevated && elevated > 0) {
    return "orange";
  } else if (available === 0 && elevated === 0) {
    return "red";
  } else {
    return "grey";
  }
};

export const getStockStatusText = (
  available: number,
  elevated: null | number
) => {
  if (available > 0) {
    return "inStockTitle";
  } else if (available === 0 && elevated && elevated > 0) {
    return "lowInStockTitle";
  } else if (available === 0 && (elevated === 0 || elevated === null)) {
    return "outOfStockTitle";
  } else {
    return "loadingString";
  }
};

export const getStockStatusIcon = (
  available: number,
  elevated: null | number
) => {
  if (available > 0) {
    return CircleFilledIconPath;
  } else if (available === 0 && elevated && elevated > 0) {
    return CircleHalfIconPath;
  } else if (available === 0 && elevated === 0) {
    return CircleIconPath;
  } else {
    return IncorrectIconPath;
  }
};
