import React from "react";
import { useTranslation } from "@coworker/locales";

import styled from "styled-components";
import { SimpleContainer } from "../styles/styles";
import { Text } from "../Atoms/Text";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { DeleteArticleConfirmModal } from "../Organisms/Modals/DeleteArticleConfirmModal";
import TrashCanIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TrashCanIconPath";

import { Article } from "../types/article";
import { Room } from "../types/room";

const RowContainerBorderBottom = styled(SimpleContainer)`
  border-bottom: 1px solid var(--grey200);
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

interface DeleteArticleRowProps {
  index: number;
  room: Room;
  article: Article;
  articleDeleted: (article: Article) => Promise<void>;
}

export function DeleteArticleRow({
  index,
  room,
  article,
  articleDeleted,
}: DeleteArticleRowProps) {
  const { t } = useTranslation();

  const [showDeleteArticleApprovalModal, setShowDeleteArticleApprovalModal] =
    React.useState(false);

  return (
    <>
      <RowContainerBorderBottom key={index} $direction={"row"}>
        <LeftContainer>
          <Text
            text={article.groupName ? t("Group") : t("roomSettingsString")}
            bold
          />
          <Text text={article.groupName ? article.groupName : room.name} />
        </LeftContainer>
        <FixaButton
          ssrIcon={TrashCanIconPath}
          onClick={() => setShowDeleteArticleApprovalModal(true)}
          iconOnly
          type="tertiary"
          style={{
            color: "var(--pink)",
          }}
        />
      </RowContainerBorderBottom>
      {showDeleteArticleApprovalModal && (
        <DeleteArticleConfirmModal
          article={article}
          noInvalidateQueries={true}
          onModalOpenChange={() => setShowDeleteArticleApprovalModal(false)}
          postDeleteFunction={async () => await articleDeleted(article)}
        />
      )}
    </>
  );
}
