export function generateLivligProductLink(storeId: string, productId: string) {
  if (!storeId || !productId) return null;
  const reducedStoreId = storeId.split("#")[0] || "";
  const zeroFill = 5 - reducedStoreId.length;
  const store = "STO" + "0".repeat(Math.max(zeroFill, 0)) + reducedStoreId;

  return `https://item-sales.livlig.ingka.com/locations/SellingUnit/${store}/Item/${productId}`;
}

export function generateLivligProductsLink(
  storeId: string,
  articleNumbers: string
) {
  return `https://item-sales.livlig.ingka.com/locations/SellingUnit/STO00${storeId.slice(
    0,
    3
  )}/Items/${articleNumbers}?disableNavigation=1`; // &groupTabType=Items`; // Items can be insights
}
