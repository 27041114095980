import { useAPIRequest } from "./useAPIRequest";

async function parseJson(response) {
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch (err) {
    throw new Error("Did not receive JSON, instead received: " + text);
  }
}

const getCallUrlRequestPromise = (url, requestOptions) => () =>
  fetch(url, requestOptions).then(parseJson);

export default function useCallUrl(
  url,
  requestOptions = {},
  options = {},
  processResponseFunction
) {
  return useAPIRequest(
    getCallUrlRequestPromise(url, requestOptions),
    `${url}:${JSON.stringify(requestOptions)}`,
    options,
    processResponseFunction
  );
}
