import React from "react";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { Group } from "../../types/groups";
import { ModalTypes } from "../../types/views";
import { useTranslation } from "@coworker/locales";
import { FullWidthButton } from "../../../SkapaButton";
import { PleaseFillIn } from "../../../Reusable/PleaseFillIn";
import { InputPopupContainer } from "../../styles/styles";
import { useUpdateGroup } from "../../hooks/useUpdateGroup";
import { logEvent } from "../../../../helpers/tracker";
import { useToastNotification } from "@coworker/components";

interface EditGroupModalProps {
  modalVisibility: boolean;
  currentGroup: Group;
  groups: Group[];
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export function EditGroupModal({
  modalVisibility,
  currentGroup,
  groups,
  onTriggerNewModal,
}: EditGroupModalProps) {
  const { showToast } = useToastNotification();

  const [newGroupName, setNewGroupName] = React.useState<string>(
    currentGroup?.name ?? ""
  );
  const [isDuplicateGroupName, setIsDuplicateGroupName] =
    React.useState<boolean>(false);

  const updateGroup = useUpdateGroup(currentGroup?.id || "");

  const { t } = useTranslation();

  if (!currentGroup) return <></>;

  return (
    <FixaModal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
      }}
    >
      <FixaSheets
        labelledById="edit-group-modal"
        size={"small"}
        header={
          <FixaModalHeader
            ariaCloseTxt="Close"
            title={`${t("editGroupString")}`}
          />
        }
        footer={
          <FixaModalFooter>
            <FullWidthButton
              disabled={isDuplicateGroupName || newGroupName === ""}
              text={t("editGroupString")}
              type={"primary"}
              onClick={async () => {
                const updatedGroupWithNewName = {
                  name: newGroupName,
                } as Group;

                await updateGroup.mutateAsync(updatedGroupWithNewName);

                logEvent("ce:editSelectedGroup");
                setIsDuplicateGroupName(false);
                onTriggerNewModal(ModalTypes.DEFAULT_VIEW);

                showToast(t("groupUpdatedString"));
              }}
              data
            />
          </FixaModalFooter>
        }
      >
        <InputPopupContainer>
          <FixaInputField
            id={"newRoomName"}
            type={"text"}
            label={t("nameOfGroupString")}
            value={newGroupName}
            onChange={(event) => {
              const newName = event.target.value as string;
              const isDuplicate = groups.some(
                (group) => group.name === event.target.value
              );
              setIsDuplicateGroupName(isDuplicate);
              setNewGroupName(newName);
            }}
          />
          {isDuplicateGroupName && (
            <PleaseFillIn
              show={isDuplicateGroupName}
              alternateWarning={t("duplicateNameString")}
            />
          )}
        </InputPopupContainer>
      </FixaSheets>
    </FixaModal>
  );
}
