import React from "react";
import styled from "styled-components";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { RefillTypes } from "../../../constants/refillTypes";
import ChooseRefillType from "./ChooseRefillType";
// Skapa styles
import {
  Container as BaseContainer,
  OverflowBackground,
} from "../CommonComponents";
import tracker from "../../../helpers/tracker";
import MessageBox from "./MessageBox";
import { Button } from "@coworker/components";
import { useTranslation } from "@coworker/locales";

const Container = styled(BaseContainer)`
  height: 92%;
`;
const RefillSelectionContainer = styled.div`
  margin: 24px 24px;
`;
const RefillSelectionHeader = styled.div`
  height: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
`;
const ButtonInnerContainer = styled.div`
  padding: 19px 18px;
  padding-bottom: 20px;
  background: var(--white);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

interface RefillTypeObject {
  refillType: RefillTypes;
}

interface RefillTypeSelectionProps {
  selectedRefillType: RefillTypes;
  onSubmit: (refillTypeObject: RefillTypeObject) => void;
  onClose: () => void;
}

export default function RefillType({
  selectedRefillType,
  onSubmit,
  onClose,
}: RefillTypeSelectionProps) {
  const { t } = useTranslation();

  const [newSelectedRefillType, setSelectedRefillType] = React.useState(
    selectedRefillType ?? RefillTypes.REGULAR
  );
  const selectedRefillTypeIndex = Object.keys(RefillTypes).indexOf(
    newSelectedRefillType
  );

  const onRefillTypeSelectionChange = React.useCallback(
    (selectedRefillType: RefillTypes) => {
      setSelectedRefillType(selectedRefillType);
    },
    []
  );

  return (
    <>
      <OverflowBackground
        onClick={() => {
          tracker.trackDismissModal(false);
          onClose();
        }}
      />
      <Container>
        <FixaModalHeader
          title={t("refillTypeString")}
          closeBtnClick={onClose}
        />
        <RefillSelectionContainer>
          <RefillSelectionHeader>
            {t("selectRefillTypeString")}
          </RefillSelectionHeader>
          <ChooseRefillType
            selectedRefillTypeIndex={selectedRefillTypeIndex}
            onRefillTypeSelectionChange={onRefillTypeSelectionChange}
          />
        </RefillSelectionContainer>
        <MessageBox selectedRefillType={newSelectedRefillType}></MessageBox>
        <ButtonInnerContainer>
          <Button
            text={t("confirmString")}
            primary
            dark
            onClick={() => onSubmit({ refillType: newSelectedRefillType })}
            data-testid="confirm_refillType"
          />
        </ButtonInnerContainer>
      </Container>
    </>
  );
}
