import React from "react";
import { Trans as OriginalTrans } from "react-i18next";

const CustomTrans = (props) => {
  if (window.BLABLA_LANGUAGE) {
    return <>blabla</>;
  }

  if (window.SHOW_TRANSLATION_KEYS) {
    return <>{props.children}</>;
  }

  const newProps = {
    ...props,
    defaults: props.defaults,
  };

  return <OriginalTrans {...newProps} />;
};

export default CustomTrans;
