import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { ReactComponent as AmountIcon } from "../assets/svg/amount-icon.svg";
import amountTypes from "@coworker/enums/amountTypes";

const { PIECES, MULTI_PACK, PALLET } = amountTypes;

const Container = styled.div`
  background: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  height: 100%;
  color: ${(props) =>
    props.deactivated ? "var(--grey600)" : "var(--grey900)"};
  font-weight: ${(props) => (props.deactivated ? "normal" : "bold")};
`;
const ContentContainer = styled.div`
  height: 90px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: ${(props) => props.overridePadding || "29px"};
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  margin-left: ${(props) => (props.deactivated ? "20px" : "14px")};
  padding-bottom: 2px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-left: ${(props) => (props.deactivated ? "20px" : "14px")};
  padding-bottom: 2px;
  font-weight: normal;
`;

// Wrapping should prevent removeNode error
const wrap2 = (a, b) => (
  <>
    <>{a}</> <>{b}</>
  </>
);

function Wrap({ value, unit, fallback = <Trans>piecesString</Trans> }) {
  return isNaN(value) ? <>{fallback}</> : wrap2(value, unit);
}

// TODO: Rework to avoid complex logic and duplicated parameters (amount vs enteredAmount)
function SelectAmount({
  deactivated,
  amount,
  baseUnit,
  type,
  overridePadding,
  enteredAmount,
  typeString,
  additionalSubTitle,
}) {
  const isNotPieces = type === MULTI_PACK || type === PALLET;
  const unitString = baseUnit || type;

  return (
    <Container deactivated={deactivated}>
      <ContentContainer overridePadding={overridePadding}>
        <div>
          <AmountIcon />
        </div>
        <Stack>
          <Title>
            {(!type && baseUnit !== "") || type === PIECES ? (
              <Wrap value={amount} unit={unitString} />
            ) : (
              <Wrap value={enteredAmount} unit={typeString} fallback="" />
            )}
          </Title>
          {isNotPieces && (
            <SubTitle>
              <Wrap value={amount} unit={unitString} />
            </SubTitle>
          )}
          <SubTitle>{additionalSubTitle}</SubTitle>
        </Stack>
      </ContentContainer>
    </Container>
  );
}

export default SelectAmount;
