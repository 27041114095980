import { ActivityFilterType } from "../types/filterOptionsTypes";

export const DEFAULT_FILTER_VALUES: ActivityFilterType = {
  assignedTo: "",
  createdBy: "",
  taskType: "",
  sortedBy: "createdAtDesc",
  locations: [],
  pickupLocations: [],
  refillLocations: [],
  priority: false,
  period: "",
};
