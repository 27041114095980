import React from "react";
import Choice from "@ingka/choice";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/focus/dist/style.css";

interface Props {
  preselectedIndex?: number;
  subtle?: boolean;
  children: React.ReactElement | React.ReactElement[];
}
export const FixaChoice = (props: Props) => {
  return <Choice {...props} />;
};
