import React from "react";
import { ActivityFilterType } from "../../../../types/filterOptionsTypes";
import styles from "./activity-filters-panel.module.css";
import { Trans, useTranslation } from "@coworker/locales";
import { Task } from "@coworker/types/lib/tasks/base";
import {
  LocationCategory,
  formatLocationName,
  getLocationsByHfb,
  locationCategories,
  mfaqLocationCategories,
} from "../../helper";
import { FilterListView } from "./FilterListView";
import {
  FixaAccordion,
  FixaAccordionItem,
} from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";
import { useActivityLocations } from "../../../../hooks/useActivityData";
import SkapaTabs from "@coworker/app/src/components/InputPopup/SkapaTabs";
import { getCheckedStatus } from "../../../../helpers/getCheckedStatus";
import { FixaSkeleton } from "../../../../../shared/wrappers/FixaSkeleton/FixaSkeleton";

interface LocationFilterProps {
  panelFilters: ActivityFilterType;
  allTasks: Task[];
  handleChange: (value: string) => void;
  addonLocationType: string;
  setAddonLocationType: React.Dispatch<React.SetStateAction<string>>;
}

export const LocationFilter = ({
  panelFilters,
  handleChange,
  addonLocationType,
  setAddonLocationType,
}: LocationFilterProps) => {
  const { t } = useTranslation();
  const hasTaskType = panelFilters.taskType;
  const isMfaqTask = panelFilters.taskType === "mfaq";
  const isAddonTask =
    panelFilters.taskType === "refill" ||
    panelFilters.taskType === "pickAndRefill";

  const [mappedLocations, setMappedLocations] =
    React.useState<LocationCategory>(getLocationsByHfb([]));

  const { data: taskLocations, isLoading: taskLocationsIsLoading } =
    useActivityLocations(
      panelFilters.taskType,
      isAddonTask ? addonLocationType : ""
    );

  React.useEffect(() => {
    if (!taskLocationsIsLoading && taskLocations) {
      const newMappedLocations = getLocationsByHfb(taskLocations as string[]);
      setMappedLocations(newMappedLocations);
    }
  }, [taskLocationsIsLoading, taskLocations]);

  const locationCategoryList = (locationCategory: keyof LocationCategory) => {
    if (taskLocationsIsLoading) {
      return <FixaSkeleton height="100%" />;
    } else {
      if (!mappedLocations) return null;

      const filterListItems = mappedLocations[locationCategory].map(
        (location) => {
          return {
            id: formatLocationName(t as (key?: string) => string, location),
            title: formatLocationName(t as (key?: string) => string, location),
            value: location,
            control: "checkbox" as const,
            checked: getCheckedStatus(
              location,
              isAddonTask,
              addonLocationType,
              panelFilters
            ),
          };
        }
      );

      if (!filterListItems || filterListItems?.length === 0) {
        return null;
      }

      return (
        <FilterListView
          title={t(`${locationCategory}String`)}
          list={filterListItems}
          handleChange={handleChange}
        />
      );
    }
  };

  const tabs = [
    {
      title: (
        <>
          <Trans>pickupLocation</Trans>{" "}
          {panelFilters.pickupLocations.length > 0 &&
            `(${panelFilters.pickupLocations.length})`}
        </>
      ),
      key: "pickup",
    },
    {
      title: (
        <>
          <Trans>refillLocString</Trans>{" "}
          {panelFilters.refillLocations.length > 0 &&
            `(${panelFilters.refillLocations.length})`}
        </>
      ),
      key: "refill",
    },
  ];

  const tabChangedHandler = (tabKey: string) => {
    setAddonLocationType(tabKey);
  };

  const categories = isMfaqTask ? mfaqLocationCategories : locationCategories;

  return (
    <div className={styles["separator"]}>
      <FixaAccordion padded size="small">
        <FixaAccordionItem
          id={"location"}
          title={<Trans>locationsString</Trans>}
          className={styles["location"] as string}
        >
          {isAddonTask && (
            <div className={styles["location-tabs"]}>
              <SkapaTabs
                tabs={tabs}
                active={addonLocationType}
                onTabChanged={tabChangedHandler}
                noTabPanelPadding
              />
            </div>
          )}
          {hasTaskType ? (
            categories.map((locationCategory, i) => (
              <div key={i}>{locationCategoryList(locationCategory)}</div>
            ))
          ) : (
            <div className={styles["no-task-type"]}>
              <Trans>selectTaskTypeToFilterByLocationString</Trans>
            </div>
          )}
        </FixaAccordionItem>
      </FixaAccordion>
      <div className={styles["separator"]} />
    </div>
  );
};
