import React from "react";
import styles from "./discover-now.module.css";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import LightbulbWithRaysIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/LightbulbWithRaysIconPath";
import ArrowRightIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ArrowRightIconPath";
import { Trans } from "@coworker/locales";
import { DiscoverNowSheet } from "./DiscoverNowSheet";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { useFocusTile } from "@coworker/app/src/core/hooks/useFocusTile";
import preferences from "@coworker/enums/profilePreferences";
import { useUserPreference } from "@coworker/app/src/hooks/useProfilePreferencesQuery";

export const DiscoverNow = () => {
  const [isSheetOpen, setIsSheetOpen] = React.useState(false);
  const [userLanguage] = useUserPreference(preferences.LANGUAGE, "");
  const focusTile = useFocusTile(userLanguage);

  if (!focusTile) return null;

  return (
    <>
      <div
        className={styles["container"]}
        onClick={() => {
          setIsSheetOpen(!isSheetOpen);
          trackerHelper.trackDiscoverNowClick();
        }}
      >
        <div>
          <div className={styles["top-wrapper"]}>
            <FixaSSRIcon
              paths={LightbulbWithRaysIconPath}
              className={styles["light-icon"] as string}
            />
            <div className={styles["discover-now"]}>
              <Trans>discoverNowString</Trans>
            </div>
          </div>
          <div className={styles["focus-tile"]}>
            <Trans values={{ tile: focusTile.title }}>
              tileNameInUpptackaString
            </Trans>
          </div>
          <div className={styles["description"]}>
            <Trans>newOptionHomeScreenString</Trans>
          </div>
        </div>
        <div className={styles["arrow-icon-wrapper"]}>
          <FixaSSRIcon
            paths={ArrowRightIconPath}
            className={styles["arrow-icon"] as string}
          />
        </div>
      </div>
      <DiscoverNowSheet
        tileName={focusTile.title}
        imageUrl={focusTile.url}
        isSheetOpen={isSheetOpen}
        setIsSheetOpen={setIsSheetOpen}
      />
    </>
  );
};
