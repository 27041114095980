import { useQuery } from "@tanstack/react-query";
import { getArticlesByRoomId } from "../services/rooms.service";
import { QueryKeys } from "./queryKeys";

export const useArticlesByRoomId = (
  roomId?: string | undefined,
  active?: "ACTIVE" | "INACTIVE" | undefined
) => {
  return useQuery({
    queryKey: [
      QueryKeys.ArticlesByRoom,
      { id: roomId, active: active || "NONE" },
    ],
    queryFn: () => getArticlesByRoomId(roomId, active),
    enabled: !!roomId,
  });
};
