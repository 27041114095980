import {
  PRODUCT,
  HEADER,
  DESCRIPTION,
  SECTION,
  CREATOR,
  CREATED,
  ACTIVITY,
  RADIOBUTTONSWITHTEXT,
  DESCRIBE_THE_PROBLEM,
} from "./_common";
import { pqHowList } from "@coworker/enums/pqHowList";

import { PickUpTask, RejectPQReport, AcceptPQReport } from "../actions/index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [
    HEADER,
    SECTION("Product information"),
    PRODUCT,
    // 'supplier_number' and 'supplier_number_unknown' should, by data stored, always be mutually exclusive meaning
    // that if 'supplier_number_unknown' is true then 'supplier_number' should be empty.
    // Furthermore if 'supplier_number_unknown' is false then 'supplier_number' should have a value i.e. 5 digits.
    DESCRIPTION("supplier_number", "supplierNumberString", "truck"),
    DESCRIPTION(
      "supplier_number_unknown",
      "supplierNumberString",
      "truck",
      20,
      "iDontHaveASuppliedNumber"
    ),
    DESCRIPTION("pq_date_stamp", "dateStampString", "calendar", 16),
    SECTION("description4String"),
    DESCRIBE_THE_PROBLEM,
    // 'location' and 'location_custom' should, by data stored, always be mutually exclusive meaning
    // that if 'location' has a value then 'location_custom' should be empty.
    // Furthermore if 'location_custom' has a value then 'location' should be empty.
    DESCRIPTION("location", "defectedProductLocationString", "location"),
    DESCRIPTION("location_custom", "defectedProductLocationString", "location"),
    RADIOBUTTONSWITHTEXT(
      pqHowList,
      "pq_discovered_type",
      "pq_discovered_other_text",
      "howDidYouDiscoverString",
      "magnifying_glass"
    ),
    DESCRIPTION("done_so_far", "whatOtherActions", "wrench"),
    SECTION("activityString"),
    CREATOR,
    CREATED,
    ACTIVITY,
  ],
  actions: [PickUpTask, AcceptPQReport, RejectPQReport],
};
