import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { TaskTodos, TaskTodosSummaries } from "../types/taskCreation";
import { Reasons } from "../constants";

export function todosAggregation(todos: TaskTodos): TaskTodosSummaries {
  const summaries: TaskTodosSummaries = [];
  todos.forEach((todo) => {
    const summary = summaries.find(
      (s) =>
        s.is_new_article === todo.is_new_article && s.is_ptag === todo.is_ptag
    );
    if (summary) {
      summary.pieces += 1;
    } else {
      summaries.push({
        pieces: 1,
        is_new_article: todo.is_new_article,
        is_ptag: todo.is_ptag,
      });
    }
  });
  return summaries;
}

export const getHeader = (task: RoomSettings, t: (key: string) => string) => {
  switch (task.reason) {
    case Reasons.DAILY_MAINTENANCE:
      return t("replaceProduct");
    case Reasons.REPLACE_PRODUCT:
      return t("changeProductString");
    case Reasons.REMOVE_PRODUCT:
      return t("removeProductString");
    default:
      return t("replaceProduct");
  }
};
