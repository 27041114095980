import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { PleaseFillIn } from "../Reusable/PleaseFillIn";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { pqHowOptions } from "@coworker/enums/pqHowOptions";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 100%;
  background: var(--white);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ScrollContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
`;
const StyledListView = styled(FixaListView)`
  display: flex;
  flex-direction: column;
  margin-top 70px;
`;
const StyledListViewItem = styled(FixaListViewItem)`
  display: flex;
  span {
    text-decoration: none !important;
  }
`;

const StyledButton = styled(FixaButton)`
  margin: 0px 24px 8px 24px;
`;
const TextAreaContainer = styled.div`
  margin: 16px;
`;
const PleaseFillInArea = styled.div`
  margin-top: 8px;
`;

export default function RadioButtons({
  list = [],
  value,
  onSubmit,
  testParams,
}) {
  const [initialType, otherText] = value;
  const [type, setType] = React.useState(initialType);
  const [text, setText] = React.useState(otherText || "");
  const [buttonPressed, setButtonPressed] = React.useState(
    testParams?.buttonPressed || false
  );
  return (
    <Container data-testid="RadiobuttonsPopup">
      <ScrollContainer>
        <StyledListView id="listviewid" size="small">
          {list
            .filter((x) => !x?.deprecated)
            .map((item) => (
              <StyledListViewItem
                key={item.value}
                inset
                onChange={() => setType(item.value)}
                control="radiobutton"
                controlProps={{
                  value: type === item.value ? "ON" : "OFF",
                  checked: type === item.value,
                }}
                data-testid={item.value}
                title={<Trans>{item.translationKey}</Trans>}
              />
            ))}
        </StyledListView>
        <TextAreaContainer>
          <PleaseFillInArea>
            <PleaseFillIn
              show={
                type === pqHowOptions.OTHER && !text.length && buttonPressed
              }
              centerText={true}
            />
          </PleaseFillInArea>
          <FixaTextArea
            disabled={type !== pqHowOptions.OTHER}
            data-testid="otherTextInput"
            value={text}
            characterLimit={250}
            onChange={(e) => setText(e.target.value)}
            label={<Trans>addNoteString</Trans>}
          />
        </TextAreaContainer>
      </ScrollContainer>
      <PleaseFillInArea>
        <PleaseFillIn
          show={!type && buttonPressed}
          alternateWarning="pleaseMakeASelectionString"
          centerText={true}
          testId="makeASelectionWarning"
        />
      </PleaseFillInArea>

      <StyledButton
        data-testid="continueButton"
        text={<Trans>continue2String</Trans>}
        type="primary"
        onClick={(event) => {
          setButtonPressed(true);
          if (!type || (type && type === pqHowOptions.OTHER && !text?.length)) {
            event.preventDefault();
          } else {
            onSubmit([type, text]);
          }
        }}
      />
    </Container>
  );
}
