import React from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/tabs/dist/style.css";
import "@ingka/focus/dist/style.css";

type FixaTabPanelProps = {
  children?: React.ReactNode;
  key?: React.Key;
  tabPanelId: string;
};

type FixaTabProps = {
  key?: React.Key;
  tabPanelId: string;
  text?: React.ReactNode;
};

type FixaTabsProps = {
  className?: string;
  activeTab?: string;
  defaultActiveTab?: string;
  onTabChanged?: (tabId: string) => void;
  tabPanels?: React.ReactElement<FixaTabPanelProps>[];
  tabs?: React.ReactElement<FixaTabProps>[];
  "data-testid"?: string;
};

export function FixaTab(props: FixaTabProps) {
  return <Tab {...props} />;
}

export function FixaTabPanel(props: FixaTabPanelProps) {
  return <TabPanel {...props} />;
}

function FixaTabs(props: FixaTabsProps) {
  return <Tabs {...props} />;
}

export default FixaTabs;
