import React from "react";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import { Trans } from "@coworker/locales";
import { HEADER_COLOR_SCHEMA, typesOfShoppingToolsList } from "../constants";
import { SettingsDefault, SettingsResponse } from "../Types";
import { SettingsCardSkeleton } from "../Components/Skeletons/SettingsCardSkeleton";
import { SettingsListView } from "../Components/SettingsListView";

interface SettingsCardProps {
  title: string;
  id: string;
  divisionSettings: SettingsDefault | SettingsResponse;
  setSettingsList: React.Dispatch<
    React.SetStateAction<SettingsDefault[] | SettingsResponse[]>
  >;
  settingsList: SettingsDefault[] | SettingsResponse[];
  loading: boolean;
}

export const SettingsCard = ({
  title,
  id,
  divisionSettings,
  setSettingsList,
  settingsList,
  loading,
}: SettingsCardProps) => {
  if (loading) {
    return (
      <>
        <SubHeaderCard
          title={<Trans>{title}</Trans>}
          fontSize={16}
          colorSchema={HEADER_COLOR_SCHEMA.GREY}
        />
        <SettingsCardSkeleton />
      </>
    );
  }
  return (
    <>
      <SubHeaderCard
        title={<Trans>{title}</Trans>}
        fontSize={16}
        colorSchema={HEADER_COLOR_SCHEMA.GREY}
      />
      <SettingsListView
        id={id}
        itemList={typesOfShoppingToolsList("checkbox")}
        divisionSettings={divisionSettings}
        setSettingsList={setSettingsList}
        settingsList={settingsList}
      />
    </>
  );
};
