import React from "react";
import TextArea from "@ingka/text-area";
import { CSSProperties } from "styled-components";

import "@ingka/forms/dist/style.css";

interface TextAreaProps {
  id?: string;
  autoFocus?: boolean;
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onKeyDown?: (object: any) => void;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;
  rows?: number | undefined;
}

export const FixaTextArea = (props: TextAreaProps) => {
  return <TextArea {...props} />;
};
