import React from "react";
import { parseProduct as parseProductHelper } from "@coworker/utils/products.helper";
import { writeLocalProductCache } from "../hooks/useBasicProductsMap";
import { useStoreId } from "../core/auth/useLoggedInUser";
import {
  addSoftSpace,
  cleanupProductTitle,
  formatProductIdWithDots,
  formatWithCommas,
} from "@coworker/reusable";
import { callInternalApi } from "../hooks/API/useCallInternal";
import { useMyStore } from "../hooks/useMyStore";

/**
 * Caching for MFS data.
 *
 * key
 * [code, store_id]
 *
 * cached object
 * {
 *  key,
 *  notFound,
 *
 *  number,
 *  type (ART/SPR),
 *  title,
 *  kindAndColor,
 *  images,
 *  price
 *  product_type,
 *  product_color_description,
 *  imperialMeasurements,
 *  metricMeasurements,
 *  unitCode,
 * }
 */
const cachedProductData = {};
const smallProductImage = {};

// prevent AKAMAI from guessing wrongly about supported image formats
export function correctImageFormat(url) {
  if (!url?.split) return null;
  const [base, ...params] = url.split(/\?|\&/);
  const newParams = params
    .filter((part) => !part.startsWith("imformat=") && !part.startsWith("f="))
    .concat(["f=u", "imformat=generic"]);
  return base ? base + "?" + newParams.join("&") : "";
}

// Returns the best (big or not) image from an Images array from a MFS record
function pickImageMFS(images, big = false) {
  if (!images || !images.length) return null;
  let found;
  for (const imageSizes of images) {
    const { S1, S2, S3, S4, S5 } = imageSizes || {};
    if (big) found = (S3 || S4 || S5)?.Url;
    // The specific ordering of image sizes here are so that we get the best possible dimensions for the scrollable search hits list
    if (!found) found = (S2 || S3 || S4 || S1 || S5)?.Url;
    if (found) break;
  }
  // Fix issue with images from MFS sometimes breaking.
  return correctImageFormat(found);
}

function checkIfImperial(language) {
  return language?.includes && language.includes("US"); // es_US and en_US
}

/**
 * We might get product images from:
 * - MFS Search as Images = [{S1: "...", ... "S5": "..."}]
 * - SIK search mainImageUrl
 * - (other APIs like "communications" have images, slightly different, but that API is no longer used by us)
 */
export function keepSmallImage(code, { image, Images, images } = {}) {
  const [short_id] = parseProduct(code);
  const found = smallProductImage[short_id];
  return (smallProductImage[short_id] =
    found || image || pickImageMFS(Images || images, false) || images?.[0]);
}

// helper
export function cachedProductKeyAndData(code) {
  const [short_id] = parseProduct(code);
  const value = cachedProductData[short_id]
    ? Object.assign({}, cachedProductData[short_id])
    : null;
  return [short_id, value];
}

// helper for the parseSearchProduct.from... methods below
function keepProductData(data) {
  const [key] = cachedProductKeyAndData(data.key);

  // Keep also in localStorage cache.
  if (data) {
    const basicProduct = {
      itemType: data?.itemType,
      itemNo: data?.itemNo,
      itemName: data?.itemName,
      description: data?.description,
      smallImage: data.smallImage,
      paNo: data.paNo ?? "",
    };
    writeLocalProductCache(data?.itemNo, basicProduct, false);
  }

  return (cachedProductData[key] = data);
}

function kindAndColorMFS(item) {
  const color = item.ColourDescription || item.Colour;
  return addSoftSpace(`${item.ProductType}${color ? `, ${color}` : ""}`);
  // \u200b is a zero-width space. Needed to help browser break long lines.
}

// Returns cached data.
export function getProductCachedData(code) {
  const [, data] = cachedProductKeyAndData(code);
  if (!data) return;
  return data;
}

export function useCachedBasicProduct(shortId) {
  const store = useStoreId();
  const [productBasics, setProductBasics] = React.useState();
  const primary_locale = useMyStore()?.configuration?.locale?.primary_locale;

  React.useEffect(() => {
    if (shortId && store) {
      callInternalApi(
        `product_basics?shortId=${shortId}&locale=${primary_locale}`
      ).then((response) => {
        setProductBasics(response);
      });
    }
  }, [shortId, store, primary_locale]);

  return productBasics;
}

/**
 * Parses a MFS search result item and returns a product object
 * @param {Object} item
 * @returns {Object}
 */
function parseMFSItem(item, language) {
  const [, previous] = cachedProductKeyAndData(item.ItemId);
  return {
    key: `${item.ItemType}${item.ItemId}`,
    id: item.ItemId,
    number: formatProductIdWithDots(item.ItemId),
    type: item.ItemType,
    title: cleanupProductTitle(item.title || item.Name || previous?.title),
    image: keepSmallImage(item.ItemId, item),
    kindAndColor:
      // Prefer already cached kindAndColor if the new `item` lacks colour info (i.e. from retailitems endpoint)
      (!item.ColourDescription && previous?.kindAndColor) ||
      kindAndColorMFS(item),
    product_type: item.ProductType,
    product_color_description:
      // Prefer already cached if the new `item` lacks colour info (i.e. from retailitems endpoint)
      item.ColourDescription ||
      item.Colour ||
      previous?.product_color_description,
    Images: item.Images,
    price: item.price || returnPriceObject(item),
    unitCode: item.ItemUnitCode,
    measurements:
      item.measurements ||
      measurementsMFS(item, language) ||
      previous?.measurements,
    // LatestSalesDate is only present in search this is to prevent it from being removed
    // or have an invalid date
    latestSalesDate:
      (item.LatestSalesDate || previous?.latestSalesDate) &&
      new Date(item.LatestSalesDate || previous.latestSalesDate),
  };
}

/**
 * Parses a SIK search result item and returns a product object
 * @param {Object} item
 * @returns {Object}
 */
function parseSIKItem(item) {
  return {
    key: `${item.itemType}${item.itemNo}`,
    paNo: item.businessStructure?.productAreaNo,
    noFormatted: formatProductIdWithDots(item.itemNo),
    type: item.itemType,
    name: item.name,
    no: item.itemNo,
    smallImageUrl: item?.mainImageUrl,
    imageUrl: keepSmallImage(item.itemNo, { image: item.mainImageUrl }),
    description: formatWithCommas([
      item.typeName,
      item.validDesignText,
      item.itemMeasureReferenceText,
    ]),
    productTypeName: item.typeName,
    salesPrice: {
      price: item.salesPrice.numeral,
      currency: item.salesPrice.currencyCode,
      previous: {
        price: item.salesPrice?.previous?.wholeNumber,
      },
    },
    measurements: item.itemMeasureReferenceText,
  };
}

/**
 * Parses a MFS search result item and returns the measurements text
 * @param {Object} item
 * @returns {String}
 */
function measurementsMFS(item, language) {
  return checkIfImperial(language)
    ? item.ItemMeasureReferenceTextImperial
    : item.ItemMeasureReferenceTextMetric;
}

const parseAndKeep = (parser) => (_, language, items) =>
  (items || []).map((item) => keepProductData(parser(item, language)));

const parseOnly = (parser) => (_, language, items) =>
  (items || []).map((item) => parser(item, language));

// converts MFS and SIK search results into a rerender-friendly internal format, and stored (kept) in cache
export const parseSearchProduct = {
  fromMFS: parseOnly(parseMFSItem),
  fromSIK: parseAndKeep(parseSIKItem),
};

/**
 * Return price unit string.
 * @param {Number} [priceFactor = '']
 * @param {String} unit
 * @returns {String}
 */
const returnPriceUnit = (priceFactor = "", unit) => `/${priceFactor} ${unit}`;

/**
 * Return correct price object for item.
 * @param {Object} item
 * @param {Boolean} isImperial
 * @returns {Object}
 */
export function returnPriceObject(item, isImperial) {
  if (!item) return;
  /*
    Properties:
    - price
    - originalPrice
    - isFamilyPrice
    - newLowerPrice
    - isBreathTakingItem
    - priceUnit
    - currency
  */
  let price = "";
  let originalPrice = null;
  let isFamilyPrice = false;
  let newLowerPrice = false;
  let priceUnit = null;
  let currency = "";
  // get the normal price
  const normalPriceObj =
    item.RetailUnitPrices &&
    item.RetailUnitPrices.find(({ Type }) => Type === "NORMAL");
  price = normalPriceObj?.Value || null;
  currency = normalPriceObj?.Currency || null;

  if (
    normalPriceObj &&
    "PriceReasonCode" in normalPriceObj &&
    normalPriceObj.PriceReasonCode === "NEW_LOWER_PRICE"
  ) {
    newLowerPrice = true;
    originalPrice = normalPriceObj.OriginalValue;
  }
  // find out if item has family price. check if type is FAMILY
  const familyPriceObj =
    item.RetailUnitPrices &&
    item.RetailUnitPrices.find(({ Type }) => Type === "FAMILY");
  if (familyPriceObj) {
    isFamilyPrice = true;
    newLowerPrice = false;
    price = familyPriceObj?.Value;
    originalPrice = normalPriceObj?.Value;
  }
  // price unit check and get
  if ("UnitPriceFactor" in item) {
    const { UnitPriceFactor } = item;
    const unitText = isImperial
      ? UnitPriceFactor.UnitTextImperial
      : UnitPriceFactor.UnitTextMetric;
    let priceFactor = "";

    if (unitText) {
      priceUnit = returnPriceUnit(priceFactor, unitText);
    }

    if (
      "UnitPriceFactorMetric" in UnitPriceFactor ||
      "UnitPriceFactorImperial" in UnitPriceFactor
    ) {
      priceFactor = isImperial
        ? UnitPriceFactor.UnitPriceFactorImperial
        : UnitPriceFactor.UnitPriceFactorMetric;

      priceUnit = returnPriceUnit(priceFactor, unitText);
      // !!!! The below calculation to adjust the price for products with a declared AREA price
      // !!!! (like SEK / m²) has turned out to cause big issues with refills of carpet KOMPLEMENT (504.653.89 and more)
      // !!!! which should not be priced per sq.m. It also caused very very wrong numbers in Insights.
      // if (UnitPriceFactor.UnitPriceGroupCode === "AREA") {
      //   price = Number(price) / Number(priceFactor);
      //   priceUnit = returnPriceUnit("", unitText);
      // }
    } else {
      if (UnitPriceFactor.UnitPriceGroupCode === "MULTIPACK") {
        priceUnit = "";
      }
    }
  }

  const priceObj = {
    price,
    isFamilyPrice,
    newLowerPrice,
    currency,
  };
  if (originalPrice !== null) {
    priceObj.originalPrice = originalPrice;
  }

  if (priceUnit !== null && priceUnit !== "") {
    priceObj.priceUnit = priceUnit;
  }
  return priceObj?.price ? priceObj : null;
}

export function useNearbyProducts(location) {
  const [nearbyProductsFromService, setNearbyProductsFromService] =
    React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (location) {
      callInternalApi(`nearby_products?location=${location}`)
        .then((response) => setNearbyProductsFromService(response))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [location]);

  return {
    nearbyProducts: nearbyProductsFromService?.data ?? [],
    loading: isLoading,
  };
}

/**
 * parse product number
 * @param {string} string Anything resembling an IKEA product number, so ART12345678, 132.456.78, s12345678 and some more variants accepted.
 * @returns {[shortId:string, type:"ART"|"SPR"|""]}
 */
export function parseProduct(string = "") {
  const [shortId, type] = parseProductHelper(string);
  return [shortId, type || cachedProductData[shortId]?.type || ""];
}
