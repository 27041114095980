export const present = (text: string): boolean =>
  !!text && !["", "-"].includes(text.trim());

export const firstPresent = (list: Array<string>): string =>
  list.find(present) || "";

export const allPresent = (list: Array<string>): string[] =>
  list.filter(present);

export function firstArrayWithContent<T>(arrays: T[][]): T[] {
  for (const arr of arrays) {
    if (
      arr &&
      arr.some((item) => typeof item === "string" && firstPresent([item]))
    ) {
      return arr;
    }
  }
  return [];
}

export function inChunks<T>(chunkSize: number, list: T[]): T[][] {
  const array: T[][] = [];
  for (let i = 0; i < list.length; i += chunkSize) {
    array.push(list.slice(i, i + chunkSize));
  }
  return array;
}
