import taskStateOptions from "@coworker/enums/taskState";
import taskTypeOptions from "@coworker/enums/taskType";
import {
  isAssignedOrUnassigned,
  isFinishedState,
} from "../../../constants/taskState";
import {
  canPerformAction,
  permissionActions,
} from "@coworker/common/services/permissions.service";

export type UsableTask = {
  id: string;
  state: string;
  task_type: string;
  assigned_user_id: string;
  assigned_team_id: string;
  picked?: boolean;
  planned?: object;
  is_new_article?: boolean;
  is_ptag?: boolean;
  is_new_article_handled?: boolean;
  is_ptag_handled?: boolean;
  proof_of_completion?: boolean;
  new_product_article_id?: string;
};

export type TeamAndUserIds = {
  team_id: string;
  uid: string;
  fixaUid: string;
  role_id: string;
};

function getEditLink(taskType: string, taskId: string) {
  switch (taskType) {
    case taskTypeOptions.ADDON:
      return `/task/edit/addon/${taskId}`;
    case taskTypeOptions.CUSTOM:
      return `/task/edit/custom/${taskId}`;
    case taskTypeOptions.PLANNED:
      return `/task/edit/planned/${taskId}`;
    case taskTypeOptions.PRODUCT_ISSUE:
    case "PRODUCT": //fallback for previous bug
      return `/task/edit/product/${taskId}`;
    case taskTypeOptions.ROOM_SETTINGS:
      return `/task/edit/room-settings/${taskId}`;
    default:
      return "";
  }
}

function shouldDisplayPickupTask(
  task: UsableTask,
  { team_id }: TeamAndUserIds
) {
  return (
    taskStateOptions.UNASSIGNED === task.state &&
    !task.assigned_user_id &&
    task.assigned_team_id === team_id
  );
}

function shouldDisplayCompleteTask(
  task: UsableTask,
  { uid, fixaUid }: TeamAndUserIds,
  picking: boolean
) {
  return (
    taskTypeOptions.PRODUCT_QUALITY !== task.task_type &&
    (!picking || (task.picked && picking)) &&
    taskStateOptions.ASSIGNED === task.state &&
    (task.assigned_user_id === uid || task.assigned_user_id === fixaUid)
  );
}

function shouldDisplayCompletePicking(
  task: UsableTask,
  { uid, fixaUid }: TeamAndUserIds,
  picking: boolean
) {
  return (
    picking &&
    !task.picked &&
    taskStateOptions.ASSIGNED === task.state &&
    (task.assigned_user_id === uid || task.assigned_user_id === fixaUid)
  );
}

function shouldDisplayReassignTask(task: UsableTask) {
  return !isFinishedState(task.state);
}

function shouldDisplayEditTask(task: UsableTask) {
  return [
    taskTypeOptions.MFAQ_FOLLOW_UP,
    taskTypeOptions.TESTBUY_FOLLOW_UP,
  ].includes(task.task_type)
    ? false
    : !task.picked && isAssignedOrUnassigned(task.state);
}

function shouldDisplayDropTask(
  task: UsableTask,
  { uid, team_id, fixaUid, role_id }: TeamAndUserIds
) {
  return (
    team_id === task.assigned_team_id &&
    taskTypeOptions.PLANNED !== task.task_type &&
    taskStateOptions.ASSIGNED === task.state &&
    (task.assigned_user_id === uid ||
      task.assigned_user_id === fixaUid ||
      canPerformAction(role_id, permissionActions.RELEASE_TEAMS_TASK))
  );
}

function shouldDisplayCloseTask(task: UsableTask) {
  return isAssignedOrUnassigned(task.state);
}

function shouldDisplayProductQualityCompleteActions(
  task: UsableTask,
  { uid, fixaUid }: TeamAndUserIds
) {
  return (
    task.state === taskStateOptions.ASSIGNED &&
    (task.assigned_user_id === uid || task.assigned_user_id === fixaUid) &&
    taskTypeOptions.PRODUCT_QUALITY === task.task_type
  );
}

function shouldDisplayMenu(
  task: UsableTask,
  { uid, team_id, fixaUid, role_id }: TeamAndUserIds
) {
  return (
    task.task_type !== taskTypeOptions.PRODUCT_QUALITY &&
    (shouldDisplayDropTask(task, { uid, team_id, fixaUid, role_id }) ||
      shouldDisplayEditTask(task) ||
      shouldDisplayReassignTask(task) ||
      shouldDisplayCloseTask(task))
  );
}

export {
  shouldDisplayPickupTask,
  shouldDisplayCompleteTask,
  shouldDisplayCompletePicking,
  shouldDisplayReassignTask,
  shouldDisplayEditTask,
  shouldDisplayDropTask,
  shouldDisplayCloseTask,
  shouldDisplayProductQualityCompleteActions,
  shouldDisplayMenu,
  getEditLink,
};
