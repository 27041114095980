import React from "react";
import { WidgetRow } from "../PaddedRow";
import { FixaSwitch } from "@coworker/apprestructured/src/shared/wrappers/FixaSwitch/FixaSwitch";

interface ToggleFieldProps {
  title: React.ReactNode;
  onChange: (value: boolean) => void;
  image?: string | React.ReactNode;
  value?: boolean;
  testId?: string;
}

export function ToggleField({
  title,
  onChange,
  image,
  value = false,
  testId = "toggle-field",
}: ToggleFieldProps) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.checked);
  };
  return (
    <WidgetRow
      className="WidgetView"
      image={image}
      middle={title}
      right={
        <FixaSwitch
          id=""
          data-testid={testId}
          checked={value}
          value={value.toString()}
          onChange={handleChange}
          subtle={true}
        />
      }
      testId={testId || "toggle-field-widget"}
      disableRightArrow={true}
    />
  );
}
