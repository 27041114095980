import React from "react";
import { Prompt } from "@ingka/modal";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";

interface PromptProps {
  title: React.ReactNode;
  titleId: string;
  header: React.ReactElement | null;
  footer: React.ReactElement | null;
  children: React.ReactNode;
}

export const FixaPrompt = (props: PromptProps) => {
  return <Prompt {...props} />;
};
