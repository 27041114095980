import React from "react";
import styled from "styled-components";

import InformationCircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/InformationCircleIconPath";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import { useTranslation } from "@coworker/locales";

const BannerContainer = styled.div<{ padding: string | undefined }>`
  background-color: var(--grey100);
  ${({ padding }) => padding && `padding: ${padding};`}
`;

const DashboardLink = styled.a`
  color: var(--color-primary);
`;

const BodyText = () => {
  const { t } = useTranslation();
  return (
    <>
      {t("insightsDeprecationBannerBodyPartOne")}
      <DashboardLink href="https://fixadashboard.ingka.com/" target="_blank">
        https://fixadashboard.ingka.com
      </DashboardLink>
      {t("insightsDeprecationBannerBodyPartTwo")}
    </>
  );
};

const InsightsDeprecationBanner: React.FC<{ padding?: string }> = ({
  padding,
}) => {
  const { t } = useTranslation();
  return (
    <BannerContainer padding={padding}>
      <FixaInlineMessage
        variant="informative"
        title={t("insightsDeprecationBannerTitle")}
        body={<BodyText />}
        ssrIcon={InformationCircleIconPath}
      />
    </BannerContainer>
  );
};

export default InsightsDeprecationBanner;
