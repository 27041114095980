import taskTypeOptions from "@coworker/enums/taskType";
import { callInternalApi } from "../hooks/API/useCallInternal";
import { updateTask as updateTaskInTasksService } from "../hooks/API/tasks.service.helper";

export async function updateTask(data, id, task_type, storeId) {
  try {
    return await updateTaskInTasksService(data, id);
  } catch (ex) {
    return;
  }
}

export async function updateTaskWithImages(
  data,
  taskId,
  originalImages,
  currentImages,
  storeId
) {
  const isRegular = taskTypeOptions.PLANNED !== data.task_type;
  const promises = [updateTask(data, taskId, data.task_type, storeId)];

  // Clear away removed image references
  const removeImageEndpoint = (image) =>
    `task/${
      isRegular ? "regular" : "repeatable"
    }/image/remove?id=${taskId}&imageId=${image.id}`;
  const keepIds = (currentImages || []).map((image) => image.id);
  for (const image of originalImages || []) {
    if (!keepIds.includes(image.id)) {
      promises.push(
        callInternalApi(removeImageEndpoint(image), { store_id: storeId })
      );
    }
  }

  // Persist new image references
  const toAdd = currentImages.filter((image) => !image.id);
  if (toAdd.length) {
    const collectionName = isRegular ? "tasks" : "repeatable_tasks";
    const parentPath = `${collectionName}/${taskId}/images`;
    promises.push(
      callInternalApi("create/images", {
        list: toAdd,
        parentPath,
        store_id: storeId,
      })
    );
  }

  await Promise.all(promises);
}
