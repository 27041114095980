import React from "react";
import trackerHelper from "../../../helpers/tracker";
import {
  TextArea,
  NpsSlider,
  useToastNotification,
} from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { useTextAreaPopup } from "../../InputPopup/_common";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useCreateFeedbackV1 } from "../../../hooks/API/tasks.service";
import useFeedbackQuestions from "../../../hooks/useFeedbackQuestions";
import {
  ButtonWrapper,
  StyledLink,
  SliderContainer,
  QuestionTitle,
  ParagraphText,
  HalfButtonLeft,
  HalfButtonRight,
  HeaderTitle,
  Container,
} from "./styles";

export default function FeedbackForm({
  containerRef,
  question: {
    id,
    question,
    title,
    type,
    textAreaPlaceholder,
    paragraphs = [],
    options = {},
  },
}) {
  const { min, max, value, leftLabel, rightLabel } = options;

  const { t } = useTranslation();
  const [comment, setComment] = React.useState("");
  const [nps, setNps] = React.useState(value || 0);
  const { textArea, onFocus, onBlur } = useTextAreaPopup(
    containerRef,
    comment,
    {
      focusOnMount: false,
      scrollToTopOfTextarea: true,
      scrollToBottomOnInit: false,
    }
  );
  const { pop } = useWorkspacesAction();
  const { showToast } = useToastNotification();
  const [, setAnswered] = useFeedbackQuestions();
  const [changed, setChanged] = React.useState(null);
  React.useEffect(() => {
    if (changed === null) {
      setChanged(false);
    } else if (!changed) {
      setChanged(true);
    }
    // eslint-disable-next-line
  }, [nps, comment]);

  const storeInDB = useCreateFeedbackV1(question, id, type);

  async function submitFeedback() {
    storeInDB(String(nps), comment);
    await new Promise((resolve) => setTimeout(resolve, 1));
    setAnswered(id);
    pop();
    showToast(
      <Trans>feedbackThanksString</Trans>,
      <Trans>dismissString</Trans>
    );
  }

  return (
    <Container>
      <HeaderTitle>{title ? t(title) : question}</HeaderTitle>
      {paragraphs.map((paragraph) => (
        <ParagraphText key={paragraph}>{t(paragraph)}</ParagraphText>
      ))}
      {!!title && <QuestionTitle>{question}</QuestionTitle>}
      <SliderContainer>
        <NpsSlider
          value={nps}
          setValue={setNps}
          min={min}
          max={max}
          leftLabel={t(leftLabel || "zeroNotLikelyString")}
          rightLabel={t(rightLabel || "tenMuchLikelyString")}
        />
      </SliderContainer>
      <div>
        <TextArea
          data-testid="feedbackTextArea"
          maxLength={1000}
          wrap="hard"
          value={comment}
          placeholder={t(
            textAreaPlaceholder || "feedbackReasonScoreOptionalString"
          )}
          onChange={(e) => setComment(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={textArea}
        />
      </div>
      <ButtonWrapper>
        <HalfButtonLeft
          type="submit"
          text={<Trans>askMeLaterString</Trans>}
          primary
          dark
          fullWidth
          data-testid="dismissFeedbackButton"
          onClick={() => {
            trackerHelper.trackAskMeLater();
            pop();
          }}
          secondary
        />
        <HalfButtonRight
          type="submit"
          text={<Trans>submitString</Trans>}
          primary
          dark
          fullWidth
          data-testid="submitFeedbackButton"
          onClick={submitFeedback}
          disabled={!changed}
        />
      </ButtonWrapper>
      <StyledLink onClick={() => {}}>
        <Trans>notInterestedString</Trans>
      </StyledLink>
    </Container>
  );
}
