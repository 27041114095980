import React, { PropsWithChildren} from "react";
import { FullSpaceAnchor } from "../styles/styles";

interface ConditionalAnchorProps {
  isAnchor: boolean;
  href: string;
  onClick: () => void;
}

export const ConditionalAnchor = ({
  isAnchor,
  href,
  onClick,
  children,
}: PropsWithChildren<ConditionalAnchorProps>) => {
  if (isAnchor) {
    return (
      <FullSpaceAnchor
        href={href}
        onClick={(event) => {
          event.preventDefault();
          onClick();
        }}
      >
        {children}
      </FullSpaceAnchor>
    );
  } else return <>{children}</>;
};
