import React from "react";
import styles from "./task-card.module.css";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import trackerHelper from "@coworker/app/src/helpers/tracker";

interface LayoutProps {
  taskId: string;
  isSwiping: boolean;
  isPlannedTask?: boolean;
  children: React.ReactNode;
}

export const Layout = ({
  taskId,
  isSwiping,
  isPlannedTask,
  children,
}: LayoutProps) => {
  const { toggleModal } = React.useContext(ModalContext);

  return (
    <div
      className={`${styles["layout"]}
      ${isPlannedTask && styles["planned-task"]}
      `}
      onClick={() => {
        if (!isSwiping) {
          toggleModal({
            type: ModalType.TaskDetail,
            payload: { taskId: taskId },
          });
          trackerHelper.trackActivityTaskCardClick();
        } else {
          return;
        }
      }}
    >
      {children}
    </div>
  );
};
