import {
  AddonProductsListPopup,
  AddonLocationsListPopup,
  AddonProductLocationsListPopup,
  AddonLocationProductsListPopup,
} from "./components/Insights/AddonWithPagination";
import AddPhoneNumber from "./components/Profile/AddPhoneNumber";
import CreateTaskScreen from "./components/CreateTaskScreen";
import CustomerMFAQ from "./components/MFAQ/CustomerMFAQ";
import CustomerMFAQDetail from "./components/MFAQ/QuestionDetailsPage/CustomerMFAQDetail";
import Department from "./components/Insights/Department";
import {
  InsightsLocationDetails,
  InsightsProductDetails,
  InsightsProductLocationDetails,
} from "./components/Insights/Details";
import Feedback from "./components/Profile/Feedback";
import Insights from "./components/Insights/Insights";
import NewInsights from "./components/Insights/NewInsights";
import Language from "./components/Language";
import { LogoutView } from "./features/login/LogoutView";
import NewQuestionMFAQ from "./components/MFAQ/NewQuestion";
import { PIPHomeView } from "./features/PIP/PIPHomeView";
import { PIPStockView } from "./features/PIP/PIPStockView";
import PrivacyStatement from "./components/Privacy/PrivacyStatement";
import PushSettings from "./components/Profile/NotificationsSettings";
import RelatedTasks from "./components/RelatedTasks";
import Search from "./components/Search";
import Store from "./components/Insights/Store";
import StoreContacts from "./components/StoreContacts";
import Support from "./components/Profile/Support";
import TaskDetail from "./components/TaskDetail";
import { generateTaskForm } from "./components/TaskForm";
import TaskNotifications from "./components/Notifications/Notifications";
import { TeamMembers } from "./components/TeamMembers";
import AddonForm from "./components/AddonForm";
import { NearbyAddons } from "./components/NearbyAddons/NearbyAddons";
import { AnnouncementPage } from "./components/AnnouncableFeatures/AnnouncementPage";
import { TestBuyOverview } from "./components/TestBuy/Overview/TestBuyOverview";
import { ShoppingToolsOverview } from "./components/ShoppingTools/Overview/ShoppingToolsOverview";
import { ProgressOverview } from "./components/ShoppingTools/Progress/ProgressOverview";
import { ShowroomOW } from "./components/ShoppingTools/Showroom/ShowroomOW";
import { MarkethallOW } from "./components/ShoppingTools/Markethall/MarkethallOW";
import { Cashline } from "./components/ShoppingTools/Cashline/Cashline";
import { ShoppingToolsSettings } from "./components/ShoppingTools/Settings/ShoppingToolsSettings";
import { NewTestBuyForm } from "./components/TestBuy/NewTestBuy/NewTestBuyForm";
import { CustomTestBuyForm } from "./components/TestBuy/NewTestBuy/CustomTestBuyForm";
import { ArticleDetailsPage } from "./components/TestBuy/ArticleDetailPage/ArticleDetailsPage";
import { TestBuyDetailPage } from "./components/TestBuy/TestBuyDetailPage/TestBuyDetailPage";
import { TestbuyTaskCreationPage } from "./components/TestBuy/TaskCreationPage/TestbuyTaskCreationPage";
import { LOGOUT_PATH } from "./paths";
import { RoomsView } from "./components/RoomSettings/Rooms/RoomsView";
import { TabbedRoomView } from "./components/RoomSettings/Rooms/TabbedRoomView";
import { ArticleView } from "./components/RoomSettings/Articles/ArticleView";
import { AggregatedArticleView } from "./components/RoomSettings/Articles/AggregatedArticleView";
import { CreateTaskView } from "./components/RoomSettings/Tasks/CreateTaskView";
import { GroupView } from "./components/RoomSettings/Rooms/GroupView";
import { OngoingTasksListingView } from "./components/RoomSettings/Tasks/OngoingTasksListingView";
import { ActivityOverview } from "@coworker/apprestructured/src/activity/components/ActivityOverview";
import { SearchedArticleView } from "./components/RoomSettings/Articles/SearchedArticleView";
import { HomePage } from "@coworker/apprestructured/src/home/components/HomePage/HomePage";
import { TasksListTabs } from "@coworker/apprestructured/src/tasks/components/TaskListTabs/TaskListTabs";
import { LandingPage } from "@coworker/apprestructured/src/login/components/LandingPage/LandingPage";
import { TeamSelection } from "@coworker/apprestructured/src/login/components/TeamSelection/TeamSelection";
const isFullHeight = true,
  isHidden = true,
  alwaysSticky = true;

/***
 *    ATTENTION: When adding a new top-level path, also add it in here: firebase.json  under hosting.rewrites and repeated twice.
 *    It is necessary for reloads to work.
 */

const routes = [
  // Home
  {
    path: "/teamselection",
    content: TeamSelection,
  },
  {
    path: "/",
    content: LandingPage,
  },
  {
    path: "/home",
    content: HomePage,
    layoutProps: { isFullHeight },
  },
  {
    path: "/team",
    content: TeamMembers,
  },
  {
    path: "/contacts",
    content: StoreContacts,
  },
  {
    path: LOGOUT_PATH,
    content: LogoutView,
    navProps: { isHidden },
    layoutProps: { isFullHeight },
  },

  // Profile
  { path: "/phone", content: AddPhoneNumber },
  { path: "/support", content: Support },
  { path: "/feedback", content: Feedback },
  { path: "/language", content: Language },
  { path: "/notifications", content: PushSettings },
  { path: "/privacy", content: PrivacyStatement },
  { path: "/featureannouncement", content: AnnouncementPage },

  // Insights
  {
    path: "/insights",
    content: Insights,
  },
  {
    path: "/insights/store",
    content: Store,
    layoutProps: { isFullHeight },
    navProps: { isHidden },
  },
  {
    path: "/insights/location/:location_id/products",
    content: AddonLocationProductsListPopup,
  },
  {
    path: "/insights/product/:product_id/locations",
    content: AddonProductLocationsListPopup,
  },
  {
    path: "/insights/location/:location_id",
    content: InsightsLocationDetails,
  },
  {
    path: "/insights/product/:product_id",
    content: InsightsProductDetails,
  },
  {
    path: "/insights/product/:product_id/location/:location_id",
    content: InsightsProductLocationDetails,
  },
  {
    path: "/insights/products",
    content: AddonProductsListPopup,
  },
  {
    path: "/insights/locations",
    content: AddonLocationsListPopup,
  },
  {
    path: "/insights/department/:department_id",
    content: Department,
  },
  {
    path: "/search",
    content: Search,
    layoutProps: { isFullHeight },
  },

  // New insights
  {
    path: "new_insights",
    content: NewInsights,
  },

  // Tasks - create/edit
  {
    path: "/task/new/product",
    content: generateTaskForm("product", "create"),
  },
  {
    path: "/task/edit/product_quality/:id",
    content: generateTaskForm("product_quality", "edit"),
  },
  {
    path: "/task/new/product_quality",
    content: generateTaskForm("product_quality", "create"),
  },
  {
    path: "/task/edit/product/:id",
    content: generateTaskForm("product", "edit"),
  },
  {
    path: "/task/new/addon",
    content: AddonForm,
  },
  {
    path: "/task/new/addon/nearby",
    content: NearbyAddons,
  },
  {
    path: "/task/edit/addon/:id",
    content: generateTaskForm("addon_edit", "edit"),
  },
  {
    path: "/task/new/custom",
    content: generateTaskForm("custom", "create"),
  },
  {
    path: "/task/new/mfaq_followup",
    content: generateTaskForm("mfaq_followup", "create"),
  },
  {
    path: "/task/edit/mfaq_followup/:id",
    content: generateTaskForm("mfaq_followup", "edit"),
  },
  {
    path: "/task/edit/custom/:id",
    content: generateTaskForm("custom", "edit"),
  },
  {
    path: "/task/edit/planned/:id",
    content: generateTaskForm("planned", "edit"),
  },
  { path: "/task/new", content: CreateTaskScreen, navProps: { alwaysSticky } },

  // PIP
  { path: "/product/:full_id", content: PIPHomeView },
  { path: "/product/:full_id/stock", content: PIPStockView },

  // Task detail
  { path: "/task/:id", content: TaskDetail },
  {
    path: "/task/:id/changes",
    content: generateTaskForm("addon_changes", "complete"),
  },
  { path: "/tasks", content: TasksListTabs },
  { path: "/tasks/related/:id", content: RelatedTasks },
  { path: "/tasks/activity/notifications", content: TaskNotifications },
  // MFAQ
  {
    path: "/customerquestions",
    content: CustomerMFAQ,
  },
  {
    path: "/customerquestions/new",
    content: NewQuestionMFAQ,
  },
  {
    path: "/customerquestions/:id",
    content: CustomerMFAQDetail,
  },

  // TestBuy
  {
    path: "/testbuy",
    content: TestBuyOverview,
  },
  {
    path: "/testbuy/:id",
    content: TestBuyDetailPage,
    navProps: { isHidden },
  },
  {
    path: "/testbuy/:id/article/:articleId",
    content: ArticleDetailsPage,
  },
  {
    path: "/testbuy/new",
    content: NewTestBuyForm,
  },
  {
    path: "/testbuy/custom",
    content: CustomTestBuyForm,
  },
  {
    path: "/testbuy/:id/article/:articleId/createTask",
    content: TestbuyTaskCreationPage,
  },

  // Shopping tools
  {
    path: "/shoppingtools",
    content: ShoppingToolsOverview,
  },
  {
    path: "/shoppingtools/progress",
    content: ProgressOverview,
  },
  {
    path: "/shoppingtools/progress/showroom/:id",
    content: ShowroomOW,
    layoutProps: { isFullHeight },
    navProps: { isHidden },
  },
  {
    path: "/shoppingtools/progress/markethall/:id",
    content: MarkethallOW,
    layoutProps: { isFullHeight },
    navProps: { isHidden },
  },
  {
    path: "/shoppingtools/progress/cashline/:id",
    content: Cashline,
    layoutProps: { isFullHeight },
    navProps: { isHidden },
  },
  {
    path: "/shoppingtools/settings",
    content: ShoppingToolsSettings,
    layoutProps: { isFullHeight },
    navProps: { isHidden },
  },

  // Room settings
  {
    path: "/roomsettings",
    content: RoomsView,
  },
  {
    path: "/roomsettings/tabs/:roomId",
    content: TabbedRoomView,
  },
  {
    path: "/roomsettings/groups/:groupId",
    content: GroupView,
  },
  {
    path: "/roomsettings/articles/:articleId",
    content: ArticleView,
  },
  {
    path: "/roomsettings/rooms/:roomId/articles/product_article_id/:productArticleId",
    content: AggregatedArticleView,
  },
  {
    path: "/roomsettings/:roomId/articles/:articleId/createtask",
    content: CreateTaskView,
  },
  {
    path: "/roomsettings/tasks",
    content: OngoingTasksListingView,
  },
  {
    path: "/roomsettings/articles/search/:productArticleId",
    content: SearchedArticleView,
  },
  {
    path: "/task/edit/room-settings/:id",
    content: generateTaskForm("roomsettings_edit", "edit"),
  },

  // Activity
  {
    path: "/activity",
    content: ActivityOverview,
  },
];

export default routes;
