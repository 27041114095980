import React, { ReactNode } from "react";
import ListView from "@ingka/list-view";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/list-view/dist/style.css";
import "@ingka/focus/dist/style.css";

interface ListViewProps {
  children?: ReactNode;
  id: string;
  size?: "small" | "medium";
  className?: string;
  showDivider?: boolean;
}

export const FixaListView = (props: ListViewProps) => {
  return <ListView {...props}>{props.children}</ListView>;
};
