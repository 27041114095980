import { useMemo } from "react";
import { Room } from "../components/RoomSettings/types/room";

const roomCompare = (a: Room, b: Room) => {
  if (a.active !== b.active) {
    if (a.active === "ACTIVE") {
      return -1;
    } else {
      return 1;
    }
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const useSortedRooms = (rooms: Room[] | undefined) => {
  const sortedRooms = useMemo(() => {
    if (!rooms) {
      return undefined;
    } else if (!rooms.length) {
      return [];
    }
    return rooms.sort(roomCompare);
  }, [rooms]);

  return sortedRooms;
};
