import { useMemo } from "react";
import { useLocation } from "react-router";

type FixaLocation = {
  pathname: string;
  search: string;
};

function useFixaLocation(): FixaLocation {
  const { pathname, search } = useLocation();

  return useMemo(() => {
    return { pathname, search };
  }, [pathname, search]);
}

export default useFixaLocation;
