import React from "react";
import { RoomsViewMobile } from "./RoomsViewMobile";
import { RoomsViewDesktop } from "./RoomsViewDesktop";
import { DesktopOnly } from "@coworker/apprestructured/src/shared/components/DesktopOnly";
import { MobileOnly } from "@coworker/apprestructured/src/shared/components/MobileOnly";

export function RoomsView() {
  return (
    <>
      <MobileOnly>
        <RoomsViewMobile />
      </MobileOnly>
      <DesktopOnly>
        <RoomsViewDesktop />
      </DesktopOnly>
    </>
  );
}
