import React from "react";
import { useCallInsightsFunction } from "./API/useCallFirebaseFunction";
import { parseProduct } from "../services/products.service";
import { splitLocation } from "../services/locations.service";
import { INSIGHTS_GENERAL } from "@coworker/enums/insightsRequestTypes";
import { useProductsMap } from "./useProductsMap";

function getTitles(item, { showLocation, productsMap }) {
  if (showLocation) {
    const [title, subtitle] = splitLocation(
      item.raw_location || item.group_current
    ); // NOTE: Only use raw_location when group_by === "LOCATION"
    return { title, subtitle };
  }
  const [short_id] = parseProduct(item.group_current);
  const foundItem = productsMap[short_id] || {};
  const image = foundItem?.smallImageUrl;
  const description = foundItem?.description;
  return { title: foundItem.name || "?", description, image };
}

const defaultState = (limit) => ({
  loading: true,
  data: [],
  page: 0,
  limit,
  total: 0,
});

export default function useInsightsAddonLists(
  showLocation,
  {
    start_date,
    end_date,
    store_id,
    limit = 3,
    hfb,
    hfb_request_type,
    deptFilters: { orderBy: order_by, orderByAsc: order_by_asc } = {},
    location,
    product_article_code,
    isFetchingSavedFilters,
  }
) {
  const paginationreducer = (state, action) => {
    switch (action.type) {
      case "loading":
        return {
          ...state,
          loading: true,
        };
      case "finishedLoading":
        return {
          ...state,
          loading: false,
        };
      case "loadNewData":
        const list = [...state.data];
        for (const row of action.data || []) {
          if (!list.find(({ id }) => id === row.id)) list.push(row);
        }
        return {
          ...state,
          loading: false,
          data: list,
          total: action.total || 0, // 0 to avoid rerender flash
        };
      case "newPage":
        return {
          ...state,
          page: state.page + 1,
        };
      case "reset":
        return defaultState(limit);
      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(
    paginationreducer,
    defaultState(limit)
  );
  React.useEffect(() => {
    dispatch({ type: "reset" });
  }, [
    start_date,
    end_date,
    store_id,
    limit,
    order_by,
    order_by_asc,
    location,
    product_article_code,
    hfb,
    hfb_request_type,
  ]);

  const { data, loading } = useCallInsightsFunction(
    INSIGHTS_GENERAL,
    {
      store_id,
      start_date,
      end_date,
      group_by: showLocation ? "LOCATION" : "PRODUCT",
      limit,
      offset: limit * state.page,
      order_by,
      order_by_asc,
      ...(location && { location }),
      ...(product_article_code && { product_id: product_article_code }),
      ...(hfb_request_type && { hfb_request_type: hfb_request_type }),
      ...(hfb && { hfb: hfb }),
      isFetchingSavedFilters,
    },
    {
      blockRequest: !store_id,
    }
  );
  // TODO: handle also insights.error

  const list =
    (!showLocation && data?.records?.map((row) => row.group_current)) || [];

  const itemNosList = list.map((string) => parseProduct(string)[0]);

  const { itemsMap: productsMap, isLoading: isLoadingItems } = useProductsMap(
    itemNosList?.filter(Boolean)
  );
  const loadingProducts =
    isLoadingItems || (list.length && !Object.keys(productsMap).length);

  React.useEffect(() => {
    if (loading || loadingProducts) dispatch({ type: "loading" });
  }, [loading, loadingProducts]);

  React.useEffect(() => {
    // TODO: handle also retailItems.error
    if (!data?.records || (!showLocation && !productsMap)) return;
    const preparedData = data.records.map((item) => {
      return {
        product_id: item.group_current,
        refilled: item.current_sum,
        turnover: item.current_turnover_sum,
        currency: item.currency,
        ...getTitles(item, { showLocation, productsMap }),
        id: item.group_current,
        newest_refill: item.newest_refill,
      };
    });
    if (preparedData[0]?.title && (showLocation || productsMap)) {
      dispatch({
        type: "loadNewData",
        data: preparedData,
        total: data.rows_count,
      });
    } else {
      dispatch({ type: "finishedLoading" });
    }
    // Ignored: productsMap specifics to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLocation, data, loading, !productsMap]);

  React.useEffect(() => {
    if (!loading && !loadingProducts && !showLocation && !productsMap) {
      dispatch({ type: "finishedLoading" });
    }
  }, [loading, loadingProducts, productsMap, showLocation]);

  return {
    ...state,
    nextPage: () => dispatch({ type: "newPage" }),
  };
}
