import roles_enum from "@coworker/enums/roleType";
import statusTypes from "@coworker/enums/statusTypes";
import { sign } from "@coworker/reusable/helpers/math";

function isNotSuperuser(user) {
  return user.role_id !== roles_enum.SUPERUSER;
}

function isStoreSuperuser(user) {
  return user && user.role_id === roles_enum.STOREOWNER;
}

function isCountrySuperuser(user) {
  return user && user.role_id === roles_enum.COUNTRY_SUPERUSER;
}

function isActive(user) {
  return user.status === statusTypes.ACTIVE;
}

function belongsToTeam(user, teamId) {
  return user && user.team_id === teamId;
}

const userSortSequence = {
  [roles_enum.SUPERUSER]: 0,
  [roles_enum.COUNTRY_SUPERUSER]: 1,
  [roles_enum.STOREOWNER]: 2,
  [roles_enum.COWORKER]: 3,
};

function getRoleSequence(role) {
  return userSortSequence[role] || 1000;
}

function sortUsersByRoleAndName(user1, user2) {
  if (user1.role_id === user2.role_id) {
    return sortUsersByName(user1, user2);
  }
  return sign(getRoleSequence(user1.role_id) - getRoleSequence(user2.role_id));
}

function sortUsersByName(user1, user2) {
  return user1.name && user2.name && user1.name[0] < user2.name[0] ? -1 : 1;
}

export const filterAndSortTeamMembers = (users, teamId) => {
  return Object.values(users)
    .filter((u) => belongsToTeam(u, teamId) && isActive(u) && isNotSuperuser(u))
    .sort(sortUsersByRoleAndName);
};

export const sortStoreSuperUsers = (users) =>
  Object.values(users).filter(isStoreSuperuser).sort(sortUsersByName);

export const sortCountrySuperUsers = (users) =>
  Object.values(users).filter(isCountrySuperuser).sort(sortUsersByName);
