import React from "react";
import { User } from "@coworker/types";
import styles from "./users-list-view.module.css";
import { useTranslation } from "@coworker/locales";
import { SimpleToast } from "../../../../simple/SimpleToast/SimpleToast";
import { FixaListView } from "../../../../wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../wrappers/FixaListView/FixaListViewItem";

interface UsersListViewProps {
  users: User[];
  id: string;
}
export const UsersListView: React.FC<UsersListViewProps> = ({ users, id }) => {
  const [toastVisible, setToastVisible] = React.useState(false);
  const [copiedEmail, setCopiedEmail] = React.useState("");
  const { t } = useTranslation();
  const copyUserEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    setCopiedEmail(email);
    setToastVisible(true);
  };
  return (
    <div>
      <SimpleToast
        text={`${t("copiedString")} ${copiedEmail}!`}
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <FixaListView
        key={id}
        id={id}
        className={styles["users-list-view"] ?? ""}
      >
        {users.map((user: User, index: number) => {
          return (
            <FixaListViewItem
              className={styles["users-list-view-item"] || ""}
              name={user?.id ?? `user_${index}`}
              control={"navigational"}
              controlIcon={"copy"}
              onClick={() => copyUserEmail(user?.email)}
              title={user?.name}
              description={user?.email}
            ></FixaListViewItem>
          );
        })}
      </FixaListView>
    </div>
  );
};
