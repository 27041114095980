import React, { ReactNode } from "react";
import CommercialMessage from "@ingka/commercial-message";
import "@ingka/commercial-message/dist/style.css";

interface Props {
  message: ReactNode;
  className?: string;
}

export const FixaCommercialMessage = (props: Props) => {
  return <CommercialMessage {...props} />;
};
