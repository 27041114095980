import React from "react";
import MagnifyingGlassIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/MagnifyingGlassIconPath";
import styles from "./global-search.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";
// This component is used in the MainHeader componentcon
const GlobalSearch = () => {
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();
  const handleClick = () => {
    trackerHelper.trackSearchButtonClick();
    push("/search");
  };
  return (
    <div className={styles["global-search"]}>
      <FixaButton
        aria-label={t("searchBasicString")}
        type="tertiary"
        inverseTheme
        onClick={handleClick}
        iconOnly
        ssrIcon={MagnifyingGlassIconPath}
      />
    </div>
  );
};

export default GlobalSearch;
