import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import {
  areaOptionsList,
  periodOptionsList,
  roundOptionsList,
} from "../constants";
import { FiltersOptions } from "./FiltersOptions";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import FilterOptionName from "./FilterOptionName";

const FilterAreaOptionsWrapper = styled.div`
  margin-bottom: 2rem;
`;

type FiltersPopupProps = {
  onClose: React.MouseEventHandler;
  showOptions: FilterOptionName[];
};

export const FiltersPopup: FC<FiltersPopupProps> = ({
  onClose,
  showOptions,
}) => {
  const [activePeriod, setActivePeriod] = React.useState(
    localStorage.getItem("shoppingToolsPeriodFilter") ?? "today"
  );
  const [activeArea, setActiveArea] = React.useState(
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore"
  );
  const [activeRound, setActiveRound] = React.useState(
    localStorage.getItem("shoppingToolsRoundFilter") ?? "allRounds"
  );
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      [
        {
          name: FilterOptionName.PERIOD,
          component: (
            <FiltersOptions
              key={FilterOptionName.PERIOD}
              id={FilterOptionName.PERIOD}
              title="periodString"
              list={periodOptionsList(activePeriod)}
              setState={setActivePeriod}
              showPeriod
            />
          ),
        },
        {
          name: FilterOptionName.AREA,
          component: (
            <FilterAreaOptionsWrapper key={FilterOptionName.AREA}>
              <FiltersOptions
                id={FilterOptionName.AREA}
                title="areaString"
                list={areaOptionsList(activeArea)}
                setState={setActiveArea}
              />
            </FilterAreaOptionsWrapper>
          ),
        },
        {
          name: FilterOptionName.ROUND,
          component: (
            <FilterAreaOptionsWrapper key={FilterOptionName.ROUND}>
              <FiltersOptions
                id={FilterOptionName.ROUND}
                title="roundString"
                list={roundOptionsList(activeRound)}
                setState={setActiveRound}
              />
            </FilterAreaOptionsWrapper>
          ),
        },
      ]
        .filter(({ name }) => showOptions.includes(name))
        .map(({ component }) => component),
    [activeArea, activePeriod, activeRound, showOptions]
  );

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("shoppingToolsHeaderString"),
        actions: [
          {
            name: "close",
            icon: <FixaSSRIcon paths={CrossIconPath} />,
            onClick: onClose,
            position: "right",
          },
        ],
      }}
      noPadding
    >
      {options}
    </FullScreenPopup>
  );
};
