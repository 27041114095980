import { TaskTabsTypes } from "../../shared/constants/taskTabsTypes";
import { useSelectedTaskFilters } from "./useSelectedTaskFilters";
import { refilter } from "@coworker/app/src/components/Tasks";
import { sortTasks } from "@coworker/reusable/helpers/taskSorters";
import { useUserPreferences } from "@coworker/app/src/hooks/useProfilePreferencesQuery";
import { TASKS_FILTERS_DEFAULT } from "../constants/tasksFilters";
import { TaskTypesFilters } from "../enums/taskTypesFilters";
import { LocationType, TasksFilterType } from "../types/tasksFilterType";
import { useTasksQuery } from "./useTasksQuery";
import preferences from "@coworker/enums/profilePreferences";
import { mapPreferencesToTaskFilters } from "../mappers/taskFiltersMapper";

/**
 * @param {string[]} activeFilters
 * @param {string[]} addonOption
 */
const shouldSortByPickingLocation = (
  selectedTaskTypeFilter: TaskTypesFilters[],
  selectedAddonLocationTypeFilter: LocationType
) =>
  selectedTaskTypeFilter?.includes(TaskTypesFilters.PICK_AND_REFILL) &&
  selectedAddonLocationTypeFilter?.includes("pickingFilter");

export const useFilteredSortedTasks = (
  taskTabType: TaskTabsTypes,
  productIdFilter?: string
) => {
  const { data: userPreferences, isLoading: isPreferencesLoading } =
    useUserPreferences(preferences.TASK_FILTERS_ALL);

  const savedTaskFilters: TasksFilterType = !isPreferencesLoading
    ? mapPreferencesToTaskFilters(userPreferences)
    : TASKS_FILTERS_DEFAULT;

  const selectedTaskTypeFilter = savedTaskFilters?.taskType;

  const selectedAddonLocationTypeFilter = savedTaskFilters?.addonLocationType;

  const selectedTaskFilters = useSelectedTaskFilters(
    taskTabType,
    !isPreferencesLoading ? savedTaskFilters : undefined,
    productIdFilter
  );

  const { data: tasksList, isLoading } = useTasksQuery(taskTabType);

  const selectedSortByFilter = savedTaskFilters?.sortBy;

  const sortByPickingLocation = shouldSortByPickingLocation(
    selectedTaskTypeFilter,
    selectedAddonLocationTypeFilter as LocationType
  );
  if (!isLoading) {
    const filteredTasks = refilter(selectedTaskFilters, tasksList?.list ?? []);
    const sortedTasks = sortTasks(
      filteredTasks,
      selectedSortByFilter,
      taskTabType,
      sortByPickingLocation
    );
    return { sortedTasks, isLoading };
  }
  return { sortedTasks: [], isLoading };
};
