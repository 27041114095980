import React from "react";
import { useTranslation } from "@coworker/locales";
import { useQueryClient } from "@tanstack/react-query";
import { addArticle } from "../../services/articles.service";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalBody";
import { AggregatedArticle } from "../../hooks/useAggregatedArticles";
import { Room } from "../../types/room";
import { ArticleListItem } from "../../Molecules/ArticleListItem";
import { EditArticleRow } from "../../Molecules/EditArticleRow";
import { Article } from "../../types/article";
import { sortArticlesByGroup } from "../../Articles/ArticleView.helper";
import { ArticleListItemMobile } from "../../styles/styles";
import { invalidateArticleQueries } from "../../tools";
import styled from "styled-components";

const StyledFixaSheets = styled(FixaSheets)`
  borderbottom: 1px solid var(--grey200);
`;

const StyledFixaModalBody = styled(FixaModalBody)`
  padding: 0px;
`;

interface EditArticlesModalProps {
  aggregatedArticle: AggregatedArticle;
  room: Room;
  onClose: () => void;
}

export const EditArticlesModal = ({
  aggregatedArticle,
  onClose,
  room,
}: EditArticlesModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [articles, setArticles] = React.useState<Article[]>([]);

  if (articles.length === 0) {
    const hasNoneGroup = aggregatedArticle.aggregatedArticles.find(
      (a) => !a.groupName
    );
    if (!hasNoneGroup) {
      const articleCopyBase = aggregatedArticle.aggregatedArticles.find(
        (_a) => true
      );
      setArticles(
        sortArticlesByGroup([
          {
            ...(articleCopyBase as Article),
            nbrArticles: 0,
            groupId: undefined,
            groupName: undefined,
          },
          ...aggregatedArticle.aggregatedArticles,
        ])
      );
    } else
      setArticles(sortArticlesByGroup(aggregatedArticle.aggregatedArticles));
  }

  const addArticleWithNoGroup = async (article: Article) => {
    const articleIds = await addArticle({
      ...article,
      groupId: undefined,
      nbrArticles: 1,
    });
    if (articleIds && articleIds.length > 0) {
      const articleId = articleIds?.shift();
      const articleWithNoGroup = articles.find((a) => !a.groupId);
      if (articleWithNoGroup && articleId) {
        articleWithNoGroup.id = articleId.id;
        articleWithNoGroup.nbrArticles = 1;

        setArticles(
          articles.map((article) => {
            return { ...article };
          })
        );
      }
    }
  };

  return (
    <FixaModal
      handleCloseBtn={async () => {
        await invalidateArticleQueries(queryClient);
        onClose();
      }}
      visible={true}
    >
      <StyledFixaSheets
        alignment="right"
        aria-label="Accessibility header for a modal"
        footer={null}
        header={
          <FixaModalHeader
            ariaCloseTxt="Close edit quantity"
            title={t("editQuantityString")}
            closeBtnClick={async () => {
              await invalidateArticleQueries(queryClient);
              onClose();
            }}
          />
        }
        size="small"
      >
        <StyledFixaModalBody>
          <ArticleListItemMobile>
            <ArticleListItem article={aggregatedArticle} hideChevron={true} />
          </ArticleListItemMobile>
          {articles.map((article, index) => (
            <EditArticleRow
              key={index}
              index={index}
              room={room}
              article={article}
              addArticleWithNoGroup={addArticleWithNoGroup}
            />
          ))}
        </StyledFixaModalBody>
      </StyledFixaSheets>
    </FixaModal>
  );
};
