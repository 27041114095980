import React from "react";
import styled from "styled-components";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";
const Container = styled.div`
  margin-top: 20px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Chart = styled(FixaSkeleton)`
  width: 45vw !important;
  height: 45vw;
  border-radius: 50%;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 15px 0px 15px;
`;

const Label = styled(FixaSkeleton)`
  width: 20vw !important;
  height: 12px;
  margin-left: 10px;
`;

const LabelDots = styled(FixaSkeleton)`
  width: 12px !important;
  height: 12px;
  border-radius: 50%;
`;

export const ChartSkeleton = () => {
  return (
    <Container>
      <ChartWrapper>
        <Chart />
        <LabelContainer>
          <LabelWrapper>
            <LabelDots />
            <Label />
          </LabelWrapper>
          <LabelWrapper>
            <LabelDots />
            <Label />
          </LabelWrapper>
          <LabelWrapper>
            <LabelDots />
            <Label />
          </LabelWrapper>
          <LabelWrapper>
            <LabelDots />
            <Label />
          </LabelWrapper>
          <LabelWrapper>
            <LabelDots />
            <Label />
          </LabelWrapper>
        </LabelContainer>
      </ChartWrapper>
    </Container>
  );
};
