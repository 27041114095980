import React from "react";
import { Sheets } from "@ingka/modal";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";

interface SheetsProps {
  alignment?: "left" | "right";
  preserveAlignment?: boolean;
  size?: "small" | "medium" | "large";
  fullSize?: boolean;
  header: React.ReactElement | null;
  footer: React.ReactElement | null;
  children: React.ReactNode | React.ReactNode[];
  labelledById?: string;
  className?: string;
}

export const FixaSheets = (props: SheetsProps) => {
  return <Sheets {...props} />;
};
