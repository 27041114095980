import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";
import { coreServiceBaseUrl } from "@coworker/app/src/core/cloud-services/cloud-services.helper";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import { ErrorType } from "../../shared/constants/errorTypes";
import { getCookieByName } from "@coworker/app/src/helpers/cookies";
import { makeServiceHeaders } from "@coworker/app/src/core/hooks/fetch.helpers";

const envId = getEnvironmentId();
const CORE_SERVICE_URL = coreServiceBaseUrl(envId, isChineseEnvironment());

export const upsertUserByEmail = async (email: string) => {
  const impersonateStoreUser = getCookieByName(
    "impersonateStoreUser"
  ) as boolean;

  let headers: HeadersInit;

  try {
    if (typeof makeServiceHeaders !== "function") {
      console.error("makeServiceHeaders is undefined");
      return [];
    }

    headers = await makeServiceHeaders();
  } catch (error) {
    console.error("Error creating service headers:", error);
    return [];
  }

  const response = await fetch(
    `${CORE_SERVICE_URL}/auth/users/email/${email}`,
    {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({
        email,
        impersonateStoreUser: Boolean(impersonateStoreUser),
      }),
    }
  );

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(ErrorType.NOTFOUND, { cause: response.status });
    } else if (response.status === 403) {
      throw new Error(ErrorType.FORBIDDEN, { cause: response.status });
    } else if (response.status === 401) {
      throw new Error(ErrorType.UNAUTHORIZED, { cause: response.status });
    }
    throw new Error(ErrorType.INTERNALERROR);
  }

  const user = await response.json();
  return user?.data?.data;
};

export const useUpsertUserByEmail = (email: string) => {
  return useQuery<any>({
    queryKey: ["userByEmail", email],
    queryFn: async () => await upsertUserByEmail(email),
    staleTime: 2 * INTERVAL.MINUTE,
  });
};
