import React from "react";
import FixaStatus from "@coworker/apprestructured/src/shared/wrappers/FixaStatus/FixaStatus";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import { useTranslation } from "@coworker/locales";
import { useAvailableElevatedStock } from "../../../hooks/useAvailableElevatedStock";
import {
  getStockStatusIcon,
  getStockStatusIndicatorColor,
  getStockStatusText,
} from "./ArticleStock.helper";

interface ArticleStockProps {
  productArticleId: string;
  productArticleType: string;
}

export const ArticleStock = ({
  productArticleId,
  productArticleType,
}: ArticleStockProps) => {
  const { t } = useTranslation();

  const fullId = `${productArticleType}${productArticleId}`;
  const { available, elevated, loading } = useAvailableElevatedStock(fullId);

  const stockStatusColor = getStockStatusIndicatorColor(available, elevated);
  const stockStatusText = getStockStatusText(available, elevated);
  const stockStatusIcon = getStockStatusIcon(available, elevated);

  const elevatedStatus = !elevated ? "(0)" : `(${elevated})`;
  let addOnContent = (
    <FixaStatus
      label={`${available} ${elevatedStatus}`}
      statusColor={stockStatusColor}
      small
    />
  );

  return (
    <FixaListViewItem
      title={t("statusString")}
      description={t(stockStatusText)}
      leadingIcon={stockStatusIcon}
      addOn={loading ? t("loadingString") : addOnContent}
      emphasised
      inset
    />
  );
};
