import React from "react";
import { useParams } from "react-router-dom";
import taskTypes from "./types";
import widgets from "./widgets";
import { useOnline } from "../../core/hooks/useOnline";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import TaskError from "./Error";
import { useWatchedTask } from "../../hooks/useWatchedTasks";
import FullscreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useTeamId, useUID, useFixaUID } from "../../core/auth/useLoggedInUser";
import EllipsesHorizontalIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/EllipsesHorizontalIconPath";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import { useTaskOptionPopupMenu } from "../../features/tasks/useTaskOptionPopupMenu";
import { shouldDisplayMenu } from "./actions/helpers";
import taskStateOptions from "@coworker/enums/taskState";
import taskTypeOptions from "@coworker/enums/taskType";
import { issueTypeTitle } from "../issueTypeTranslations";
import { Trans } from "@coworker/locales";

function TaskDetail() {
  const { id: taskId } = useParams();
  const loadedTask = useWatchedTask(taskId);
  const { getTaskMenu } = useTaskOptionPopupMenu({ ...loadedTask, id: taskId });

  const [isDone, setIsDone] = React.useState(false);
  const task = React.useMemo(() => {
    return {
      ...loadedTask,
      isDone,
      setIsDone,
    };
  }, [isDone, loadedTask]);

  // Using state to display resolution instantly when a user enters and to avoid any backend slowness on a slow network.
  const [taskResolution, setTaskResolution] = React.useState(
    task?.finished_summary
  );
  const [picking, setPicking] = useUserPreference(
    profilePreferences.PICKING_DEFAULT,
    false
  );
  const { isOnline } = useOnline();
  const uid = useUID();
  const fixaUid = useFixaUID();
  const team_id = useTeamId();
  const userAndTeamIds = { uid, team_id, fixaUid };

  if (!loadedTask)
    return (
      <FullscreenPopup>
        <PositionedLoaderIcon />
      </FullscreenPopup>
    );
  const conf = taskTypes[task?.task_type];
  if (!conf?.fields) return <TaskError />; // Protect against crash when loading a not supported task, i.e. on an older Fixa with a task from a newer Fixa.

  const isRefill =
    task.picked ||
    (task.task_type === taskTypeOptions.ADDON &&
      (task.state === taskStateOptions.CLOSED ||
        task.state === taskStateOptions.COMPLETED));

  const title = isRefill ? (
    <Trans>refillString</Trans>
  ) : (
    task.custom_title ||
    issueTypeTitle[task.task_type][task.issue_type] ||
    issueTypeTitle[task.task_type].default
  );

  return (
    <FullscreenPopup
      noPadding
      appBarConfig={{
        title: conf.title || title,
        actions: [
          task.state !== "COMPLETED" && {
            name: "Menu",
            icon: <FixaSSRIcon paths={EllipsesHorizontalIconPath} />,
            onClick: getTaskMenu,
            position: "right",
            disabled:
              !isOnline || !shouldDisplayMenu(task, { uid, team_id, fixaUid }),
          },
        ],
      }}
      actionBarContent={
        <>
          {conf.actions
            .filter(({ display }) =>
              display({
                task,
                profile: userAndTeamIds,
                online: isOnline,
                picking: task.task_type === "ADDON" && picking,
              })
            )
            .map(({ component: Action }, key) => (
              <Action task={task} taskId={taskId} key={key} />
            ))}
        </>
      }
    >
      {conf.fields.map((field, idx) => {
        const Widget = widgets[field.type];
        return (
          <Widget
            picking={picking}
            setPicking={setPicking}
            key={field.type + idx}
            value={(field.values?.in || ((id) => id))(task)}
            taskId={taskId}
            task={task}
            taskPath={`${
              task?.task_type === "PLANNED" ? "repeatable_tasks" : "tasks"
            }/${taskId}/`}
            conf={field.conf}
            initialResolution={taskResolution || task?.finished_summary}
            onChange={(resolution) =>
              resolution !== undefined && setTaskResolution(resolution)
            }
          />
        );
      })}
    </FullscreenPopup>
  );
}

export default TaskDetail;
