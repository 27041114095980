import React from "react";
import { Trans } from "@coworker/locales";
import { NewReview } from "./NewReview";
import { AppBar } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import { ChartCard } from "../Chart/ChartCard";
import { Filters } from "../Filters/Filters";
import { FiltersPopup } from "../Filters/FiltersPopup";
import styled from "styled-components";
import useBooleanParamState from "@coworker/apprestructured/src/shared/hooks/useBooleanParamState";
import FilterOptionName from "../Filters/FilterOptionName";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import GearIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/GearIconPath";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--grey150);
`;

const SHOW_FILTER_OPTIONS = [FilterOptionName.AREA, FilterOptionName.PERIOD];

export const ShoppingToolsOverview = () => {
  const { push } = useWorkspacesAction();
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";

  const {
    value: showFilters,
    setTrue: handleOpenFilters,
    setFalse: handleCloseFilters,
  } = useBooleanParamState("showFilters");

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <FixaSSRIcon paths={GearIconPath} />,
      onClick: () => push("/shoppingtools/settings"),
      position: "right",
    },
  ];

  if (showFilters) {
    return (
      <FiltersPopup
        onClose={handleCloseFilters}
        showOptions={SHOW_FILTER_OPTIONS}
      />
    );
  }

  return (
    <div>
      <AppBar
        title={<Trans>shoppingToolsHeaderString</Trans>}
        actions={appBarActions}
      />
      <NewReview />

      <Filters onOpen={handleOpenFilters} showOptions={SHOW_FILTER_OPTIONS} />
      {(activeArea === "showroomOW" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>showroomOWString</Trans>}
          showProgress
          area={"showroom"}
        />
      )}
      {activeArea === "wholeStore" && <Line />}
      {(activeArea === "markethallOW" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>markethallOWString</Trans>}
          showProgress
          area={"markethall"}
        />
      )}
      {activeArea === "wholeStore" && <Line />}
      {(activeArea === "otherAreaCashline" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>otherAreaCashlineString</Trans>}
          area={"cashline"}
        />
      )}
    </div>
  );
};
