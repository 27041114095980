import React from "react";
import trackerHelper from ".././helpers/tracker";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { OfflineScreen } from "@coworker/components";
import preferences from "@coworker/enums/profilePreferences";
import { Trans } from "@coworker/locales";
import { wrap } from "../helpers/mapHelpers";
import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import FiltersIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FiltersIconPath";
import { FullWidthButton } from "./SkapaButton";
import {
  getFilterFunction,
  userFilters,
  userLocationFilters,
  teamFilter,
  taskStatusFilter,
  taskStateFilter,
  isRefillOrPickAndRefill,
  closedReasonFilter,
  onlyTasksFilter,
  notClosedTooLongAgo,
} from "../helpers/taskFilters";
import { taskFilters } from "../constants/filters";
import { sortTasks } from "@coworker/reusable/helpers/taskSorters";
import { useInputPopup } from "./InputPopup";
import { useTaskListFilters } from "./InputPopup/TaskListFilters";
import { useMultiselectOverflowMenu } from "../features/tasks/useMultiselectOverflowMenu";
import TaskList from "@coworker/apprestructured/src/tasks/components/TaskList/TaskList";
import { ReactComponent as MultiSelectIcon } from "../assets/svg/stack.svg";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { useOnline } from "../core/hooks/useOnline";
import { FlexContainer } from "./Layout/styles";
import EmptyScreen from "./EmptyScreen";
import { useMultiSelectForTab } from "../hooks/useMultiSelect";
import { useTasksForProduct } from "../hooks/useTasksForProduct";
import { useUserPreferences } from "../hooks/useProfilePreferencesQuery";
import { useFixaUID, useTeamId, useUID } from "../core/auth/useLoggedInUser";
import { useStoreTeams } from "../core/hooks/useStoreTeams";
import { useCachedFilteredTasks } from "../core/hooks/useTasksSources";
import {
  titleKeys,
  TaskGroupFilterTitle,
  TaskSortFilterTitle,
  TaskTypeFilterTitle,
  TaskStatusFilterTitle,
  ClosedReasonFilterTitle,
  TaskStateFilterTitle,
} from "./taskCardTitles";
import { taskStatusFilterTypes } from "@coworker/types/lib/taskStatusFilterTypes";
import { taskStateFilterTypes } from "@coworker/types/lib/taskStateFilterTypes";
import { closedReasonFilterTypes } from "@coworker/types/lib/closedReasonFilterTypes";
import { ViewingPartsOfTotal } from "./Reusable/ViewingPartsOfTotal";
import { MultiselectTasksButton } from "@coworker/apprestructured/src/tasks/components/TaskList/MultiselectTasksButton";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";
import { formatFullIdWithDots } from "@coworker/reusable";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";
import { groupByTypes } from "../constants/groupBy";
import { sortingTypes } from "@coworker/types/lib/taskSortingTypes";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";
import { useCachedTasks } from "../services/tasks.service";

const PAGE_SIZE = 500;

const emptyList = [];

const Loader = styled(LoaderIcon)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 1;
`;
const StyledMultiSelectIcon = styled(MultiSelectIcon)`
  margin-right: 15px;
  margin-top: 10px;
  ${({ disabled }) => css`
    color: ${disabled ? "var(--grey500)" : "var(--grey900)"};
  `}
`;

const FiltersWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: var(--white);
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid var(--grey150);
  padding: 10px 0 10px 12px;
`;

const FilterIcons = styled.div`
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: pointer;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Pill = styled(FixaPill)`
  margin-left: 8px;
`;

const emptyParams = {
  open: {
    action: { path: "/task/new", text: <Trans>create2String</Trans> },
    title: <Trans>whyNotCreateATask</Trans>,
  },
  my: {
    action: { path: "/tasks?active=open", text: <Trans>viewOpenString</Trans> },
    title: <Trans>pickUpTask</Trans>,
  },
};

function DisplayTaskFilterPills({ tasks, type, tasksByType }) {
  const {
    activeFilters,
    activeTaskStatusFilters,
    activeTeamFilter,
    activeClosedReasonFilters,
    activeTaskSortFilters,
    locationGroup,
    isGrouping,
    groupByActiveOption,
    activeTaskStateFilter,
  } = useTaskListFilters();

  const location = useFixaLocation();

  const showFiltersPopup = useShowFiltersPopup(tasksByType);
  // TODO: 'disabled' doesn't convey what is disabled, rename to something more descriptive
  const { disabled, reset, showingMultiSelect } = useMultiSelectForTab(type);
  const teams = useStoreTeams();
  const teamId = useTeamId();
  const isRelated = type === "related_product_tasks";
  const isPartOfPQTeam = teams?.find(
    (team) => teamId === team.id && team.is_product_quality
  );
  const isCompletedFilterView =
    type.endsWith("completed") && isRefillOrPickAndRefill(activeFilters);
  const isClosedFilterView = type.endsWith("closed");
  const canMultiSelect = !(tasks.length < 2 || isPartOfPQTeam);
  const showGroupBy =
    !window.location.pathname.includes("/activity") &&
    !window.location.pathname.includes("/tasks/related");
  const selectedTeam =
    activeTeamFilter && teams.find((team) => team.id === activeTeamFilter);
  const isOpenOrMy = type === "open" || type === "my";

  return (
    <FiltersWrapper data-testid="filters">
      {!showingMultiSelect && (
        <FilterIcons onClick={showFiltersPopup}>
          {!disabled && (
            <StyledMultiSelectIcon
              disabled={!canMultiSelect}
              onClick={(event) => {
                event.stopPropagation();
                if (canMultiSelect) reset(event);
                const taskPath = location.pathname + location.search;
                const taskTab = taskPath.split("=")[1];
                const tabTaskOpenedFrom =
                  taskTab === "my" ? "my_tasks" : "open_tasks";
                trackerHelper.trackMultiSelectButtonTab(tabTaskOpenedFrom);
              }}
            />
          )}

          <FullWidthButton
            style={{ margin: 0, marginRight: "16px" }}
            type={"primary"}
            data-testid="filtersButton"
            ssrIcon={FiltersIconPath}
            text={<Trans>Filters</Trans>}
            size="small"
            onClick={() => {
              trackerHelper.filtersButtonTracker();
            }}
          />

          {!!activeFilters.length &&
            !activeFilters?.includes(taskFilters.ALL) && (
              <Pill
                size="xsmall"
                data-testid="activeFilters"
                label={<TaskTypeFilterTitle filter={activeFilters} />}
              />
            )}
          {!!activeTaskSortFilters.length && (
            <Pill
              size="xsmall"
              data-testid="taskSortFilters"
              label={<TaskSortFilterTitle filter={activeTaskSortFilters} />}
            />
          )}
          {isCompletedFilterView && !!activeTaskStatusFilters.length && (
            <Pill
              size="xsmall"
              data-testid="taskStatusFilters"
              label={<TaskStatusFilterTitle filter={activeTaskStatusFilters} />}
            />
          )}
          {isClosedFilterView && !!activeClosedReasonFilters.length && (
            <Pill
              size="xsmall"
              data-testid="closeReasonFilters"
              label={
                <ClosedReasonFilterTitle filter={activeClosedReasonFilters} />
              }
            />
          )}
          {isRelated &&
          activeTaskStateFilter !== taskStateFilterTypes.ALL_STATES ? (
            <Pill
              size="xsmall"
              data-testid="stateFilter"
              label={<TaskStateFilterTitle state={activeTaskStateFilter} />}
            />
          ) : null}
          {isGrouping && showGroupBy && (
            <Pill
              size="xsmall"
              data-testid="groupingOptions"
              label={<TaskGroupFilterTitle groupBy={groupByActiveOption} />}
            />
          )}
          {!!locationGroup.length && !locationGroup?.includes("wholeStore") && (
            <Pill
              size="xsmall"
              data-testid="locationFilters"
              label={<Trans>{locationGroup + "String"}</Trans>}
            />
          )}
          {!isOpenOrMy && selectedTeam && (
            <Pill
              size="xsmall"
              data-testid="activeTeamFilter"
              label={selectedTeam.name}
            />
          )}
        </FilterIcons>
      )}
    </FiltersWrapper>
  );
}

function useTasksByType(type, productIdFilter) {
  const uid = useUID();
  const team_id = useTeamId();
  const fixa_uid = useFixaUID();
  const filterParamsString = JSON.stringify([
    productIdFilter,
    uid,
    team_id,
    type,
    fixa_uid,
  ]);
  const filters = React.useMemo(
    () => [
      wrap(
        getFilterFunction(type),
        { uid, team_id, fixa_uid },
        productIdFilter
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParamsString]
  );
  const [tasksByType, , , , totalTasksCount] = useTasksFromAnySource(
    type,
    filters,
    productIdFilter
  );
  return {
    tasksByType,
    totalTasksCount,
  };
}

function DisplayTasks({
  isOpenOrMy,
  tasks,
  scheduledTasks,
  productIdFilter,
  activeFilters,
  addonOption,
  type,
  groupBy,
  productId,
}) {
  const { selectedTasksCount, selectedTaskIds } = useMultiSelectForTab(type);
  const { getInput } = useInputPopup();

  const { getTaskMenu, loading } = useMultiselectOverflowMenu(
    selectedTasksCount,
    type,
    selectedTaskIds
  );

  const warningPopup = React.useCallback(async () => {
    const response = await getInput("confirmation", {
      question: <Trans count={selectedTasksCount}>aboutToChangeString</Trans>,
      description: (
        <>
          <Trans>undoNotPossibleString</Trans>
          <span>
            <Trans>sureToContinueString</Trans>
          </span>
        </>
      ),
      positiveText: <Trans>yesString</Trans>,
      negativeText: <Trans>negativeString</Trans>,
    });
    if (response) {
      await getTaskMenu();
    }
  }, [getInput, selectedTasksCount, getTaskMenu]);

  async function handleSelectedTasks(event) {
    event.stopPropagation();

    if (selectedTasksCount > 14) {
      await warningPopup();
    } else {
      await getTaskMenu();
    }
  }

  const { tasksByType, totalTasksCount } = useTasksByType(
    type,
    productIdFilter
  );

  const { showingMultiSelect, cancel } = useMultiSelectForTab(type);

  const appBarActions = [
    {
      name: "cancel",
      icon: <FixaSSRIcon paths={CrossIconPath} />,
      onClick: cancel,
      position: "right",
    },
  ];

  const { data: basicItem } = useItemInfo(productId);
  return (
    <>
      {!isOpenOrMy && (
        <FullScreenPopup
          appBarConfig={{
            title: <Trans>{titleKeys[type]}</Trans>,
            subtitle: (
              <>
                {!!productId && (
                  <>
                    {formatFullIdWithDots(productId)}
                    {!!basicItem?.itemName && (
                      <>
                        {" • "}
                        {basicItem?.itemName}
                      </>
                    )}
                  </>
                )}
              </>
            ),
          }}
          noPadding
        >
          <DisplayTaskFilterPills
            tasks={tasks}
            type={type}
            productIdFilter={productIdFilter}
            multiSelectProcessing={loading}
            tasksByType={tasksByType}
          />
          {loading && <Loader />}
          <TaskList
            tasks={tasks}
            scheduledTasks={scheduledTasks}
            groupBy={groupBy}
            filter={activeFilters}
            tabType={type}
            addonFilterOption={isOpenOrMy ? addonOption : []}
            isDraggable={isOpenOrMy}
            totalTasksCount={totalTasksCount}
            isMyTasks={type === "my"}
            multiSelect={showingMultiSelect}
            type={type}
          />
        </FullScreenPopup>
      )}
      <DisplayTaskFilterPills
        tasks={tasks}
        type={type}
        productIdFilter={productIdFilter}
        multiSelectProcessing={loading}
        tasksByType={tasksByType}
      />

      {loading && <Loader />}
      <TaskList
        tasks={tasks}
        scheduledTasks={scheduledTasks}
        groupBy={groupBy}
        filter={activeFilters}
        tabType={type}
        addonFilterOption={isOpenOrMy ? addonOption : []}
        isDraggable={isOpenOrMy}
        totalTasksCount={totalTasksCount}
        isMyTasks={type === "my"}
        multiSelect={showingMultiSelect}
        type={type}
      />
      {showingMultiSelect && (
        <FullScreenPopup
          actionBarContent={
            <>
              {!!selectedTasksCount && (
                <MultiselectTasksButton
                  selectedTasksCount={selectedTasksCount}
                  handleSelectedTasks={handleSelectedTasks}
                />
              )}
            </>
          }
          appBarConfig={{
            title: <Trans>bulkEditTasks</Trans>,
            actions: appBarActions,
          }}
          noPadding
        >
          <TaskList
            tasks={tasks}
            scheduledTasks={scheduledTasks}
            groupBy={groupBy}
            filter={activeFilters}
            tabType={type}
            addonFilterOption={isOpenOrMy ? addonOption : []}
            isDraggable={isOpenOrMy}
            totalTasksCount={totalTasksCount}
            isMyTasks={type === "my"}
            multiSelect={showingMultiSelect}
            type={type}
          />
        </FullScreenPopup>
      )}
    </>
  );
}

function DisplayOffline({ isOpenOrMy, type }) {
  return (
    <FlexContainer>
      {!isOpenOrMy && (
        <FullScreenPopup
          appBarConfig={{
            title: <Trans>{titleKeys[type]}</Trans>,
          }}
          noPadding
        >
          <OfflineScreen task />
        </FullScreenPopup>
      )}
      <OfflineScreen task />
    </FlexContainer>
  );
}

function DisplayEmpty({ isOpenOrMy, type, tasks, productIdFilter }) {
  const { resetTaskListFilters } = useTaskListFilters();
  const { isFiltering } = useTaskListFilters();

  const { tasksByType, totalTasksCount } = useTasksByType(
    type,
    productIdFilter
  );
  const { data: basicItem } = useItemInfo(productIdFilter);

  return (
    <FlexContainer>
      {!isOpenOrMy && (
        <FullScreenPopup
          appBarConfig={{
            title: <Trans>{titleKeys[type]}</Trans>,
            subtitle: (
              <>
                {!!productIdFilter && (
                  <>
                    {formatFullIdWithDots(productIdFilter)}
                    {!!basicItem?.itemName && (
                      <>
                        {" • "}
                        {basicItem?.itemName}
                      </>
                    )}
                  </>
                )}
              </>
            ),
          }}
          noPadding
        >
          <>
            <DisplayTaskFilterPills
              tasks={tasks}
              type={type}
              tasksByType={tasksByType}
            />

            <ViewingPartsOfTotal
              partCount={onlyTasksFilter(tasks).length}
              totalCount={totalTasksCount}
              itemsString={"TasksString"}
            />

            {!isFiltering ? (
              <EmptyScreen
                subTitle={<Trans>nothingString</Trans>}
                filter={type}
                {...(emptyParams[type] || emptyParams.open)}
              />
            ) : (
              <EmptyScreen
                subTitle={<Trans>nothingString</Trans>}
                title={<Trans>noTasksFiltersString</Trans>}
                action={{
                  click: () => resetTaskListFilters(),
                  text: <Trans>resetFiltersString</Trans>,
                }}
              />
            )}
          </>
        </FullScreenPopup>
      )}

      <DisplayTaskFilterPills
        tasks={tasks}
        type={type}
        tasksByType={tasksByType}
      />

      <ViewingPartsOfTotal
        partCount={onlyTasksFilter(tasks).length}
        totalCount={totalTasksCount}
        itemsString={"TasksString"}
      />

      {!isFiltering ? (
        <EmptyScreen
          subTitle={<Trans>nothingString</Trans>}
          filter={type}
          {...(emptyParams[type] || emptyParams.open)}
        />
      ) : (
        <EmptyScreen
          subTitle={<Trans>nothingString</Trans>}
          title={<Trans>noTasksFiltersString</Trans>}
          action={{
            click: () => resetTaskListFilters(),
            text: <Trans>resetFiltersString</Trans>,
          }}
        />
      )}
    </FlexContainer>
  );
}

function hasTasks(tasks) {
  const [first, second] = tasks;
  return (first && !first?.isProductHeader) || second;
}

/**
 * @param {string[]} activeFilters
 * @param {string[]} addonOption
 */
const shouldSortByPickingLocation = (activeFilters, addonOption) =>
  activeFilters?.includes(taskFilters.PICK_AND_REFILL) &&
  addonOption?.includes("pickingFilter");

export default function Tasks({ type: overrideType, productIdFilter }) {
  const { active: activityType } = useParams();
  const type = overrideType || activityType || "";
  const { data: userPreferences } = useUserPreferences(
    preferences.TASK_FILTERS_ALL
  );

  const [userFiltersPreferences, setUserFiltersPreferences] = React.useState(
    {}
  );

  React.useEffect(() => {
    setUserFiltersPreferences({
      activeFilters: userPreferences?.[preferences.TASK_FILTERS] ?? [
        taskFilters.ALL,
      ],
      activeTaskStateFilter:
        userPreferences?.[preferences.TASK_STATE_FILTER] ??
        taskStateFilterTypes.ALL_STATES,
      activeTaskStatusFilters:
        userPreferences?.[preferences.TASK_STATUS_FILTERS] ??
        taskStatusFilterTypes.ALL_COMPLETED_TASKS,
      activeClosedReasonFilters:
        userPreferences?.[preferences.CLOSED_REASON_FILTERS] ??
        closedReasonFilterTypes.ALL_CLOSED_REASONS,
      activeLocationFilters:
        userPreferences?.[preferences.LOCATION_FILTERS] ?? [],
      activeTeamFilter: userPreferences?.[preferences.TEAM_FILTER] ?? "",
      locationGroup: userPreferences?.[preferences.LOCATION_GROUP] ?? [],
      addonOption: userPreferences?.[preferences.ADDON_OPTION] ?? [],
      activeGrouping:
        userPreferences?.[preferences.TASK_GROUP_BY_FILTERS] ??
        groupByTypes.NO_GROUPING,
      activeSortFilter:
        userPreferences?.[preferences.TASK_SORT_FILTERS] ??
        sortingTypes.BY_NEWEST_FIRST,
    });
  }, [userPreferences]);

  const isOpenOrMy = type === "open" || type === "my";
  const filters = useGetTaskFilters(type, productIdFilter);
  const [filteredTasks, scheduledTasks] = useTasksFromAnySource(
    type,
    filters,
    productIdFilter
  );

  const sortByPickingLocation = shouldSortByPickingLocation(
    userFiltersPreferences.activeFilters,
    userFiltersPreferences.addonOption
  );
  const sortedTasks = React.useMemo(
    () =>
      sortTasks(
        filteredTasks,
        userFiltersPreferences.activeSortFilter,
        type,
        sortByPickingLocation
      ),
    [
      filteredTasks,
      type,
      userFiltersPreferences.activeSortFilter,
      sortByPickingLocation,
    ]
  );
  const { isOnline } = useOnline();

  if (hasTasks(sortedTasks)) {
    return (
      <DisplayTasks
        isOpenOrMy={isOpenOrMy}
        type={type}
        tasks={sortedTasks}
        scheduledTasks={scheduledTasks}
        activeFilters={userFiltersPreferences.activeFilters}
        productIdFilter={productIdFilter}
        groupBy={userFiltersPreferences.activeGrouping}
        addonOption={userFiltersPreferences.addonOption}
        productId={productIdFilter}
      />
    );
  } else if (isOnline) {
    return (
      <DisplayEmpty isOpenOrMy={isOpenOrMy} type={type} tasks={sortedTasks} />
    );
  } else {
    return <DisplayOffline isOpenOrMy={isOpenOrMy} type={type} />;
  }
}

function useShowFiltersPopup(tasksByType) {
  const { getInput } = useInputPopup();
  const showFiltersPopup = React.useCallback(
    async () =>
      getInput("filters", {
        title: <Trans>refineString</Trans>,
        tasks: tasksByType,
      }),
    [getInput, tasksByType]
  );

  return showFiltersPopup;
}

/**
 * @typedef FilterFunction: false | (t:Task) => boolean
 * @param {FilterFunction[]} filters - Use the same functions as we already use for the old redux-firestore approach.
 * @param {Task[]} tasks
 * @returns {Task[]} filtered tasks
 */
export function refilter(filters, tasks) {
  return tasks.filter((task) => filters.every((fn) => !fn || fn(task)));
}

/**
 * @param {FilterFunction[]} filters - Use the same functions as we already use for the old redux-firestore approach.
 * @param {Task[]} tasks
 * @returns {Task[]} filtered tasks
 */
export function useGetTaskFilters(type, productIdFilter) {
  const uid = useUID();
  const fixa_uid = useFixaUID();
  const team_id = useTeamId();
  const { data: userPreferences } = useUserPreferences(
    preferences.TASK_FILTERS_ALL
  );

  const [userFiltersPreferences, setUserFiltersPreferences] = React.useState(
    {}
  );

  React.useEffect(() => {
    setUserFiltersPreferences({
      activeFilters: userPreferences?.[preferences.TASK_FILTERS] ?? [
        taskFilters.ALL,
      ],
      activeTaskStateFilter:
        userPreferences?.[preferences.TASK_STATE_FILTER] ??
        taskStateFilterTypes.ALL_STATES,
      activeTaskStatusFilters:
        userPreferences?.[preferences.TASK_STATUS_FILTERS] ??
        taskStatusFilterTypes.ALL_COMPLETED_TASKS,
      activeClosedReasonFilters:
        userPreferences?.[preferences.CLOSED_REASON_FILTERS] ??
        closedReasonFilterTypes.ALL_CLOSED_REASONS,
      activeLocationFilters:
        userPreferences?.[preferences.LOCATION_FILTERS] ?? [],
      activeTeamFilter: userPreferences?.[preferences.TEAM_FILTER] ?? "",
      locationGroup: userPreferences?.[preferences.LOCATION_GROUP] ?? [],
      addonOption: userPreferences?.[preferences.ADDON_OPTION] ?? [],
    });
  }, [userPreferences]);

  const isOpenOrMy = type === "open" || type === "my";

  const taskStatusFiltering =
    type?.endsWith("completed") &&
    isRefillOrPickAndRefill(userFiltersPreferences.activeFilters);
  const isRelated = !isOpenOrMy;
  const closedReasonFiltering = type?.endsWith("closed");

  const filterParamsString = JSON.stringify([
    userFiltersPreferences,
    userFiltersPreferences.activeFilters,
    userFiltersPreferences.activeTaskStatusFilters,
    userFiltersPreferences.activeTaskStateFilter,
    userFiltersPreferences.activeClosedReasonFilters,
    userFiltersPreferences.activeTeamFilter,
    userFiltersPreferences.addonOption,
    userFiltersPreferences.locationGroup,
    userFiltersPreferences.activeLocationFilters,
    productIdFilter,
    uid,
    team_id,
    type,
    fixa_uid,
  ]);

  return React.useMemo(
    () => {
      // The Activity tab there shold not be an filtering. The user has no visual clue that there is a filter in action. So, do not filter.
      if (
        window.location.href.includes("active=activity") ||
        window.location.href.includes("activity/created_by_me")
      ) {
        return [
          wrap(
            getFilterFunction(type),
            { uid, team_id, fixa_uid },
            productIdFilter
          ),
        ];
      }

      return [
        wrap(
          getFilterFunction(type),
          { uid, team_id, fixa_uid },
          productIdFilter
        ),
        wrap(userFilters, userFiltersPreferences.activeFilters),
        wrap(
          userLocationFilters,
          userFiltersPreferences.activeLocationFilters,
          userFiltersPreferences.locationGroup,
          userFiltersPreferences.addonOption,
          userFiltersPreferences.activeFilters
        ),
        wrap(
          taskStatusFiltering && taskStatusFilter,
          userFiltersPreferences.activeTaskStatusFilters
        ),
        wrap(
          !isOpenOrMy && teamFilter,
          userFiltersPreferences.activeTeamFilter
        ),
        wrap(
          isRelated && taskStateFilter,
          userFiltersPreferences.activeTaskStateFilter
        ),
        wrap(
          closedReasonFiltering && closedReasonFilter,
          userFiltersPreferences.activeClosedReasonFilters
        ),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParamsString]
  );
}

export function useTasksFromAnySource(type, filters, productIdFilter) {
  const isOpenOrMy = type === "open" || type === "my";
  const tasksForProduct = useTasksForProduct(
    "related_product_tasks" === type && productIdFilter
  );
  const relatedTasks = React.useMemo(() => {
    if (!tasksForProduct?.loading && tasksForProduct?.list) {
      return tasksForProduct?.list?.filter(notClosedTooLongAgo);
    }
  }, [tasksForProduct]); // Ensure we only include recently closed tasks.

  const cachedTasks = useCachedFilteredTasks(type);

  const cachedPlannedTasks = useCachedTasks(isOpenOrMy ? "planned" : "");

  const [tasks, totalTasksCount] = (() => {
    let currentTasks = emptyList;
    switch (type) {
      case false:
        return [emptyList, 0];

      case "related_product_tasks":
        currentTasks = [
          {
            priority_flag: true,
            created_at: Date.now(),
            isProductHeader: true,
            fullId: productIdFilter,
          },
          ...refilter(filters, relatedTasks),
        ];

        return [currentTasks, onlyTasksFilter(currentTasks).length];

      default:
        return [
          cachedTasks?.list || emptyList,
          cachedTasks?.typeFilteredList?.list
            ? onlyTasksFilter(cachedTasks?.typeFilteredList.list).length
            : cachedTasks?.typeFilteredList?.length ?? 0,
        ];
    }
  })();

  const loading = !isOpenOrMy && !!cachedTasks?.loading;
  const hasMore = !loading && tasks.length >= PAGE_SIZE;

  return [tasks, cachedPlannedTasks?.list, loading, hasMore, totalTasksCount];
}
