import React from "react";
import styled from "styled-components";
import {
  getI18nLocaleCode,
  languageName,
  baseLanguage,
  useTranslation,
} from "@coworker/locales";
import { ReactComponent as DownIcon } from "../assets/svg/arrow_down_small.svg";
import { ReactComponent as Check } from "../assets/svg/check.svg";
import { useInputPopup } from "./InputPopup";
import { resolveCountryId } from "./LanguageSetter";
import { useMyStore } from "../hooks/useMyStore";
import { useCountryId } from "../core/auth/useLoggedInUser";
import { useUserPreference } from "../hooks/useProfilePreferencesQuery";
import userPreferences from "@coworker/enums/profilePreferences";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";

const Selected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 90px;
  margin: 0 29px;
  border-bottom: 1px solid var(--grey500);
  cursor: pointer;
`;
const SelectedLanguage = styled.p`
  padding-bottom: 5px;
`;

const splitIKEAlocale = (locale) => locale.split("_");
const joinIKEAlocale = (iso639_1, countryCode) => `${iso639_1}_${countryCode}`;

const emptyObj = {};

/**
 * @param {Array} locales List of supported locales from MFS search.
 * @returns {Array<Object>} All available and enabled locales. If `locales` isn't listing English we add that option.
 */
function listAvailableTranslations(locales, countryCode, profileLanguage = "") {
  const list = [];
  let foundEnglish = false;

  // Collect list of enabled locales.
  for (let locale of locales) {
    const i18nLocale = getI18nLocaleCode(locale);
    const name = languageName(i18nLocale);
    if (i18nLocale) {
      list.push({ name, locale, i18nLocale });
      if (i18nLocale.startsWith("en")) foundEnglish = true;
    }
  }

  // Include currenly active language, ensuring country code is correct.
  if (!list.length && profileLanguage) {
    const [iso639_1] = splitIKEAlocale(profileLanguage);
    list.push({
      name: languageName(profileLanguage),
      locale: joinIKEAlocale(iso639_1, countryCode),
      i18nLocale: getI18nLocaleCode(profileLanguage),
    });
    if (iso639_1 === "en") foundEnglish = true;
  }

  // Always have English as an option.
  if (!foundEnglish) {
    const locale = joinIKEAlocale("en", countryCode);
    list.push({
      name: languageName(locale),
      locale,
      i18nLocale: getI18nLocaleCode(locale) || baseLanguage,
    });
  }
  list.sort((a, b) => a.name.localeCompare(b.name));
  return list;
}

function LanguageTitle({ name, locale, userLanguage, i }) {
  return (
    <>
      {name}
      {locale === userLanguage && <Check data-testid={`check${i}`} />}
    </>
  );
}

export default function Language() {
  const myStoreConfig = useMyStore()?.configuration || emptyObj;
  const userCountryId = useCountryId();
  const storeLocaleConfig = myStoreConfig?.locale || {};
  const storePrimaryLocale = storeLocaleConfig.primary_locale ?? "";
  const locales = storeLocaleConfig.supported_locales || [];
  const [userLanguage, setUserLanguage] = useUserPreference(
    userPreferences.LANGUAGE,
    storePrimaryLocale
  );
  const { changeLanguage } = useTranslation();
  const countryCode = resolveCountryId(userCountryId, myStoreConfig);
  const supportedLanguages = listAvailableTranslations(
    locales,
    countryCode,
    userLanguage
  );

  const { getInput, closePopup } = useInputPopup();
  const { t } = useTranslation();

  const actions = React.useMemo(
    () =>
      supportedLanguages.map(({ name, locale }, i) => ({
        onClick: () => {
          setUserLanguage(locale);
          changeLanguage(locale);
          closePopup();
        },
        title: (
          <LanguageTitle
            name={name}
            locale={locale}
            userLanguage={userLanguage}
            i={i}
          />
        ),
      })),
    [
      supportedLanguages,
      userLanguage,
      setUserLanguage,
      changeLanguage,
      closePopup,
    ]
  );

  const onSelectClick = React.useCallback(() => {
    if (supportedLanguages.length > 1) {
      getInput("popupMenu", { testId: "languageSelector", actions });
    }
  }, [actions, getInput, supportedLanguages.length]);

  return (
    <FullScreenPopup
      noPadding={true}
      linkbar={<Linkbar currentLabel={t("languageString")} currentAsH1 />}
    >
      <Selected
        data-testid="selectedLanguage"
        tabIndex="0"
        onClick={onSelectClick}
      >
        {!!userLanguage && (
          <SelectedLanguage data-testid="selectedLanguageValue">
            {languageName(
              getI18nLocaleCode(userLanguage) || userLanguage?.slice(0, 2)
            )}
          </SelectedLanguage>
        )}
        {supportedLanguages.length > 1 && <DownIcon />}
      </Selected>
    </FullScreenPopup>
  );
}
