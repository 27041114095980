import { TasksFilterType } from "../types/tasksFilterType";
import preferences from "@coworker/enums/profilePreferences";
import { TASKS_FILTERS_DEFAULT } from "../constants/tasksFilters";

export const mapPreferencesToTaskFilters = (userPreferences: any) => {
  return {
    taskType:
      userPreferences?.[preferences.TASK_FILTERS] ??
      TASKS_FILTERS_DEFAULT.taskType,
    sortBy:
      userPreferences?.[preferences.TASK_SORT_FILTERS] ??
      TASKS_FILTERS_DEFAULT.sortBy,
    locationFilter: {
      locationGroup: userPreferences?.[preferences.LOCATION_GROUP] ?? [],
      locationGroupName: userPreferences?.[preferences.LOCATION_FILTERS] ?? [],
    },
    groupBy:
      userPreferences?.[preferences.TASK_GROUP_BY_FILTERS] ??
      TASKS_FILTERS_DEFAULT.groupBy,
    addonLocationType:
      userPreferences?.[preferences.ADDON_OPTION] ??
      TASKS_FILTERS_DEFAULT.addonLocationType,
    related: {
      taskState:
        userPreferences?.[preferences.TASK_STATE_FILTER] ??
        TASKS_FILTERS_DEFAULT.related.taskState,
    },
    activityFilters: {
      completedTaskStatusType:
        userPreferences?.[preferences.TASK_STATUS_FILTERS] ??
        TASKS_FILTERS_DEFAULT.activityFilters.completedTaskStatusType,
      closedReason:
        userPreferences?.[preferences.CLOSED_REASON_FILTERS] ??
        TASKS_FILTERS_DEFAULT.activityFilters.closedReason,
      selectedTeam:
        userPreferences?.[preferences.TEAM_FILTER] ??
        TASKS_FILTERS_DEFAULT.activityFilters.selectedTeam,
    },
  } as TasksFilterType;
};
