import React from "react";
import { RoundCard } from "./RoundCard";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import { Trans, useTranslation } from "@coworker/locales";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { DIVISION_HASH, HEADER_COLOR_SCHEMA } from "../constants";
import { ActiveRoundObject, RoundCounts } from "../Types";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import { checkActiveRoundFilter } from "../helper";

export interface DivisionCardProps {
  round: string;
  title: string;
  roundCounts: RoundCounts | undefined;
  loading: boolean;
  activeRound: ActiveRoundObject;
}
const InlineMessageWrapper = styled.div`
  padding: 0px 16px 16px 16px;
  background-color: var(--grey200);
`;
const Container = styled.div`
  width: 100%;
`;

export const Round = ({
  round,
  title,
  roundCounts,
  loading,
  activeRound,
}: DivisionCardProps) => {
  const { push } = useWorkspacesAction();
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";
  const { t } = useTranslation();

  const isActiveRoundFilter = checkActiveRoundFilter(title);

  return (
    <Container>
      {isActiveRoundFilter && (
        <>
          <SubHeaderCard
            title={<Trans>{title}</Trans>}
            fontSize={16}
            colorSchema={HEADER_COLOR_SCHEMA.GREY}
          />
          {!activeRound.isActiveRound ? (
            <InlineMessageWrapper>
              <FixaInlineMessage
                title={t("surveyAvailabilityString")}
                body={
                  t("onlyAvailableBetweenString") +
                  " " +
                  `${activeRound.startTime}-${activeRound.endTime}`
                }
                variant={"cautionary"}
              />
            </InlineMessageWrapper>
          ) : null}

          {(activeArea === "showroomOW" || activeArea === "wholeStore") && (
            <RoundCard
              title={DIVISION_HASH.SHOWROOM?.transKey}
              currentValue={roundCounts?.showroom}
              onClick={() => push(`/shoppingtools/progress/showroom/${round}`)}
              loading={loading}
              activeRound={activeRound}
            />
          )}
          {(activeArea === "markethallOW" || activeArea === "wholeStore") && (
            <RoundCard
              title={DIVISION_HASH.MARKETHALL?.transKey}
              currentValue={roundCounts?.markethall}
              onClick={() =>
                push(`/shoppingtools/progress/markethall/${round}`)
              }
              loading={loading}
              activeRound={activeRound}
            />
          )}
          {(activeArea === "otherAreaCashline" ||
            activeArea === "wholeStore") && (
            <RoundCard
              title={DIVISION_HASH.CASHLINE?.transKey}
              currentValue={roundCounts?.cashline}
              onClick={() => push(`/shoppingtools/progress/cashline/${round}`)}
              loading={loading}
              activeRound={activeRound}
            />
          )}
        </>
      )}
    </Container>
  );
};
