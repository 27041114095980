import React from "react";
import { Team } from "../../../../../shared/types/team";
import { useActiveUsers } from "@coworker/app/src/hooks/useActiveUsers";
import { TeamsListViewItem } from "../TeamsListViewItem/TeamsListViewItem";
import styles from "./teams-list-view.module.css";
import { FixaListView } from "../../../../../shared/wrappers/FixaListView/FixaListView";

interface TeamsListViewProps {
  activeTeams: Team[];
  handleTeamSelection: (team: Team) => void;
  selectedTeamId: string;
}

export const TeamsListView: React.FC<TeamsListViewProps> = ({
  activeTeams,
  handleTeamSelection,
  selectedTeamId,
}) => {
  const activeStoreUsers = useActiveUsers();

  return (
    <FixaListView
      id={"teams-list-view"}
      className={styles["teams-list-view"] ?? ""}
    >
      {activeTeams.map((team: Team, index: number) => {
        const teamMembers = activeStoreUsers?.filter(
          (user: any) => user.team_id === team.id
        );

        return (
          <TeamsListViewItem
            team={team}
            index={index}
            teamMembers={teamMembers}
            handleTeamSelection={handleTeamSelection}
            selectedTeamId={selectedTeamId}
          ></TeamsListViewItem>
        );
      })}
    </FixaListView>
  );
};
