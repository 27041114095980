import React from "react";
import dayjs from "dayjs";
import { postToTasksService } from "./tasks.service.helper";

export function useCallInsightsFunction(type, body, options = {}) {
  const [insightsList, setInsightsList] = React.useState([]);
  const badParams = [body.start_date, body.end_date].some(
    (date) => !dayjs(date, true).isValid() // https://stackoverflow.com/a/68965345/296639
  );
  const block =
    !body?.store_id ||
    badParams ||
    !!body.isFetchingSavedFilters ||
    body.isFetchingSavedFilters === undefined;

  // Note: The below could be a deep-equality debounce, but we have no hook prepared -- and such a hook would look very close the the below code anyway. The hack to stringify and then parse inside is hacky, but lets us avoid ignoring linter warnings. And eliminates risk of again sending hundreds of API requests due to re-triggering the hook as body is a fresh object on every render.
  const stringBody = JSON.stringify(body);
  const data = React.useMemo(
    () => ({ ...JSON.parse(stringBody), insights_request_type: type }),
    [stringBody, type]
  );

  //Call tasks-service to fetch insights data
  React.useEffect(() => {
    const getInsightsData = async () => {
      await postToTasksService("/v1/insights/insights_v0", data).then(
        (response) => {
          setInsightsList({
            data: response?.result,
            dataIsFromAPI: true,
            loading: false,
            error: "",
          });
        }
      );
    };

    if (!block) {
      getInsightsData();
    }
  }, [data, block]);
  return insightsList;
}
