import { allPresent } from "@coworker/utils/array.helpers";

// Return article number formatted with dots, like xxx.yyy.zz
export const formatProductIdWithDots = (code: string) =>
  code
    ? `${code.substring(0, 3)}.${code.substring(3, 6)}.${code.substring(6, 8)}`
    : "";

const SHORT_ID_PARTS_RE = /(\d{2,3})/g;
export const formatFullIdWithDots = (code: string) => {
  if (!code) return "";
  return (code.match(SHORT_ID_PARTS_RE) || []).join(".");
};

export const addSoftSpace = (text: string) =>
  text.replace(/(.)\/(.)/g, "$1\u200b/\u200b$2");

export const formatWithCommas = (items: string[]) =>
  addSoftSpace(allPresent(items || []).join(", "));

// IKEA product names are expected to be UPPERCASE and numbers.
// Some MFS APIs (browse!) returns a different kind of title that can
// have additional lowercase content, and sometimes a " N " as a delimiter
// which also may indicate the UPPERCASE part of the name is abbreviated
// or no longer being sold or something like that.
export function cleanupProductTitle(string: string) {
  return (string || "").split(/ [a-z]/)[0];
}
