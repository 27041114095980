import React from "react";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";
import { StickyButtonContainer } from "../styles/styles";

interface StickyButtonMenuProps {
  onReset: () => void;
  onDone: () => void;
}

export const StickyButtonMenu = ({
  onReset,
  onDone,
}: StickyButtonMenuProps) => {
  const { t } = useTranslation();

  return (
    <StickyButtonContainer>
      <FixaButton
        text={t("resetString")}
        type={"secondary"}
        onClick={onReset}
        size="small"
        fluid
      />
      <FixaButton
        text={t("applyString")}
        type={"primary"}
        onClick={onDone}
        size="small"
        fluid
      />
    </StickyButtonContainer>
  );
};
