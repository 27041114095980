import React from "react";
import InlineMessage from "@ingka/inline-message";
import { ButtonProps } from "@ingka/button";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import "@ingka/focus/dist/style.css";

export declare type FixaInlineMessageVariants =
  | "informative"
  | "cautionary"
  | "negative";

interface Props {
  title?: string | React.ReactNode;
  subtle?: boolean;
  dismissable?: boolean;
  body?: string | React.ReactNode;
  ssrIcon?: (prefix?: string) => React.SVGProps<SVGElement>[];
  variant?: FixaInlineMessageVariants;
  actions?: ButtonProps[]; // Feels hacky?
  className?: string;
}

const FixaInlineMessage = (props: Props) => {
  return <InlineMessage {...props} />;
};

export default FixaInlineMessage;
