import React from "react";
import styles from "./task-card.module.css";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import { useTranslation } from "@coworker/locales";
import PeopleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PeopleIconPath";
import PersonIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PersonIconPath";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import taskStateOptions from "@coworker/enums/taskState";
import { useStoreUsers } from "@coworker/app/src/hooks/useStoreContacts";
import { UserProfile } from "@coworker/types";

interface TeamNameProps {
  state: string;
  assignee: string;
}

export const Assignee = ({ state, assignee }: TeamNameProps) => {
  const { t } = useTranslation();

  const isTaskOpen = state === taskStateOptions.UNASSIGNED;

  const teams = useStoreTeams();
  const users = useStoreUsers();

  const displayName = React.useMemo(() => {
    if (isTaskOpen) {
      const teamName =
        teams.find((team) => team.id === assignee)?.name ??
        t("unknownTeamString");
      return teamName;
    } else {
      const user = users.find(
        (user: UserProfile) =>
          user.fixa_uid === assignee || user.uid === assignee
      );
      const userName = user
        ? `${user.given_name} ${user.surname}`
        : t("unknownUserString");
      return userName;
    }
  }, [isTaskOpen, teams, users, assignee, t]);

  return (
    <div className={styles["assignee-wrapper"]}>
      <FixaSSRIcon
        className={styles["assignee-icon"] as string}
        paths={isTaskOpen ? PeopleIconPath : PersonIconPath}
      />
      <div className={styles["assignee"]}>{displayName}</div>
    </div>
  );
};
