import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { reportMessageToSentry } from "./useConfigureSentry";

const WorkspacesStateContext = React.createContext();
const WorkspacesActionContext = React.createContext();
WorkspacesStateContext.displayName = "WorkspacesStateContext";
WorkspacesActionContext.displayName = "WorkspacesActionContext";

export function useWorkspacesAction() {
  return React.useContext(WorkspacesActionContext);
}

export function useWorkspacesState() {
  const state = React.useContext(WorkspacesStateContext);
  if (!state) {
    //was a log here saying this should not happen, removed but keeping this check until for now
    return { navigationState: {} };
  }
  return state;
}

export function WorkspacesProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const replace = React.useCallback(
    (path, routeState = {}) => {
      navigate(path, {
        replace: true,
        state: routeState,
      });
    },
    [navigate]
  );

  const push = React.useCallback(
    (path, routeState = {}) => {
      navigate(path, {
        state: {
          /**
           * This is a hack so that we don't have to replace
           * all usages of 'pop' everywhere right now.
           */
          back: location.pathname + location.search,
          ...routeState,
        },
      });
    },
    [location.pathname, location.search, navigate]
  );

  /** @deprecated please push to the correct path instead of using this hack.
   */
  const pop = React.useCallback(() => {
    const { back } = location.state ?? {};
    let toPath = back || "/";
    const message =
      `pop is deprecated: '${window.location}'. ` +
      (back
        ? `'back' defined, going to '${toPath}'`
        : `No 'back' defined, going to '${toPath}'`);
    console.warn(message);
    reportMessageToSentry(message, {}).catch(() =>
      console.error("Failed to report to Sentry")
    );
    navigate(toPath);
  }, [location.state, navigate]);

  // Note that this merges the old state with the new and does not overwrite it.
  const setNavigationState = React.useCallback(
    (value) => {
      navigate(location.pathname + location.search, {
        replace: true,
        state: { ...(location.state || {}), ...value },
      });
    },
    [location.pathname, location.search, location.state, navigate]
  );

  return (
    <WorkspacesActionContext.Provider
      value={{
        push,
        pop,
        setNavigationState,
        replace,
      }}
    >
      <WorkspacesStateContext.Provider
        value={{
          navigationPath: location.pathname,
          navigationState: location.state || {},
        }}
      >
        {children}
      </WorkspacesStateContext.Provider>
    </WorkspacesActionContext.Provider>
  );
}

window.debug_workspaces = false;
