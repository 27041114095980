import React from "react";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import LocationPinIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/LocationPinIconPath";
import { useTranslation } from "@coworker/locales";
import { PlacementsText } from "./PlacementsText";
import { RoomsAndArticleCountResponse } from "../types/room";

interface RoomSettingsPlacementProps {
  roomsArticleCount: RoomsAndArticleCountResponse[];
  currentArticleId?: string | undefined;
}

export function RoomSettingsPlacement({
  currentArticleId,
  roomsArticleCount,
}: RoomSettingsPlacementProps) {
  const { t } = useTranslation();

  const addOnContent = (
    <PlacementsText
      currentArticleId={currentArticleId || ""}
      roomsArticleCount={roomsArticleCount}
    />
  );

  return (
    <FixaListViewItem
      title={t("roomSettingsPlacementString")}
      leadingIcon={LocationPinIconPath}
      addOn={addOnContent}
      emphasised
      inset
    />
  );
}
