import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getUserPermissions } from "./API/core.service";
import { useFixaUID } from "../core/auth/useLoggedInUser";
import { INTERVAL } from "../constants/clientTime";
const { FLAGS } = require("@coworker/enums/featureFlags");

async function fetchUserPermissions(fixaUid: string) {
  // TODO added temporarily to test, These should be added to database.
  const result = await getUserPermissions(fixaUid);
  return result;
}

export function useUserPermissionsQuery() {
  const fixaUid = useFixaUID();
  const result = useQuery({
    queryKey: ["userPermissions", fixaUid],
    queryFn: async () => await fetchUserPermissions(fixaUid),
    staleTime: 5 * INTERVAL.MINUTE, // Need to find right interval to get fresh flags, when we add new flags.
  });

  return result;
}

export function useFlagsState() {
  const { data } = useUserPermissionsQuery();
  const setOfFlags: Set<string> = new Set(data);
  return { setOfFlags };
}

export function useHasFlag() {
  const { setOfFlags } = useFlagsState();
  return React.useCallback((flag) => setOfFlags.has(flag), [setOfFlags]);
}

// NOTE: When we need to know that a flag is final, see useFinalFlag.
function useFlag(flag: string) {
  const { setOfFlags } = useFlagsState();
  return setOfFlags.has(flag);
}

export default useFlag;
export { FLAGS };
