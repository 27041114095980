import React from "react";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { sfmHomeLocation } from "./foodMarket.service";
import { parseProduct } from "./products.service";
import { callInternalApi } from "../hooks/API/useCallInternal";
import { makePartialMatcher } from "@coworker/utils/location.helper";
import { INTERVAL } from "../constants/clientTime";
import useFlag, { FLAGS } from "../hooks/useFlag";
import { useQuery } from "@tanstack/react-query";

/**
 * @param {String} code Location code
 * @param {String} name Location name
 * @returns {String} Joined string with <Location code> - <location name>
 */
export const joinLocation = (code, name) => `${code} - ${name || ""}`;

/**
 * @param {string|undefined} location Location code and name formatted with " - " in between.
 * @returns {[string, string]} The location code and the location name
 */
export function splitLocation(location) {
  const matches = (location || "").match(/(.+?) - (.+)/);
  if (!matches) return [location || "", ""];
  return matches.slice(1, 3).map((a) => (a || "").trim());
}

export function useRecentLocationsByProduct(shortId) {
  const storeId = useStoreId();
  const [response, setResponse] = React.useState({ data: {} });
  React.useEffect(() => {
    if (shortId) {
      callInternalApi(`locations/custom/article?short_id=${shortId}`, {
        store_id: storeId,
      }).then(setResponse);
    }
  }, [shortId, storeId]);

  if (shortId === "") {
    return { data: { pickups: [], refills: [] } };
  }
  return response;
}

export function useRecentLocationsByQuery(customLocationQuery) {
  const storeId = useStoreId();
  const [response, setResponse] = React.useState({ data: {} });
  React.useEffect(() => {
    if (!customLocationQuery === "") return setResponse({ data: {} });
    callInternalApi(`locations/custom`, {
      customLocationQuery,
      store_id: storeId,
    })
      .then(setResponse)
      .catch((e) => console.error(e));
  }, [customLocationQuery, storeId]);

  if (customLocationQuery === "") {
    return { data: { pickups: [], refills: [] } };
  }

  return response;
}

const queryFilter = (query, locations) => {
  const partialMatch = makePartialMatcher(query);
  return locations.filter(partialMatch);
};

async function fetchAllSalesLocations(fullId, storeId) {
  const path = `locations/defined?full_id=${fullId}`;
  const result = await callInternalApi(path, {
    store_id: storeId,
  });
  return result?.data?.list || {};
}

export function useAllSalesLocationsForArticleQuery(fullId) {
  const storeId = useStoreId();
  return useQuery({
    enabled: !!fullId && !!storeId,
    queryKey: fullId && storeId ? ["allSalesLocations", fullId, storeId] : null,
    queryFn: async () => {
      if (!fullId || !storeId) {
        throw new Error("Invalid fullId or storeId");
      }
      return await fetchAllSalesLocations(fullId, storeId);
    },
    staleTime: 1 * INTERVAL.HOUR,
    initialData: !fullId || !storeId ? [] : undefined,
  });
}

export function useRecentCustomLocations(query, short_id, onlyPickups) {
  const productLocations = useRecentLocationsByProduct(short_id);
  const queryLocations = useRecentLocationsByQuery((query || "").slice(0, 1));

  return React.useMemo(() => {
    const key = onlyPickups ? "pickups" : "refills";
    const fromQuery = queryLocations?.data?.[key] || [];
    const fromShortId = productLocations?.[key] || [];
    const set = new Set([...fromQuery, ...fromShortId]);

    return queryFilter(query, [...set]);
  }, [productLocations, queryLocations, query, onlyPickups]);
}

/**
 * Returns sales AND SGF locations for a single product, including subproducts when `type` == SPR.
 * Uses MFS, and sometimes not all locations are returned.
 * @param {String} code
 * @param {String} type
 */
export function useMFSLocations(short_id, type) {
  const storeId = useStoreId();
  const full_id = `${type}${short_id}`;
  const [groupedLocationsFromService, setGroupedLocationsFromService] =
    React.useState([]);
  React.useEffect(() => {
    if (full_id && storeId) {
      callInternalApi(`grouped_locations?full_id=${full_id}`, {
        store_id: storeId,
      }).then((response) => {
        setGroupedLocationsFromService({ data: [response], loading: false });
      });
    }
  }, [full_id, storeId]);

  return groupedLocationsFromService;
}

export function useLocationCapacity(gridcode, full_id) {
  const [short_id, type] = parseProduct(full_id);
  const { list: locationsData } = useAllSalesLocationsForArticleQuery(
    !!type && !!short_id ? `${type}${short_id}` : ""
  );
  const found = locationsData?.find((l) => gridcode === l.location);
  return found?.quantity || "";
}

const foodHFBs = ["60", "61"];
const isSFM = (panumber) => foodHFBs.includes((panumber || "").slice(0, 2));

function makePickupFields(storeLocation, locationDescription, custom) {
  return {
    pickup_location: custom
      ? ""
      : joinLocation(storeLocation, locationDescription),
    pickup_location_grid_code: storeLocation,
    pickup_location_department: locationDescription,
    pickup_location_custom: custom || "",
  };
}

export function useTaskFormDefaultPickupFields(task) {
  const short_id = task?.product_article_id;
  const type = task?.product_article_type;
  const panumber = task?.panumber;

  const { data: locationsData, isLoading } =
    useAllSalesLocationsForArticleQuery(
      !!short_id && !!type ? `${type}${short_id}` : ""
    );
  const pickupFieldsExperiment = useFlag(FLAGS.BETTER_PICKUP);

  return React.useMemo(() => {
    if (!short_id) return null;

    if (!isLoading && locationsData) {
      if (isSFM(panumber)) {
        return makePickupFields("", "", sfmHomeLocation);
      }

      let homeLoc = null;
      let primaryLoc = null;
      if (locationsData) {
        for (const loc of locationsData) {
          if (loc?.isHome || loc?.isHomeBase) homeLoc = loc;
          if (loc?.isPrimary) primaryLoc = loc;
        }
      }
      const found = (pickupFieldsExperiment && primaryLoc) || homeLoc;
      if (!found) return null;
      return makePickupFields(
        found.storeLocation || found.location,
        found.locationDescription || found.descriptive
      );
    }
  }, [locationsData, panumber, short_id, pickupFieldsExperiment, isLoading]);
}

export function useNonSLMFoodLocation(isFoodArticle, hasNoSLMLocations) {
  const sfmLocationList = [];
  if (isFoodArticle && hasNoSLMLocations) {
    const frontendFriendlyObject = {
      location: "SFM",
      locationDescription: "Swedish Food Market",
      department: "",
      isPrimary: true,
      isHomeBase: true,
    };
    sfmLocationList.push(frontendFriendlyObject);
  }
  return { sfmLocationList };
}

export async function getAllLocationsFromSLM(bu) {
  if (bu) {
    const response = await callInternalApi(`locations/store?storeId=${bu}`, {
      store_id: bu,
    });
    return response?.data;
  }
}
