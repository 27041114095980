import React from "react";
import {
  CenterContainer,
  LeftContainer,
  FlexibleRowContainer,
} from "../../styles/styles";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";

export const LargeSkeletonListItem = () => {
  return (
    <FlexibleRowContainer style={{ padding: "20px 24px" }}>
      <LeftContainer>
        <FixaSkeleton height={"73px"} width={"73px"} />
      </LeftContainer>
      <CenterContainer $centerText={false} style={{ gap: "5px" }}>
        <FixaSkeleton height={"14px"} width={"164px"} />
        <FixaSkeleton height={"14px"} width={"164px"} />
        <FixaSkeleton height={"14px"} width={"71px"} />
      </CenterContainer>
    </FlexibleRowContainer>
  );
};
