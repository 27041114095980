import React from "react";
import { WideButton } from "@coworker/components";
import { Trans } from "@coworker/locales";
import styled from "styled-components";
import useFormatter from "../../../hooks/useFormatter";
import Instructions from "../../Instructions";
import { useToggle } from "../../../hooks/useToggle";
// import profilePreferences from "@coworker/enums/profilePreferences";

const Content = styled.div`
  background-color: var(--white);
  margin: 10px;
  padding: 10px 0;
`;

const Total = styled.h2`
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.0063em;
`;

const TopLegendText = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--grey718);
`;

interface LostPassedSalesProps {
  currency: string;
  passedSales: number;
  lostSales: number;
  isSingleTestbuy?: boolean;
}

export function LostPassedSalesWidget({
  currency,
  passedSales,
  lostSales,
  isSingleTestbuy,
}: LostPassedSalesProps) {
  const { formatCurrency } = useFormatter();
  const [showLostSales, toggleShowLost] = useToggle(true);

  const transKeys = React.useMemo(() => {
    if (isSingleTestbuy) {
      return {
        title: showLostSales
          ? "sumFailedThisTestBuyString"
          : "sumPassedThisTestBuyString",
        button: showLostSales ? "viewPassedSalesString" : "viewLostSalesString",
      };
    } else {
      return {
        title: showLostSales
          ? "totalSumPassedTestBuysString"
          : "totalSumFailedTestBuysString",
        button: showLostSales ? "viewSumPassedTestbuys" : "viewLostSalesString",
      };
    }
  }, [isSingleTestbuy, showLostSales]);

  return (
    <Content>
      <TopLegendText>
        <Trans>{transKeys.title}</Trans>
      </TopLegendText>
      <Total data-testid="lostSalesValue">
        {formatCurrency(showLostSales ? lostSales : passedSales, currency)}
      </Total>
      {!showLostSales && (
        <Instructions
          title={<Trans>howIsThisCalculatedString</Trans>}
          text={<Trans>testBuyLostSalesExplanationString</Trans>}
          // type={profilePreferences.DISABLE_POPUPS_TESTBUY_LOST_SALES_CALC}
          type={"disablePopups.lostSalesCalc"}
        />
      )}
      <WideButton
        data-testid="toggleShowLostButton"
        secondary
        gray
        showLoader={false}
        onClick={toggleShowLost}
        text={<Trans>{transKeys.button}</Trans>}
      />
    </Content>
  );
}
