import React from "react";
import Switch from "@ingka/switch";

import "@ingka/focus/dist/style.css";
import "@ingka/switch/dist/style.css";

interface Props {
  id: string;
  checked?: boolean;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  subtle?: boolean;
  "data-testid"?: string;
}

export const FixaSwitch = (props: Props) => {
  return <Switch {...props} />;
};
