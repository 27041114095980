import React from "react";
import ListBox, { MenuItem } from "@ingka/list-box";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/list-box/dist/style.css";

interface ListBoxProps {
  open: boolean;
  value: string[];
  children: React.ReactNode | React.ReactNode[];
}
interface MenuItemProps {
  key: string;
  onClick: () => void;
  id: string;
  title: string | React.ReactNode;
}

export const FixaListBox = (props: ListBoxProps) => {
  return <ListBox {...props} />;
};

export const FixaMenuItem = (props: MenuItemProps) => {
  return <MenuItem {...props} />;
};
