import React, { useContext } from "react";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import styles from "./teams-modal.module.css";
import { useTranslation } from "@coworker/locales";
import { TeamsListView } from "./TeamsListView/TeamsListView";
import { Team } from "../../../../shared/types/team";
import { ModalContext } from "../../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../../layout/context/modalType";

interface TeamsModalProps {
  handleTeamSelection: (team: Team) => void;
  activeTeams: Team[];
  selectedTeamId: string;
}

export const TeamsModal: React.FC<TeamsModalProps> = ({
  handleTeamSelection,
  activeTeams,
  selectedTeamId,
}) => {
  const { t } = useTranslation();
  const { modalState, toggleModal } = useContext(ModalContext);
  return (
    <FixaModal
      escapable={true}
      visible={modalState.isTeamSelectionOpen}
      handleCloseBtn={() => {
        toggleModal({ type: ModalType.TeamSelection });
      }}
    >
      <FixaSheets
        fullSize={false}
        labelledById={t("joinTeamString")}
        size="small"
        className={styles["teams-sheets"] as string}
        header={
          <FixaModalHeader
            className={styles["teams-header-title"] as string}
            title={t("joinTeamString")}
          ></FixaModalHeader>
        }
        footer={null}
      >
        <TeamsListView
          handleTeamSelection={handleTeamSelection}
          activeTeams={activeTeams}
          selectedTeamId={selectedTeamId}
        ></TeamsListView>
      </FixaSheets>
    </FixaModal>
  );
};
