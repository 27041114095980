import React from "react";
import styles from "./multi-select.module.css";
import FixaCheckbox from "../../../../../shared/wrappers/FixaCheckbox/FixaCheckbox";
import { Trans, useTranslation } from "@coworker/locales";
import { useMultiSelectForTab } from "@coworker/app/src/hooks/useMultiSelect";
import { Task } from "@coworker/types/lib/tasks/base";

interface SelectedTasksProps {
  type: "my" | "open";
  tasks: Task[];
}

export const SelectedTasks = ({ type, tasks }: SelectedTasksProps) => {
  const { toggleAll, selectedTaskIds = [] } = useMultiSelectForTab(type) as {
    toggleAll?: (taskIds: string[]) => void;
    selectedTaskIds?: string[];
    disabled: boolean;
  };

  const { t } = useTranslation();

  const allTaskIds = React.useMemo(
    () => tasks.map((task) => task.id ?? ""),
    [tasks]
  );

  const allTasksAreSelected = React.useMemo(() => {
    if (allTaskIds.length !== selectedTaskIds.length) return false;

    const sortedAllTaskIds = [...allTaskIds].sort();
    const sortedSelectedTaskIds = [...selectedTaskIds].sort();

    return sortedAllTaskIds.every(
      (id, index) => id === sortedSelectedTaskIds[index]
    );
  }, [allTaskIds, selectedTaskIds]);

  const handleToggleAll = () => {
    if (toggleAll) toggleAll(allTaskIds);
  };

  return (
    <div className={styles["select-all"]}>
      <FixaCheckbox
        id="select-all"
        value="select-all"
        label={<Trans>selectAllString</Trans>}
        indeterminate={
          selectedTaskIds.length > 0 &&
          selectedTaskIds.length < allTaskIds.length
        }
        checked={allTasksAreSelected}
        onChange={handleToggleAll}
      />
      <div className={styles["selected-counter"]}>
        {t("selectedCountString", {
          count: `${selectedTaskIds.length}/${allTaskIds.length}`,
        })}
      </div>
    </div>
  );
};
