import React from "react";
import { useToastNotification } from "@coworker/components";
import useUndoneNotification from "../../components/useUndoneNotification";
import { updateTask } from "../../services/task.service";
import { useInputPopup } from "../../components/InputPopup";
import { Trans } from "@coworker/locales";
import { ReactComponent as Time } from "../../assets/svg/time.svg";
import { dropTypeKeys, dropTypeOptions } from "@coworker/enums/dropTypes";
import { ReactComponent as Issue } from "../../assets/svg/issue.svg";
import { ReactComponent as IssueOther } from "@coworker/reusable/svg/three-dots.svg";
import taskStateOptions from "@coworker/enums/taskState";
import activityTypes from "@coworker/enums/activityTypes";
import trackerHelper from "../../helpers/tracker";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useTaskRefresher } from "../useWatchedTasks";

export const useUndoDropTask = ({ task, afterUndo }) => {
  const undoCurtain = useUndoneNotification();
  const uid = useUserId();
  const refreshTask = useTaskRefresher();

  const undoTask = React.useCallback(
    async (actionId) => {
      const data = {
        last_editor_id: uid,
        state: task.state,
        assigned_user_id: task.assigned_user_id,
        drop_type: "",
        drop_description: "",
        undo_action_id: actionId || "",
      };

      await undoCurtain(data, task.id, task.task_type);
      refreshTask(task.id, data, task);
      afterUndo();
    },
    [refreshTask, afterUndo, task, uid, undoCurtain]
  );

  return {
    call: undoTask,
  };
};

export const useDropTask = (task, taskId, config = {}) => {
  const { undo, afterUndo = () => {} } = config;
  const uid = useUserId();
  const storeId = useStoreId();
  const { showToastWithUndo } = useToastNotification();
  const { getInput } = useInputPopup();
  const { call: undoTask } = useUndoDropTask({
    task: { ...task, id: taskId },
    afterUndo,
  });
  const refreshTask = useTaskRefresher();

  const dropTask = React.useCallback(async () => {
    const actionId = `release-${new Date().getTime()}`;

    const data = await getInput("taskAction", {
      customTitle: <Trans>whyAreYouDroppingString</Trans>,
      content: [
        {
          icon: Time,
          name: <Trans>{dropTypeKeys.END_OF_SHIFT}</Trans>,
          type: dropTypeOptions.END_OF_SHIFT,
        },
        {
          icon: Issue,
          name: <Trans>{dropTypeKeys.ISSUE}</Trans>,
          type: dropTypeOptions.ISSUE,
        },
        {
          icon: IssueOther,
          name: <Trans>{dropTypeKeys.OTHER}</Trans>,
          type: dropTypeOptions.OTHER,
          descriptionRequired: true,
        },
      ],
    });

    if (!data) return;

    const updateData = {
      last_editor_id: uid,
      state: taskStateOptions.UNASSIGNED,
      assigned_user_id: "",
      drop_type: data.issue,
      drop_description: data.description,
      action_id: actionId,
      // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
      undo_action_id: "",
      activity_type:
        task.assigned_user_id === uid
          ? activityTypes.DROP
          : activityTypes.RELEASE,
    };

    await updateTask(updateData, task.id, task.task_type, storeId);
    refreshTask(task.id, updateData, task);

    trackerHelper.trackDropTask(task, updateData.drop_type);

    if (!undo) return;

    showToastWithUndo(
      task.assigned_user_id === uid ? (
        <Trans>youDroppedString</Trans>
      ) : (
        <Trans>youReleasedString</Trans>
      ),
      () => undoTask(actionId)
    );

    return true;
  }, [
    refreshTask,
    undoTask,
    task,
    undo,
    uid,
    getInput,
    showToastWithUndo,
    storeId,
  ]);

  return {
    call: dropTask,
  };
};
