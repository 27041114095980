import React from "react";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";
import { RoomListItemCardDesktop } from "../styles/styles";

export function RoomListItemSkeletonDesktop() {
  return (
    <RoomListItemCardDesktop active={true}>
      <FixaSkeleton height={"100%"} width={"100%"} />
    </RoomListItemCardDesktop>
  );
}
