import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import Filters from "./Filters";
import HomeAndAwayBox from "./HomeAndAwayBox";
import { AddonsWithPagination } from "./AddonWithPagination";
import { useInsightsFetchParams } from "./helpers";
import { SCROLL_DOWN } from "../../hooks/useScrollDirection";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import tracker from "../../helpers/tracker";
import { useCallInsightsFunction } from "../../hooks/API/useCallFirebaseFunction";
import { INSIGHTS_HOME_PAGE } from "@coworker/enums/insightsRequestTypes";
import { useTranslation } from "@coworker/locales";
import { isRDTDevice } from "@coworker/reusable";
import { getInsightsProductPath } from "../../helpers/insightsHelper";
import { useLanguage } from "../../core/hooks/useLanguage";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useGenerateReport } from "@coworker/apprestructured/src/home/hooks/useGenerateReport";
import { LoaderIcon } from "@coworker/reusable/Loader";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ArrowDownToBaseIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ArrowDownToBaseIconPath";
import InsightsDeprecationBanner from "./InsightsDeprecationBanner";

const Toggle = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;
const Selection = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? "2px solid black" : "1px solid var(--grey100)"};
`;

const HeadingFollowerContainer = styled.div`
  position: sticky;
  top: 57px;
  width: 100%;
  z-index: var(--z-heading-follower);
`;

const Department = ({ scrollDirection, product_id, location_id }) => {
  const store_id = useStoreId();

  const [dataFetchParams, onChangeInsightsParams, isFetchingSavedFilters] =
    useInsightsFetchParams({
      store_id,
      location_id,
      product_id,
    });

  const hfb_request_type = dataFetchParams?.deptFilters?.salesShare;
  const department = dataFetchParams?.deptFilters?.hfb;
  const isAll = department === "ALL";
  const hfb = isAll ? null : department;
  const { setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const language = useLanguage();
  const { t } = useTranslation();
  const subtitle = ["OT", "CL", "OW", "ALL"].includes(department)
    ? t(`hfb${department}String`)
    : `${t(`hfb${department}String`)} • HFB ${department}`;
  const pose = scrollDirection === SCROLL_DOWN ? "sticky" : "notSticky";

  const activeHFB = useCallInsightsFunction(INSIGHTS_HOME_PAGE, {
    ...dataFetchParams,
    isFetchingSavedFilters,
  });
  const { isReportLoading, fetchReport } = useGenerateReport();
  const isRDT = isRDTDevice();

  const chosenDeptTab = navigationState.insights_department_tab;
  const isLocation = chosenDeptTab === "location";
  React.useEffect(() => {
    if (chosenDeptTab) tracker.insights.trackDepartmentTab(chosenDeptTab);
  }, [chosenDeptTab]);

  return (
    <FullScreenPopup
      noPadding
      appBarConfig={{
        title: t("addonInsightsString"),
        subtitle: subtitle,
        actions: [
          !isReportLoading &&
            !isRDT && {
              name: "exportExcel",
              icon: <FixaSSRIcon paths={ArrowDownToBaseIconPath} />,
              position: "right",
              onClick: () => {
                fetchReport({
                  dataFetchParams: dataFetchParams,
                  extra: {
                    insightsProductURL: getInsightsProductPath(),
                    locale: language,
                  },
                  type: "insights",
                });
              },
            },
          isReportLoading && {
            name: "exportExcelLoading",
            icon: <LoaderIcon />,
            position: "right",
            onClick: () => {},
          },
        ],
      }}
    >
      <InsightsDeprecationBanner padding="16px" />
      <HeadingFollowerContainer pose={pose} data-testid="filter-container">
        <Filters
          dataFetchParams={dataFetchParams}
          scrollDirection={scrollDirection}
          onChangeInsightsParams={onChangeInsightsParams}
          activeHFB={activeHFB}
          level={2}
        />
      </HeadingFollowerContainer>
      <HomeAndAwayBox
        status={hfb_request_type}
        location={isLocation}
        hfb={hfb}
        level="2"
      />
      <Toggle active={chosenDeptTab || "product"} data-testid="tabs">
        <Selection
          data-testid="product_tab"
          data-active={!isLocation}
          active={!isLocation}
          onClick={() =>
            setNavigationState({ insights_department_tab: "product" })
          }
        >
          <Trans>articleString</Trans>
        </Selection>
        <Selection
          data-testid="location_tab"
          active={isLocation}
          data-active={isLocation}
          onClick={() =>
            setNavigationState({ insights_department_tab: "location" })
          }
        >
          <Trans>location3String</Trans>
        </Selection>
      </Toggle>

      {!isLocation && (
        <AddonsWithPagination
          path="product"
          dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
          {...dataFetchParams}
          activeHFB={activeHFB}
        />
      )}
      {isLocation && (
        <AddonsWithPagination
          path="location"
          dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
          {...dataFetchParams}
          activeHFB={activeHFB}
        />
      )}
    </FullScreenPopup>
  );
};

export default Department;
