import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";

import {
  PQDescribeTheProblemOptions,
  PQDescribeTheProblemTag,
} from "@coworker/types/lib/tasks/constants";
import { DescribeTheProblemProps } from "../../types/describeTheProblem";
import {
  DescribeTheProblemList,
  OptionListItem,
  TagTranslationKey,
} from "../../constants/DescribeTheProblemList";
import { getTypeListEntry } from "../../helpers/describeTheProblem";

import { PleaseFillIn } from "../Reusable/PleaseFillIn";
// Skapa components
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 70px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 70px;
  overflow-y: auto;
`;
const ScrollContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
`;
const StyledListView = styled(FixaListView)`
  display: flex;
  flex-direction: column;
`;
const StyledListViewItem = styled(FixaListViewItem)`
  display: flex;
  span {
    text-decoration: none !important;
  }
`;
const MetaText = styled.div`
  font-size: 14px;
  color: var(--grey718);
  margin: 0px 24px;
`;
const TypeChangeArea = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 32px;
  margin: 24px 0px 0px 24px;
`;
const TypeText = styled.div`
  font-size: 14px;
  font-color: var(--grey718);
  text-align: left;
  font-weight: bold;
`;
const TextAreaContainer = styled.div`
  margin: 0px 24px;
`;
const StyledTextArea = styled(FixaTextArea)`
  margin: 10px 0;
`;
const PleaseFillInArea = styled.div`
  margin: 0px 24px 12px 24px;
`;
const FormWarningArea = styled.div`
  margin-top: 16px;
`;
const PillStack = styled.div`
  margin: 16px;
`;

export const DescribeTheProblem = ({
  value,
  testParams,
  onSubmit,
}: DescribeTheProblemProps) => {
  const { t } = useTranslation();

  // States
  const [selectedType, setSelectedType] =
    React.useState<PQDescribeTheProblemOptions>(
      value.pq_describe_the_problem_type
    );

  const [buttonPressed, setButtonPressed] = React.useState<boolean>(
    testParams?.buttonPressed || false
  );
  const [description, setDescription] = React.useState<string>(
    value.description
  );
  const [tags, setTags] = React.useState<PQDescribeTheProblemTag[]>(
    value.pq_describe_the_problem_tags ?? new Array<PQDescribeTheProblemTag>()
  );

  // Methods
  function toggleTags(tag: PQDescribeTheProblemTag) {
    const filteredTags = tags.filter(
      (tagItem: PQDescribeTheProblemTag) => tagItem !== tag
    );
    if (filteredTags.length === tags.length) {
      filteredTags.push(tag);
    }
    setTags(filteredTags);
  }

  function pillIsSelected(tag: PQDescribeTheProblemTag): boolean {
    const matchingTag = tags.find(
      (tagItem: PQDescribeTheProblemTag) => tagItem === tag
    );
    return !!matchingTag;
  }

  function choiceClicked(item: OptionListItem) {
    if (item.type !== selectedType) {
      setTags(new Array<PQDescribeTheProblemTag>());
    }
    setSelectedType(item.type);
  }

  function handleSubmit() {
    setButtonPressed(true);
    if (description && selectedType) {
      value.pq_describe_the_problem_type = selectedType;
      value.description = description;
      value.pq_describe_the_problem_tags = tags;
      onSubmit(value);
    }
  }

  const typeTranslationKey =
    selectedType && getTypeListEntry(selectedType)?.translationKey;

  // Components
  return (
    <Container>
      <ScrollContainer>
        <StyledListView id="listviewid" size="small">
          {DescribeTheProblemList.map((item: OptionListItem) => (
            <StyledListViewItem
              id={item.type}
              key={item.type}
              inset
              onChange={() => choiceClicked(item)}
              control="radiobutton"
              controlProps={{
                value: selectedType === item.type ? "ON" : "OFF",
                checked: selectedType === item.type ? true : false,
              }}
              title={t(item.translationKey)}
              data-testid={item.type}
            />
          ))}
          <TypeChangeArea>
            <TypeText>
              <Trans>{typeTranslationKey}</Trans>
            </TypeText>
          </TypeChangeArea>
          <TextAreaContainer>
            <StyledTextArea
              label={t("description4String")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              data-testid={"descriptionTextArea"}
            />
          </TextAreaContainer>
          <PleaseFillInArea>
            <PleaseFillIn
              show={!description && buttonPressed}
              alternateWarning={`${t("pleaseFillInString")} ${t(
                "description4String"
              )}`}
              testId="fillInDescriptionWarning"
            />
          </PleaseFillInArea>
          <MetaText>
            <Trans>optionalColonString</Trans>
          </MetaText>
          <PillStack>
            {selectedType &&
              DescribeTheProblemList.find(
                (item: OptionListItem) => item.type === selectedType
              )?.tags.map((tag: TagTranslationKey) => (
                <FixaPill
                  style={{ margin: "4px" }}
                  key={tag.tag}
                  label={t(tag.translationKey)}
                  onClick={() => toggleTags(tag.tag)}
                  selected={pillIsSelected(tag.tag)}
                  size="small"
                  data-testid={tag.tag}
                />
              ))}
          </PillStack>
        </StyledListView>
      </ScrollContainer>
      <FormWarningArea>
        <PleaseFillIn
          show={!selectedType && buttonPressed}
          alternateWarning="pleaseMakeASelectionString"
          centerText={true}
          testId="pleaseMakeASelectionWarning"
        />
      </FormWarningArea>
      <FixaButton
        style={{ margin: "0px 24px 8px 24px" }}
        text={t("continue2String")}
        type="primary"
        onClick={() => handleSubmit()}
        data-testid={"handleSubmitButton"}
      />
    </Container>
  );
};
