import React, { useMemo } from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { RoundCard } from "../Rounds/RoundCard";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useParams } from "react-router-dom";
import { DIVISION_HASH, HEADER_COLOR_SCHEMA } from "../constants";
import { ShowroomQuantitySteppers } from "./ShowroomQuantitySteppers";
import { useFetchDivisionsCountByRound } from "../Hooks/useFetchDivisionsCountByRound";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowroomOW = () => {
  const [currentTotalValue, setCurrentTotalValue] = React.useState<number>(0);

  const { id } = useParams();
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();

  const { data: startValue, isFetching } = useFetchDivisionsCountByRound(
    DIVISION_HASH.SHOWROOM?.division ?? "",
    id ?? ""
  );

  React.useEffect(() => {
    setCurrentTotalValue(startValue);
  }, [startValue]);

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("shoppingToolsHeaderString"),
        to: "/shoppingtools",
      },
      {
        label: t("shoppingToolsProgress"),
        to: "/shoppingtools/progress",
      },
    ];
  }, [t]);

  return (
    <FullScreenPopup
      noPadding
      linkbar={
        <Linkbar
          items={linkbarItems}
          currentLabel={`${t("showroomOWString")} - ${t(`${id}String`)}`}
          currentAsH1
        />
      }
    >
      <SubHeaderCard
        title={<Trans>customerTookToolString</Trans>}
        fontSize={16}
        colorSchema={HEADER_COLOR_SCHEMA.GREY}
      />
      <ShowroomQuantitySteppers
        round={id ?? ""}
        currentTotalValue={currentTotalValue}
        setCurrentTotalValue={setCurrentTotalValue}
      />
      <RoundCard
        title={DIVISION_HASH.SHOWROOM?.transKey ?? ""}
        currentValue={currentTotalValue}
        showNav={false}
        background="secondary"
        whiteBarBackground
        loading={isFetching && !startValue}
      />
      <ButtonWrapper>
        <FixaButton
          aria-label={t("goToMarkethallAriaString")}
          type="primary"
          disabled={currentTotalValue < 50}
          style={{ margin: "15px 0px", width: "80%" }}
          text={<Trans>goToMarkethallString</Trans>}
          onClick={() => push(`/shoppingtools/progress/markethall/${id}`)}
        />
      </ButtonWrapper>
    </FullScreenPopup>
  );
};
