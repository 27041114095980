import React, { useContext } from "react";
import { Trans } from "@coworker/locales";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { ProfileSectionItem } from "../../../types/profileSectionType";
import styles from "./profile-section.module.css";
import { ModalContext } from "../../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../../layout/context/modalType";
import { FixaListView } from "../../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../shared/wrappers/FixaListView/FixaListViewItem";

interface ProfileSectionListViewProps {
  id: string;
  sectionsList: ProfileSectionItem[];
}

export const ProfileSectionListView: React.FC<ProfileSectionListViewProps> = ({
  id,
  sectionsList,
}) => {
  const { push } = useWorkspacesAction();
  const { toggleModal } = useContext(ModalContext);

  return (
    <FixaListView id={id} className={styles["hidden-overflow-x"] as string}>
      {sectionsList.map((section: ProfileSectionItem) => {
        return (
          <FixaListViewItem
            className={styles["profile-list-item"] || ""}
            key={section.id}
            id={section.id}
            control={section.isNavigational ? "navigational" : "default"}
            onClick={() => {
              toggleModal({ type: ModalType.Profile });
              push(section.navigationUrl);
            }}
            inset
            controlIcon={section.isNavigational ? "chevronRight" : null}
            title={<Trans>{section.title}</Trans>}
            description={section.description ?? ""}
            size="small"
            emphasised={section.isTitleBold}
          />
        );
      })}
    </FixaListView>
  );
};
