import amountTypeOptions from "@coworker/enums/amountTypes";
import taskStateOptions from "@coworker/enums/taskState";
import { splitLocation } from "../services/locations.service";
import { isFinishedState } from "../constants/taskState";
import { isoDate } from "./dates";

const { CLOSED, COMPLETED } = taskStateOptions;

export function getAmounts(task) {
  if (!task) return {};
  return {
    requested: [
      task.actual_requested_quantity,
      task.requested_quantity,
      task.requested_type,
    ],
    picked: task.picked && [
      task.actual_pick_quantity || task.actual_requested_quantity,
      task.pick_quantity || task.requested_quantity, // PLAN: Rebuild frontend to remove || task.requested_quantity
      task.pick_type || task.requested_type,
    ],
    refilled:
      (task.state === CLOSED && [0, 0, "pieces"]) ||
      (task.state === COMPLETED && [
        task.actual_refilled_quantity ||
          task.actual_pick_quantity ||
          task.actual_requested_quantity,
        task.refilled_quantity || task.pick_quantity || task.requested_quantity,
        task.refilled_type || task.pick_type || task.requested_type,
      ]),
  };
}

export function getLocation(task) {
  return task.location || task.location_custom;
}

export function getLocationGridCode(task) {
  return (
    task.location_custom ||
    task.location_grid_code ||
    splitLocation(task.location)[0]
  );
}

export function getPickupLocation(task) {
  return task.pickup_location_custom || task.pickup_location;
}

export function getPickupGridCode(task) {
  return task.pickup_location_custom || splitLocation(task.pickup_location)[0];
}

export function getPickedAmountWithProductUnit(task, productUnit) {
  if ("pick_quantity" in task && task.pick_quantity !== "") {
    return getPickedAmountWithUnit(task, productUnit);
  } else {
    return getRequestedAmountWithUnit(task, productUnit);
  }
}

export function getRefillAmountWithProductUnit(task, productUnit) {
  if ("refilled_quantity" in task && task.refilled_quantity !== "") {
    return getRefillAmountWithUnit(task, productUnit);
  } else if ("pick_quantity" in task && task.pick_quantity !== "") {
    return getPickedAmountWithUnit(task, productUnit);
  } else {
    return getRequestedAmountWithUnit(task, productUnit);
  }
}

export function getAmountByProductUnit(totalAmount, type, task) {
  if (totalAmount) {
    if (type === amountTypeOptions.MULTI_PACK && task.pieces_per_multi_pack)
      return totalAmount / task.pieces_per_multi_pack;
    else if (type === amountTypeOptions.PALLET && task.pieces_per_pallet)
      return totalAmount / task.pieces_per_pallet;
  }
  return totalAmount;
}

function getRefillAmountWithUnit(task, productUnit) {
  const refillAmount =
    task.actual_refilled_quantity ??
    getAmountByProductUnit(task.refilled_quantity, task.refilled_type, task);

  const refillUnit = productUnit(task.refilled_type, refillAmount);
  if (!refillUnit && refillAmount === 0) return `0 ${productUnit("pieces", 0)}`;
  return `${refillAmount} ${refillUnit}`;
}

export function getPickedAmountWithUnit(task, productUnit) {
  const pickAmount =
    task.actual_pick_quantity ??
    getAmountByProductUnit(
      task.pick_quantity,
      task.pick_type ? task.pick_type : task.requested_type,
      task
    );

  const pickUnit = productUnit(
    task.pick_type ? task.pick_type : task.requested_type,
    pickAmount
  );
  return `${pickAmount} ${pickUnit}`;
}

export function getRequestedAmountWithUnit(task, productUnit) {
  const requestedAmount =
    task.actual_requested_quantity ??
    getAmountByProductUnit(task.requested_quantity, task.requested_type, task);

  const requestedUnit = productUnit(task.requested_type, requestedAmount);
  return `${requestedAmount} ${requestedUnit}`;
}

export function getPiecesInformation(type, piecesQuantity, t) {
  if (
    type === amountTypeOptions.MULTI_PACK ||
    type === amountTypeOptions.PALLET
  )
    return `(${piecesQuantity} ${t("piecesUnit")})`;
  else return ``;
}

export function hasChangedQuantity(task) {
  const finalQuantity =
    task.refilled_quantity || task.pick_quantity || task.requested_quantity;
  return task.requested_quantity !== finalQuantity;
}

export const isOverdue = (task) => {
  if (!task.due_date || isFinishedState(task.state)) return false;
  const dueDate = isoDate(task.due_date);
  const now = isoDate(new Date());
  return now > dueDate;
};
