import { TestBuyProductWithCriteria } from "@coworker/types";
import { articleFactory } from "../ObjectFactories/ArticleFactory";
import React from "react";
import { useStoreId } from "../../../../core/auth/useLoggedInUser";
import { postToTestbuyService } from "../../../../hooks/API/testbuy.service";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  fetchArticleById,
  fetchPreviousTestbuyNumberOfArticles,
  fetchReplaceArticle,
  fetchTestbuyArticles,
} from "./testbuy.article.api";
import profilePreferences from "@coworker/enums/profilePreferences";
import { useUserPreference } from "../../../../hooks/useProfilePreferencesQuery";
import { useMyStore } from "../../../../hooks/useMyStore";

export function useGetArticleById(
  testbuyKey: string,
  testbuyArticleKey: string
): {
  data: TestBuyProductWithCriteria;
  loading: boolean;
} {
  const { data: articleData, isLoading: loading } = useQuery({
    queryKey: ["use-get-article-by-id", testbuyArticleKey],
    queryFn: async () => {
      return await fetchArticleById(testbuyKey, testbuyArticleKey);
    },
  });

  return { data: articleData ?? articleFactory(), loading };
}

export function useArticleById(testbuyKey: string, testbuyArticleKey: string) {
  const { data: articleData } = useQuery({
    queryKey: ["get-article-by-id", testbuyArticleKey],
    queryFn: async () => {
      return await fetchArticleById(testbuyKey, testbuyArticleKey);
    },
  });

  return articleData as TestBuyProductWithCriteria;
}

export function useTestBuyArticles(testbuyKey: string | undefined): {
  articles: TestBuyProductWithCriteria[];
  loading: boolean;
} {
  const { data: articles, isLoading: loading } = useQuery({
    queryKey: ["get-testbuy-articles", testbuyKey],
    queryFn: async () => {
      return await fetchTestbuyArticles(testbuyKey);
    },
  });

  return { articles: articles ?? [], loading: loading ?? false };
}

export async function useAddFollowUpTaskId(
  testbuyKey: string,
  articleKey: string,
  followUpTaskId: string
) {
  postToTestbuyService(
    "article/addFollowUpTaskId",
    {
      testbuyId: testbuyKey,
      articleId: articleKey,
      followUpTaskId,
    },
    () => {}
  );
}

export const useReplaceArticle = (
  testbuyId: string,
  activeArticleId: string
): [
  TestBuyProductWithCriteria | undefined,
  boolean,
  boolean,
  React.Dispatch<React.SetStateAction<string | undefined>>
] => {
  const storeId: string = useStoreId();
  const [newArticleId, setNewArticleId] = React.useState<string>();
  const store = useMyStore();
  const storeConfiguration = store?.configuration || {};
  const [userLanguage] = useUserPreference(
    profilePreferences.LANGUAGE,
    storeConfiguration?.locale?.primary_locale
  );
  const queryClient = useQueryClient();

  const replaceArticleMutation = useMutation(
    (newArticleId: string) =>
      fetchReplaceArticle(
        testbuyId,
        activeArticleId,
        newArticleId,
        userLanguage,
        storeId
      ),
    {
      onSuccess: (newArticleData: TestBuyProductWithCriteria) => {
        if (newArticleData) {
          queryClient.setQueryData(["article", newArticleId], newArticleData);
        }
      },
    }
  );

  const { mutate: replaceArticle } = replaceArticleMutation;

  React.useEffect(() => {
    if (newArticleId && newArticleId !== activeArticleId) {
      replaceArticle(newArticleId);
    }
  }, [activeArticleId, newArticleId, storeId, testbuyId, replaceArticle]);

  const newArticle = queryClient.getQueryData<TestBuyProductWithCriteria>([
    "article",
    newArticleId,
  ]);

  return [
    newArticle,
    replaceArticleMutation.isLoading,
    replaceArticleMutation.isError,
    setNewArticleId,
  ];
};
export function useNumberOfArticles(
  storeId: string,
  area: string
): {
  numberOfArticlesForArea: number;
  loading: boolean;
} {
  const { data: noOfArticles, isLoading: loading } = useQuery({
    queryKey: ["number-of-articles", storeId, area],
    queryFn: async () => {
      if (area === "whole=whole") {
        return 5;
      }
      return await fetchPreviousTestbuyNumberOfArticles(storeId, area);
    },
  });

  return { numberOfArticlesForArea: noOfArticles?.number_of_articles, loading };
}
