import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

const DoNotGrow = styled.div`
  padding: 17px 24px;
  text-align: center;
  position: flex;
  top: 56px;
  background-color: white;
  z-index: 1;
`;

export function FullWidthButton(props) {
  return <FixaButton type="primary" {...props} />;
}

export function NarrowButton(props) {
  return (
    <DoNotGrow>
      <FixaButton type="primary" {...props} />
    </DoNotGrow>
  );
}
