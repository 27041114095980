import React from "react";
import styles from "./task-card.module.css";
import { Trans } from "@coworker/locales";
import { Task } from "@coworker/types/lib/tasks/base";
import taskStateOptions from "@coworker/enums/taskState";
import { TaskType } from "../../enums/taskTypes";

interface PriorityProps {
  task: Task;
  state: string;
}

export const Priority = ({ task, state }: PriorityProps) => {
  const isTaskCompletedOrClosed =
    state === taskStateOptions.COMPLETED || state === taskStateOptions.CLOSED;

  return (
    <>
      {task.priority_flag && (
        <div className={styles["priority-wrapper"]}>
          <div
            className={`${styles["priority-dot"]} ${
              isTaskCompletedOrClosed && styles["completed-or-closed"]
            }`}
          />
          <div
            className={`${styles["priority"]} ${
              isTaskCompletedOrClosed && styles["completed-or-closed"]
            }`}
          >
            {task.task_type === TaskType.PRODUCT_QUALITY ? (
              <Trans>safetyAlarmString</Trans>
            ) : (
              <Trans>priorityString</Trans>
            )}
          </div>
        </div>
      )}
    </>
  );
};
