import React from "react";
import styled from "styled-components";
import { revealFromBelow } from "../helpers/animationHelper";
import { StoredImage } from "./StoredImage";
import { ReactComponent as CloseIcon } from "../assets/svg/close-large.svg";
import { Trans } from "@coworker/locales";
import { isRDTDevice } from "@coworker/reusable";
import FixaCarousel from "@coworker/apprestructured/src/shared/wrappers/FixaCarousel/FixaCarousel";

const Background = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: var(--z-imageslider);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ButtonHeader = styled.div`
  position: absolute;
  flex-direction: row;
  padding: 43px 19px 10px 19px;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  z-index: var(--z-imageslider);
`;

const Button = styled.button`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: white;
  border: none;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  cursor: pointer;
  svg {
    vertical-align: middle;
    color: var(--grey900);
  }
`;

const ImagePreviewContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const StyledCarousel = styled(FixaCarousel)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const Slider = styled.div`
  padding: 16px 24px;
`;

const SliderTitle = styled.div`
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  color: white;
`;

const SlideBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const SlideItem = styled.div`
  width: 100%;
  cursor: pointer;
  div {
    height: 2px;
    background-color: white;
    margin: 10px 0px;
    width: 100%;
    ${(props) => !props.active && "opacity: 0.2;"}
  }
`;

const ImageHolder = styled.div`
  overflow: hidden;
  display: inline-flex;
  vertical-align: top;
  width: 100%;
  height: 100%;
`;

const NavigationButtons = styled.div`
  height: calc(100% - 95px);
  width: 100%;
  padding-top: 95px;
  position: absolute;
  display: flex;
  flex-direction: row;
`;

const NavigationButton = styled.div`
  height: 100%;
  width: 100%;
`;

const AdditionalInformation = styled.p`
  margin: 20px;
`;

function ImageSlider(props, ref) {
  const isRDT = isRDTDevice();
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const next = React.useCallback(async () => {
    setCurrentIndex((currentIndex + 1) % props.images.length);
  }, [currentIndex, props.images]);

  const prev = React.useCallback(async () => {
    setCurrentIndex(
      (currentIndex - 1 + props.images.length) % props.images.length
    );
  }, [currentIndex, props.images]);

  React.useEffect(() => {
    function onKeyUp(event) {
      switch (event.key) {
        case "ArrowRight":
          next();
          break;
        case "ArrowLeft":
          prev();
          break;
        case "Escape":
          props.onClose();
          break;
        default:
          break;
      }
    }

    document.addEventListener("keyup", onKeyUp);
    return () => document.removeEventListener("keyup", onKeyUp);
  }, [props.images, currentIndex, props, next, prev]);

  const scrollableElements =
    props.scrollables && props.scrollables.map((s) => <img src={s} alt="" />);

  const carouselProps = {
    id: "Carousel",
    hasNoControls: false,
    isSlideShow: true,
  };

  return props.scrollables && props.scrollables.length > 0 ? (
    <Background ref={ref}>
      <ButtonHeader>
        <Button onClick={props.onClose}>
          <CloseIcon />
        </Button>
      </ButtonHeader>
      <StyledCarousel {...carouselProps}>
        {scrollableElements.map((item, i) => {
          return React.cloneElement(item, { key: i });
        })}
      </StyledCarousel>
      {!isRDT && (
        <AdditionalInformation>
          <Trans>downloadImageInstructionString</Trans>
        </AdditionalInformation>
      )}
    </Background>
  ) : (
    props.images && (
      <Background ref={ref}>
        <ButtonHeader>
          <Button onClick={props.onClose}>
            <CloseIcon />
          </Button>
        </ButtonHeader>
        <ImagePreviewContainer>
          <NavigationButtons>
            <NavigationButton onClick={prev} />
            <NavigationButton onClick={next} />
          </NavigationButtons>
          <ImageHolder>
            <StoredImage fullscreen image={props.images[currentIndex]} />
          </ImageHolder>
        </ImagePreviewContainer>
        {props.images.length > 1 && (
          <Slider>
            <SliderTitle>
              {currentIndex + 1} of {props.images.length} images
              <SlideBar>
                {props.images.map((image, index) => {
                  return (
                    <SlideItem
                      key={image.id}
                      active={index === currentIndex}
                      onClick={() => setCurrentIndex(index)}
                    >
                      <div></div>
                    </SlideItem>
                  );
                })}
              </SlideBar>
            </SliderTitle>
          </Slider>
        )}
      </Background>
    )
  );
}

export default revealFromBelow(React.forwardRef(ImageSlider));
