import React, { useContext } from "react";
import FixaInlineMessage, {
  FixaInlineMessageVariants,
} from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { ModalContext } from "../../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../../layout/context/modalType";
import { useTranslation } from "@coworker/locales";

interface TeamSelectionMessageProps {
  title: string;
  message: string;
  icon: () => any;
  variant: FixaInlineMessageVariants;
  showAction: boolean;
  actionText?: string;
  actionLink?: string;
}

export const TeamSelectionMessage: React.FC<TeamSelectionMessageProps> = ({
  title,
  message,
  icon,
  actionText,
  variant,
  showAction,
}) => {
  const { t } = useTranslation();
  const { toggleModal } = useContext(ModalContext);
  const handleSuperUsersClick = () => {
    toggleModal({ type: ModalType.SuperUsersList });
  };
  return (
    <FixaInlineMessage
      title={title}
      body={message}
      ssrIcon={icon}
      actions={
        showAction
          ? [
              {
                text: (
                  <FixaButton
                    aria-label={t("seeListOfSuperUsersString")}
                    type="secondary"
                    className="inline-message-button"
                    text={actionText || ""}
                  />
                ),
                onClick: () => handleSuperUsersClick(),
              },
            ]
          : []
      }
      variant={variant}
      className="inline-message-skapa"
    />
  );
};
