const allPermissions = {
  COUNTRY_SUPERUSER: {
    name: "Country Super user",
    description: "User that has access to all stores within a country",
    ADMIN_ALL_STORES: true,
    ADMIN_LOGIN: true,
    CLOSE_TEAMS_TASK: true,
    CONTACTS_READ_ALL: false,
    CONTACTS_READ_MY_COUNTRY: true,
    CONTACTS_READ_MY_STORE: true,
    CONTACTS_WRITE_ALL: false,
    CONTACTS_WRITE_MY_COUNTRY: true,
    CONTACTS_WRITE_MY_STORE: true,
    CREATE_ADD_ON_TASK: true,
    CREATE_CUSTOM_TASK: true,
    CREATE_PRODUCT_ISSUE_TASK: true,
    DELETED_USERS_READ: true,
    DELETED_USERS_REMOVE: true,
    DELETED_USERS_WRITE: true,
    FEEDBACK_CREATE_MY_FEEDBACK: true,
    FEEDBACK_READ_ALL: false,
    FEEDBACK_READ_MY_FEEDBACK: false,
    FEEDBACK_UPDATE_MY_FEEDBACK: false,
    FEEDBACK_WRITE_ALL: false,
    RELEASE_TEAMS_TASK: true,
    REOPEN_TEAMS_TASK: true,
    REPEATABLE_TASKS_CREATE_ALL: false,
    REPEATABLE_TASKS_CREATE_MY_TEAMS_TASKS: false,
    REPEATABLE_TASKS_READ_ALL: false,
    REPEATABLE_TASKS_READ_MY_STORE_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TEAMS_TASKS: true,
    REPEATABLE_TASKS_UPDATE_ALL: false,
    REPEATABLE_TASKS_UPDATE_MY_TASKS: true,
    REPEATABLE_TASKS_UPDATE_MY_TEAMS_TASKS: true,
    STORES_READ_ALL: false,
    STORES_READ_MY_COUNTRY: true,
    STORES_READ_MY_STORE: true,
    STORES_WRITE_ALL: false,
    STORES_WRITE_MY_COUNTRY: true,
    STORES_WRITE_MY_STORE: true,
    TASKS_CREATE_ALL: true,
    TASKS_CREATE_MY_TEAMS_TASKS: true,
    TASKS_READ_ALL: false,
    TASKS_READ_MY_STORE_TASKS: true,
    TASKS_READ_MY_TASKS: true,
    TASKS_READ_MY_TEAMS_TASKS: true,
    TASKS_UPDATE_ALL: false,
    TASKS_UPDATE_MY_STORE_TASKS: true,
    TASKS_UPDATE_MY_TASKS: true,
    TASKS_UPDATE_MY_TEAMS_TASKS: true,
    TEAMS_READ_ALL: false,
    TEAMS_READ_MY_COUNTRY: true,
    TEAMS_READ_MY_STORE: true,
    TEAMS_WRITE_ALL: false,
    TEAMS_WRITE_MY_COUNTRY: true,
    TEAMS_WRITE_MY_STORE: true,
    UPDATE_TEAMS_TASK: true,
    USERS_READ_ALL: true,
    USERS_READ_MY_COUNTRY: true,
    USERS_READ_MY_STORE: true,
    USERS_WRITE_ALL: false,
    USERS_WRITE_CAN_SET_COUNTRY_SUPERUSER: true,
    USERS_WRITE_CAN_SET_COWORKER: true,
    USERS_WRITE_CAN_SET_STOREOWNER: true,
    USERS_WRITE_CAN_SET_SUPERUSER: false,
    USERS_WRITE_MY_COUNTRY: true,
    USERS_WRITE_MY_STORE: true,
  },
  COWORKER: {
    name: "Co-worker",
    description: "Department team member",
    ADMIN_ALL_STORES: false,
    ADMIN_LOGIN: false,
    CLOSE_TEAMS_TASK: true,
    CONTACTS_READ_ALL: false,
    CONTACTS_READ_MY_COUNTRY: true,
    CONTACTS_READ_MY_STORE: true,
    CONTACTS_WRITE_ALL: false,
    CONTACTS_WRITE_MY_COUNTRY: false,
    CONTACTS_WRITE_MY_STORE: false,
    CREATE_ADD_ON_TASK: true,
    CREATE_CUSTOM_TASK: true,
    CREATE_PRODUCT_ISSUE_TASK: true,
    DELETED_USERS_READ: true,
    DELETED_USERS_REMOVE: true,
    DELETED_USERS_WRITE: true,
    FEEDBACK_CREATE_MY_FEEDBACK: true,
    FEEDBACK_READ_ALL: false,
    FEEDBACK_READ_MY_FEEDBACK: false,
    FEEDBACK_UPDATE_MY_FEEDBACK: false,
    FEEDBACK_WRITE_ALL: false,
    RELEASE_TEAMS_TASK: true,
    REOPEN_TEAMS_TASK: true,
    REPEATABLE_TASKS_CREATE_ALL: false,
    REPEATABLE_TASKS_CREATE_MY_TEAMS_TASKS: false,
    REPEATABLE_TASKS_READ_ALL: false,
    REPEATABLE_TASKS_READ_MY_STORE_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TEAMS_TASKS: true,
    REPEATABLE_TASKS_UPDATE_ALL: false,
    REPEATABLE_TASKS_UPDATE_MY_TASKS: true,
    REPEATABLE_TASKS_UPDATE_MY_TEAMS_TASKS: true,
    STORES_READ_ALL: false,
    STORES_READ_MY_COUNTRY: false,
    STORES_READ_MY_STORE: true,
    STORES_WRITE_ALL: false,
    STORES_WRITE_MY_COUNTRY: false,
    STORES_WRITE_MY_STORE: false,
    TASKS_CREATE_ALL: true,
    TASKS_CREATE_MY_TEAMS_TASKS: true,
    TASKS_READ_ALL: false,
    TASKS_READ_MY_STORE_TASKS: true,
    TASKS_READ_MY_TASKS: true,
    TASKS_READ_MY_TEAMS_TASKS: true,
    TASKS_UPDATE_ALL: false,
    TASKS_UPDATE_MY_STORE_TASKS: true,
    TASKS_UPDATE_MY_TASKS: true,
    TASKS_UPDATE_MY_TEAMS_TASKS: true,
    TEAMS_READ_ALL: false,
    TEAMS_READ_MY_COUNTRY: false,
    TEAMS_READ_MY_STORE: true,
    TEAMS_WRITE_ALL: false,
    TEAMS_WRITE_MY_COUNTRY: false,
    TEAMS_WRITE_MY_STORE: false,
    UPDATE_TEAMS_TASK: true,
    USERS_READ_ALL: false,
    USERS_READ_MY_COUNTRY: true,
    USERS_READ_MY_STORE: true,
    USERS_WRITE_ALL: false,
    USERS_WRITE_CAN_SET_COUNTRY_SUPERUSER: false,
    USERS_WRITE_CAN_SET_COWORKER: false,
    USERS_WRITE_CAN_SET_STOREOWNER: false,
    USERS_WRITE_CAN_SET_SUPERUSER: false,
    USERS_WRITE_MY_COUNTRY: false,
    USERS_WRITE_MY_STORE: false,
  },
  GUEST: {
    description: "Guest user",
    name: "Guest",
  },
  MANAGER: {
    ADMIN_ALL_STORES: false,
    ADMIN_LOGIN: false,
    CLOSE_TEAMS_TASK: true,
    CONTACTS_READ_ALL: false,
    CONTACTS_READ_MY_STORE: true,
    CONTACTS_WRITE_ALL: false,
    CONTACTS_WRITE_MY_STORE: false,
    CREATE_ADD_ON_TASK: true,
    CREATE_CUSTOM_TASK: true,
    CREATE_PRODUCT_ISSUE_TASK: true,
    DELETED_USERS_REMOVE: true,
    FEEDBACK_CREATE_MY_FEEDBACK: true,
    FEEDBACK_READ_ALL: false,
    FEEDBACK_READ_MY_FEEDBACK: false,
    FEEDBACK_UPDATE_MY_FEEDBACK: false,
    FEEDBACK_WRITE_ALL: false,
    RELEASE_TEAMS_TASK: true,
    REOPEN_TEAMS_TASK: true,
    REPEATABLE_TASKS_CREATE_ALL: false,
    REPEATABLE_TASKS_CREATE_MY_TEAMS_TASKS: false,
    REPEATABLE_TASKS_READ_ALL: false,
    REPEATABLE_TASKS_READ_MY_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TEAMS_TASKS: true,
    REPEATABLE_TASKS_UPDATE_ALL: false,
    REPEATABLE_TASKS_UPDATE_MY_TASKS: true,
    REPEATABLE_TASKS_UPDATE_MY_TEAMS_TASKS: true,
    STORES_READ_ALL: false,
    STORES_READ_MY_STORE: true,
    STORES_WRITE_ALL: false,
    STORES_WRITE_MY_STORE: false,
    TASKS_CREATE_ALL: true,
    TASKS_CREATE_MY_TEAMS_TASKS: true,
    TASKS_READ_ALL: false,
    TASKS_READ_MY_TASKS: true,
    TASKS_READ_MY_TEAMS_TASKS: true,
    TASKS_UPDATE_ALL: false,
    TASKS_UPDATE_MY_TASKS: true,
    TASKS_UPDATE_MY_TEAMS_TASKS: true,
    TEAMS_READ_ALL: false,
    TEAMS_READ_MY_STORE: true,
    TEAMS_WRITE_ALL: false,
    TEAMS_WRITE_MY_STORE: false,
    UPDATE_TEAMS_TASK: true,
    USERS_READ_ALL: false,
    USERS_READ_MY_STORE: true,
    USERS_WRITE_ALL: false,
    USERS_WRITE_CAN_SET_ALL_ROLES: false,
    USERS_WRITE_CAN_SET_MINOR_ROLES: false,
    USERS_WRITE_MY_STORE: false,
  },
  STOREOWNER: {
    name: "Store Super user",
    description: "Owner of one particular store",
    ADMIN_ALL_STORES: true,
    ADMIN_LOGIN: true,
    CLOSE_TEAMS_TASK: true,
    CONTACTS_READ_ALL: false,
    CONTACTS_READ_MY_COUNTRY: false,
    CONTACTS_READ_MY_STORE: true,
    CONTACTS_WRITE_ALL: false,
    CONTACTS_WRITE_MY_COUNTRY: false,
    CONTACTS_WRITE_MY_STORE: true,
    CREATE_ADD_ON_TASK: true,
    CREATE_CUSTOM_TASK: true,
    CREATE_PRODUCT_ISSUE_TASK: true,
    DELETED_USERS_READ: true,
    DELETED_USERS_REMOVE: true,
    DELETED_USERS_WRITE: true,
    FEEDBACK_CREATE_MY_FEEDBACK: true,
    FEEDBACK_READ_ALL: false,
    FEEDBACK_READ_MY_FEEDBACK: false,
    FEEDBACK_UPDATE_MY_FEEDBACK: false,
    FEEDBACK_WRITE_ALL: false,
    RELEASE_TEAMS_TASK: true,
    REOPEN_TEAMS_TASK: true,
    REPEATABLE_TASKS_CREATE_ALL: false,
    REPEATABLE_TASKS_CREATE_MY_TEAMS_TASKS: false,
    REPEATABLE_TASKS_READ_ALL: false,
    REPEATABLE_TASKS_READ_MY_STORE_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TEAMS_TASKS: true,
    REPEATABLE_TASKS_UPDATE_ALL: false,
    REPEATABLE_TASKS_UPDATE_MY_TASKS: true,
    REPEATABLE_TASKS_UPDATE_MY_TEAMS_TASKS: true,
    STORES_READ_ALL: true,
    STORES_READ_MY_COUNTRY: false,
    STORES_READ_MY_STORE: true,
    STORES_WRITE_ALL: false,
    STORES_WRITE_MY_COUNTRY: false,
    STORES_WRITE_MY_STORE: true,
    TASKS_CREATE_ALL: true,
    TASKS_CREATE_MY_TEAMS_TASKS: true,
    TASKS_READ_ALL: false,
    TASKS_READ_MY_STORE_TASKS: true,
    TASKS_READ_MY_TASKS: true,
    TASKS_READ_MY_TEAMS_TASKS: true,
    TASKS_UPDATE_ALL: false,
    TASKS_UPDATE_MY_STORE_TASKS: true,
    TASKS_UPDATE_MY_TASKS: true,
    TASKS_UPDATE_MY_TEAMS_TASKS: true,
    TEAMS_READ_ALL: false,
    TEAMS_READ_MY_COUNTRY: false,
    TEAMS_READ_MY_STORE: true,
    TEAMS_WRITE_ALL: false,
    TEAMS_WRITE_MY_COUNTRY: false,
    TEAMS_WRITE_MY_STORE: true,
    UPDATE_TEAMS_TASK: true,
    USERS_READ_ALL: true,
    USERS_READ_MY_COUNTRY: false,
    USERS_READ_MY_STORE: true,
    USERS_WRITE_ALL: false,
    USERS_WRITE_CAN_SET_COUNTRY_SUPERUSER: false,
    USERS_WRITE_CAN_SET_COWORKER: true,
    USERS_WRITE_CAN_SET_STOREOWNER: true,
    USERS_WRITE_CAN_SET_SUPERUSER: false,
    USERS_WRITE_MY_COUNTRY: false,
    USERS_WRITE_MY_STORE: true,
  },
  SUPERUSER: {
    name: "Global Super user",
    description: "User that have access to all stores",
    ADMIN_ALL_STORES: true,
    ADMIN_LOGIN: true,
    CLOSE_TEAMS_TASK: true,
    CONTACTS_READ_ALL: true,
    CONTACTS_READ_MY_COUNTRY: true,
    CONTACTS_READ_MY_STORE: true,
    CONTACTS_WRITE_ALL: true,
    CONTACTS_WRITE_MY_COUNTRY: true,
    CONTACTS_WRITE_MY_STORE: true,
    CREATE_ADD_ON_TASK: true,
    CREATE_CUSTOM_TASK: true,
    CREATE_PRODUCT_ISSUE_TASK: true,
    DELETED_USERS_READ: true,
    DELETED_USERS_REMOVE: true,
    DELETED_USERS_WRITE: true,
    FEEDBACK_CREATE_MY_FEEDBACK: true,
    FEEDBACK_READ_ALL: false,
    FEEDBACK_READ_MY_FEEDBACK: false,
    FEEDBACK_UPDATE_MY_FEEDBACK: false,
    FEEDBACK_WRITE_ALL: false,
    RELEASE_TEAMS_TASK: true,
    REOPEN_TEAMS_TASK: true,
    REPEATABLE_TASKS_CREATE_ALL: false,
    REPEATABLE_TASKS_CREATE_MY_TEAMS_TASKS: false,
    REPEATABLE_TASKS_READ_ALL: false,
    REPEATABLE_TASKS_READ_MY_STORE_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TASKS: true,
    REPEATABLE_TASKS_READ_MY_TEAMS_TASKS: true,
    REPEATABLE_TASKS_UPDATE_ALL: false,
    REPEATABLE_TASKS_UPDATE_MY_TASKS: true,
    REPEATABLE_TASKS_UPDATE_MY_TEAMS_TASKS: true,
    STORES_READ_ALL: true,
    STORES_READ_MY_COUNTRY: true,
    STORES_READ_MY_STORE: true,
    STORES_WRITE_ALL: true,
    STORES_WRITE_MY_COUNTRY: true,
    STORES_WRITE_MY_STORE: true,
    TASKS_CREATE_ALL: true,
    TASKS_CREATE_MY_TEAMS_TASKS: true,
    TASKS_READ_ALL: false,
    TASKS_READ_MY_STORE_TASKS: true,
    TASKS_READ_MY_TASKS: true,
    TASKS_READ_MY_TEAMS_TASKS: true,
    TASKS_UPDATE_ALL: false,
    TASKS_UPDATE_MY_STORE_TASKS: true,
    TASKS_UPDATE_MY_TASKS: true,
    TASKS_UPDATE_MY_TEAMS_TASKS: true,
    TEAMS_READ_ALL: true,
    TEAMS_READ_MY_COUNTRY: true,
    TEAMS_READ_MY_STORE: true,
    TEAMS_WRITE_ALL: true,
    TEAMS_WRITE_MY_COUNTRY: true,
    TEAMS_WRITE_MY_STORE: true,
    UPDATE_TEAMS_TASK: true,
    USERS_READ_ALL: true,
    USERS_READ_MY_COUNTRY: true,
    USERS_READ_MY_STORE: true,
    USERS_WRITE_ALL: true,
    USERS_WRITE_CAN_SET_COUNTRY_SUPERUSER: true,
    USERS_WRITE_CAN_SET_COWORKER: true,
    USERS_WRITE_CAN_SET_STOREOWNER: true,
    USERS_WRITE_CAN_SET_SUPERUSER: true,
    USERS_WRITE_MY_COUNTRY: true,
    USERS_WRITE_MY_STORE: true,
  },
};

export default allPermissions;
