import { getTokenAsync } from "../../hooks/API/getAuthToken";
import packageJson from "../../../package.json";

export async function makeServiceHeaders(): Promise<HeadersInit> {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    throw new Error("Token is not a string");
  }
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "X-Client-Version": packageJson.version,
  };
}
