import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { eventTypes, eventTypesList } from "@coworker/enums/eventTypes";
import { Options } from "@coworker/components";
import { FixaSwitch } from "@coworker/apprestructured/src/shared/wrappers/FixaSwitch/FixaSwitch";
import profilePreferences from "@coworker/enums/profilePreferences";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import { useUserId } from "../../core/auth/useLoggedInUser";
import useFlag, { FLAGS } from "../../hooks/useFlag";
import { BasicOption } from "@coworker/components/src/components/Option";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";

const Section = styled.div`
  height: 50px;
  background: var(--grey100);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 28px;
`;

const NotificationOption = ({ type, filters, uid, onNewFiltersList }) => {
  const key = `notification.disabled.message.${uid}`;
  const isFiltered = filters.has(type);
  const handleNotificationToggle = () => {
    const newFilters = new Set(filters);

    if (isFiltered) {
      sessionStorage.removeItem(key);
      newFilters.delete(type);
    } else {
      newFilters.add(type);
    }

    onNewFiltersList([...newFilters.values()]);
  };

  return (
    <BasicOption
      testId={`option_${type}`}
      title={<Trans>{`eventTypeString_${type}`}</Trans>}
      onClick={handleNotificationToggle}
      titleExtra={<FixaSwitch checked={!isFiltered} subtle />}
    />
  );
};

const ToggleAllOption = ({ filters, uid, onNewFiltersList }) => {
  const key = `notification.disabled.message.${uid}`;

  const isToggled = React.useMemo(() => {
    return eventTypesList.every((type) => filters.has(type));
  }, [filters]);

  const handleToggleAll = React.useCallback(() => {
    if (!isToggled) {
      onNewFiltersList(eventTypesList);
    } else {
      onNewFiltersList([]);
      sessionStorage.removeItem(key);
    }
  }, [isToggled, onNewFiltersList, key]);

  const title = <Trans>toggleAllString</Trans>;
  return (
    <BasicOption
      title={<b>{title}</b>}
      onClick={handleToggleAll}
      titleExtra={<FixaSwitch checked={!isToggled} />}
    />
  );
};

const NotificationSettings = () => {
  const uid = useUserId();
  const { t } = useTranslation();
  const canDisableFeedbackRequest = useFlag(FLAGS.FEEDBACK_REQUEST);

  const [notificationChoices, onNewFiltersList] = useUserPreference(
    profilePreferences.NOTIFICATION_CHOICES,
    []
  );
  const filterSet = new Set(notificationChoices);

  const sections = {
    assignees: {
      title: <Trans>notificationSettings_assigneesTitle</Trans>,
      types: [],
    },
    events: {
      title: <Trans>notificationSettings_eventsTitle</Trans>,
      types: [],
    },
    reminders: {
      title: <Trans>notificationSettings_remindersTitle</Trans>,
      types: [],
    },
    testbuys: {
      title: <Trans>notificationSettings_testbuysTitle</Trans>,
      types: [],
    },
    others: {
      title: <Trans>notificationSettings_otherNotificationsTitle</Trans>,
      types: [],
    },
  };

  eventTypesList.forEach((eventType) => {
    switch (eventType) {
      case eventTypes.TASK_ASSIGNED:
      case eventTypes.TASK_ASSIGNED_TO_TEAM:
      case eventTypes.TASK_REASSIGNED:
      case eventTypes.TASK_REASSIGNED_TO_TEAM:
        sections.assignees.types.push(eventType);
        break;
      case eventTypes.TASK_EDITED:
      case eventTypes.TASK_CLOSED:
      case eventTypes.TASK_PICKED_UP:
      case eventTypes.TASK_COMMENTED:
      case eventTypes.TASK_COMPLETED_WITH_CHANGES:
        sections.events.types.push(eventType);
        break;
      case eventTypes.TASK_IS_ONE_DAY_OLD:
      case eventTypes.TASK_IS_FOUR_HOURS_OLD:
      case eventTypes.TEAM_TASK_IS_ONE_DAY_OLD:
      case eventTypes.TEAM_TASK_IS_FOUR_HOURS_OLD:
        sections.reminders.types.push(eventType);
        break;

      case eventTypes.TESTBUY_REASSIGNED:
        sections.testbuys.types.push(eventType);
        break;

      case eventTypes.FEEDBACK_REQUEST:
        if (canDisableFeedbackRequest) sections.others.types.push(eventType);
        break;

      default:
        break;
    }
  });

  return (
    <FullScreenPopup
      noPadding
      linkbar={
        <Linkbar currentLabel={t("notificationsSettingsString")} currentAsH1 />
      }
    >
      <Options>
        <ToggleAllOption
          isToggle
          onNewFiltersList={onNewFiltersList}
          filters={filterSet}
          uid={uid}
        />
        {Object.entries(sections).map(([section, obj]) => {
          if (!obj.types.length) return null;
          return (
            <div key={`section_${section}`}>
              <Section>{obj.title}</Section>
              {obj.types.map((eventType) => (
                <NotificationOption
                  onNewFiltersList={onNewFiltersList}
                  key={`notificationOption_${eventType}`}
                  type={eventType}
                  filters={filterSet}
                  uid={uid}
                />
              ))}
            </div>
          );
        })}
      </Options>
    </FullScreenPopup>
  );
};

export default NotificationSettings;
