import React from "react";
import { ModalFooter } from "@ingka/modal";

interface ModalFooterProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const FixaModalFooter = (props: ModalFooterProps) => {
  return <ModalFooter {...props} />;
};
