import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { AddRepeatable } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";
import { recurringTaskOptions } from "@coworker/enums/recurringTaskOptions";
import useFormatScheduled from "../../../hooks/useFormatScheduled";
import tracker from "../../../helpers/tracker";

const Preview = styled.span`
  font-size: 11px;
  ${({ disabled }) => !disabled && `color: var(--grey900);`}
`;

export default function TaskFormRepeatable({ value, onChange, taskId }) {
  const { getInput } = useInputPopup();
  const { preview, previewHint, startDate } = useFormatScheduled(value ?? {});

  const getSchedule = async () => {
    const data = await getInput("schedule", {
      ...value,
      title: <Trans>scheduleTaskString</Trans>,
    });

    if (data) {
      onChange({ frequency: recurringTaskOptions.PLANNED, ...data });
      tracker.plannedTasks.submitted(data);
    } else {
      tracker.plannedTasks.formClickX();
    }
  };

  const name = React.useMemo(() => {
    if (value?.frequency === recurringTaskOptions.PLANNED) {
      if (value?.recurring) {
        return (
          <>
            {preview}
            {previewHint && (
              <>
                <br />
                <Preview disabled={taskId}>{previewHint}</Preview>
              </>
            )}
          </>
        );
      } else {
        return (
          <Trans i18nKey="plannedPreviewString" values={{ date: startDate }} />
        );
      }
    } else {
      return null;
    }
  }, [value, preview, previewHint, startDate, taskId]);

  if (taskId && value?.frequency !== recurringTaskOptions.PLANNED) {
    return null;
  }

  return (
    <Widget
      onClick={getSchedule}
      uneditable={taskId}
      data-testid="repeatableWidget"
    >
      <AddRepeatable
        repeatableString={<Trans>scheduleTaskString</Trans>}
        deactivated={!value || value.frequency === recurringTaskOptions.NONE}
        value={name}
        none={!name && <Trans>scheduleTaskString</Trans>}
        optional={<Trans>optionalString</Trans>}
      />
    </Widget>
  );
}
