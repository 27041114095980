import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import PaNumbers from "@coworker/consts/paNumbers";
import { ReactComponent as Arrow } from "../../assets/svg/arrow-back.svg";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { DepartmentDetail } from "./DepartmentDetail";
import { sign } from "@coworker/reusable/helpers/math";

const All = styled.div`
  box-sizing: border-box;
  height: 63px;
  padding: 20px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--grey100);
  border-bottom: 1px solid var(--grey100);
  background: var(--white);
`;

const LinkButton = styled.div`
  position: relative;
  background: var(--grey100);
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  color: ${(props) => props.disabled && "var(--grey700)"};
  ${(props) => props.clickable && !props.disabled && "cursor: pointer;"}
  & > * {
    color: ${(props) => props.disabled && "var(--grey700)"};
    ${(props) => props.clickable && !props.disabled && "cursor: pointer;"}
  }
`;

const Text = styled.div`
  margin-left: 30px;
  font-size: 11px;
  font-weight: bold;
`;

const StyledArrow = styled(Arrow)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-left: 17px;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 20px;
  border-top: 1px solid var(--grey100);
  background: var(--white);
  width: 100%;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 11px;
  color: #484848;
  margin-bottom: 10px;
`;

function getValue(data, home_sort, home_sort_value) {
  if (!data) return 0;
  const isAway = home_sort_value === "AWAY";
  if (["leastPieces", "mostPieces"].includes(home_sort)) {
    return (isAway ? data.away_pieces : data.home_pieces) || 0;
  } else {
    return (isAway ? data.away_turnover : data.home_turnover) || 0;
  }
}

export function sortDepartments(
  departments,
  departmentsData,
  home_sort,
  home_sort_value
) {
  return departments.sort((a, b) => {
    if (!departmentsData?.data || departmentsData.loading) {
      return a.localeCompare(b);
    }
    if (home_sort === "hfbNumber" || !departmentsData) {
      return a.localeCompare(b);
    } else {
      const aValue = getValue(
        departmentsData?.data[a],
        home_sort,
        home_sort_value
      );
      const bValue = getValue(
        departmentsData?.data[b],
        home_sort,
        home_sort_value
      );

      const aValueBeforeBValue = sign(aValue - bValue);
      if (["highestTurnover", "mostPieces"].includes(home_sort)) {
        return -aValueBeforeBValue;
      } else {
        return aValueBeforeBValue;
      }
    }
  });
}

const AllDepartments = ({ onChangeInsightsParams, loading }) => {
  const { push } = useWorkspacesAction();

  return (
    <All key={"ALL"} data-testid="allDepartments">
      <div>
        <Trans>allHFBsString</Trans>
      </div>
      <LinkButton
        data-testid="see_all_button"
        onClick={
          loading
            ? null
            : () => {
                const updatedInsightsFilter = {
                  deptFilters: { hfb: "ALL", salesShare: "HOME" },
                };
                onChangeInsightsParams(updatedInsightsFilter);
                push(`/insights/department/ALL`);
              }
        }
      >
        <Text>
          <Trans>seeAll2String</Trans>
        </Text>
        <StyledArrow />
      </LinkButton>
    </All>
  );
};

const Department = ({
  dataFetchParams,
  onChangeInsightsParams,
  department,
  data,
  loading,
  homeValueToShow,
}) => {
  return (
    <Container key={department} data-testid={department}>
      <Title>
        <Trans>{`hfb${department}String`}</Trans>
      </Title>
      {department.includes(["OT", "CL", "OW"]) && (
        <Subtitle>HFB{department}</Subtitle>
      )}
      <DepartmentDetail
        dataFetchParams={dataFetchParams}
        onChangeInsightsParams={onChangeInsightsParams}
        data={data}
        department={department}
        loading={loading}
        savedHomeValueToShow={homeValueToShow}
      />
    </Container>
  );
};

export function Departments({
  onChangeInsightsParams,
  activeHFB,
  dataFetchParams: baseFetchParams,
}) {
  const home_value_to_show = baseFetchParams?.home?.valueToShow;
  const home_sort = baseFetchParams?.home?.sortBy?.type;
  const home_sort_value = baseFetchParams?.home?.sortBy?.value;

  return React.useMemo(() => {
    const departments = sortDepartments(
      [
        ...Object.keys(PaNumbers).filter(
          (key) => (key.length === 2 || key === "SSFA") && key !== "96"
        ),
        "OT",
      ],
      activeHFB,
      home_sort,
      home_sort_value
    );

    return (
      <>
        <AllDepartments
          onChangeInsightsParams={onChangeInsightsParams}
          loading={activeHFB.loading}
          key={"ALL"}
        />
        {departments.map((department) => (
          <Department
            dataFetchParams={baseFetchParams}
            onChangeInsightsParams={onChangeInsightsParams}
            department={department}
            loading={activeHFB.loading}
            data={!!activeHFB?.data && activeHFB?.data[department]}
            key={department}
            homeValueToShow={home_value_to_show}
          />
        ))}
      </>
    );
  }, [
    activeHFB,
    home_sort_value,
    home_sort,
    home_value_to_show,
    onChangeInsightsParams,
    baseFetchParams,
  ]);
}
