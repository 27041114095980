import React from "react";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import LocationPinSmallIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/LocationPinSmallIconPath";
import { useTranslation } from "@coworker/locales";
import { Text } from "../Atoms/Text";

interface HFBLocationProps {
  hfb: string;
}

export const HFBLocation = ({ hfb }: HFBLocationProps) => {
  const { t } = useTranslation();

  const addOnContent = hfb ? <Text text={hfb} grey /> : "";

  return (
    <FixaListViewItem
      title={t("hfbLocationInStoreString")}
      leadingIcon={LocationPinSmallIconPath}
      addOn={addOnContent}
      emphasised
      inset
    />
  );
};
