// For now, all users get their Sunday at the end of the week.
// If this turns out it needs to be dynamic, try https://stackoverflow.com/a/57102881/296639
export const weekEndsWithSunday: boolean = true;

export const SORTED_WEEKDAYS = weekEndsWithSunday
  ? [1, 2, 3, 4, 5, 6, 0]
  : [0, 1, 2, 3, 4, 5, 6];

// If weekEndsWithSunday we sort the 0=Sunday as if it was a 7
export const weekdaySorter = weekEndsWithSunday
  ? (a: any, b: any) => (a || 7) - (b || 7)
  : (a: any, b: any) => a - b;
