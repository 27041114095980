import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { removeProductType } from "./helpers";
import useFormatter from "../../hooks/useFormatter";
import { useCallInsightsFunction } from "../../hooks/API/useCallFirebaseFunction";
import { INSIGHTS_GENERAL } from "@coworker/enums/insightsRequestTypes";

const useInsightsGraphData = (dataFetchParams, insights = false) => {
  const { t } = useTranslation();
  const { data, error, loading } = useCallInsightsFunction(
    INSIGHTS_GENERAL,
    {
      ...dataFetchParams,
      product_id: removeProductType(dataFetchParams?.product_id),
    },
    {
      blockRequest:
        !dataFetchParams.group_by || dataFetchParams.isFetchingSavedFilters,
    }
  );

  const records = React.useMemo(() => {
    if (
      dataFetchParams?.period?.weeksBack === 0 &&
      data &&
      data.records?.length !== 7
    ) {
      return [];
    }
    return (data && data.records) || [];
  }, [data, dataFetchParams]);

  const {
    period: { weeksBack },
    today,
  } = dataFetchParams;

  const weekdayLabels = React.useMemo(
    () => [
      <Trans>sunString</Trans>,
      <Trans>monString</Trans>,
      <Trans>tueString</Trans>,
      <Trans>wedString</Trans>,
      <Trans>thuString</Trans>,
      <Trans>friString</Trans>,
      <Trans>satString</Trans>,
    ],
    []
  );

  const {
    primarySeries,
    secondarySeries,
    todaysIndex,
    currency,
    xLabels,
    primaryTotal,
    secondaryTotal,
    barWidths,
  } = React.useMemo(() => {
    let currency = "";
    const primarySeries = [];
    const secondarySeries = [];
    let xLabels = weeksBack ? [] : weekdayLabels;

    const getValue = (record, getSecondary = false) => {
      if (!!data && (dataFetchParams.graph?.type === "turnover" || insights)) {
        return record[
          getSecondary ? "previous_turnover_sum" : "current_turnover_sum"
        ];
      }
      return record[getSecondary ? "previous_sum" : "current_sum"];
    };

    let todaysIndex;
    for (let record of records) {
      const x = record.group_index;

      if (weeksBack)
        xLabels[x] = record.year
          ? `${record.group_current}|${record.year}`
          : record.group_current;
      if (!currency && record.currency) currency = record.currency;
      if (record.group_current === today) todaysIndex = x;

      const value = getValue(record);
      const secondaryValue = getValue(record, true);
      primarySeries[x] = value;
      secondarySeries[x] = secondaryValue;
    }

    let primaryTotal = 0;
    let secondaryTotal = 0;
    let maxValue = 1; // Start at 1 to never divide by 0
    const barWidths = [];
    if (!loading) {
      for (const index in xLabels) {
        if (primarySeries[index] > maxValue) maxValue = primarySeries[index];
        if (secondarySeries[index] > maxValue)
          maxValue = secondarySeries[index];
        if (primarySeries[index]) primaryTotal += primarySeries[index];
        if (secondarySeries[index]) secondaryTotal += secondarySeries[index];
      }

      const barScale = 100.0 / maxValue;
      for (const index in xLabels) {
        barWidths[index] = [
          primarySeries[index] ? primarySeries[index] * barScale : null,
          secondarySeries[index] ? secondarySeries[index] * barScale : null,
        ];
      }
    }

    return {
      primarySeries,
      secondarySeries,
      todaysIndex,
      currency,
      xLabels,
      primaryTotal,
      secondaryTotal,
      barWidths,
      maxValue,
    };
  }, [
    data,
    dataFetchParams?.graph?.type,
    insights,
    records,
    today,
    weekdayLabels,
    weeksBack,
    loading,
  ]);

  const { formatNumber, formatCurrency } = useFormatter();
  const reformat = (value) => {
    return dataFetchParams.graph?.type === "turnover" || insights
      ? formatCurrency(value, currency)
      : formatNumber(value);
  };

  const formatAmount = (amount) => reformat(Math.abs(amount));
  const formatTurnover = (amount) => reformat(amount);

  const totalLabelText = (amountDiff) => {
    const formattedAmount = formatAmount(amountDiff);
    return amountDiff < 0
      ? t("soFarBelowLastWeekString", { formattedAmount })
      : t("soFarAboveLastWeekString", { formattedAmount });
  };

  const formattedWeekNumber = xLabels[0];
  const totalTurnover = formatTurnover(primaryTotal);

  return {
    weeksBack,
    formattedWeekNumber,
    primarySeries,
    secondarySeries,
    totalLabelText,
    formatTurnover,
    xLabels,
    barWidths,
    reformat,
    todaysIndex,
    primaryTotal,
    secondaryTotal,
    totalTurnover,
    weekdayLabels,
    error,
    loading,
  };
};

export default useInsightsGraphData;
