import React from "react";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";

interface KeypadType {
  keypadType: "number" | "email" | "text" | "hidden" | "password";
  inputMode:
    | "search"
    | "email"
    | "text"
    | "none"
    | "url"
    | "tel"
    | "numeric"
    | "decimal";
}

interface NativeNumberKeyPadProps extends KeypadType {
  disabled: boolean;
  maxLength: number;
  initialValue?: string;
  onUpdate: (value: string) => void;
  onComplete: (value: string) => void;
}

export function NativeNumberKeyPad({
  disabled,
  maxLength,
  initialValue = "",
  onUpdate,
  onComplete,
  keypadType = "number",
  inputMode = "numeric",
}: NativeNumberKeyPadProps) {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (event: { target: { value: string } }) => {
    const newValue = event.target.value.replace(/[^0-9,.]/g, "");
    if (maxLength && newValue.length > maxLength) {
      return;
    }
    setValue(newValue);
    onUpdate(newValue);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      onComplete(value);
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FixaInputField
      disabled={disabled}
      maxLength={maxLength}
      type={keypadType}
      id="inputField"
      inputMode={inputMode}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
}
