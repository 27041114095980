import React from "react";
import styles from "./task-card.module.css";
import { getTaskInfo } from "../../helpers/getTaskInfo";
import { useTranslation } from "@coworker/locales";
import { Task } from "@coworker/types/lib/tasks/base";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import useFormatter from "@coworker/app/src/hooks/useFormatter";
import { Type } from "./Type";
import { Priority } from "./Priority";
import { ImagesAndComments } from "./ImagesAndComments";
import { Image } from "./Image";
import { Title } from "./Title";
import { Frequency } from "./Frequency";
import { Assignee } from "./Assignee";
import { Layout } from "./Layout";
import taskStateOptions from "@coworker/enums/taskState";
import FixaCheckbox from "../../../shared/wrappers/FixaCheckbox/FixaCheckbox";
import { useMultiSelectForTab } from "@coworker/app/src/hooks/useMultiSelect";
import { Item } from "../../../shared/types/item";
import { SubTitles } from "./SubTitles";
import { InformationBanner } from "./InformationBanner";
import { Timestamp } from "./Timestamp";
import { TaskType } from "../../enums/taskTypes";

interface TaskCardProps {
  task: Task;
  item: Item;
  multiSelect?: boolean;
  isSwiping?: boolean;
  type?: string;
}

export const TaskCard = ({
  task,
  item,
  multiSelect,
  isSwiping,
  type,
}: TaskCardProps) => {
  const { t } = useTranslation();

  const addonTask = task as AddonTask;
  const { productUnit } = useFormatter();
  const productUnitTitle = productUnit(
    addonTask.requested_type,
    addonTask.actual_requested_quantity
  );

  const taskInfo = getTaskInfo(
    task,
    t as (key?: string) => string,
    productUnitTitle,
    item
  );

  const isTaskClosed = task.state === taskStateOptions.CLOSED;

  const { toggle, selectedTaskIds = [] } = useMultiSelectForTab(type) as {
    toggle?: (s: string) => void | undefined;
    selectedTaskIds?: string[] | undefined;
    disabled: boolean;
  };

  if (!task) return null;

  return (
    <div className={styles["task-card"]}>
      {multiSelect && (
        <div className={styles["checkbox-wrapper"]}>
          <FixaCheckbox
            id="select-task"
            value="select-task"
            checked={selectedTaskIds.includes(task.id as string)}
            onChange={() => toggle && toggle(task.id as string)}
          />
        </div>
      )}
      <Layout
        taskId={task.id ?? ""}
        isSwiping={isSwiping!!}
        isPlannedTask={task.task_type === TaskType.PLANNED && !isTaskClosed}
      >
        <div className={styles["top-wrapper"]}>
          <Type task={task} type={taskInfo.type ?? ""} />
          <Priority task={task} state={task.state} />
          <ImagesAndComments
            hasNotes={task?.has_notes as boolean}
            hasImages={task?.has_images as boolean}
          />
        </div>
        <div className={styles["middle-wrapper"]}>
          <Image image={item?.smallImageUrl ?? item?.imageUrl ?? ""} />
          <div
            className={`${styles["title-wrapper"]} ${
              item?.imageUrl && styles["with-image"]
            }`}
          >
            <Title title={taskInfo.title ?? ""} />
            <SubTitles subtitles={taskInfo.subtitles ?? []} />
            {(task.task_type === TaskType.CUSTOM ||
              task.task_type === TaskType.PLANNED) && <Frequency task={task} />}
          </div>
        </div>
        <InformationBanner
          information={taskInfo.bannerInformation}
          isTaskClosed={isTaskClosed}
          type={task.task_type}
        />
        <div className={styles["bottom-wrapper"]}>
          <Assignee
            state={task.state}
            assignee={
              task.state === taskStateOptions.UNASSIGNED
                ? task.assigned_team_id
                : task.state === taskStateOptions.ASSIGNED
                ? task.assigned_user_id
                : task.task_finisher_id ??
                  task.assigned_user_id ??
                  task.assigned_team_id
            }
          />
          <Timestamp
            timestamp={
              task.state === taskStateOptions.UNASSIGNED ||
              task.state === taskStateOptions.ASSIGNED
                ? task.created_at ?? 0
                : task.finished_at ?? 0
            }
            type={task.state}
          />
        </div>
      </Layout>
    </div>
  );
};
