import React, { ReactNode } from "react";
import Accordion, { AccordionItem } from "@ingka/accordion";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/accordion/dist/style.css";
import "@ingka/focus/dist/style.css";

type FixaAccordionItemProps = {
  id: string;
  title: string | React.ReactElement;
  children: ReactNode;
  subtle?: boolean;
  onHeadingClicked?: () => void;
  onClick?: () => void;
  open?: boolean;
  className?: string;
};

const FixaAccordionItem: React.FC<FixaAccordionItemProps> = (props) => {
  return <AccordionItem {...props} />;
};

type FixaAccordionProps = {
  children: ReactNode;
  collapsible?: boolean;
  padded?: boolean;
  size?: "small";
};

const FixaAccordion: React.FC<FixaAccordionProps> = (props) => {
  return <Accordion {...props} />;
};

export { FixaAccordion, FixaAccordionItem };
