import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ChevronDownIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronDownIconPath";
import ChevronUpIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronUpIconPath";
import React from "react";
import styles from "./teams-list-view-item-icon.module.css";
interface TeamsListViewItemIconProps {
  id: string;
  toggleTeamMembers: (index: number) => void;
  isOpen: boolean;
  index: number;
}

export const TeamsListViewItemIcon: React.FC<TeamsListViewItemIconProps> = ({
  isOpen,
  id,
  toggleTeamMembers,
  index,
}) => {
  return (
    <div
      className={styles["teams-list-view-item-icon"]}
      id={id}
      onClick={() => {
        toggleTeamMembers(index);
      }}
    >
      <FixaSSRIcon
        paths={isOpen ? ChevronUpIconPath : ChevronDownIconPath}
      ></FixaSSRIcon>
    </div>
  );
};
