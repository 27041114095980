import React, { CSSProperties } from "react";
import Button, { ButtonType } from "@ingka/button";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/avatar/dist/style.css";
import "@ingka/focus/dist/style.css";

type FixaButtonProps = {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  "data-testid"?: string;
  disabled?: boolean;
  fluid?: boolean;
  iconOnly?: boolean;
  iconPosition?: "leading" | "trailing";
  inverseTheme?: boolean;
  loading?: boolean;
  onClick?:
    | ({
        href: string;
      } & ((e: React.MouseEvent<HTMLAnchorElement>) => void))
    | ({
        href?: undefined;
      } & ((e: React.MouseEvent<HTMLButtonElement>) => void));
  text?: React.ReactNode;
  size?: "xsmall" | "small" | "medium";
  ssrIcon?: (prefix?: string) => React.SVGProps<SVGElement>[];
  style?: CSSProperties;
  type?: ButtonType;
};

function FixaButton(props: FixaButtonProps) {
  return <Button {...props} />;
}

export default FixaButton;
