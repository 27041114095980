import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { StoredImage } from "../StoredImage";
import AvatarImage from "../AvatarImage";
import activityTypes from "@coworker/enums/activityTypes";
import { RotatedImage } from "../RotatedImage";
import useFormatter, { capitalizeFirst } from "../../hooks/useFormatter";
import { useInputPopup } from "../InputPopup";
import { actorTypes } from "@coworker/enums/actorTypes";
import { closeTypeKeys } from "@coworker/enums/closeTypes";
import { dropTypeKeys } from "@coworker/enums/dropTypes";
import statusTypes from "@coworker/enums/statusTypes";
import { ReactComponent as SystemAvatar } from "../../assets/svg/system-avatar.svg";
import { ReactComponent as GenericAvatar } from "../../assets/svg/generic-profile.svg";
import { firstPresent } from "@coworker/utils/array.helpers";
import { useStoreTeams } from "../../core/hooks/useStoreTeams";
import { useNoteImages } from "../../core/hooks/useImages";
import { useStoreUsers, useAsMap } from "../../hooks/useStoreContacts";

const ImageHolder = styled.div`
  border-radius: 5px;
  margin-right: 9px;
  margin-bottom: 9px;
  background-color: var(--grey150);
  overflow: hidden;
  display: inline-flex;
  vertical-align: top;
  width: 73px;
  height: 73px;
`;

const ImageContainer = styled.div`
  margin-top: 16px;
`;

const Main = styled.div`
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  margin-left: 20px;
  margin-top: 10px;
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Name = styled.div`
  width: 52px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.09px;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
`;

const Time = styled.div`
  line-height: 24px;
  font-size: 12px;
  letter-spacing: -0.09px;
  color: #949494;
  flex-grow: 0;
  flex-shrink: 0;
`;

const NoteText = styled.div`
  line-height: 21px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.09px;
  color: var(--grey700);
`;

const NoteDescription = styled.div`
  margin-top: 10px;
  line-height: 21px;
  font-size: 14px;
  color: var(--grey700);
`;

const subtypesMap = {
  [activityTypes.CLOSE]: closeTypeKeys,
  [activityTypes.DROP]: dropTypeKeys,
  [activityTypes.RELEASE]: dropTypeKeys,
};

const activityTypeTextMap = {
  [activityTypes.PICK_UP]: () => <Trans>pickedUpTheTaskString</Trans>,
  [activityTypes.PICKED]: () => <Trans>completedPickingString</Trans>,
  [activityTypes.PICKED_AND_REASSIGNED_TEAM]: (params) =>
    getAssingedToText({
      ...params,
      i18nKey: "completedAndAssignedToTeam",
      optionsKey: "team",
    }),
  [activityTypes.PICKED_AND_REASSIGNED_USER]: (params) =>
    params.t("completedPickingAndTaskAssignedToPerson", {
      name: params.toShortName(params.assigned_user_id),
    }),
  [activityTypes.COMPLETE]: (params) =>
    getReasonText({ ...params, i18nKey: "completedLowerString" }),
  [activityTypes.CLOSE]: (params) =>
    getReasonText({ ...params, i18nKey: "closedLowerString" }),
  [activityTypes.DROP]: () => <Trans>droppedTheTaskString</Trans>,
  [activityTypes.RELEASE]: (params) =>
    getAssingedToText({
      ...params,
      i18nKey: "releasedTheTaskString",
      optionsKey: "team",
    }),
  [activityTypes.REASSIGN]: (params) =>
    params.t("assignedTaskToString", {
      name: params.toShortName(params.assigned_user_id),
    }),
  [activityTypes.REASSIGN_TEAM]: (params) =>
    getAssingedToText({
      ...params,
      i18nKey: "assignedTaskToString",
      optionsKey: "name",
    }),
  [activityTypes.EDIT]: () => <Trans>editedTheTaskString</Trans>,
  [activityTypes.CREATE_AND_ASSIGN_TO_TEAM]: (params) =>
    getAssingedToText({
      ...params,
      i18nKey: "createdAndAssignedToTeam",
      optionsKey: "team",
    }),
  [activityTypes.CREATE_AND_ASSIGN_TO_USER]: (params) =>
    params.t("createdAndAssignedTaskToName", {
      name: params.toShortName(params.assigned_user_id),
    }),
  [activityTypes.REFILL_QUANTITY_EDIT]: () => (
    <Trans>refillCorrectedTaskString</Trans>
  ),
};
activityTypeTextMap.RELEASE_DUE_TO_USER_DELETED = activityTypeTextMap.RELEASE;

// Input: a history `notes` document
// Outputs a translated description
function translatedDescription({ event, description, event_subtype }) {
  const i18nKey = (subtypesMap[event] || {})[event_subtype];
  if (!i18nKey) return description; // backwards compatible. Just keep existing description text.
  return (
    <>
      <Trans>{i18nKey}</Trans>
      {!!description ? ` - ${description}` : "."}
    </>
  );
}

function displayAvatar(uid, users) {
  if (uid === actorTypes.SYSTEM) {
    return <SystemAvatar />;
  } else if (users && users[uid]) {
    if (users[uid].status === statusTypes.DELETED) {
      return <GenericAvatar />;
    } else {
      return <AvatarImage uid={uid} />;
    }
  }
}

const Note = ({ note, noteId, taskId, localImages }) => {
  const images = useNoteImages(taskId, noteId);
  const groups = useAsMap(useStoreTeams());
  const users = useStoreUsers();
  const { toTimeAgo } = useFormatter();
  const { getInput } = useInputPopup();
  const { t } = useTranslation();

  const toLongName = (uid) => {
    if (uid === actorTypes.SYSTEM) return t("systemString");
    const user = users.filter(
      (user) => user.fixa_uid === uid || user.uid === uid || user.id === uid
    ); // users[uid] || {};
    if (user.status === statusTypes.DELETED) return t("deletedUserString");

    const { given_name, surname, name } = user?.[0] || {};

    return firstPresent([
      `${capitalizeFirst(given_name)} ${capitalizeFirst(surname)}`,
      name,
      t("unknownUserString"),
    ]);
  };

  const openImages = React.useCallback(() => {
    getInput("imageGallery", { data: images });
  }, [images, getInput]);

  if (!note) return null;
  return (
    <NoteContainer>
      {displayAvatar(note.creator_id, users)}
      <Main>
        <TitleRow>
          <Name data-testid="noteUser">{toLongName(note.creator_id)}</Name>
          {note.created_at ? (
            <Time data-testid="noteTime">{toTimeAgo(note.created_at)}</Time>
          ) : (
            <Time data-testid="noteTime">
              <Trans>fewString</Trans>
            </Time>
          )}
        </TitleRow>
        <NoteText data-testid="noteText">
          {note.event ? (
            <i>{getTextFromEvent(note, toLongName, groups, t)}</i>
          ) : (
            note.text
          )}
        </NoteText>
        {(note.event_subtype || note.description || "") && (
          <NoteDescription>{translatedDescription(note)}</NoteDescription>
        )}
        <ImageContainer>
          {images &&
            images.map((image) => (
              <ImageHolder key={image.file_name} onClick={openImages}>
                <StoredImage image={image} size="small" />
              </ImageHolder>
            ))}
          {localImages?.noteId === noteId &&
            localImages.map((localImage) => {
              return (
                <ImageHolder key={localImage.image}>
                  <RotatedImage
                    src={localImage.image}
                    alt="uploading"
                    orientation={localImage.orientation}
                  />
                </ImageHolder>
              );
            })}
        </ImageContainer>
      </Main>
    </NoteContainer>
  );
};

export default Note;

const WithReason = ({ activity, issue, description }) => {
  return (
    <>
      {activity} <Trans>theTaskString</Trans>
      <p>
        {issue && <Trans which={issue}>whichIssueString</Trans>}
        {!!description && ` - ${description}`}
      </p>
    </>
  );
};

function getTextFromEvent(activity, toShortName, groups, t) {
  const {
    assigned_user_id,
    assigned_team_id,
    refilled_issue,
    refilled_description,
    event,
  } = activity;
  const params = {
    assigned_user_id,
    assigned_team_id,
    refilled_issue,
    refilled_description,
    groups,
    toShortName,
    t,
  };
  if (Object.keys(activityTypeTextMap).includes(event)) {
    return activityTypeTextMap[event](params);
  } else {
    return "";
  }
}

function getAssingedToText(params) {
  const { i18nKey, groups, assigned_team_id, optionsKey, t } = params;
  const teamPR = getGroupNameFromId(groups, assigned_team_id);
  return t(i18nKey, { [optionsKey]: teamPR });
}

function getReasonText(params) {
  const { i18nKey, refilled_issue, refilled_description } = params;
  return (
    <WithReason
      activity={<Trans>{i18nKey}</Trans>}
      issue={refilled_issue}
      description={refilled_description}
    />
  );
}

function getGroupNameFromId(groups, id) {
  return (groups && groups[id] && groups[id].name) || "";
}
