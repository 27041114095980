// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
// Enums
import taskTypeOptions from "@coworker/enums/taskType";
import { ISSUE, LOCATION } from "../widgets";
import {
  ASSIGN_WIDGET,
  PHOTOS_WIDGET,
  PRIORITY_WIDGET,
  getProductWidget,
} from "./_common";
import issueTypeOptions from "@coworker/enums/issueTypes";
// Icons
import { ReactComponent as Assemble } from "../../../assets/svg/issue_assemble.svg";
import { ReactComponent as Damaged } from "../../../assets/svg/issue_damaged.svg";
import { ReactComponent as Dirty } from "../../../assets/svg/issue_dirty.svg";
import { ReactComponent as Inventory } from "../../../assets/svg/issue_inventory.svg";
import { ReactComponent as Missing } from "../../../assets/svg/issue_missing.svg";
import { ReactComponent as NoPriceTag } from "../../../assets/svg/issue_no_price_tag.svg";
import { ReactComponent as Racking } from "../../../assets/svg/issue_racking.svg";
import { ReactComponent as Other } from "@coworker/reusable/svg/three-dots.svg";
import { ReactComponent as OutOfStock } from "../../../assets/svg/issue_out_of_stock.svg";
import { ReactComponent as MissingDisplayModel } from "../../../assets/svg/issue_missing_display_model.svg";
import { ReactComponent as Location } from "../../../assets/svg/location.svg";
import { splitLocation } from "../../../services/locations.service";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../../hooks/useWorkspaces";
import { getProductIssuePopup } from "../common/popup.helpers";
import { useInputPopup } from "../../InputPopup";

const issueTypeDescriptions = [
  {
    icon: Inventory,
    name: <Trans>issueNameInventoryString</Trans>,
    type: issueTypeOptions.INVENTORY,
  },
  {
    icon: Damaged,
    name: <Trans>issueNameDamagedString</Trans>,
    type: issueTypeOptions.DAMAGED_OR_BROKEN,
  },
  {
    icon: Assemble,
    name: <Trans>issueNameAssemblyString</Trans>,
    type: issueTypeOptions.POOR_ASSEMBLY,
  },
  {
    icon: Dirty,
    name: <Trans>issueNameDirtyString</Trans>,
    type: issueTypeOptions.DIRTY,
  },
  {
    icon: Missing,
    name: <Trans>issueNameMissingPartsString</Trans>,
    type: issueTypeOptions.MISSING_PARTS,
  },
  {
    icon: NoPriceTag,
    name: <Trans>issueNameNoPriceTagString</Trans>,
    type: issueTypeOptions.NO_PRICE_TAG,
  },
  {
    icon: Racking,
    name: <Trans>issueNameRackingString</Trans>,
    type: issueTypeOptions.RACKING,
  },
  {
    icon: OutOfStock,
    name: <Trans>issueNameOutOfStockString</Trans>,
    type: issueTypeOptions.OUT_OF_STOCK,
  },
  {
    icon: MissingDisplayModel,
    name: <Trans>issueNameMissingDisplayModelString</Trans>,
    type: issueTypeOptions.MISSING_DISPLAY_MODEL,
  },
  {
    icon: Location,
    name: <Trans>issueNameWrongSalesLocationString</Trans>,
    type: issueTypeOptions.WRONG_SALES_LOCATION,
  },
  {
    icon: Other,
    name: <Trans>issueNameOtherString</Trans>,
    type: issueTypeOptions.OTHER,
    descriptionRequired: true,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  table: "tasks",
  type: "product",
  enumType: taskTypeOptions.PRODUCT_ISSUE,
  validate: (form) =>
    [
      form.issue_type,
      form.description ||
        !issueTypeDescriptions.find(({ type }) => form.issue_type === type)
          ?.descriptionRequired,
      form.product_article_id,
      form.assigned_team_id,
      form.location || form.location_custom,
      !form.images?.some((image) => image.loading),
    ].every((x) => x),
  fieldsFilledCount: (form) => {
    let count = 0;
    if (form.issue_type) count++;
    if (form.description) count++;
    if (form.assigned_team_id) count++;
    if (form.location || form.location_custom) count++;
    if (form.priority_flag) count++;
    if (form.images && form.images.length > 0) count++; // Do not care if the image is loading. User has interacted with the field if length > 0
    return count;
  },
  useReactToChanges: function (taskId, task, onChange) {
    const { navigationState } = useWorkspacesState();
    const { setNavigationState } = useWorkspacesAction();
    const { getInput } = useInputPopup();
    React.useEffect(() => {
      if (navigationState.isSelectProductIssueType) {
        getProductIssuePopup({
          init: true,
          getInput,
          onChange,
          description: "",
          issues: issueTypeDescriptions,
          issue: issueTypeOptions.OUT_OF_STOCK,
        });
        setNavigationState({ isSelectProductIssueType: false }); // to avoid opening popup when user goes back using browser back button.
      }
      // Ignored: getInput, navigationState.isSelectProductIssueType, onChange, setNavigationState
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  },
  header: {
    newTitle: <Trans>productString</Trans>,
    editTitle: <Trans>editTaskString</Trans>,
    testId: "productIssueFromTitle",
  },
  fields: [
    getProductWidget(),
    {
      type: ISSUE,
      conf: { issues: issueTypeDescriptions },
      values: {
        in: ({ issue_type: issue, description }) => ({ issue, description }),
        out: ({ issue: issue_type, description }) => ({
          issue_type,
          description,
        }),
      },
    },
    PRIORITY_WIDGET,
    {
      type: LOCATION,
      conf: {
        optional: false,
        locationString: <Trans>locationString</Trans>,
        popupName: "addonLocation",
        deactivated: false,
      },
      values: {
        in: ({
          location,
          location_custom,
          product_article_id,
          product_article_type,
        }) => {
          const [code = "", department = ""] = splitLocation(location);

          return {
            code,
            department,
            custom: location_custom,
            articleId: product_article_id,
            articleType: product_article_type,
          };
        },
        out: ({ code, department, custom, section }) => {
          let locationText = code || "";
          let departmentText = "";
          if (department || section)
            locationText += ` - ${department || section}`;
          if (department || section)
            departmentText += `${department || section}`;

          return {
            location: custom ? "" : locationText,
            location_grid_code: custom ? "" : code,
            location_department: custom ? "" : departmentText,
            location_custom: custom ? custom : "",
          };
        },
      },
    },
    ASSIGN_WIDGET,
    PHOTOS_WIDGET,
  ],
};
