const paNumbers = {
  ALL: "All departments",
  "01": "Living room seating",
  "011": "Sofas",
  "0111": "Sofas",
  "0113": "Sofa beds",
  "012": "Armchairs, footstool & sofa tables",
  "0121": "Sofa tables",
  "0125": "Armchairs",
  "0126": "Footstools",
  "02": "Store and organise furniture",
  "021": "Storage",
  "0211": "System cabinets",
  "0212": "Solitaire cabinets",
  "0213": "Side boards",
  "0214": "Bookcases",
  "0215": "Open shelving units",
  "0216": "Wall shelves",
  "022": "Display furniture",
  "0221": "Display cabinets",
  "023": "TV furniture",
  "0231": "TV benches",
  "03": "Workspaces",
  "034": "Home workspace",
  "0341": "Home desks",
  "0342": "Home work chairs",
  "0343": "Work at home storage",
  "035": "Office workspace",
  "0351": "Office desks and tables",
  "0352": "Office chairs",
  "0353": "Office storage",
  "036": "Complementary work items",
  "0361": "Complementary work items",
  "04": "Bedroom furniture",
  "042": "Wardrobes",
  "0421": "System wardrobes",
  "0422": "Interior organisers",
  "0423": "Solitaire wardrobes",
  "0424": "Opened storage",
  "043": "Chests & other furniture",
  "0431": "Chest of drawers",
  "0432": "Bedside tables",
  "0433": "Complementary bedroom furniture",
  "0434": "Clothes stands",
  "05": "Beds & Mattresses",
  "051": "Mattresses and accessories",
  "0511": "Mattresses",
  "0512": "Wooden base mattresses",
  "0513": "Mattress pads",
  "0514": "Bases and slats",
  "052": "Beds",
  "0521": "Bed frames",
  "0522": "Flexible sleep solutions",
  "0523": "Beds others",
  "0524": "Continental & Divan beds",
  "06": "Bathroom",
  "061": "Bathroom furniture",
  "0611": "Bathroom furniture",
  "0612": "Bathroom small furniture",
  "0613": "Bathroom sinks",
  "062": "Bathroom organisers",
  "0621": "Bathroom around the sink",
  "0622": "Bathroom towel solutions",
  "0623": "Bathroom, shower and toilet solutions",
  "063": "Bathroom taps and showers",
  "0631": "Bathroom taps",
  "0632": "Shower mixers and accessories",
  "0634": "Bathroom sinks",
  "064": "Cleaning and laundry solutions",
  "0641": "Cleaning and cleaning accessories ",
  "0642": "Laundry and laundry accessories",
  "07": "Kitchen",
  "071": "Kitchen cabinets",
  "0711": "Kitchen cabinets",
  "072": "Kitchen fronts",
  "0721": "Kitchen fronts, coverpanels, plinths, and deco strips ",
  "073": "Kitchen fixed interiors",
  "0731": "Kitchen fixed interiors",
  "074": "Kitchen worktops",
  "0741": "Kitchen worktops",
  "0742": "Kitchen wall panels",
  "075": "Kitchen taps, sinks and sink accessories",
  "0751": "Kitchen taps",
  "0752": "Kitchen sinks and sink accessories",
  "076": "Kitchen accessories",
  "0761": "Kitchen interior organisers",
  "0762": "Kitchen organisers",
  "0763": "Kitchen accessories",
  "078": "Freestanding kitchens and kitchen furniture",
  "0781": "Freestanding kitchens",
  "0782": "Kitchen furniture",
  "0783": "Complete kitchen cabinets",
  "079": "Knobs and handles",
  "0791": "Knobs and handles",
  "08": "Dining",
  "081": "Dining tables",
  "0811": "Tables",
  "0812": "High tables",
  "082": "Dining seating",
  "0821": "Chairs incl covers, folding chairs and benches",
  "0822": "Stools incl covers",
  "0823": "Bar stools incl covers",
  "09": "Children's IKEA",
  "091": "Baby",
  "0911": "Cots",
  "0912": "Cot mattresses",
  "0913": "Accessories for babies",
  "0914": "Textiles for babies",
  "0915": "Toys for babies",
  "0917": "Highchairs",
  "0918": "Changing tables",
  "0919": "Safety for babies",
  "092": "Children's storage",
  "0921": "Children's storage furniture",
  "0922": "Children's small storage",
  "093": "Children 3-7 years",
  "0931": "Beds for small children",
  "0932": "Mattresses for small children",
  "0934": "Textiles for small children",
  "0935": "Accessories for small children",
  "0937": "Furniture for small children",
  "094": "Play",
  "0942": "Toys for small children",
  "0945": "Books and creativity",
  "095": "Children 8-14 year",
  "0951": "Beds for children",
  "0952": "Accessories for children",
  "0953": "Textiles for children",
  "0954": "Study for children",
  10: "Lighting and Home electronics",
  101: "",
  1011: "Floor lamps",
  1012: "Table lamps",
  1013: "Pendant lamps",
  1014: "Ceiling lamps and spotlights",
  1015: "Shades, bases and cord set",
  1016: "Clamp and wall lamps",
  1017: "Bathroom lightning",
  1018: "Children's lighting",
  102: "Electronics",
  1021: "Power supply and charging",
  1022: "Ceiling lamps and spotlights",
  1023: "Pendant lamps",
  1025: "Bathroom lightning",
  103: "Light sources",
  1031: "Light sources",
  1032: "Smart Lighting",
  104: "Integrated Lighting & Electronics",
  1041: "Media electronics",
  1042: "Power supply and charging",
  1043: "Integrated lighting",
  1044: "Accessories for electronics",
  1045: "Batteries",
  1046: "Accessories for installation",
  106: "Outdoor lighting",
  1061: "Outdoor lighting",
  107: "Children's lighting",
  1071: "Children's lighting",
  108: "Seasonal lighting and Light chains",
  1081: "Seasonal lighting and light chains",
  1082: "Outdoor lighting and lighting chains",
  109: "Accessories for Installation",
  1091: "Accessories for Installation",
  11: "Bed and bath textiles",
  111: "Bedlinen",
  1111: "Sheets and pillow cases",
  1112: "Quilt covers",
  1113: "Bedspreads",
  112: "Quilts and pillows",
  1121: "Quilts",
  1122: "Pillows",
  1123: "Mattress and pillow protectors",
  113: "Bath textiles",
  1131: "Towels",
  1132: "Bathmats",
  1133: "Shower curtains",
  1134: "Shower curtain rods",
  12: "Home textiles",
  121: "Window solutions",
  1211: "Curtains and window panels",
  1213: "Blinds",
  1214: "Curtain rods and systems",
  122: "Fabrics and accessories",
  1221: "Metre fabrics",
  1222: "Textile accessories",
  123: "Cushions, throws and chairpads",
  1231: "Cushions",
  1232: "Throws",
  1233: "Chairpads",
  13: "Rugs",
  131: "Home furnishing rugs",
  1311: "Rugs",
  1312: "Oriental rugs",
  1313: "Doormats & accessories",
  1314: "Small rugs and runners",
  132: "Flooring",
  1321: "Flooring",
  1322: "Flooring underlays",
  1323: "Flooring accessories",
  1324: "Skirting boards and profiles",
  14: "Cooking",
  141: "Cookware",
  1411: "Pots and cooking accessories",
  1412: "Pans",
  1413: "Ovenware",
  1414: "Bakeware",
  1415: "Cooking textiles",
  142: "Kitchen tools",
  1421: "Cooking utensils",
  1422: "Kitchen gadgets",
  1423: "Knives",
  1424: "Chopping boards",
  143: "Storing and washing",
  1431: "Food containers",
  1432: "Jar and tins",
  1433: "Organisers and washing-up",
  15: "Eating",
  151: "Dining and serving",
  1511: "Dinnerware and serving",
  152: "Glassware",
  1521: "Drinking glasses",
  1522: "Jugs and carafes",
  153: "Cutlery",
  1531: "Cutlery",
  154: "Set the table",
  1541: "Napkins",
  1542: "Trays",
  1543: "Textiles, place mats and coasters",
  155: "Coffee and tea",
  1551: "Mugs and cups",
  1552: "Coffee and tea accessories",
  16: "Decoration",
  162: "Green decoration",
  1621: "Plant pots",
  1622: "Plants",
  1623: "Artificial plants",
  163: "Home decoration",
  1631: "Candleholders",
  1632: "Candles",
  1633: "Vases, bowls and accessories",
  1634: "Artificial flowers",
  1637: "Holiday decoration",
  164: "Wall decoration",
  1641: "Frames",
  1642: "Pictures",
  1643: "Clocks",
  1644: "Wall accessories",
  1645: "Mirrors",
  17: "Outdoor & Secondary storage",
  171: "Outdoor furniture & Secondary storage",
  1711: "Outdoor dining tables",
  1712: "Outdoor relax furniture",
  1713: "Outdoor lounge furniture",
  1714: "Outdoor & Secondary storage",
  1715: "Outdoor dining seating",
  1716: "Outdoor cooking",
  1717: "Parasols and protections",
  1718: "Outdoor accessories",
  1719: "Outdoor cushions",
  18: "Home organisation",
  181: "Boxes & organisers",
  1811: "Boxes and baskets",
  1812: "Stationery",
  1813: "Stationery organisers",
  182: "Wall organisers",
  1821: "Boards",
  1822: "Hooks",
  183: "Clothes & shoe organisers & accessories",
  1831: "Clothes and shoe organisers",
  1832: "Hangers and acc for clothes and shoes",
  1836: "Bags and travel accessories",
  185: "Sorting solutions",
  1851: "Storage boxes",
  1852: "Sorting bins",
  1853: "Sorting bags",
  20: "Other business opportunities",
  201: "Other businesses",
  2017: "Walls",
  2018: "Lifestyle and play",
  204: "Living with pets",
  2041: "Eating supplies",
  2042: "Sleeping",
  2043: "Walking and travelling",
  2044: "Grooming and cleaning accessories",
  2045: "Playing and training",
  2046: "Storage solutions",
  205: "Life at home knowledge and home furnishing inspiration",
  2051: "Books",
  50: "Home electronics",
  501: "Connectivity and control",
  5011: "Connectivity and control",
  502: "Smart sound",
  5021: "Speakers and sound accessories",
  504: "Smart power",
  5041: "Smart power",
  60: "Food services",
  61: "Consumer Packed Goods",
  70: "Home Appliances",
  701: "Cooking Appliances",
  7011: "Ovens",
  7012: "Microwave ovens",
  7013: "Combi ovens",
  7014: "Hobs",
  7015: "Extractor hoods",
  7016: "Cookers",
  702: "Cooling Appliances",
  7021: "Fridges",
  7022: "Freezers",
  7023: "Fridge/Freezers",
  703: "Dish Care Appliances",
  7031: "Dishwashers",
  704: "Laundry Appliances",
  7041: "Washing machines",
  709: "Appliances Accessories",
  7091: "Extractor hoods accessories",
  7092: "Other appliances accessories",
  96: "IKEA Food",
  961: "Swedish Food Market",
  9611: "Meat, fish & seafood",
  9612: "Easy to prepare",
  9613: "Sauces, jam & condiments",
  9614: "Bread & dairy",
  9615: "Pastries, desserts and cookies",
  9616: "Sweets, chocolate & snacks",
  9617: "Grab & Go",
  9618: "Beverage",
  9619: "Activities",
  962: "Restaurant",
  9621: "Breakfasts and brunches",
  9622: "Cold plates and starters",
  9623: "Sandwiches and wraps",
  9624: "Salads",
  9625: "Main courses and buffets",
  9626: "Desserts and pastries",
  9627: "Add on",
  9628: "Cold beverages",
  9629: "Hot beverages",
  963: "Bistro",
  9631: "Hot snacks Bistro",
  9632: "Cold snacks Bistro",
  9633: "Cold beverages Bistro",
  9634: "Hot beverages Bistro",
  9635: "Desserts and patries Bistro",
  9636: "Add-on Bistro",
  965: "Co-worker Restaurant",
  9651: "Breakfasts and brunches CW Rest",
  9652: "Cold plates and starters CW Rest",
  9653: "Sandwiches and wraps CW Rest",
  9654: "Salads CW Rest",
  9655: "Main courses and buffets CW Rest",
  9656: "Desserts and pastries CW Rest",
  9657: "Add-on CW Rest",
  9658: "Cold beverages CW Rest",
  966: "Other products",
  9661: "Others, non food",
  9662: "Catering service",
  OW: "Open the wallet",
  CL: "Cashline",
  AA: "Activity Area",
  AS: "ASIS",
  ST: "Staircase",
  WH: "Warehouse",
  SSFA: "Self serve furniture area",
};

export default paNumbers;
