import React from "react";
import styled, { css } from "styled-components";
import { getValidCode, getSupplierNumber } from "../helpers/barcodeValidator";
import { Button, Icon, ScannerZoomSlider } from "@coworker/components";
import { isRDTDevice } from "@coworker/reusable";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { useInputPopup } from "./InputPopup";
import { Trans } from "@coworker/locales";
import { isIOS } from "../helpers/browserChecks";
import useFlag, { FLAGS } from "../hooks/useFlag";
import tracker from "../helpers/tracker";
import { useUserPreference } from "../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";
import { useDebouncedFunc } from "../hooks/useDebounce";
import { scannerLog } from "./Scanning/log";
import { reportMessageToSentry } from "../hooks/useConfigureSentry";
import { detectMainCamera } from "./Scanning/detectMainCamera";
import { blocksBrowserScanner } from "./Scanning/useBrowserScanner";
import { useMyStore } from "../hooks/useMyStore";

const StyledLoaderIcon = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
`;

const {
  barcode: { logScannerType, logZoomUsage },
} = tracker;

const CAPTURE_SIZE = [290, 260];
// Offset that will be scanned against barcodes added to CAPTURE_SIZE for better
// scanning chances
const OVERSCAN_SIZE = [26, 10];

// Measured in milliseconds. TODO: maybe adjust this depending on device speed, to not overburden slow devices
const SCANNING_INTERVAL = 68;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
  overflow: hidden;
`;

const MinimalVideo = styled.video`
  width: 1px;
  height: 1px;
`;

const Canvas = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  ${({ scaled, scale }) =>
    scaled &&
    css`
      right: unset;
      bottom: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(${scale});
    `}
`;

const ButtonContainer = styled.div`
  margin: 0 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 2px;
`;

const SliderContainer = styled.div`
  margin: 30px 19px 0;
  flex-grow: 1;
`;

const ControlsContainer = styled.div`
  position: fixed;
  ${({ inPopup }) => css`
    bottom: ${inPopup ? "10px" : "90px"};
  `};
  width: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  margin: 0 19px;
  justify-content: center;
`;

const noop = () => null;

function Scanner({
  onBarcode = noop,
  onError = noop,
  scanningPaused = false,
  streamPaused = false,
  videoRef,
  switchToRDTScanner,
}) {
  // Utilities
  const { popupOpen: inPopup } = useInputPopup();
  const [pausedPushToScan, setPausedPushToScan] = React.useState(true);
  const isRDT = isRDTDevice();
  const scanningDebug = useFlag(FLAGS.BARCODE_DEBUG) && !isRDT;
  const [scaleMultiplierPreference, setScaleMultiplierPreference] =
    useUserPreference(profilePreferences.SCANNER_SCALE_FACTOR, 1.2);
  const [scaleMultiplier, setScaleMultiplier] = React.useState(
    scaleMultiplierPreference
  );
  const primaryLocale = useMyStore()?.configuration?.locale?.primary_locale;
  const setScaleMultiplierPreferenceDebounced = useDebouncedFunc(
    setScaleMultiplierPreference,
    1000
  );
  const zoomTrackerDebounced = useDebouncedFunc(logZoomUsage, 1000);
  const setScaleMultiplierDebounced = (multiplier) => {
    setScaleMultiplier(multiplier);
    setScaleMultiplierPreferenceDebounced(multiplier);
    zoomTrackerDebounced(multiplier);
  };

  // Refs
  const containerRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  // Scanner flow
  const canvasSize = useElementSize(containerRef);
  const videoStream = useVideoStream({ onError });
  const [videoWidth, videoHeight] = useSynchronizeVideo({
    stream: videoStream,
    videoRef,
  });
  const scale = useScale(
    [videoWidth, videoHeight],
    [canvasSize.width, canvasSize.height],
    scaleMultiplier
  );
  useSynchronizeCanvas({ videoRef, canvasRef, paused: streamPaused });
  const { barcode, clearBarcode } = useScanner({
    videoStream,
    canvasRef,
    paused: scanningPaused || streamPaused || pausedPushToScan,
    scale: 1 / scale,
  });

  React.useEffect(() => {
    if (barcode?.code) {
      setPausedPushToScan(true);
      clearBarcode();
      scannerLog("legacyScanner: Barcode detected", barcode);
      onBarcode(
        barcode.code,
        undefined,
        undefined,
        barcode.supplierNumber,
        primaryLocale
      );
      logScannerType("LEGACY_SCANNER", barcode.code);
    }
  }, [barcode, onBarcode, clearBarcode, primaryLocale]);

  const [flash, setFlash] = React.useState(false);
  const [flashAvailable, setFlashAvailable] = React.useState(
    localStorage?.getItem("_fixa_flash_available") === "1"
  );

  React.useEffect(() => {
    let mounted = true;
    async function applyFlash() {
      let [track] = videoStream.getVideoTracks();
      if (track.readyState === "live" && mounted) {
        try {
          await track.applyConstraints({
            advanced: [{ torch: flash }],
          });
          setFlashAvailable(true);
          if (!localStorage?.getItem("_fixa_flash_available"))
            localStorage?.setItem("_fixa_flash_available", "1");
        } catch (e) {
          tracker.barcode.logErrorTrackTorch(e);
        }
      }
    }
    if (videoStream && "ImageCapture" in window) {
      applyFlash();
    }
    return () => {
      mounted = false;
    };
  }, [flash, videoStream, flashAvailable]);

  return (
    <Container ref={containerRef}>
      <MinimalVideo
        ref={videoRef}
        autoplay
        muted
        controls={false}
        playsInline
      />
      <Canvas
        ref={canvasRef}
        width={scanningDebug ? videoWidth : canvasSize.width}
        height={scanningDebug ? videoHeight : canvasSize.height}
        scaled={scanningDebug}
        scale={scale}
      />
      <FrameCanvas width={canvasSize.width} height={canvasSize.height} />
      {/* <canvas // Used to debug image crop
        ref={captureRef}
        style={{
          position: "absolute",
          zIndex: 100,
          transform: "scale(0.33)",
          transformOrigin: "top left",
        }}
      /> */}
      <ControlsContainer inPopup={inPopup}>
        <ButtonContainer>
          {isRDT && (
            <Button
              primary
              dark
              style={{ flexGrow: 0 }}
              text={<Icon family="icons" name="rdt_scanner" color="white" />}
              onClick={switchToRDTScanner}
              flexNoGrow
            />
          )}
          <Button
            primary
            dark
            data-testid="pushToScan"
            flexGrow
            text={
              pausedPushToScan ? (
                <Trans>scanString</Trans>
              ) : (
                <StyledLoaderIcon />
              )
            }
            onClick={() => {
              if (pausedPushToScan && !isIOS) {
                setPausedPushToScan(false);
              }
            }}
            onTouchStart={() => {
              if (pausedPushToScan && isIOS) {
                setPausedPushToScan(false);
              }
            }}
          />
          {flashAvailable && (
            <Button
              primary
              dark
              flexNoGrow
              text={
                <Icon
                  family="actions"
                  name={flash ? "torch-off" : "torch-on"}
                  color="white"
                />
              }
              onClick={() => setFlash(!flash)}
            />
          )}
        </ButtonContainer>
        {scanningDebug && (
          <SliderContainer inPupup={inPopup} scanHeight={CAPTURE_SIZE[1]}>
            <ScannerZoomSlider
              value={scaleMultiplier}
              setValue={setScaleMultiplierDebounced}
              min={1}
              max={3}
              step={0.2}
            />
          </SliderContainer>
        )}
      </ControlsContainer>
    </Container>
  );
}

function useScale(
  [videoWidth, videoHeight],
  [canvasWidth, canvasHeight],
  scaleMultiplier
) {
  const scaleFactor = Math.max(
    canvasWidth / videoWidth,
    canvasHeight / videoHeight
  );
  if (Number.isNaN(scaleFactor) || !Number.isFinite(scaleFactor)) {
    return 1;
  }
  // This enables more zoom to get smaller scanning area and better scanning
  // performance
  if (scaleFactor * scaleMultiplier < 1) return scaleFactor * scaleMultiplier;
  return scaleFactor;
}

function scaleVector([x, y], scale) {
  return [Math.round(x * scale), Math.round(y * scale)];
}

function extractImage(canvas, scale) {
  const ctx = canvas.getContext("2d");
  const [width, height] = scaleVector(CAPTURE_SIZE, scale);
  const [overscanWidth, overscanHeight] = scaleVector(OVERSCAN_SIZE, scale);
  const top = Math.floor((canvas.width - width - overscanWidth) / 2);
  const left = Math.floor((canvas.height - height - overscanHeight) / 2);
  let data;
  try {
    data = ctx.getImageData(
      top,
      left,
      width + overscanWidth / 2,
      height + overscanHeight / 2
    );
  } catch (error) {
    console.log(
      "ctx.getImageData fail",
      [top, left, width + overscanWidth / 2, height + overscanHeight / 2],
      error
    );
    return null;
  }
  return data;
}

function useScanner({ canvasRef, paused, scale, captureRef }) {
  const isRDT = isRDTDevice();
  const scanningDebug = useFlag(FLAGS.BARCODE_DEBUG) && !isRDT;
  const [qrworker, setQrworker] = React.useState();
  const [barcode, setBarcode] = React.useState(null);
  const isScanningRef = React.useRef(null);
  React.useEffect(() => {
    scannerLog(
      `Creating wasm worker, window.isSecureContext=${window.isSecureContext}, window.devicePixelRatio=${window.devicePixelRatio}`
    );
    const worker = new Worker("/wasmBarcodeWorker.js");
    setQrworker(worker);
    return () => {
      worker.terminate();
    };
  }, []);

  React.useEffect(() => {
    if (qrworker && !paused && !barcode) {
      function scanningLoop() {
        if (isScanningRef.current || !canvasRef.current) return;
        const image = extractImage(canvasRef.current, scale);
        const [width, height] = scaleVector(CAPTURE_SIZE, scale);
        const [overscanWidth, overscanHeight] = scaleVector(
          OVERSCAN_SIZE,
          scale
        );
        // if (captureRef.current) { // Used to debug image crop
        //   captureRef.current.width = image.width;
        //   captureRef.current.height = image.height;
        //   captureRef.current.getContext("2d").putImageData(image, 0, 0);
        // }
        isScanningRef.current = true; // Flag that we've sent off an image to avoid sending images faster than the recognition worker can handle.
        if (image?.data) {
          qrworker.postMessage({
            width: Math.ceil(width + overscanWidth / 2),
            height: Math.ceil(height + overscanHeight / 2),
          });
          qrworker.postMessage(image, [image.data.buffer]);
        }
      }
      const interval = setInterval(scanningLoop, SCANNING_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [qrworker, canvasRef, paused, barcode, scale, captureRef]);

  React.useEffect(() => {
    if (qrworker) {
      qrworker.onmessage = (ev) => {
        isScanningRef.current = false;
        const scanned = ev?.data?.data || "";
        const code = ev?.data?.data?.length && getValidCode(ev.data.data);
        const supplierNumber =
          ev?.data?.data?.length && getSupplierNumber(ev.data.data);
        if (scanningDebug) {
          tracker.barcode.logCaptured(scanned, code);
        }
        if (code) {
          setBarcode({ code, supplierNumber });
        }
      };
    }
    return () => {
      if (qrworker) qrworker.onmessage = noop;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrworker]);
  const clearBarcode = React.useCallback(() => {
    setBarcode(null);
  }, []);
  React.useEffect(() => {
    window.injectBarcode = setBarcode;
    return () => (window.injectBarcode = null);
  }, [setBarcode]);
  return {
    barcode,
    clearBarcode,
  };
}

function useElementSize(elementRef) {
  const [elementSize, setElementSize] = React.useState({ width: 0, height: 0 });
  // This should get run on every render to ensure that size of canvas matches
  // the screen size.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (!elementRef.current) return;
    const { clientWidth, clientHeight } = elementRef.current;
    const { width, height } = elementSize;
    if (clientWidth !== width || clientHeight !== height) {
      setElementSize({ width: clientWidth, height: clientHeight });
    }
  });

  React.useEffect(() => {
    const onResize = () => setElementSize({ width: 0, height: 0 });
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return elementSize;
}

function useSynchronizeVideo({ stream, videoRef }) {
  const [videoSize, setVideoSize] = React.useState([0, 0]);
  React.useEffect(() => {
    if (stream && videoRef.current) {
      let mounted = true;
      videoRef.current.srcObject = stream;
      videoRef.current
        .play()
        .catch((e) => {
          tracker.barcode.logErrorVideoPlay(e);
          console.error("use sync video", e);
          setTimeout(
            () =>
              mounted &&
              videoRef.current
                ?.play()
                .then(
                  () =>
                    mounted &&
                    setVideoSize([
                      videoRef.current.videoWidth,
                      videoRef.current.videoHeight,
                    ])
                ),
            500
          );
        })
        .then(
          () =>
            mounted &&
            videoRef.current &&
            setVideoSize([
              videoRef.current.videoWidth,
              videoRef.current.videoHeight,
            ])
        );
      return () => (mounted = false);
    }
  }, [stream, videoRef]);

  return videoSize;
}

function useSynchronizeCanvas({ videoRef, canvasRef, paused }) {
  const isRDT = isRDTDevice();
  const scanningDebug = useFlag(FLAGS.BARCODE_DEBUG) && !isRDT;
  React.useEffect(() => {
    if (videoRef.current && !paused) {
      let mounted = true;
      let syncCanvasCountFails = 0;
      function syncCanvas() {
        if (!mounted || !canvasRef.current) {
          return;
        }
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const video = videoRef.current;

        if (scanningDebug) {
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        } else {
          // Sometimes the video and canvas will not be of the same size. In those cases we need to show as much content as possible
          // without skewing the pixel density.

          // We first compare aspect ration between canvas and video and decide if video is too wide or to tall.
          if (
            canvas.height / canvas.width >
            video.videoHeight / video.videoWidth
          ) {
            // If video is too wide, we adjust video height to canvas height and proportionately resize width as well. As video is now too wide,
            // we need to move it half the excess size to the left.
            const ratio = canvas.height / video.videoHeight;
            const adjustedVideoWidth = ratio * video.videoWidth;
            const diff = (adjustedVideoWidth - canvas.width) / 2;
            ctx.drawImage(
              video,
              -diff,
              0,
              video.videoWidth * ratio,
              canvas.height
            );
          } else {
            // If video is too high, we adjust video width to canvas width and proportionately resize height as well. As video is now too high,
            // we need to move it half the excess size from the top.
            const ratio = canvas.width / video.videoWidth;
            const adjustedVideoHeight = ratio * video.videoHeight;
            const diff = (adjustedVideoHeight - canvas.height) / 2;
            ctx.drawImage(
              video,
              0,
              -diff,
              canvas.width,
              video.videoHeight * ratio
            );
          }
        }
        try {
          requestAnimationFrame(syncCanvas);
        } catch (error) {
          syncCanvasCountFails += 1;
          if (0 === syncCanvasCountFails % 10) {
            reportMessageToSentry(
              "old scanner: requestAnimationFrame/syncCancas having trouble multiple times",
              { syncCanvasCountFails }
            );
          }
        }
      }
      syncCanvas();

      return () => {
        mounted = false;
      };
    }
  }, [videoRef, paused, canvasRef, scanningDebug]);
}

function useVideoStream({ onError }) {
  const [stream, setStream] = React.useState(null);
  const errorCallback = React.useRef(onError);
  const [paused, setPaused] = React.useState(false);
  const [deviceId, setChosenDeviceId] = React.useState();
  React.useEffect(() => {
    if (!blocksBrowserScanner()) {
      console.log("Camera detection start");
      detectMainCamera()
        .catch(({ code, message }) => {
          window.enableScannerLogging = true;
          scannerLog("Could not detect camera", code, message);
        })
        .then((c) => {
          const { deviceId, label } = c?.device || {};
          if (deviceId) {
            scannerLog(`Choosing camera: "${deviceId} - ${label}"`);
            setChosenDeviceId(deviceId);
          } else {
            scannerLog("No main camera detected", c?.device || c);
          }
        });
    }
  }, []);
  React.useEffect(() => {
    const onChange = () => {
      setPaused(document.hidden);
    };
    document.addEventListener("visibilitychange", onChange);
    return () => document.removeEventListener("visibilitychange", onChange);
  }, []);

  React.useEffect(() => {
    if (!navigator?.mediaDevices?.getUserMedia) {
      onError(new Error("getUserMedia not supported"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    errorCallback.current = onError;
  }, [onError]);
  React.useEffect(() => {
    let mounted = true;
    async function getStream() {
      const constraints = !!deviceId
        ? { audio: false, video: { deviceId: { exact: deviceId } } }
        : {
            audio: false,
            video: {
              facingMode: "environment",
              width: { ideal: 2560 },
            },
          };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (!mounted) stopAllActiveTracks(stream);
      mounted && setStream(stream);
    }

    if (!paused) {
      getStream().catch((error) => {
        tracker.barcode.logErrorUseStream(error);
        // reportMessageToSentry("use video stream - getStream", { error });
        if (mounted) return errorCallback.current?.(error);
      });
    }
    return () => {
      mounted = false;
    };
  }, [deviceId, paused]);
  React.useEffect(() => {
    if (stream) {
      return () => stopAllActiveTracks(stream);
    }
  }, [stream, paused]);
  return stream;
}

/**
 * @param {MediaStream} stream
 */
function stopAllActiveTracks(stream) {
  for (const track of stream?.getTracks()) {
    if (track.readyState === "live") {
      track.stop();
    }
  }
}

// If we really, really want to test 2d canvas too: https://stackoverflow.com/a/55141040/296639
export function FrameCanvas({ width, height }) {
  const overlayCanvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = overlayCanvasRef.current;
    const ctx = canvas.getContext("2d");

    const [windowWidth, windowHeight] = CAPTURE_SIZE;
    const captureWindowTop = Math.floor((canvas.height - windowHeight) / 2);
    const captureWindowLeft = Math.floor((canvas.width - windowWidth) / 2);
    const MARGIN = 12;
    const SIZE = 16;

    drawShades();
    drawCaptureIndicators();

    // Draws black area around capture area
    function drawShades() {
      const { width, height } = canvas;
      ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
      ctx.fillRect(0, 0, captureWindowLeft, height); // left side
      ctx.fillRect(
        captureWindowLeft + windowWidth,
        0,
        width - (captureWindowLeft + windowWidth),
        height
      );
      ctx.fillRect(captureWindowLeft, 0, windowWidth, captureWindowTop); // top middle
      ctx.fillRect(
        captureWindowLeft,
        captureWindowTop + windowHeight,
        windowWidth,
        height - (captureWindowTop + windowHeight)
      );
    }

    // Draws white angles around capture area
    function drawCaptureIndicators() {
      const outsideEdge = {
        top: captureWindowTop - MARGIN,
        bottom: captureWindowTop + windowHeight + MARGIN,
        left: captureWindowLeft - MARGIN,
        right: captureWindowLeft + windowWidth + MARGIN,
      };
      ctx.lineWidth = 2;
      ctx.strokeStyle = "rgba(255, 255, 255, 1)";

      // Top left
      ctx.moveTo(outsideEdge.left + SIZE, outsideEdge.top);
      ctx.lineTo(outsideEdge.left, outsideEdge.top);
      ctx.lineTo(outsideEdge.left, outsideEdge.top + SIZE);
      ctx.stroke();

      ctx.moveTo(outsideEdge.right - SIZE, outsideEdge.top);
      ctx.lineTo(outsideEdge.right, outsideEdge.top);
      ctx.lineTo(outsideEdge.right, outsideEdge.top + SIZE);
      ctx.stroke();

      ctx.moveTo(outsideEdge.left + SIZE, outsideEdge.bottom);
      ctx.lineTo(outsideEdge.left, outsideEdge.bottom);
      ctx.lineTo(outsideEdge.left, outsideEdge.bottom - SIZE);
      ctx.stroke();

      // Bottom right
      ctx.moveTo(outsideEdge.right - SIZE, outsideEdge.bottom);
      ctx.lineTo(outsideEdge.right, outsideEdge.bottom);
      ctx.lineTo(outsideEdge.right, outsideEdge.bottom - SIZE);
      ctx.stroke();
    }
  }, [width, height]);

  return <Canvas ref={overlayCanvasRef} width={width} height={height} />;
}

// In case of CI we don't want scanner.
export default process.env.REACT_APP_ENV === "ci" ? () => null : Scanner;
