import { ChecklistItem } from "@coworker/types/lib/tasks/testbuy_followup";
import { sortingIndexValue } from "../../TestBuy/ArticleDetailPage/CriterionComponent/CriteriaHelper";
import { updateTask } from "../../../services/task.service";
import taskTypeOptions from "@coworker/enums/taskType";

export function sortChecklistCriteriaDisplayOrder(
  checklist: ChecklistItem[]
): ChecklistItem[] {
  return [...checklist].sort(
    (first, second) =>
      sortingIndexValue(first.key) - sortingIndexValue(second.key)
  );
}

export const toggleChecklistItem = (
  taskKey: string,
  checklistKey: string,
  isDone: boolean
) => {
  const body = {
    markDoneChecklistKey: "",
    markNotDoneChecklistKey: "",
  };
  if (isDone) body.markDoneChecklistKey = checklistKey;
  if (!isDone) body.markNotDoneChecklistKey = checklistKey;
  updateTask(body, taskKey, taskTypeOptions.TESTBUY_FOLLOW_UP);
};
