import React from "react";
import { Container, TextWrapper } from "../../Progress/TodaysProgress";
import { SmallText } from "../../Overview/NewReview";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";

export const TodaysProgressSkeleton = () => {
  return (
    <Container>
      <TextWrapper>
        <SmallText style={{ marginBottom: "10px" }}>
          <FixaSkeleton width="100px" height="17px" />
        </SmallText>
        <FixaSkeleton width="100%" height="75px" />
      </TextWrapper>
    </Container>
  );
};
