import React from "react";
import Skeleton from "@ingka/skeleton";
import "@ingka/focus/dist/style.css";
import "@ingka/switch/dist/style.css";

interface Props {
  height?: string;
  width?: string;
}

export const FixaSkeleton = (props: Props) => {
  return <Skeleton {...props} />;
};
