import React, { JSXElementConstructor, ReactElement } from "react";
import ListViewItem from "@ingka/list-view/ListViewItem";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/list-view/dist/style.css";
import "@ingka/focus/dist/style.css";

interface ListViewProps {
  key?: string;
  id?: string;
  className?: string;
  control?:
    | "navigational"
    | "default"
    | "checkbox"
    | "radiobutton"
    | "link"
    | "switch";
  onChange?: () => void;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  controlProps?: {
    checked: boolean;
    value: string;
    subtle?: boolean;
  };
  inset?: boolean;
  controlIcon?:
    | "chevronRight"
    | "copy"
    | "chevronRightSmall"
    | "arrowRight"
    | "linkOut"
    | null;
  title?: string | React.ReactElement;
  description?: string | ReactElement<any, string | JSXElementConstructor<any>>;
  size?: "small" | "medium";
  emphasised?: boolean;
  leadingIcon?: (prefix?: string) => React.SVGProps<SVGElement>[];
  image?: string;
  addOn?: string | React.ReactElement;
  name?: string;
}

export const FixaListViewItem = (props: ListViewProps) => {
  return <ListViewItem {...props} />;
};
