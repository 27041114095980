import React, { useEffect, useState } from "react";
import { Room } from "../../types/room";
import { useRoomsByStoreId } from "../../hooks/useRoomsByStoreId";
import { useRoomById } from "../../hooks/useRoomById";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useUpdateRoom } from "../../hooks/useUpdateRoom";
import { useTranslation } from "@coworker/locales";
import {
  checkEmptyRoomName,
  checkRoomName,
  EmptyRoom,
} from "../SettingsForm.helper";
import { MessageModal } from "./MessageModal";
import { OkCancelModal } from "./OkCancelModal";
import { SettingsForm } from "../SettingsForm";
import { useArticlesByRoomId } from "../../hooks/useArticlesByRoomId";
import { DeleteRoomModal } from "./DeleteRoomModal";
import { Image } from "../../types/images";
import { useAddImageToRoom } from "../../hooks/useAddImageToRoom";
import { useDeleteImages } from "../../hooks/useDeleteImages";
import { useQueryClient } from "@tanstack/react-query";
import { updateImage } from "../../services/images.service";
import { QueryKeys } from "../../hooks/queryKeys";
import { useImagesByRoomId } from "../../hooks/useImagesByRoomId";
import { useImagesBySignedUrl } from "../../hooks/useImagesBySignedUrl";

interface UpdateSettingsModalProps {
  roomId: string;
  isVisible: boolean;
  onClose: () => void;
}

export const UpdateSettingsModal = function ({
  roomId,
  isVisible,
  onClose,
}: UpdateSettingsModalProps) {
  const { t } = useTranslation();
  const { data: roomData } = useRoomById(roomId);
  const updateRoomMutation = useUpdateRoom(roomId);
  const [showModalMessage, setShowModalMessage] = useState("");
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [roomIsUpdated, setRoomIsUpdated] = useState(false);
  const [imagesAreUploading, setImagesAreUploading] = useState(false);
  const [room, setRoom] = useState(EmptyRoom);
  const { data: rooms } = useRoomsByStoreId(room.store_id);
  const { data: articles } = useArticlesByRoomId(roomId);
  const addImageToRoom = useAddImageToRoom(room.id);
  const deleteImageMutation = useDeleteImages();
  const queryClient = useQueryClient();
  const { data: images } = useImagesByRoomId(room.id);
  const signedImages = useImagesBySignedUrl(images ?? []);

  useEffect(() => {
    if (roomData) {
      setRoom(roomData);
    }
  }, [roomData]);

  const onSubmit = async () => {
    try {
      await updateRoomMutation.mutateAsync(room);
      onClose();
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotUpdateRoomTryAgainString"));
    }
  };

  const onCloseWarning = () => {
    if (roomIsUpdated) {
      setShowConfirmCloseModal(true);
    } else {
      onClose();
    }
  };

  const saveRoomChanges = (saveRoom: Room) => {
    setRoomIsUpdated(true);
    setRoom(saveRoom);
  };

  const onImageAddStart = () => {
    setImagesAreUploading(true);
  };

  const onImageAddEnd = async (images: Image[]) => {
    setRoomIsUpdated(true);
    for (const image of images) {
      try {
        await addImageToRoom.mutateAsync(image);
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
    setImagesAreUploading(false);
  };

  const onImageRemove = async (images: Image[]) => {
    try {
      await deleteImageMutation.mutateAsync({
        imageIds: images.map((image) => image.id ?? ""),
        roomId: room.id,
      });
    } catch (error) {
      alert(t("searchErrorString"));
    }
  };

  const onImageUpdate = async (image: Image) => {
    setRoomIsUpdated(true);
    if (image.id) {
      try {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ImagesByRoom],
        });
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  if (!room) {
    return <></>;
  }

  const submitDisabled =
    !rooms ||
    checkEmptyRoomName(room) ||
    checkRoomName(room, rooms) ||
    !roomIsUpdated ||
    imagesAreUploading;

  return (
    <>
      <FixaModal visible={isVisible} handleCloseBtn={onCloseWarning}>
        <FixaSheets
          labelledById="edit-room-setting"
          header={<FixaModalHeader title={t("editRoomSettingsString")} />}
          footer={
            <FixaModalFooter>
              <FixaButton
                type="primary"
                onClick={onSubmit}
                disabled={submitDisabled}
                aria-label={t("saveString")}
              >
                {t("saveString")}
              </FixaButton>
            </FixaModalFooter>
          }
        >
          <SettingsForm
            room={room}
            articles={articles ?? []}
            onSave={saveRoomChanges}
            images={signedImages}
            onImageAddStart={onImageAddStart}
            onImageAddEnd={onImageAddEnd}
            onImageRemove={onImageRemove}
            onImageUpdate={onImageUpdate}
          />
          <DeleteRoomModal room={room} />
        </FixaSheets>
      </FixaModal>
      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      {showConfirmCloseModal && (
        <OkCancelModal
          onOk={() => {
            setShowConfirmCloseModal(false);
            onClose();
            setRoom(roomData ?? EmptyRoom);
          }}
          onCancel={() => {
            setShowConfirmCloseModal(false);
          }}
          title={t("confirmCloseString")}
          text={t("allChangesLostCloseString")}
        />
      )}
    </>
  );
};
