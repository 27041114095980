import React from "react";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { RoomListItemMobile } from "../Molecules/RoomListItemMobile";
import { AlertResponse, Room } from "../types/room";
import { TaskLinks } from "../types/taskLink";
import { MediumSkeletonListItem } from "../Molecules/skeletons/MediumSkeletonListItem";
import { useSortedRooms } from "../../../hooks/useSortedRooms";

interface RoomsViewRoomsListMobileProps {
  rooms: Room[] | undefined;
  taskLinks: TaskLinks;
  alerts: AlertResponse[] | undefined;
}

export function RoomsViewRoomsListMobile({
  rooms,
  taskLinks,
  alerts,
}: RoomsViewRoomsListMobileProps) {
  const sortedRooms = useSortedRooms(rooms);

  if (!sortedRooms) {
    return (
      <FixaListView id="rooms-list">
        <MediumSkeletonListItem key="rooms-view-list-0" />
        <MediumSkeletonListItem key="rooms-view-list-1" />
        <MediumSkeletonListItem key="rooms-view-list-2" />
        <MediumSkeletonListItem key="rooms-view-list-3" />
      </FixaListView>
    );
  } else {
    return (
      <FixaListView id="rooms-list">
        {sortedRooms &&
          sortedRooms.map((room) => (
            <RoomListItemMobile
              key={room.id}
              room={room}
              taskLinks={taskLinks}
              alerts={alerts || []}
            />
          ))}
      </FixaListView>
    );
  }
}
