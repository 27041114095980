export const dropTypeKeys = {
  END_OF_SHIFT: "endOfShiftLabelString",
  ISSUE: "issueString",
  OTHER: "otherString",
};

export const dropTypeOptions = {
  END_OF_SHIFT: "END_OF_SHIFT",
  ISSUE: "ISSUE",
  OTHER: "OTHER",
};
