import React from "react";
import styled from "styled-components";
import { FixaQuantityStepper } from "@coworker/apprestructured/src/shared/wrappers/FixaQuantityStepper/FixaQuantityStepper";
import { createVote } from "../useMFAQsService";
import { canVote, onDownvote, onUpvote } from "./QuestionsDetailPage.helper";
import { Question } from "../BackendApiRepository/QuestionResponse";
import { Trans } from "@coworker/locales";

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const QuestionText = styled.p`
  color: var(--grey900);
  letter-spacing: -0.0042em;
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  margin-bottom: 24px;
`;

const CannotVoteWarning = styled.p`
  color: var(--grey900);
  letter-spacing: -0.0042em;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  padding-top: 16px;
`;

const VoteBoxContainer = styled.div`
  box-sizing: border-box;
  margin: 24px;
  margin-bottom: 32px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface VoteBoxProps {
  numberOfVotes: number;
  questionText: string;
  isWholeStore: boolean;
  questionId: string;
  storeId: string;
  area: string;
  question: Question;
  setQuestion: Function;
  endDate: number;
}

export default function VoteBox({
  numberOfVotes,
  questionText,
  isWholeStore,
  questionId,
  storeId,
  area,
  question,
  setQuestion,
  endDate,
}: VoteBoxProps) {
  return (
    <VoteBoxContainer>
      <QuestionText>{questionText}</QuestionText>
      {!isWholeStore && (
        <Container>
          <Center>
            <FixaQuantityStepper
              ariaDescribedBy={"VoteBox to step votes up and down"}
              ariaDescribedById={"VoteBox"}
              minVal={0}
              maxVal={99999}
              onDecrease={() => {
                onDownvote(
                  questionId,
                  area,
                  storeId,
                  createVote,
                  setQuestion,
                  question
                );
              }}
              onIncrease={() => {
                onUpvote(
                  questionId,
                  area,
                  storeId,
                  createVote,
                  setQuestion,
                  question
                );
              }}
              small={false}
              value={numberOfVotes}
              disabled={!canVote(endDate)}
            />
          </Center>
          {!canVote(endDate) && (
            <CannotVoteWarning>
              <Trans>cantUpvoteInThePastString</Trans>
            </CannotVoteWarning>
          )}
        </Container>
      )}
      {isWholeStore && (
        <CannotVoteWarning>
          <Trans>cantUpvoteInWholeStore</Trans>
        </CannotVoteWarning>
      )}
    </VoteBoxContainer>
  );
}
