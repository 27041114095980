export const closeTypeKeys = {
  INCORRECT: "closeIncorrectString",
  ALREADY_RESOLVED: "closeAlreadyResolvedString",
  NOT_IN_STOCK: "notInStockString",
  OTHER: "otherString",
};

export const closeTypeOptions = {
  INCORRECT: "INCORRECT",
  ALREADY_RESOLVED: "ALREADY_RESOLVED",
  NOT_IN_STOCK: "NOT_IN_STOCK",
  OTHER: "OTHER",
};
