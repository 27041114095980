import type { Store } from "@coworker/types/lib/store";
import { useQuery } from "@tanstack/react-query";
import { coreServiceBaseUrl } from "@coworker/app/src/core/cloud-services/cloud-services.helper";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import { INTERVAL } from "../../constants/clientTime";
import { makeServiceHeaders } from "./fetch.helpers";

const isChina = isChineseEnvironment();

export const CORE_SERVICE_URL = coreServiceBaseUrl(getEnvironmentId(), isChina);

async function fetchAllStores(): Promise<Store[]> {
  console.log("Fetching stores, isChina is ", isChina);
  const url = `${CORE_SERVICE_URL}/stores`;
  let storesFromApi: Store[];

  let headers: HeadersInit;

  try {
    if (typeof makeServiceHeaders !== "function") {
      console.error("makeServiceHeaders is undefined");
      return [];
    }

    headers = await makeServiceHeaders();
  } catch (error) {
    console.error("Error creating service headers:", error);
    return [];
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    storesFromApi = await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }

  return storesFromApi.map((store: any) => ({
    id: store.storeId,
    ...store,
  }));
}

export function useAllStoresQuery() {
  return useQuery<Store[]>({
    queryKey: ["allStores"],
    queryFn: fetchAllStores,
    staleTime: 1 * INTERVAL.HOUR, // Since this data does not change frequently, it can stay fresh for a long time
  });
}
