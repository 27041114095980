import React from "react";
import { Trans } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { SelectAmount } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";
import { getProductCachedData } from "../../../services/products.service";
import { useLocationCapacity } from "../../../services/locations.service";
import useFormatter from "../../../hooks/useFormatter";
import { useAvailableElevatedStock } from "../../../hooks/useAvailableElevatedStock";
import amountTypeOptions from "@coworker/enums/amountTypes";

export default function TaskFormAmount({
  value: {
    quantity,
    actualQuantity,
    type,
    productId,
    location,
    articleType,
    requestedQuantity,
    piecesPerMultiPack,
    piecesPerPallet,
  },
  conf: {
    changeAmount = false,
    changeName,
    changeTitle,
    deactivated,
    overridePadding,
  } = {},
  onChange,
  additionalSubTitle,
}) {
  const full_id = `${articleType}${productId}`;
  const maxRefillForLocation = useLocationCapacity(location, full_id);

  const { getInput } = useInputPopup();
  const { available } = useAvailableElevatedStock(articleType + productId);

  const pieces = quantity;

  // Find base unit type (meters, pieces/PIECES, MULTI_PACK or PALLET)
  const { unitCode } = getProductCachedData(productId) || {};
  const { productUnit } = useFormatter();
  const baseUnitType = unitCode || amountTypeOptions.PIECES;
  const baseUnit = productUnit(baseUnitType, pieces);
  const guessEnteredAmount = () => {
    if (!!type && pieces >= 0) {
      return type === amountTypeOptions.MULTI_PACK
        ? pieces / piecesPerMultiPack
        : type === amountTypeOptions.PALLET
        ? pieces / piecesPerPallet
        : pieces;
    }
  };

  const enteredAmount = actualQuantity ?? guessEnteredAmount();

  const getAmount = async () => {
    const data = await getInput("selectAmount", {
      title: <Trans>piecesString</Trans>,
      oldAmount: pieces,
      oldActualAmount: enteredAmount,
      oldAmountType: type,
      baseUnit,
      baseUnitType,
      maxAmount: changeAmount ? null : maxRefillForLocation,
      initializeWithOldAmount: true,
      changeAmount,
      changeName,
      changeTitle,
      requestedQuantity,
      available,
    });

    if (data) onChange(data);
  };

  // TODO: Rework so SelectAmount can avoid complex logic and duplicated parameters (amount vs enteredAmount)
  return (
    <Widget onClick={getAmount} data-testid={"amount"}>
      <SelectAmount
        amount={pieces}
        type={type}
        baseUnit={baseUnit}
        deactivated={deactivated != null ? deactivated : isNaN(pieces)} // TODO: Explain why isNaN is good enough, or otherwise bugfix.
        overridePadding={overridePadding}
        enteredAmount={enteredAmount}
        typeString={productUnit(type, enteredAmount)}
        additionalSubTitle={!pieces ? additionalSubTitle : <></>}
      />
    </Widget>
  );
}
