import React, { ReactNode } from "react";
import {
  CenterContainer,
  LeftContainer,
  RightContainer,
  SimpleContainer,
} from "../styles/styles";
import { Text } from "../Atoms/Text";

interface SimpleListContainerProps {
  headline: string;
  subtext?: string;
  leftcontent?: ReactNode;
  rightcontent?: ReactNode;
  withAsterisk?: boolean;
}

export const SimpleListContainer =({
  headline,
  subtext,
  leftcontent,
  rightcontent,
  withAsterisk,
}: SimpleListContainerProps) => {
  return (
    <SimpleContainer $direction={"row"}>
      <LeftContainer>{leftcontent }</LeftContainer>
      <CenterContainer $centerText={false}>
        <div style={{ display: "flex" }}>
          <Text text={headline} bold />
          {withAsterisk && <span style={{ color: "#E00751" }}>*</span>}
        </div>
        {subtext && <Text text={subtext} grey />}
      </CenterContainer>
      <RightContainer>{rightcontent}</RightContainer>
    </SimpleContainer>
  );
};
