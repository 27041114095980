import React from "react";
import Select, { Option } from "@ingka/select";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/forms/dist/style.css";

interface SelectProps {
  id: string;
  label?: string;
  children?: React.ReactNode;
  onChange?: (
    e: React.ChangeEvent<HTMLSelectElement>,
    withHint: boolean
  ) => void;
  ssrIcon?: (prefix?: string) => React.SVGProps<SVGElement>[];
  value?: string;
}
interface OptionProps {
  key?: string;
  value?: string;
  name?: string;
}

export const FixaSelect = (props: SelectProps) => {
  return <Select {...props} />;
};

export const FixaOption = (props: OptionProps) => {
  return <Option {...props} />;
};
