import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/performance";
import "firebase/compat/storage";

firebase.initializeApp({
  apiKey: process.env["REACT_APP_API_KEY"],
  authDomain: process.env["REACT_APP_AUTH_DOMAIN"],
  databaseURL: process.env["REACT_APP_DATABASE_URL"],
  projectId: process.env["REACT_APP_PROJECT_ID"],
  storageBucket: process.env["REACT_APP_STORAGE_BUCKET"],
  messagingSenderId: process.env["REACT_APP_MESSAGING_SENDER_ID"],
  measurementId: process.env["REACT_APP_MEASUREMENT_ID"],
  appId: process.env["REACT_APP_APPID"],
});

//firebase.performance(); // COAPP-785 enable automatic performance tracking of PWA app load and network/function calls. Turned off temporarily as we have notices it
// contributes to a lot of lagging.

let analytics: firebase.analytics.Analytics | undefined;
if (
  process.env["REACT_APP_ENV"] === "prod" &&
  process.env["REACT_APP_PROJECT_ID"] === "ikea-coworker-app-prod" // Non-EU prod environments are handled via custom Google Analytics integration
) {
  firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) {
      analytics = firebase.analytics(); // Enable analytics and collecting of events
    }
  });
}

export { firebase, analytics };
export default firebase;
