import React, { useMemo, useState } from "react";
import { useTranslation } from "@coworker/locales";
import { useParams } from "react-router";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { fullRoomName } from "../tools";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import GearIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/GearIconPath";
import { useRoomById } from "../hooks/useRoomById";
import { RoomViewSkeleton } from "../Skeletons/RoomViewSkeleton";
import { useImagesByRoomId } from "../hooks/useImagesByRoomId";
import { RoomImagesCarousel } from "../Molecules/RoomImagesCarousel";
import { RoomTabs } from "../Organisms/RoomTabs";
import { ModalTypes } from "../types/views";
import { FullWidthButton } from "../../SkapaButton";
import { PleaseFillIn } from "../../Reusable/PleaseFillIn";
import { ArticleIdNbrArticles, Articles } from "../types/article";
import { RoomArticlesModals } from "../Organisms/Modals/RoomArticlesModals";
import { Group } from "../types/groups";
import { useInputPopup } from "../../InputPopup";
import { useQueryClient } from "@tanstack/react-query";
import { useGroupsByRoomId } from "../hooks/useGroupsByRoomId";
import { useArticlesByRoomId } from "../hooks/useArticlesByRoomId";
import { UpdateSettingsModal } from "../Organisms/Modals/UpdateSettingsModal";
import { QueryKeys } from "../hooks/queryKeys";
import { TabIds, TabQueryKey } from "../Organisms/RoomTabs.helper";
import { useSearchParams } from "react-router-dom";
import { generateLivligProductsLink } from "@coworker/reusable";
import { LivligLinkBox } from "../../LivligLinkBox";
import { sortArticlesByItemName } from "../../../helpers/sorters";
import featureNames from "@coworker/enums/featureNames";

import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";

export const TabbedRoomView = () => {
  const { roomId } = useParams();
  const { t } = useTranslation();
  const [queryParams] = useSearchParams();
  const tabId = queryParams.get(TabQueryKey);
  const { getInput } = useInputPopup();
  const queryClient = useQueryClient();

  const { data: room } = useRoomById(roomId || "");
  const { data: roomImages } = useImagesByRoomId(roomId || "");
  const { data: groups } = useGroupsByRoomId(roomId ?? "");

  const { data: activeArticles } = useArticlesByRoomId(
    room?.id || "",
    "ACTIVE"
  );

  const [modalVisibleByType, setModalVisibleByType] = useState<ModalTypes>(
    ModalTypes.DEFAULT_VIEW
  );
  const [currentTab, setCurrentTab] = useState<string>(
    tabId === TabIds.GROUP ? TabIds.GROUP : TabIds.LIST
  );
  const [selectedArticles, setSelectedArticles] = useState<Articles>([]);
  const [isSelectArticlesMode, setIsSelectArticlesMode] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [showModal, setShowModal] = useState(false);

  const selectedArticleIdsToArticles = (
    selectedArticleIdsNbArticles: ArticleIdNbrArticles[]
  ) => {
    return !activeArticles || !selectedArticleIdsNbArticles
      ? []
      : (selectedArticleIdsNbArticles
          .map((item) => {
            const foundArticle = activeArticles.find(
              (article) => article.id === item.articleId
            );
            if (foundArticle) foundArticle.newNbrArticles = item.nbrArticles;
            return foundArticle;
          })
          .filter((article) => !!article) as Articles);
  };

  let fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[] = () => [];

  const appBarActions: AppBarAction[] = [
    {
      name: "settings",
      icon: <FixaSSRIcon paths={GearIconPath} />,
      onClick: () => {
        setShowModal(true);
      },
      position: "right",
    },
  ];

  const roomHeader = fullRoomName(
    room?.name,
    room?.main_article_name_1,
    room?.main_article_name_2
  );

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("roomSettingsString"),
        to: "/roomsettings",
      },
    ];
  }, [t]);

  const linkbar = <Linkbar items={linkbarItems} currentLabel={roomHeader} />;

  if (!room) {
    return (
      <FullScreenPopup
        appBarConfig={{
          title: roomHeader,
          actions: appBarActions,
        }}
        noPadding
        linkbar={linkbar}
      >
        <RoomViewSkeleton />
      </FullScreenPopup>
    );
  }

  return (
    <FullScreenPopup
      isActionBarHiddenOnScroll={false}
      actionBarContent={
        <>
          {currentTab === TabIds.GROUP && (
            <FullWidthButton
              text={t("createGroupString")}
              onClick={() => setModalVisibleByType(ModalTypes.CREATE_GROUP)}
              type={"primary"}
            />
          )}
          {(!currentTab || currentTab === TabIds.LIST) &&
            !isSelectArticlesMode && (
              <FullWidthButton
                text={t("addArticlesString")}
                onClick={async () => {
                  await getInput("addMultipleArticles", {
                    roomId,
                    groupId: "",
                  });
                  await queryClient.invalidateQueries([
                    QueryKeys.ArticlesByRoom,
                  ]);
                }}
                type={"primary"}
              />
            )}
          {(!currentTab || currentTab === TabIds.LIST) &&
            isSelectArticlesMode && (
              <>
                <PleaseFillIn
                  show={!!warningText}
                  alternateWarning={warningText}
                  centerText={true}
                />
                <FullWidthButton
                  text={t("continue2String")}
                  onClick={async () => {
                    const selectedArticleIds =
                      fetchSelectedArticleIdsFunction();
                    if (selectedArticleIds.length > 0) {
                      setModalVisibleByType(
                        ModalTypes.SELECTED_ARTICLES_ACTIONS
                      );
                      setSelectedArticles(
                        selectedArticleIdsToArticles(selectedArticleIds)
                      );
                    } else {
                      setWarningText(t("selectArticlesToContinueString"));
                    }
                  }}
                  type={"primary"}
                />
              </>
            )}
        </>
      }
      appBarConfig={{
        title: roomHeader,
        actions: appBarActions,
      }}
      noPadding
      fullHeightContent
      linkbar={linkbar}
    >
      <>
        {roomImages && roomImages.length > 0 ? (
          <RoomImagesCarousel room={room} />
        ) : (
          <div style={{ position: "relative", display: "inline-block" }} />
        )}
        {activeArticles && activeArticles.length > 0 && (
          <LivligLinkBox
            storeId={room.store_id}
            shortIds={activeArticles
              .sort(sortArticlesByItemName)
              .map((article) => article.productArticleId)
              .join(",")}
            generateLivligLinkFunc={generateLivligProductsLink}
            feature={featureNames.MEDIA}
            titleKey={"livligViewInString"}
            descriptionKey={"livligSalesPerformanceOfArticlesString"}
            buttonTextKey={"livligViewInString"}
          />
        )}

        <RoomTabs
          room={room}
          activeArticles={activeArticles || []}
          isSelectArticlesMode={isSelectArticlesMode}
          getSelectedArticleIds={(
            fetchFunction: () => ArticleIdNbrArticles[]
          ) => {
            fetchSelectedArticleIdsFunction = fetchFunction;
          }}
          setSelectArticleMode={() => {
            setIsSelectArticlesMode(!isSelectArticlesMode);
            setWarningText("");
          }}
          allArticlesInRoom={activeArticles || []}
          onCurrentTabIdChange={(tab: string) => {
            setCurrentTab(tab);
            setIsSelectArticlesMode(false);
            const newUrl = `${window.location.origin}${window.location.pathname}?${TabQueryKey}=${tab}`;
            window.history.replaceState({}, "", newUrl);
          }}
          currentTabId={currentTab}
          groups={groups || []}
        />
        {modalVisibleByType !== ModalTypes.DEFAULT_VIEW && (
          <RoomArticlesModals
            selectedArticles={selectedArticles}
            allArticles={activeArticles || []}
            currentModalType={modalVisibleByType}
            onModalTypeChange={(modalType: ModalTypes) => {
              if (modalType === ModalTypes.DEFAULT_VIEW) {
                setIsSelectArticlesMode(false);
                setSelectedArticles([]);
              }
              setWarningText("");
              setModalVisibleByType(modalType);
            }}
            groups={groups || []}
            storeId={room.store_id}
            roomId={room.id ?? ""}
            currentGroup={{} as Group}
          />
        )}
      </>
      <UpdateSettingsModal
        roomId={roomId || ""}
        isVisible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </FullScreenPopup>
  );
};
