import React from "react";
import { SettingsDefault, SettingsResponse, ToolsCount } from "../Types";
import { useFetchShoppingtoolsSettings } from "../Hooks/useFetchShoppingtoolsSettings";
import { QuantitySteppersSkeleton } from "../Components/Skeletons/QuantitySteppersSkeleton";
import { findSettingsObject, isMaxToolsReachedCashline } from "../helper";
import {
  DIVISION_HASH,
  MAX_CASHLINE_TOOLS,
  typesOfShoppingToolsList,
} from "../constants";
import { SettingsKeyType } from "../Components/SettingsListView";
import { CardContainer } from "../Showroom/ShowroomQuantitySteppers";
import { CashlineQuantityCard } from "./CashlineQuantityCard";
import { InlineMessageWrapper } from "../Showroom/ShowroomQuantityCard";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import { useTranslation } from "@coworker/locales";

interface CashlineQuantityStepperProps {
  lastShoppingTools: ToolsCount;
  setLastShoppingTools: React.Dispatch<React.SetStateAction<ToolsCount>>;
}

export const CashlineQuantitySteppers = ({
  lastShoppingTools,
  setLastShoppingTools,
}: CashlineQuantityStepperProps) => {
  const { data: settingsList, isFetching } = useFetchShoppingtoolsSettings();

  const { t } = useTranslation();

  if (isFetching) {
    return <QuantitySteppersSkeleton />;
  }

  if (!settingsList) return null;

  const activeSettings: SettingsDefault | SettingsResponse = findSettingsObject(
    settingsList,
    DIVISION_HASH.CASHLINE?.division ?? ""
  )!;

  const foundTools = Object.keys(activeSettings ?? []).filter(
    (key) => (activeSettings as SettingsKeyType)[key] === true
  );
  const settings = typesOfShoppingToolsList().filter((setting) =>
    foundTools.includes(setting.name)
  );

  const hideMaxAmountReachedMessage = isMaxToolsReachedCashline(
    lastShoppingTools,
    MAX_CASHLINE_TOOLS
  );

  return (
    <CardContainer>
      {settings.map((type) => {
        return (
          <CashlineQuantityCard
            key={type.name}
            type={type}
            lastShoppingTools={lastShoppingTools}
            setLastShoppingTools={setLastShoppingTools}
            isWarningShown={hideMaxAmountReachedMessage}
          />
        );
      })}
      {!hideMaxAmountReachedMessage && (
        <InlineMessageWrapper>
          <FixaInlineMessage
            variant="cautionary"
            subtle
            body={t("maxToolAmountReachedString")}
          />
        </InlineMessageWrapper>
      )}
    </CardContainer>
  );
};
