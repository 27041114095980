import React from "react";
import { Trans } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { AddRepeatable } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";
import { recurringTaskOptions } from "@coworker/enums/recurringTaskOptions";
import { useFormatRecurring } from "../../../hooks/useFormatRecurring";

export default function TaskFormRepeatable({ value, onChange, taskId }) {
  const [repeatableFrequency, setRepeatableFrequency] = React.useState("none");
  const { getInput } = useInputPopup();

  const getRepeatable = async () => {
    const data = await getInput("repeatable", {
      initialValue: repeatableFrequency,
      title: <Trans>setString</Trans>,
    });

    if (data) {
      if (data.frequency === recurringTaskOptions.NONE) {
        setRepeatableFrequency(recurringTaskOptions.NONE);
      } else {
        setRepeatableFrequency(data.name);
      }
    }

    if (data) {
      if (data.frequency === recurringTaskOptions.NONE) {
        onChange(null);
      } else if (data.frequency === recurringTaskOptions.DAILY) {
        onChange({
          frequency: data.frequency,
          timezone: data.timezone,
          name: data.name,
        });
      } else if (data.frequency === recurringTaskOptions.WEEKLY) {
        onChange({
          frequency: data.frequency,
          day_of_week: data.day_of_week,
          timezone: data.timezone,
          name: data.name,
        });
      } else if (data.frequency === recurringTaskOptions.MONTHLY) {
        onChange({
          frequency: data.frequency,
          day_of_week: data.day_of_week,
          week_of_month: data.week_of_month,
          timezone: data.timezone,
          name: data.name,
        });
      }
    }
  };

  const { formatRecurring } = useFormatRecurring();
  const name = value ? (
    formatRecurring(value.frequency, value).name
  ) : (
    <Trans>setString</Trans>
  );

  if (
    !taskId ||
    (taskId &&
      (value?.frequency ?? recurringTaskOptions.PLANNED) ===
        recurringTaskOptions.PLANNED)
  ) {
    return null; // Hide the v1 widget, since this is a Recurring v2 type of task.
  }

  return (
    <Widget
      onClick={getRepeatable}
      uneditable={taskId}
      data-testid="repeatableWidget"
    >
      <AddRepeatable
        repeatableString={<Trans>repeatableString</Trans>}
        deactivated={!value || value === recurringTaskOptions.NONE}
        value={name}
        none={value ? value === recurringTaskOptions.NONE : false}
        optional={<Trans>optionalString</Trans>}
      />
    </Widget>
  );
}
