import React from "react";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { Group, Groups } from "../../types/groups";
import { ModalTypes } from "../../types/views";
import { useTranslation } from "@coworker/locales";
import { FullWidthButton } from "../../../SkapaButton";
import { addGroup } from "../../services/groups.service";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import InformationCircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/InformationCircleIconPath";
import { Articles } from "../../types/article";
import { PleaseFillIn } from "../../../Reusable/PleaseFillIn";
import { InputPopupContainer } from "../../styles/styles";
import { useQueryClient } from "@tanstack/react-query";
import { addUniqueArticlesToGroup } from "./GroupModal.helper";
import { logEvent } from "../../../../helpers/tracker";
import { useToastNotification } from "@coworker/components";
import { QueryKeys } from "../../hooks/queryKeys";

interface CreateGroupModalProps {
  modalVisibility: boolean;
  roomId: string;
  storeId: string;
  groups: Groups;
  selectedArticles: Articles;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export function CreateGroupModal({
  modalVisibility,
  roomId,
  storeId,
  groups,
  selectedArticles,
  onTriggerNewModal,
}: CreateGroupModalProps) {
  const { showToast } = useToastNotification();
  const [newGroupName, setNewGroupName] = React.useState<string>("");
  const [isDuplicateGroupName, setIsDuplicateGroupName] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return (
    <FixaModal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.KEEP_SELECTION);
      }}
    >
      <FixaSheets
        labelledById="create-group-modal"
        size={"small"}
        header={
          <FixaModalHeader
            ariaCloseTxt="Close"
            title={`${t("createGroupString")}`}
          />
        }
        footer={
          <FixaModalFooter>
            <FullWidthButton
              disabled={isDuplicateGroupName || newGroupName === ""}
              text={t("createGroupString")}
              type={"primary"}
              loading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                const newGroup = {
                  name: newGroupName.trim(),
                  description: "",
                  roomId,
                  storeId,
                } as Group;
                const newGroupId = await addGroup(newGroup);
                if (selectedArticles.length > 0) {
                  await addUniqueArticlesToGroup(
                    selectedArticles,
                    newGroupId || ""
                  );
                }

                showToast(t("groupCreatedString"));
                groups.push(newGroup);
                logEvent("ce:articles_selected_creategroup");
                setNewGroupName("");
                setIsDuplicateGroupName(false);
                setIsLoading(false);
                await queryClient.invalidateQueries([
                  QueryKeys.GroupsByRoom,
                  roomId,
                ]);
                await queryClient.invalidateQueries([QueryKeys.ArticlesByRoom]);
                onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
              }}
              data
            />
          </FixaModalFooter>
        }
      >
        <InputPopupContainer>
          <FixaInputField
            id={"newGroupName"}
            type={"text"}
            label={t("nameOfGroupString")}
            value={newGroupName}
            onChange={(event) => {
              const newName = event.target.value as string;
              const isDuplicate = groups.some(
                (group) => group.name.trim() === event.target.value.trim()
              );
              setIsDuplicateGroupName(isDuplicate);
              setNewGroupName(newName);
            }}
          />
          {isDuplicateGroupName && (
            <PleaseFillIn
              show={isDuplicateGroupName}
              alternateWarning={t("duplicateNameString")}
            />
          )}
          {selectedArticles.length > 0 && (
            <FixaInlineMessage
              title={t("whatsNextString")}
              body={t("addToGroupClarificationString")}
              variant={"informative"}
              ssrIcon={InformationCircleIconPath}
              dismissable
            />
          )}
        </InputPopupContainer>
      </FixaSheets>
    </FixaModal>
  );
}
