import React from "react";
import styled from "styled-components";
import { TaskLinks } from "../types/taskLink";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import ChevronRightIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronRightIconPath";
import { useTranslation } from "@coworker/locales";
import { RoomListItemCardDesktop } from "../styles/styles";

const RoomListItemAnchorDesktop = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const TitleAndTaskTextContainer = styled.div``;

const TitleText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: black;
  line-height: 24px;
  margin-top: 16px;
  margin-left: 16px;
`;

const TaskText = styled.div`
  font-size: 108px;
  font-weight: 700;
  color: black;
  line-height: 142px;
  margin-left: 16px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 16px;
`;

interface RoomListItemDesktopProps {
  tasks: TaskLinks;
  onClick: () => void;
}

export function RoomListItemOngoingTasksDesktop({
  tasks,
  onClick,
}: RoomListItemDesktopProps) {
  const { t } = useTranslation();
  return (
    <RoomListItemCardDesktop active={false}>
      <RoomListItemAnchorDesktop
        href={`/roomsettings/tasks`}
        onClick={(event) => {
          event.preventDefault();
          onClick();
        }}
      >
        <TitleAndTaskTextContainer>
          <TitleText>{t("ongoingTasksString")} </TitleText>
          <TaskText>{tasks.length}</TaskText>
        </TitleAndTaskTextContainer>
        <ButtonWrapper>
          <FixaButton iconOnly={true} ssrIcon={ChevronRightIconPath} />
        </ButtonWrapper>
      </RoomListItemAnchorDesktop>
    </RoomListItemCardDesktop>
  );
}
