import React from "react";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import SkapaTabs from "../InputPopup/SkapaTabs";
import { nowAsISOWithOffsetInDays } from "../../helpers/dates";
import dayjs from "dayjs";
import styles from "./insights.module.css";

function tabToUrlParams(
  tabKey: string,
  urlParams: {
    "ds0.store": any;
    "ds0.start_date": string;
    "ds0.end_date": string;
    "ds1.store": any;
    "ds1.start_date": string;
    "ds1.end_date": string;
  }
) {
  let today = dayjs(Date.now()).format("YYYY-MM-DD");
  let yesterday = dayjs(nowAsISOWithOffsetInDays(String(-1))).format(
    "YYYY-MM-DD"
  );
  let beforeSevenDays = dayjs(nowAsISOWithOffsetInDays(String(-8))).format(
    "YYYY-MM-DD"
  );
  let beforeFourWeeks = dayjs(nowAsISOWithOffsetInDays(String(-29))).format(
    "YYYY-MM-DD"
  );
  switch (tabKey) {
    case "yesterday":
      urlParams["ds0.start_date"] = yesterday;
      urlParams["ds0.end_date"] = today;
      urlParams["ds1.start_date"] = yesterday;
      urlParams["ds1.end_date"] = today;
      break;
    case "last_7_days":
      urlParams["ds0.start_date"] = beforeSevenDays;
      urlParams["ds0.end_date"] = today;
      urlParams["ds1.start_date"] = beforeSevenDays;
      urlParams["ds1.end_date"] = today;
      break;
    case "last_4_weeks":
      urlParams["ds0.start_date"] = beforeFourWeeks;
      urlParams["ds0.end_date"] = today;
      urlParams["ds1.start_date"] = beforeFourWeeks;
      urlParams["ds1.end_date"] = today;
      break;
    default:
      urlParams["ds0.start_date"] = yesterday;
      urlParams["ds0.end_date"] = today;
      urlParams["ds1.start_date"] = yesterday;
      urlParams["ds1.end_date"] = today;
  }
  return urlParams;
}

const ReportFilter = () => {
  let store_id = useStoreId();
  if (process.env["REACT_APP_ENV"] !== "prod") {
    store_id = "445";
  }
  let start_date;
  start_date = "2021-01-01";
  let end_date;
  end_date = "2021-12-31";

  const urlParams = {
    "ds0.store": store_id,
    "ds0.start_date": start_date,
    "ds0.end_date": end_date,
    "ds1.store": store_id,
    "ds1.start_date": start_date,
    "ds1.end_date": end_date,
  };

  const [selectedTab, setSelectedTab] = React.useState("yesterday");

  const tabChangedHandler = (tabKey: string) => {
    setSelectedTab(tabKey);
  };

  const tabs = [
    {
      title: (
        <>
          {/*<Trans>pickupLocation</Trans>{" "}*/}
          Yesterday
        </>
      ),
      key: "yesterday",
    },
    {
      title: (
        <>
          Last 7 days
          {/*<Trans>refillLocString</Trans>{" "}*/}
        </>
      ),
      key: "last_7_days",
    },
    {
      title: (
        <>
          Last 4 weeks
          {/*<Trans>refillLocString</Trans>{" "}*/}
        </>
      ),
      key: "last_4_weeks",
    },
  ];

  let i;

  function loadLookerReport(tabKey: string) {
    console.log(tabKey);
    tabToUrlParams(tabKey, urlParams);
    console.log(urlParams);
    const reportUrl =
      "https://lookerstudio.google.com/embed/reporting/650b87cd-ab95-4497-acaf-fbb0a0ac56f3/page/I6iYE?params=" +
      encodeURIComponent(JSON.stringify(tabToUrlParams(tabKey, urlParams)));
    return (
      <iframe
        title="Insights"
        width={window.innerWidth}
        height={window.innerHeight * 0.6}
        src={reportUrl}
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    );
  }
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <div className={styles["date-filter-tabs"]}>
      <SkapaTabs
        tabs={tabs}
        active="active"
        onTabChanged={tabChangedHandler}
        noTabPanelPadding
      />

      <div key={i} className={styles["date-content-divider"]}>
        {loadLookerReport(selectedTab)}
      </div>
    </div>
  );
};

export default ReportFilter;
