import { ActivityFilterType } from "../types/filterOptionsTypes";

export function getLocationFilterSet(
  panelFilters: ActivityFilterType,
  addonLocationType: string,
  isAddonTask: boolean
) {
  if (isAddonTask) {
    return addonLocationType === "pickup"
      ? panelFilters.pickupLocations || []
      : panelFilters.refillLocations || [];
  }
  return panelFilters.locations || [];
}
