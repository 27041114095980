import React, { useState } from "react";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import FiltersIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FiltersIconPath";
import { DropdownPill } from "./pills/DropdownPill";
import styles from "./activity-filters.module.css";
import { SelectablePill } from "./pills/SelectablePill";
import { dropdownPills, selectablePills } from "../../constants/filterPills";
import { ActivityFiltersPanel } from "./panels/filters/ActivityFiltersPanel";
import { Trans } from "@coworker/locales";
import { ActivityFilterType } from "../../types/filterOptionsTypes";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { Task } from "@coworker/types/lib/tasks/base";
import {
  getGrouppedFilterOptions,
  FilterOptionType,
} from "../../constants/filterOptions";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import StackIconPath from "../../../shared/wrappers/FixaSSRIcon/paths/StackIconPath";
import FixaSSRIcon from "../../../shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import { MultiSelectPanel } from "./panels/multi-select/MultiSelectPanel";

interface ActivityFiltersProps {
  filters: ActivityFilterType;
  setFilters: React.Dispatch<React.SetStateAction<ActivityFilterType>>;
  appliedFiltersCount: number;
  allTasks: Task[];
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const ActivityFilters = ({
  filters,
  setFilters,
  appliedFiltersCount,
  allTasks,
  setActiveTab,
}: ActivityFiltersProps) => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const [isMultiSelectPanelOpen, setIsMultiSelectPanelOpen] =
    useState<boolean>(false);
  const [activeDropdownPill, setActiveDropdownPill] = useState<String>("");

  const usersFixaUid = useFixaUID();

  const handleFiltersSet = (newFilters: ActivityFilterType) => {
    setActiveDropdownPill("");
    if (newFilters.assignedTo === "me") {
      newFilters.assignedTo = usersFixaUid;
    }

    if (newFilters.createdBy === "me") {
      newFilters.createdBy = usersFixaUid;
    }

    setFilters(newFilters);
  };

  const teams = useStoreTeams();

  const filtersAbsentInDropdownPills = ["locations"];

  return (
    <div className={styles["filters-container"]}>
      <div
        className={styles["stack-icon-wrapper"]}
        onClick={() => setIsMultiSelectPanelOpen(true)}
      >
        <FixaSSRIcon paths={StackIconPath} />
      </div>
      <MultiSelectPanel
        isMultiSelectPanelOpen={isMultiSelectPanelOpen}
        setIsMultiSelectPanelOpen={setIsMultiSelectPanelOpen}
      />
      <div className={styles["separator"]} />
      <FixaButton
        type="primary"
        ssrIcon={FiltersIconPath}
        text={
          <>
            <Trans>allFiltersString</Trans>{" "}
            {appliedFiltersCount > 0 && `(${appliedFiltersCount})`}
          </>
        }
        size="small"
        onClick={() => {
          trackerHelper.trackActivityAllFiltersButtonClick();
          setIsFilterPanelOpen(true);
        }}
      />
      <ActivityFiltersPanel
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        filters={filters}
        setFilters={handleFiltersSet}
        allTasks={allTasks}
      />
      {selectablePills.map((pill) => (
        <SelectablePill
          key={pill.id}
          id={pill.id}
          label={pill.transKey}
          filters={filters}
          type={pill.type}
          setFilters={handleFiltersSet}
          setActiveTab={setActiveTab}
        />
      ))}
      {dropdownPills.map((pill) => {
        if (filtersAbsentInDropdownPills.includes(pill.id)) {
          return null;
        }
        const options: FilterOptionType[] = [];
        const additionalPillAttributes: Record<string, unknown> = {}; // this is for spreading attributes for the Skapa Pill which not every Pill uses (eg. loding for locations)
        if (pill.id === "assignedTo" || pill.id === "createdBy") {
          options.push(...teams);
        } else {
          options.push(...getGrouppedFilterOptions()[pill.id]);
        }

        if (options.length === 0) {
          additionalPillAttributes["disabled"] = true;
        }

        if (!filters.taskType && pill.id === "locations") {
          additionalPillAttributes["disabled"] = true;
        }

        return (
          <DropdownPill
            key={pill.id}
            id={pill.id}
            label={pill.transKey}
            isOpen={activeDropdownPill === pill.transKey}
            onClick={() =>
              setActiveDropdownPill(
                activeDropdownPill !== pill.transKey ? pill.transKey : ""
              )
            }
            filters={filters}
            setFilters={handleFiltersSet}
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            closeDropdown={() => setActiveDropdownPill("")}
            options={getGrouppedFilterOptions(options)[pill.id]}
            additionalPillAttributes={additionalPillAttributes}
          />
        );
      })}
    </div>
  );
};
