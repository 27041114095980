import { extractBUfromFixaStoreId } from "./store.helper";
const CIA_ID = "01b0da2f-d4a0-444b-be7b-28cb4c740db1";
export const headersCIAv2 = { "x-client-id": CIA_ID, Accept: "*/*;version=2" };
const CIA_API_BASE_URL = "https://api.ingka.ikea.com/cia";

// Parse the response safely without crashing (since .json() on a response is not safe to just try and .catch)

export async function parseJson(response: any) {
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch (err) {
    throw new Error("Did not receive JSON, instead received: " + text);
  }
}

/**
 * @param {string} path The path within the CIA API.
 * @param {object} queryParams
 * @returns {string}
 */
export function makeCIAurl(
  path: string,
  queryParams: Record<string, string | undefined> = {}
): URL {
  return Object.keys(queryParams).reduce((url, key) => {
    const value = queryParams[key];
    if (value !== undefined) {
      url.searchParams.append(key, value);
    }
    return url;
  }, new URL(`${CIA_API_BASE_URL}/${path}`));
}

/**
 * @param {string} path The path within the CIA API.
 * @param {object} queryParams Must include `itemNos`. Can include `expand`
 * @returns {string}
 */
export function makeAvailabilitiesCIAurl(store: string, queryParams: any) {
  if (!queryParams.itemNos?.length) return ""; // This endpoint returns error 422 if no item is included.
  return makeCIAurl(
    `availabilities/sto/${extractBUfromFixaStoreId(store)}`,
    queryParams
  );
}

/**
 * @param {object} data A response from the CIA endpoint. Handles both v1 and v2.
 */
export function extractDelivery(data: any) {
  const { restocks } =
    data?.data?.[0]?.availableStocks?.[0] ||
    data?.availabilities?.[0]?.buyingOption?.cashCarry?.availability ||
    {};
  return restocks?.find(({ type }: any) => type === "DELIVERY") || null;
}
