import React from "react";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { Trans, useTranslation } from "@coworker/locales";
import { ModalTypes } from "../../types/views";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import {
  LeftContainer,
  ListItemContainer,
  RightContainer,
  SizedText,
  TextStyle,
} from "../../styles/styles";
import { useInputPopup } from "../../../InputPopup";
import { Group } from "../../types/groups";
import { logEvent } from "../../../../helpers/tracker";

import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon"; // NOSONAR
import TrashCanIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TrashCanIconPath"; // NOSONAR

interface GroupActionsModalProps {
  modalVisibility: boolean;
  roomId: string;
  currentGroup: Group | undefined;
  onTriggerNewModal: (modalType: ModalTypes) => void;
  newGroupEditModal?: boolean;
}

export const GroupActionsModal = ({
  modalVisibility,
  roomId,
  currentGroup,
  onTriggerNewModal,
  newGroupEditModal = false,
}: GroupActionsModalProps) => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();

  const actionMenuChoice = (
    text: string,
    modalType: ModalTypes,
    disabled: boolean,
    onClickPreMethod: () => Promise<void> = async () => {}
  ) => {
    return (
      <FixaListViewItem
        onClick={() => {
          if (!disabled) onTriggerNewModal(modalType);
        }}
        title={
          <SizedText
            style={{ margin: "0px 24px" }}
            $fontSize={16}
            $bold={!disabled}
            onClick={async () => {
              onTriggerNewModal(modalType);
              await onClickPreMethod();
            }}
          >
            <Trans>{text}</Trans>
          </SizedText>
        }
        emphasised
        size="small"
      />
    );
  };
  return (
    <FixaModal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
      }}
    >
      <FixaSheets
        labelledById="groupActions"
        size={"small"}
        header={
          <FixaModalHeader
            ariaCloseTxt="Close"
            title={`${t("editGroupString")}`}
          />
        }
        footer={
          <FixaModalFooter>
            <></>
          </FixaModalFooter>
        }
      >
        <FixaListView id="groupActionsId" size="small">
          {!newGroupEditModal &&
            actionMenuChoice(
              "createPTagPrintListString",
              ModalTypes.PTAG_GROUP_ARTICLE_NUMBERS,
              false,
              async () => logEvent("ce:edit_group_copyarticlenumbers")
            )}
          {actionMenuChoice(
            "editGroupNameString",
            ModalTypes.EDIT_GROUP_NAME,
            false,
            async () => logEvent("ce:edit_group_editname")
          )}
          {!newGroupEditModal &&
            actionMenuChoice(
              "addArticlesToGroupString",
              ModalTypes.DEFAULT_VIEW,
              false,
              async () => {
                if (!roomId || !currentGroup) return;
                await getInput("addMultipleArticles", {
                  roomId: roomId,
                  groupId: currentGroup.id,
                });
                logEvent("ce:edit_group_addarticles");
              }
            )}
        </FixaListView>
        <ListItemContainer
          onClick={() => {
            onTriggerNewModal(ModalTypes.DELETE_GROUP);
            logEvent("ce:edit_group_deletegroup");
          }}
        >
          <LeftContainer>
            <div style={{ justifyContent: "left" }}>
              <TextStyle
                $bold={true}
                $italic={false}
                $size={"medium"}
                $color="pink"
              >
                <Trans>deleteGroupString</Trans>
              </TextStyle>
              <TextStyle
                $bold={false}
                $italic={false}
                $size={"medium"}
                $color="pink"
              >
                <Trans>deleteGroupAndAllArticlesString</Trans>
              </TextStyle>
            </div>
          </LeftContainer>
          <RightContainer>
            <FixaSSRIcon paths={TrashCanIconPath} color="var(--pink)" />
          </RightContainer>
        </ListItemContainer>
      </FixaSheets>
    </FixaModal>
  );
};
