import { useStoreUsers } from "../../hooks/useStoreContacts";
import { Trans } from "@coworker/locales";
import { actorTypeKeys } from "@coworker/enums/actorTypes";
import statusTypes from "@coworker/enums/statusTypes";
import { useStoreTeams } from "../hooks/useStoreTeams";

export function UserOrGroupName({ uid, gid }) {
  const users = useStoreUsers();
  const user = users.find((user) => user.fixa_uid === uid || user.uid === uid);
  const teams = useStoreTeams();
  const team = teams.find((team) => team.id === gid);

  if (actorTypeKeys[uid]) return <Trans>{actorTypeKeys[uid]}</Trans>;

  if (uid) {
    const { given_name, surname, name, status } = user || {};
    if (status === statusTypes.DELETED) return <Trans>deletedUserString</Trans>;
    return name || given_name || surname || <Trans>unknownUserString</Trans>;
  }

  return team?.name || "";
}
