import { groupByTypes, taskGroupTypes } from "../constants/groupBy";
import { getLocationGridCode, getPickupGridCode } from "./taskHelper";
import taskTypeOptions from "@coworker/enums/taskType";
import { taskFilters } from "../constants/filters";
import { groupByFunc } from "./utils";

const sortOrder = [
  taskGroupTypes.PRODUCT_QUALITY,
  taskGroupTypes.ADDON,
  taskGroupTypes.REFILL,
  taskGroupTypes.PRODUCT_ISSUE,
  taskGroupTypes.CUSTOM,
  taskGroupTypes.PALLET,
  taskGroupTypes.MULTI_PACK,
  taskGroupTypes.PIECES,
  taskGroupTypes.NO_GROUPING,
  "undefined",
];

export const showGroupTaskFilter = (filters) => {
  return filters.includes(taskFilters.ALL);
};

export const showGroupPackageFilter = (filters) => {
  return filters.some((e) =>
    [taskFilters.ALL, taskFilters.PICK_AND_REFILL, taskFilters.REFILL].includes(
      e
    )
  );
};

export const showGroupGridCodeFilter = (filters) => {
  return !filters.includes(taskFilters.PRODUCT_QUALITY);
};

export const showGroupByFilters = (filters) => {
  return (
    showGroupTaskFilter(filters) ||
    showGroupPackageFilter(filters) ||
    showGroupGridCodeFilter(filters)
  );
};

export const createTaskGroupList = (tasks, groupByOption, addonFilter) => {
  if (
    !groupByOption ||
    groupByOption === groupByTypes.NO_GROUPING ||
    window.location.pathname.includes("/activity") ||
    window.location.pathname.includes("/tasks/related")
  ) {
    return tasks;
  }

  const groupedCollection = groupByFunc(tasks, (value) => {
    let groupValue = value[groupByOption]?.toUpperCase();

    if (groupByOption === groupByTypes.BY_TASK_TYPE && isRefillTask(value)) {
      groupValue = taskGroupTypes.REFILL;
    } else if (groupByOption === groupByTypes.BY_GRID_CODE) {
      groupValue =
        isRefillTask(value) ||
        (isAddonTask(value) && addonFilter === "pickingFilter")
          ? getPickupGridCode(value)?.toUpperCase()
          : getLocationGridCode(value)?.toUpperCase();
    }

    if (!groupValue) groupValue = "undefined";

    // This mutates the collection values which is very bad practice.
    value["group"] = groupValue;
    value["color"] = [
      groupByTypes.BY_PACKAGE_TYPE,
      groupByTypes.BY_TASK_TYPE,
    ].includes(groupByOption)
      ? getGroupColor(groupValue)
      : "";

    return groupValue;
  });

  const keys = Object.keys(groupedCollection);

  const groupedList = [];

  const sortedKeys = sortKeys(keys);

  sortedKeys.forEach((key, index) => {
    const numberOfTaskInGroup = groupedCollection[key].length;
    groupedList.push(
      {
        isGroupHeader: true,
        groupByTitle: key !== "undefined" ? key : groupByTypes.NO_GROUPING,
        numberOfTaskInGroup,
        groupByOption,
      },
      ...groupedCollection[key]
    );
    if (numberOfTaskInGroup > 0)
      groupedCollection[key][numberOfTaskInGroup - 1]["lastInGroup"] = true;
  });

  return groupedList;
};

function sortKeys(groupKeys) {
  const ordering = sortOrder.reduce(
    (prev, curr, index) => ({ ...prev, [curr]: index }),
    {}
  );

  return groupKeys.sort((a, b) => {
    return ordering[a] - ordering[b] || a.localeCompare(b);
  });
}

function isAddonTask(task) {
  return task.task_type === taskTypeOptions.ADDON;
}

function isRefillTask(task) {
  return isAddonTask(task) && !!task.picked;
}

function getGroupColor(group) {
  switch (group) {
    case taskGroupTypes.ADDON:
    case taskGroupTypes.PIECES:
      return "green";

    case taskGroupTypes.REFILL:
    case taskGroupTypes.MULTI_PACK:
      return "orange";

    case taskGroupTypes.PRODUCT_ISSUE:
    case taskGroupTypes.PALLET:
      return "pink";

    case taskGroupTypes.CUSTOM:
      return "light-blue";

    case taskGroupTypes.NO_GROUPING:
    default:
      return "";
  }
}
