export const taskTypeOptions = {
  PRODUCT_ISSUE: "PRODUCT_ISSUE",
  ADDON: "ADDON",
  CUSTOM: "CUSTOM",
  PLANNED: "PLANNED",
  MFAQ_FOLLOW_UP: "MFAQ_FOLLOW_UP",
  PRODUCT_QUALITY: "PRODUCT_QUALITY",
  TESTBUY_FOLLOW_UP: "TESTBUY_FOLLOW_UP",
  ROOM_SETTINGS: "ROOM_SETTINGS",
};

export default taskTypeOptions;
