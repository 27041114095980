const WAREHOUSE_AND_SELFSERVE_REGEX = /^(\d{4})(\d{2}).*/;
const isWarehouseOrSelfServeLocation = (location: string) => {
  const match = location.match(WAREHOUSE_AND_SELFSERVE_REGEX);
  const SLID = match && match[2] ? match[2] : "";
  const num = parseInt(SLID);
  if (num >= 20 && num % 10 === 0) {
    return "warehouse";
  } else if (num < 20) {
    return "selfServeFurnitureArea";
  } else return "";
};

type LocationCategoryKeys =
  | "showroom"
  | "markethall"
  | "markethallOpenTheWallet"
  | "showroomOpenTheWallet"
  | "departments"
  | "activityArea"
  | "selfServeFurnitureArea"
  | "cashline"
  | "asis"
  | "staircase"
  | "warehouse"
  | "other";

export const locationCategories: Array<keyof LocationCategory> = [
  "showroom",
  "markethall",
  "markethallOpenTheWallet",
  "showroomOpenTheWallet",
  "activityArea",
  "selfServeFurnitureArea",
  "cashline",
  "asis",
  "staircase",
  "warehouse",
  "other",
];

export const mfaqLocationCategories: Array<"departments" | "other"> = [
  "departments",
  "other",
];

export type LocationCategory = {
  [key in LocationCategoryKeys]: string[];
};

export function getLocationsByHfb(locations?: string[]) {
  let locationsByHfb: LocationCategory = {
    showroom: [],
    markethall: [],
    departments: [],
    markethallOpenTheWallet: [],
    showroomOpenTheWallet: [],
    warehouse: [],
    selfServeFurnitureArea: [],
    cashline: [],
    asis: [],
    activityArea: [],
    staircase: [],
    other: [],
  };

  if (!!locations?.length) {
    Object.keys(locationsByHfb).forEach((key) => {
      locationsByHfb[key as LocationCategoryKeys] = locations.filter(
        (location) => {
          switch (key) {
            case "showroom":
              return location.startsWith("S0");
            case "markethall":
              return location.startsWith("M1");
            case "departments":
              return location.startsWith("depts");
            case "warehouse":
            case "selfServeFurnitureArea":
              return isWarehouseOrSelfServeLocation(location) === key;
            case "markethallOpenTheWallet":
              return location.startsWith("MOW");
            case "showroomOpenTheWallet":
              return location.startsWith("SOW");
            case "cashline":
              return location.startsWith("OTCL");
            case "asis":
              return location.startsWith("AS");
            case "activityArea":
              return location.startsWith("AA");
            case "staircase":
              return location.startsWith("ST");
            case "other":
              return (
                !location.startsWith("S0") &&
                !location.startsWith("M1") &&
                !location.startsWith("depts") &&
                !location.startsWith("MOW") &&
                !location.startsWith("SOW") &&
                !location.startsWith("OTCL") &&
                !location.startsWith("AS") &&
                !location.startsWith("AA") &&
                !location.startsWith("ST") &&
                !["warehouse", "selfServeFurnitureArea"].includes(
                  isWarehouseOrSelfServeLocation(location)
                )
              );
            default:
              return false;
          }
        }
      );
    });
  }

  return locationsByHfb;
}

export const formatLocationName = function (
  t: (key?: string) => string,
  location: string
) {
  if (location.includes("depts")) {
    return `HFB ${location.split("=")[1]}`;
  } else if (location.includes("whole")) {
    return `${t("wholeStoreString")}`;
  } else if (location.includes("other")) {
    return location.split("=")[1] ?? "";
  } else {
    return t(location);
  }
};
