import { reportMessageToSentry } from "../hooks/useConfigureSentry";
import { callInternalApi } from "../hooks/API/useCallInternal";
import { firebase } from "@coworker/common/config/firebase";

export function updateUser(uid, user) {
  if (!uid || !user) {
    try {
      throw new Error("updateUser: empty id?");
    } catch (error) {
      console.log(error);
    }
    reportMessageToSentry("updateUser empty id!");
    return;
  }
  const formattedUser = {
    ...user,
    fixaUid: uid,
  };

  if (user.team_id) formattedUser["teamId"] = user.team_id;
  if (typeof user.phone !== "undefined")
    formattedUser["phoneNumber"] = user.phone;

  callInternalApi(
    `update/user?fixa_uid=${uid}`,
    formattedUser,
    false,
    false,
    "CORE_SERVICE"
  )
    .then((result) => {
      return result;
    })
    .catch((e) => console.log("error while updating user", e));
}

export async function logoutUser(trackUserEvent = true) {
  console.log("logoutUser");
  const { uid } = firebase.auth().currentUser || {};
  if (!uid) {
    return;
  }
  try {
    await firebase.auth().signOut();
  } catch (error) {
    console.error("Error when logging out", error);
  }
}
