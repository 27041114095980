import allPermissions from "@coworker/enums/roles";

export const permissionActions = {
  UPDATE_TEAMS_TASK: "UPDATE_TEAMS_TASK",
  CLOSE_TEAMS_TASK: "CLOSE_TEAMS_TASK",
  RELEASE_TEAMS_TASK: "RELEASE_TEAMS_TASK",
  CREATE_ADD_ON_TASK: "CREATE_ADD_ON_TASK",
  CREATE_CUSTOM_TASK: "CREATE_CUSTOM_TASK",
  CREATE_PRODUCT_ISSUE_TASK: "CREATE_PRODUCT_ISSUE_TASK",
};

export function canPerformAction(roleId: string, action: string) {
  const permissions = allPermissions[roleId as keyof typeof allPermissions];
  return !!permissions?.[action as keyof typeof permissions];
}
