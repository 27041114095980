import React from "react";
import { Trans } from "@coworker/locales";
import styled from "styled-components";
import taskStateOptions from "@coworker/enums/taskState";
import AddNote from "../../notes/AddNote";
import { createdAtAscending } from "@coworker/reusable/helpers/taskSorters";
import { DetailSection, DetailSectionLeft, SectionTitle } from "./_common";
import Note from "../../notes/Note";
import { useTaskNotes } from "../../../hooks/useTaskNotes";
import { useTaskActivities } from "../../../hooks/useTaskActivities";

const Notes = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const StyledDetailSection = styled(DetailSection)`
  border: none;
`;
function shouldDisplayAddNotes(task) {
  return [taskStateOptions.ASSIGNED].includes(task.state);
}

const uniqueReducer =
  (base = new Set()) =>
  (acc, task) => {
    if (base.has(task.id)) return acc;
    base.add(task.id);
    return acc.concat(task);
  };

function joinAndSort(notes, activities) {
  const list = [...(notes || []), ...(activities || [])];
  list.sort(createdAtAscending);
  // Reduce to unique to avoid duplicated notes/activities.
  return list.reduce(uniqueReducer(), []);
}

function Activity({ task, taskId }) {
  const notes = useTaskNotes(taskId);
  const activities = useTaskActivities(taskId);
  const notesAndActivities = React.useMemo(
    () => joinAndSort(notes, activities),
    [notes, activities]
  );
  const [localImages, setLocalImages] = React.useState([]);
  const [createdNote, setCreatedNote] = React.useState(null);

  return (
    <React.Fragment>
      {notesAndActivities.length > 0 && (
        <StyledDetailSection data-testid="historySection">
          <DetailSectionLeft>
            <SectionTitle data-testid="historyTitle">
              <Trans>historyString</Trans>
            </SectionTitle>
            <Notes>
              {notesAndActivities.map((note) => (
                <Note
                  key={note.id || note.text}
                  noteId={note.id}
                  note={note}
                  taskId={taskId}
                  localImages={localImages}
                />
              ))}
              {createdNote &&
                !notesAndActivities.some(
                  (note) =>
                    note.text === createdNote.text &&
                    note.creator_id === createdNote.creator_id
                ) && (
                  <Note
                    key={`created-${createdNote.text}`}
                    noteId={createdNote.id}
                    note={createdNote}
                    localImages={localImages}
                  />
                )}
            </Notes>
          </DetailSectionLeft>
        </StyledDetailSection>
      )}
      {shouldDisplayAddNotes(task) && (
        <AddNote
          taskId={taskId}
          setCreatedNote={setCreatedNote}
          setLocalImages={setLocalImages}
          localImages={localImages}
        />
      )}
    </React.Fragment>
  );
}

export default Activity;
