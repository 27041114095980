import React from "react";
import { Trans } from "@coworker/locales";
import taskStateOptions from "@coworker/enums/taskState";
import { useInputPopup } from "../../components/InputPopup";
import { updateTask } from "../../services/task.service";
import { useToastNotification } from "@coworker/components";
import { useWorkspacesState } from "../../hooks/useWorkspaces";
import { createNote } from "../../services/note.service";
import activityTypes from "@coworker/enums/activityTypes";
import trackerHelper from "../../helpers/tracker";
import useUndoneNotification from "../../components/useUndoneNotification";
import { useCloseTask } from "./useCloseTask";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { callInternalApi } from "../API/useCallInternal";
import { prepareToCelebrateWithAnAnimation } from "../../helpers/taskFilters";
import { useTaskRefresher } from "../useWatchedTasks";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";

export const useUndoCompleteWithChanges = ({
  task,
  userId,
  taskId,
  afterUndo,
}) => {
  const undoCurtain = useUndoneNotification();
  const refreshTask = useTaskRefresher();
  const storeId = useStoreId();

  const undoCompleteWithChanges = React.useCallback(
    async ({ created_note_id, action_id }) => {
      const data = {
        picked: task.picked || false,
        pickup_location: task.pickup_location,
        pickup_location_custom: task.pickup_location_custom,
        pickup_location_grid_code: task.pickup_location_grid_code,
        pickup_location_department: task.pickup_location_department,
        pick_quantity: task.picked ? task.pick_quantity : null,
        actual_pick_quantity: task.picked ? task.actual_pick_quantity : null,
        pick_type: task.picked ? task.pick_type : "",
        refilled_quantity: null,
        actual_refilled_quantity: null,
        refilled_type: "",
        last_editor_id: userId,
        assigned_user_id: task.assigned_user_id,
        assigned_team_id: task.assigned_team_id,
        state: task.state,
        undo_action_id: action_id || "",
        activity_type: activityTypes.UNDO_COMPLETE,
        task_type_detail: task.picked ? "REFILL" : "PICK_AND_REFILL",
        searchable_location:
          task.pickup_location_custom ?? task.pickup_location,
      };

      await undoCurtain(data, taskId);
      refreshTask(taskId, data, task);

      if (created_note_id) {
        await callInternalApi(
          `delete/note?id=${taskId}&noteId=${created_note_id}`,
          { store_id: storeId }
        );
      }
      afterUndo();
    },
    [undoCurtain, task, userId, taskId, afterUndo, refreshTask, storeId]
  );

  return {
    call: undoCompleteWithChanges,
  };
};

export const useCompleteWithChanges = (preparedForm, taskId) => {
  const { navigationState } = useWorkspacesState();
  const { showToastWithUndo } = useToastNotification();
  const date = new Date().getTime();
  const actionIdComplete = `complete-${date}`;
  const actionIdPicked = `picked-${date}`;
  const { getInput } = useInputPopup();
  const userId = useUserId();
  const storeId = useStoreId();
  const refreshTask = useTaskRefresher();
  const { toggleModal } = React.useContext(ModalContext);

  const taskBeforeChanging = React.useRef(preparedForm);
  const { call: undoCompleteWithChanges } = useUndoCompleteWithChanges({
    task: taskBeforeChanging.current,
    userId,
    taskId,
    afterUndo: () =>
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: taskId },
      }),
  });

  const { call: closeTask } = useCloseTask(preparedForm, taskId, {
    undo: true,
    afterUndo: () => {
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: taskId },
      });
    },
  });

  const completeWithChanges = React.useCallback(async () => {
    const {
      pick_quantity,
      actual_pick_quantity,
      pick_type,
      refilled_quantity,
      actual_refilled_quantity,
      refilled_type,
      note,
      pickup_location,
      pickup_location_custom,
      pickup_location_grid_code,
      pickup_location_department,
      undo_action_id = "",
      location,
    } = preparedForm;

    const taskUpdate = {
      pick_quantity,
      actual_pick_quantity,
      pick_type,
      pickup_location,
      pickup_location_custom,
      pickup_location_grid_code,
      pickup_location_department,
      refilled_quantity,
      actual_refilled_quantity,
      refilled_type,
      last_editor_id: userId,
      action_id: actionIdComplete,
      activity_type: activityTypes.COMPLETE,
      undo_action_id,
      task_type_detail: "REFILL",
      searchable_location: location,
    };

    async function updateNote() {
      if (note) {
        return createNote(
          taskId,
          { text: note, creator_id: userId },
          [],
          storeId
        );
      }
    }

    async function updatePickingTask(taskUpdate) {
      if (pick_quantity === 0) {
        return closeTask();
      }
      const handoff = await getInput("handoff", {});
      if (!handoff) {
        return;
      }
      const { uid: handoffUserId, gid: handoffGroupId } = handoff;
      taskUpdate.state = !handoffUserId
        ? taskStateOptions.UNASSIGNED
        : taskStateOptions.ASSIGNED;
      taskUpdate.assigned_user_id = handoffUserId || "";
      taskUpdate.assigned_team_id = handoffGroupId;
      taskUpdate.picked = true;
      taskUpdate.action_id = actionIdPicked;
      taskUpdate.activity_type = activityTypes.PICKED;

      // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
      taskUpdate.undo_action_id = "";

      if (
        handoffGroupId !== preparedForm.assigned_team_id ||
        (!handoffUserId && handoffGroupId === preparedForm.assigned_team_id)
      ) {
        trackerHelper.trackHandoffTask(taskId, handoffUserId, handoffGroupId);
        taskUpdate.activity_type = activityTypes.PICKED_AND_REASSIGNED_TEAM;
      } else if (
        handoffUserId &&
        handoffUserId !== preparedForm.assigned_user_id
      ) {
        trackerHelper.trackHandoffTask(taskId, handoffUserId, handoffGroupId);
        taskUpdate.activity_type = activityTypes.PICKED_AND_REASSIGNED_USER;
      }

      prepareToCelebrateWithAnAnimation();
      await updateTask(taskUpdate, taskId, "", storeId);
      refreshTask(taskId, taskUpdate, taskBeforeChanging.current);

      trackerHelper.trackPickAddonTask(
        { ...taskUpdate, taskId, pick_quantity },
        true,
        !(
          preparedForm.pickup_location === taskUpdate.pickup_location &&
          preparedForm.pickup_location_custom ===
            taskUpdate.pickup_location_custom &&
          preparedForm.pickup_location_department ===
            taskUpdate.pickup_location_department &&
          preparedForm.pickup_location_grid_code ===
            taskUpdate.pickup_location_grid_code
        ),
        preparedForm.requested_quantity !== taskUpdate.pick_quantity
      );
      const created_note_id = await updateNote();

      showToastWithUndo(<Trans>pickingNotification2String</Trans>, () =>
        undoCompleteWithChanges({
          created_note_id,
          action_id: taskUpdate.action_id,
        })
      );

      return true;
    }

    async function updateRefillTask(taskUpdate) {
      if (refilled_quantity === 0) {
        return closeTask();
      }

      taskUpdate.state = taskStateOptions.COMPLETED;
      taskUpdate.activity_type = activityTypes.COMPLETE;
      // onUpdate trigger takes care of deleting undo_action_id
      prepareToCelebrateWithAnAnimation();
      await updateTask(taskUpdate, taskId, "", storeId);
      refreshTask(taskId, taskUpdate, taskBeforeChanging.current);

      trackerHelper.trackRefillAddonTask({ ...taskUpdate, taskId }, true);
      trackerHelper.trackCompleteTask(
        { ...taskUpdate, taskId },
        { with_changes: true, pick_and_refill: !preparedForm.picked }
      );

      const created_note_id = await updateNote();
      showToastWithUndo(<Trans>completed2String</Trans>, () =>
        undoCompleteWithChanges({
          created_note_id,
          action_id: taskUpdate.action_id,
        })
      );

      return true;
    }

    if (navigationState.picking) {
      return updatePickingTask(taskUpdate);
    } else {
      return updateRefillTask(taskUpdate);
    }
  }, [
    refreshTask,
    preparedForm,
    userId,
    actionIdComplete,
    navigationState.picking,
    taskId,
    getInput,
    actionIdPicked,
    showToastWithUndo,
    closeTask,
    undoCompleteWithChanges,
    storeId,
  ]);

  return { call: completeWithChanges };
};
